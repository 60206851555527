import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Input, Table, Divider, List, Card, Avatar, Button, Modal, Tooltip } from "antd";
import * as firebase from "firebase";
import moment from "moment";
import request from "request";

import default_org from "../_assets/default_org.png";
import stripe_icon from "../_assets/stripe_icon_3.png";
import { runInThisContext } from "vm";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

// class App extends Component {
export class AllOrgs extends React.Component {
	state = {
		collapsed: false,
		isVisible: false,
		loading: false,
		isLoading: false,
		isStripeToken: false,
		isStripeLoading: false,
		orgName: "",
		orgEmail: "",
		ownerEmail: "",
		isOrgModalLoading: false,
		stripeToken: {},
		stripeHandler: {},
		orgsList: []
	};

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	showModal = () => {
		this.setState({
			isVisible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, isVisible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			isVisible: false,
			isStripeLoading: false,
			isOrgModalLoading: false,
			stripeToken: {},
			orgName: "",
			orgEmail: ""
		});
	};

	getCurrentUser() {
		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				// User is signed in.
				this.setState({ user: user });
				this.getOrgs(user.uid);
			} else {
				// No user is signed in.
				console.log("error, no user signed in!");
			}
		});
	}

	getOrgs(userId) {
		console.log("getting orgs for userId: ", userId);
		firebase
			.firestore()
			.collection("orgs")
			.orderBy("created", "desc")
			.limit(20)
			// .collection(`users/${userId}/myOrgs`)
			// .orderBy("added", "desc")
			// .limit(10)
			.get()
			.then(
				querySnapshot => {
					// const orgsList = this.state.orgsList;
					const orgsList = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						orgsList.push(record);
					});
					console.log("orgsList: ", orgsList);
					this.setState({
						orgsList: orgsList,
						isLoading: false
					});
					// automatically open create org modal if user has none
					if (orgsList.length < 1) this.showModal();
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	componentDidMount() {
		console.log("this.props ALL_ORGS: ", this.props);
		this.setState({ isLoading: true });

		const script = document.createElement("script");
		script.src = "https://checkout.stripe.com/checkout.js";
		script.async = true;
		document.body.appendChild(script);
		console.log("\n\nstripe key: ", process.env.REACT_APP_STRIPE_PUB_KEY, "\n\n");

		const stripeHandler = window.StripeCheckout.configure({
			key: process.env.REACT_APP_STRIPE_PUB_KEY,
			image: "https://stripe.com/img/documentation/checkout/marketplace.png",
			locale: "auto",
			token: token => {
				// You can access the token ID with `token.id`.
				// Get the token ID to your server-side code for use.
				console.log("token: ", token);
				this.setState({
					isStripeToken: true,
					stripeToken: token,
					isStripeLoading: false
				});
			},
			closed: () => {
				// alert("stripe checkout closed!");
				this.setState({ isStripeLoading: false });
			}
		});
		this.setState({ stripeHandler: stripeHandler });

		this.getCurrentUser();
	}

	openStripeHandler() {
		this.state.stripeHandler.open({
			name: "VantageSMS.com",
			description: "Create Your New Organization",
			// description: 'Add Credit Card',
			// description: descrip,
			// amount: 4900,
			zipCode: true,
			// panelLabel: "Subscribe",
			panelLabel: "Add Card",
			// billingAddress: true, //requires card holder to input their billing address
			// email: this.state.ownerEmail,
			email: this.state.user.email,
			// image: 'http://localhost:3000/imgs/logo_4_70h.png',
			// image: "http://localhost:3000/imgs/favicon_4.png",
			// image: "https://firebasestorage.googleapis.com/v0/b/sms-crm-dev.appspot.com/o/public%2Ffavicon_4.png?alt=media&token=3f4ee620-2d3d-4644-96d6-25e70dba5efa"
			// image: "https://firebasestorage.googleapis.com/v0/b/vantagesms-prod.appspot.com/o/public%2Fstripe_icon_3.png?alt=media&token=baef842f-886a-4c60-988a-9b68d6f711ae"
			image: stripe_icon
		});
	}

	createNewOrg(orgName, orgEmail) {
		console.log("creating new org");
		if (!orgName) {
			Modal.error({
				title: "Missing Organization Name",
				content: `You must enter an Organization Name.`
			});
			return;
		}
		if (!orgEmail) {
			Modal.error({
				title: "Missing Organization Email",
				content: `You must enter an Organization Email.`
			});
			return;
		}
		// if (!stripeToken || (!stripeToken.id && !stripeToken.email)) {
		// 	Modal.error({
		// 		title: "Missing Valid Credit Card",
		// 		content: `You must enter valid credit card information.`
		// 	});
		// 	return;
		// }
		// return;
		this.setState({ isOrgModalLoading: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/org.createOrg",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						//form: { orgName: orgName, orgEmail: orgEmail, paymentToken: stripeToken }
						form: { orgName: orgName, orgEmail: orgEmail }
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						const newOrgObj = JSON.parse(body);
						newOrgObj.key = newOrgObj.id;
						this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "", orgEmail: "", orgsList: [newOrgObj].concat(this.state.orgsList) });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	// setOrgStorage(orgName, orgId) {
	// 	window.localStorage.setItem("cuOrgName", orgName);
	// 	window.localStorage.setItem("cuOrgId", orgId);
	// }

	render() {
		// console.log("this.props: ", this.props);

		// <script src="https://checkout.stripe.com/checkout.js" />;

		const columns = [
			{
				title: "Org Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link
						to={`/orgs/${record.id}/broadcasts/history`}
						className="fs15 fw500"
						onClick={() => {
							// this.setOrgStorage(record.name, record.id);
							this.props.updateState("currentOrg", record);
						}}>
						<Avatar size={40} src={record.photo === "/imgs/default_org.png" ? default_org : record.photo} className="mr16" />
						{/* <b>{text}</b> */}
						{text}
					</Link>
				)
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLLL")}>
						<span>{moment(record.created).format("llll")}</span>
					</Tooltip>
				)
			},
			{
				title: "Ago",
				dataIndex: "created",
				key: "ago",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLLL")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Organizations</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>All Organizations</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px 12px 4px" }}>
						<span style={{}}>
							{/* <Button className="successBtn2" type="" icon="check" size={"default"} onClick={this.showModal}>
								Card Added
							</Button>
							&nbsp;&nbsp;&nbsp; */}
							<Button type="primary" icon="plus" size={"default"} onClick={this.showModal} className="mr8 mb8">
								New Organization
							</Button>
							{/* &nbsp;&nbsp;&nbsp;
							<Button className="successBtn" type="" icon="check" size={"default"} onClick={this.showModal}>
								Card Added
							</Button> */}
						</span>
						<Search placeholder="Search Organziations" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton className=" mb8" />
					</div>
				</div>
				<Modal
					title="New Organization"
					visible={this.state.isVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isOrgModalLoading} onClick={() => this.createNewOrg(this.state.orgName, this.state.orgEmail)}>
							Create Organization
						</Button>
					]}>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Name:
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="orgName" placeholder="Organization Name" style={{ maxWidth: "325px" }} value={this.state.orgName} onChange={e => this.handleChange(e)} />
					</p>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Email:
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="orgEmail" placeholder="Organization Email" style={{ maxWidth: "325px" }} value={this.state.orgEmail} onChange={e => this.handleChange(e)} />
					</p>
				</Modal>
				{/* <Modal
					title="New Organization"
					visible={this.state.isVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isOrgModalLoading} onClick={() => this.createNewOrg(this.state.orgName, this.state.stripeToken)}>
							Create Organization
						</Button>
					]}>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Organization Name:
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="orgName" placeholder="Organization Name" style={{ maxWidth: "325px" }} value={this.state.orgName} onChange={e => this.handleChange(e)} />
					</p>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Credit Card:
					</p>

					{!this.state.stripeToken.id && (
						<p style={{ marginBottom: "24px" }}>
							<Button
								icon="plus"
								loading={this.state.isStripeLoading}
								onClick={() => {
									this.setState({ isStripeLoading: true });
									this.openStripeHandler();
								}}>
								Add Credit Card
							</Button>
						</p>
					)}
					{this.state.stripeToken.id && (
						<div style={{ marginBottom: "24px" }}>
							{this.state.stripeToken.id && (
								<p>
									<span style={{ border: "1px solid #d9d9d9", borderRadius: "4px", padding: "4px 11px" }}>
										{this.state.stripeToken.card.brand} **** {this.state.stripeToken.card.last4} &nbsp;&middot;&nbsp; {this.state.stripeToken.card.exp_month.toString().length < 2 ? "0" : ""}
										{this.state.stripeToken.card.exp_month}/{this.state.stripeToken.card.exp_year.toString().substr(-2)} &nbsp;&middot;&nbsp; {this.state.stripeToken.card.address_zip}
									</span>
								</p>
							)}

							<Button className="successBtn mr8" style={{}} icon="check">
								Card Added
							</Button>
							<Button
								icon="close"
								loading={this.state.isStripeLoading}
								onClick={() => {
									this.setState({ isStripeLoading: true });
									this.openStripeHandler();
								}}>
								Change Credit Card
							</Button>
						</div>
					)}
					
					<Divider style={{ marginBottom: "12px" }} />
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal> */}
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={16} onClick={this.showModal}>
							New Organization
						</Button>
					</span>
					<Search placeholder="Search Organziations" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
					
				</div> */}
				{/* CARDS LIST of ORGS */}
				{/* {!this.state.isLoading && this.state.orgsList.length > 0 && (
					<List
						grid={{ gutter: 16, xs: 1, sm: 2, md: 3 }}
						dataSource={this.state.orgsList}
						loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
						renderItem={item => (
							<List.Item>
								<Link
									to={`/orgs/${item.id}/broadcasts/history`}
									onClick={() => {
										this.props.updateState("currentOrg", item);
									}}>
									<Card title={item.name} className="linkCard">
										<p className="taCenter">
											<Avatar size={80} src={item.photo === "/imgs/default_org.png" ? default_org : item.photo} />
										</p>
										<p className="taCenter mb0 fw500">{item.email}</p>
										<p className="taCenter pointer">
											<Tooltip title={moment(item.created).format("LLLL")}>
												<span>{moment(item.created).fromNow()}</span>
											</Tooltip>
										</p>
									</Card>
								</Link>
							</List.Item>
						)}
					/>
				)} */}

				{/* {!this.state.isLoading && this.state.orgsList.length < 1 && (
					<Card className="card1" title="No Organizations Found">
						<p>You have no Organizations associated with your account!</p>
						<p>You can create a new organization by clicking:</p>
						<p>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} className="mr8 mb8 successBtn">
								Create New Organization
							</Button>
						</p>
					</Card>
				)} */}

				{/* {this.state.isLoading && (
					<p className="taCenter" style={{ marginBottom: "0px", width: "100%" }}>
						<Icon type="loading" style={{ fontSize: "28px" }} />
					</p>
				)} */}

				{/* TABLE LIST of ORGS */}
				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.orgsList}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					onRow={record => {
						return {
							onClick: e => {
								console.log("table record: ", record);
								// this.setOrgStorage(record.name, record.id);
								this.props.updateState("currentOrg", record);
								this.props.history.push(`/orgs/${record.id}/broadcasts/history`);
							}
						};
					}}
				/>
			</Content>
		);
	}
}
