import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Dropdown, List, Card, Avatar, Input, Button, Modal, Tooltip, Select } from "antd";

import * as firebase from "firebase";
// import moment from "moment";
import moment from "moment-timezone";
import request from "request";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const Option = Select.Option;

// class App extends Component {
export class AllContactLists extends React.Component {
	state = {
		collapsed: false,
		isModalVisible: false,
		loading: false,
		isLoading: false,
		isModalLoading: false,
		pageSize: 15,
		contactLists: [],
		listCountryCode: "ca",
		listCountry: "Canada",
		timezone: moment.tz.guess(),
		listName: "",
		areaCode1: "",
		areaCode2: ""
	};

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	showModal = () => {
		this.setState({
			isModalVisible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, isModalVisible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			isModalVisible: false,
			listName: "",
			listCountryCode: "ca",
			timezone: moment.tz.guess(),
			areaCode1: "",
			areaCode2: ""
		});
	};

	getContactLists(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/contactLists`)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const contactLists = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						// if (!record.email) record.email = "sample@email.com";
						// console.log("record: ", record);
						contactLists.push(record);
					});
					console.log("contact Lists: ", contactLists);
					this.setState({
						contactLists: contactLists,
						isLoading: false
					});
					if (contactLists.length < 1) this.showModal();
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		this.getContactLists(orgId);
	}

	createNewContactList(orgId, listName, listCountry, timezone, areaCode1, areaCode2) {
		console.log("creating new contact list");
		if (!orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must an Organization Id.`
			});
			return;
		}
		if (!listName) {
			Modal.error({
				title: "Missing List Name",
				content: `You must enter a Contact List Name.`
			});
			return;
		}
		if (!listCountry) {
			Modal.error({
				title: "Missing List Country",
				content: `You must select a Country associated with this List.`
			});
			return;
		}
		if (!timezone) {
			Modal.error({
				title: "Missing List Timezone",
				content: `You must select a timezone associated with this List.`
			});
			return;
		}
		if (this.state.editPrefix1 && isNaN(this.state.editPrefix1)) {
			Modal.error({
				title: "Area Code 1 is invalid!",
				content: `Area Code 1 must be 3 digits (between 100 - 999).`
			});
			return;
		}
		if (this.state.editPrefix2 && isNaN(this.state.editPrefix2)) {
			Modal.error({
				title: "Area Code 2 is invalid!",
				content: `Area Code 2 must be 3 digits (between 100 - 999).`
			});
			return;
		}
		if (!areaCode1 && !areaCode2) {
			Modal.confirm({
				title: "Missing Area Code Preferences",
				// content: `Submitting phone number prefixes helps improve message deliverability.`,
				content: (
					<div>
						<p>Submitting phone number Area Codes preferences helps improve message deliverability.</p>
						<p>Area Codes preferences are not required, but they are recommended.</p>
					</div>
				),
				cancelText: "Skip this Step",
				okText: "Set Area Codes",
				onOk: () => {
					// alert("ok!");
					return;
				},
				onCancel: () => {
					// alert("cancel!");
					this.createNewContactListAPI(listName, listCountry, timezone, areaCode1, areaCode2);
				}
			});

			// return;
		} else {
			if (areaCode1 && (areaCode1 > 999 || areaCode1 < 100)) {
				Modal.error({
					title: "Invalid Area Code 1",
					content: `Area Code 1 must be 3 digits (between 100 - 999).`
				});
				return;
			}
			if (areaCode2 && (areaCode2 > 999 || areaCode2 < 100)) {
				Modal.error({
					title: "Invalid Area Code 2",
					content: `Area Code 2 must be 3 digits (between 100 - 999).`
				});
				return;
			}
			this.createNewContactListAPI(orgId, listName, listCountry, timezone, areaCode1, areaCode2);
		}
	}

	createNewContactListAPI(orgId, listName, listCountry, timezone, areaCode1, areaCode2) {
		// alert("create contact list api");
		this.setState({ isModalLoading: true });
		const requestForm = {
			orgId: orgId,
			listName: listName,
			listCountry: listCountry,
			timezone: timezone,
			// created: firebase.firestore.FieldValue.serverTimestamp()
			created: firebase.firestore.Timestamp.now()
		};
		if (areaCode1) requestForm.areaCode1 = areaCode1;
		if (areaCode2) requestForm.areaCode2 = areaCode2;
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.createList",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "" });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isModalLoading: false, isStripeLoading: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						const newListObj = JSON.parse(body);
						newListObj.key = newListObj.id;
						this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "", contactLists: [newListObj].concat(this.state.contactLists) });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	getCountryName(countryCode) {
		let countryName = "";
		switch (countryCode) {
			case "ca":
				countryName = "Canada";
				break;
			case "us":
				countryName = "United States";
				break;
			default:
				countryName = "Country Name Not Found";
				break;
		}
		return countryName;
	}

	handleChangeTZ(value) {
		console.log(`selected ${value}`);
		this.setState({ timezone: value });
	}

	handleBlur() {
		console.log("blur");
	}

	handleFocus() {
		console.log("focus");
	}

	render() {
		// console.log("this.props: ", this.props);

		const columns = [
			{
				title: "List Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link className="fs15 fw500" to={`/orgs/${record.orgId}/contactLists/${record.id}/contacts`}>
						{text}
					</Link>
				)
			},
			{
				title: "Total Contacts",
				dataIndex: "totalContacts",
				key: "totalContacts"
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("llll")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const countryListMenu = (
			<Menu
				onClick={e => {
					console.log(e);
					this.setState({ listCountryCode: e.key });
				}}>
				<Menu.Item key="ca">Canada</Menu.Item>
				{/* <Menu.Item key="us">United States</Menu.Item> */}
			</Menu>
		);

		const timezoneMenu = (
			// moment.tz.names();
			<Menu
				onClick={e => {
					console.log(e);
					this.setState({ listTimezone: e.key });
				}}
				style={{ maxHeight: "224px", overflowY: "auto" }}>
				{moment.tz.names().map((tz, index) => {
					// console.log("timezone: ", tz);
					// return <Menu.Item key={tz}>{tz}</Menu.Item>;
					return <Menu.Item key={tz}>{" (UTC" + moment.tz(tz).format("Z") + ") " + tz}</Menu.Item>;
				})}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>All</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>All Contact Lists</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px 12px 4px" }}>
						<span style={{}}>
							<Button type="primary" icon="plus" size={"default"} className="mr8 mb8" onClick={this.showModal}>
								New Contact List
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} className="mb8" enterButton />
					</div>
				</div>
				<Modal
					title="New Contact List"
					visible={this.state.isModalVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isModalLoading} onClick={() => this.createNewContactList(this.props.match.params.orgId, this.state.listName, this.state.listCountryCode, this.state.timezone, this.state.areaCode1, this.state.areaCode2)}>
							Create List
						</Button>
					]}>
					<p className="bold mb8">List Name:</p>
					<p className="mb24">
						<Input name="listName" placeholder="List Name" className="maxW mw300" value={this.state.listName} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">List Country:</p>
					<p className="tgrey mb8">Your text messages will be sent from phone numbers associated with this country. </p>
					<p className="mb24">
						<Dropdown overlay={countryListMenu} trigger={["click"]}>
							<Button className="maxW mw300">
								{this.getCountryName(this.state.listCountryCode)} <Icon type="down" />
							</Button>
						</Dropdown>
					</p>
					{/* <p className="mb24">
						<Input name="listCountry" placeholder="List Name" style={{ maxWidth: "325px" }} value={this.state.listCountry} onChange={e => this.handleChange(e)} />
					</p> */}
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">List Timezone:</p>
					<p className="tgrey mb8">Select the timezone of your contact list recipeints. This allows scheduled messages are received in their local time.</p>
					{/* <p className="mb24">
						<Dropdown overlay={timezoneMenu} trigger={["click"]}>
							<Button style={{ minWidth: "140px" }}>
								{this.state.timezone ? (
									this.state.timezone
								) : (
									<span>
										(UTC {moment.tz(moment.tz.guess()).format("Z")}) + {moment.tz.guess()}
									</span>
								)}{" "}
								<Icon type="down" />
							</Button>
						</Dropdown>
					</p> */}
					<div className="mb24">
						<Select showSearch className="maxW mw300" name="timezone" placeholder="Select a timezone" optionFilterProp="children" onChange={e => this.handleChangeTZ(e)} value={this.state.timezone} onFocus={e => this.handleFocus(e)} onBlur={e => this.handleBlur(e)} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
							{moment.tz.names().map((tz, index) => {
								// console.log("timezone: ", tz);
								return (
									<Option key={"timezone" + index} value={tz}>
										{" (UTC" + moment.tz(tz).format("Z") + ") " + tz}
									</Option>
								);
							})}
						</Select>
					</div>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">Area Code Preferences:</p>
					{/* <p className="tgrey mb8">The preferred area code of source numbers used to send outbound messages to your contact list. Try to pick one your contact list recipients will recognize.</p> */}
					<p className="tgrey mb8">Optional: To broadcast from phone numbers with a specifc or local area code (such as N-123-NNN-NNNN), enter only the area code numbers (123) below: </p>
					<p className="tgrey mb8">Area Code 1:</p>
					<p className="mb24">
						<Input type="number" name="areaCode1" placeholder="Area Code 1: 647" className="maxW mw300" value={this.state.areaCode1} onChange={e => this.handleChange(e)} min="100" max="999" />
					</p>
					<p className="tgrey mb8">Area Code 2:</p>
					<p className="mb24">
						<Input type="number" name="areaCode2" placeholder="Area Code 2: 416" className="maxW mw300" value={this.state.areaCode2} onChange={e => this.handleChange(e)} min="100" max="999" />
					</p>
					{/* <p>Optional: To broadcast from phone numbers with a specifc or local area code (such as X-123-XXX-XXXX), enter only the area code numbers (123) below: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p> */}
				</Modal>
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							New Contact List
						</Button>
					</span>
					<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
					
				</div> */}

				{/* {!this.state.isLoading && this.state.contactLists.length > 0 && (
					<List
						grid={{ gutter: 16, xs: 1, sm: 2, md: 3 }}
						dataSource={this.state.contactLists}
						loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
						renderItem={item => (
							<List.Item>
								<Link to={`/orgs/${item.orgId}/contactLists/${item.id}/contacts`}>
									<Card title={item.name} className="linkCard">
										<p className="taCenter mb0 fw500">{item.totalContacts} Contacts</p>
										<p className="taCenter pointer">
											<Tooltip title={moment(item.created).format("LLLL")}>
												<span>{moment(item.created).fromNow()}</span>
											</Tooltip>
										</p>
									</Card>
								</Link>
							</List.Item>
						)}
					/>
				)} */}

				{!this.state.isLoading && this.state.contactLists.length < 1 && (
					<Card className="card1" title="No Contact Lists Found">
						<p>You have no Contact Lists associated with your account!</p>
						<p>You can create a new contact list by clicking:</p>
						<p>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} className="mr8 mb8 successBtn">
								New Contact List
							</Button>
						</p>
					</Card>
				)}

				{/* {this.state.isLoading && (
					<p className="taCenter" style={{ marginBottom: "0px", width: "100%" }}>
						<Icon type="loading" style={{ fontSize: "28px" }} />
					</p>
				)} */}

				{!this.state.isLoading && this.state.contactLists.length > 0 && (
					<Table
						className="card1 bgWhite"
						columns={columns}
						dataSource={this.state.contactLists}
						loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
						pagination={{ pageSize: this.state.pageSize }}
						onRow={record => {
							return {
								onClick: e => {
									console.log("table record: ", record);
									this.props.history.push(`/orgs/${record.orgId}/contactLists/${record.id}/contacts`);
								}
							};
						}}
					/>
				)}
			</Content>
		);
	}
}
