import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Card, Row, Col, Tag, Input, Button, Modal, Tooltip } from "antd";
import * as firebase from "firebase";
import moment from "moment";

// import { AgentInfo } from "./agentInfo.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

// class App extends Component {
export class AllSamples extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		agentsList: []
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	getAgents(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/members`)
			.orderBy("added", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					// const agentsList = this.state.orgsList;
					const agentsList = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.added = doc.data().added.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						agentsList.push(record);
					});
					console.log("orgsList: ", agentsList);
					this.setState({
						agentsList: agentsList,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		this.getAgents(orgId);
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.getOrgId(this.props.location.pathname);

		const columns = [
			{
				title: "Role",
				dataIndex: "status",
				key: "status"
			},
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link to={`/orgs/${orgId}/agents/${record.id}`} className="fs15 fw500">
						{text}
					</Link>
				)
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "List Access",
				dataIndex: "contactLists",
				key: "contactLists"
			},
			{
				title: "Added",
				dataIndex: "added",
				key: "added",
				render: (text, record) => (
					<Tooltip title={moment(record.added).format("LLLL")}>
						<span>{moment(record.added).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const data = [
			{
				key: "1",
				name: "Jane Smith",
				email: "jane.smith@nuco.com",
				status: "owner",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				created: 1538778093
			},
			{
				key: "2",
				name: "John doe",
				email: "jdoe@exampleco.net",
				status: "admin",
				age: 42,
				address: "London No. 1 Lake Park",
				tags: ["loser"],
				created: 1538778125
			}
		];

		return (
			<Content>
				<Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Agents</Breadcrumb.Item>
					<Breadcrumb.Item>All</Breadcrumb.Item>
				</Breadcrumb>
				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>SMS Samples</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px" }}>
						<span style={{}}>
							<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
								Add Agent
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px", float: "right" }} enterButton />
					</div>
				</div>
				<Modal
					title="Add Agent"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Add Agent
						</Button>
					]}>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Agent Email:
					</p>
					<p>Enter the email of the person you would like to add to your organization.</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="agentEmail" placeholder="person@example.com" style={{ maxWidth: "325px" }} value={this.state.agentEmail} onChange={e => this.handleChange(e)} />
					</p>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Agent Status:
					</p>
					<p>
						Owner: Full access. <br />
						Admin: Full access without billing info access. <br />
						Rep: Only chat support access on selected lists.
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="agentStatus" placeholder="owner/admin/representative/?" style={{ maxWidth: "325px" }} value={this.state.agentStatus} onChange={e => this.handleChange(e)} />
					</p>
					<p className="bold" style={{ marginBottom: "8px" }}>
						List Access:
					</p>
					<p>Select the Contact Lists this agent can access.</p>
					<p style={{ marginBottom: "24px" }}>
						<Input name="agentListAccess" placeholder="Contact Lists" style={{ maxWidth: "325px" }} value={this.state.agentListAccess} onChange={e => this.handleChange(e)} />
					</p>
				</Modal>
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							Add Agent
						</Button>
					</span>
					<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
					
                </div> */}

				<div>
					<Row gutter={16}>
						<Col span={8}>
							<Card
								className="card1 mb12"
								title="Holiday Sale"
								borderd={false}
								actions={[
									<Tooltip title={"Copy Message"}>
										{/* <Icon type="copy" /> */}
										<Button type="default" icon="copy">
											Copy
										</Button>
									</Tooltip>,
									<Tooltip title={"Create Broadcast"}>
										{/* <Icon type="notification" /> */}
										<Button type="default" icon="notification">
											Broadcast
										</Button>
									</Tooltip>
								]}>
								<p className="mb0 ">Lorem ipsum dolor sit amet, dicat diceret maiestatis est ut. Denique copiosae laboramus vix ex. Alia omnium eam ea, ad his suscipit laboramus. Cu per noluisse legendos, quo doctus incorrupte adversarium at. Cu ubique pericula imperdiet per, eum in minim nostro.</p>
								<p className="mb0 mt12">
									<Tag color="blue">3 SMS Parts</Tag>
									{/* <Tag color="cyan">343 Characters</Tag> */}
									&middot;&nbsp;&nbsp;
									<Tag color="blue">Sale</Tag>
									<Tag color="blue">Holiday</Tag>
								</p>
								{/* <p className="mb0">
									<Tag color="blue">Sale</Tag>
									<Tag color="blue">Holiday</Tag>
								</p> */}
							</Card>
							<Card className="card1 mb12" title="Customer Appreciation" borderd={false}>
								<p className="mb0 ">No per liber tritani aperiri, cu est fierent pertinacia. Ex latine constituto intellegebat vis, mea ei erant sonet, ea per facilisi singulis. Agam efficiendi an pro. Laoreet posidonium suscipiantur et mea. Ex agam erant gloriatur mei, inani petentium mea ne. Postulant referrentur et sea. Elit ridens sed at, ut appetere consetetur liberavisse usu, ut volumus officiis usu.</p>
								<p className=" mt12">
									<Tag color="blue">3 SMS Parts</Tag>
									{/* <Tag color="cyan">343 Characters</Tag> */}
								</p>
								<p className="mb0">
									<Tag color="blue">Sale</Tag>
									<Tag color="blue">Thank You</Tag>
								</p>
							</Card>
						</Col>
						<Col span={8}>
							<Card className="card1 mb12" title="Season's Greetings" borderd={false}>
								<p className="mb0 ">No per liber tritani aperiri, cu est fierent pertinacia. Ex latine constituto intellegebat vis, mea ei erant sonet, ea per facilisi singulis. Agam efficiendi an pro. Laoreet posidonium suscipiantur et mea. Ex agam erant gloriatur mei, inani petentium mea ne. Postulant referrentur et sea. Elit ridens sed at, ut appetere consetetur liberavisse usu, ut volumus officiis usu.</p>
								<p className=" mt12">
									<Tag color="blue">3 SMS Parts</Tag>
									{/* <Tag color="cyan">343 Characters</Tag> */}
								</p>
								<p className="mb0">
									<Tag color="blue">Sale</Tag>
									<Tag color="blue">Holiday</Tag>
								</p>
							</Card>
							<Card className="card1 mb12" title="BOGO" borderd={false}>
								<p className="mb0 ">It helps to outline the visual elements of a document or presentation, eg typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and philosopher Cicero. Its words and letters have been changed by addition or removal, so to deliberately render its content nonsensical; it's not genuine, correct, or comprehensible Latin anymore.</p>
								<p className=" mt12">
									<Tag color="blue">3 SMS Parts</Tag>
									{/* <Tag color="cyan">343 Characters</Tag> */}
								</p>
								<p className="mb0">
									<Tag color="blue">BOGO</Tag>
								</p>
							</Card>
						</Col>
						<Col span={8}>
							<Card className="card1 mb12" title="Happy Halloween" borderd={false}>
								<p className="mb0 ">It helps to outline the visual elements of a document or presentation, eg typography, font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and philosopher Cicero. Its words and letters have been changed by addition or removal, so to deliberately render its content nonsensical; it's not genuine, correct, or comprehensible Latin anymore.</p>
								<p className=" mt12">
									<Tag color="blue">3 SMS Parts</Tag>
									{/* <Tag color="cyan">343 Characters</Tag> */}
								</p>
								<p className="mb0">
									<Tag color="blue">Holiday</Tag>
								</p>
							</Card>
						</Col>
					</Row>
				</div>

				{/* <Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.agentsList}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					onRow={record => {
						return {
							onClick: e => {
								// console.log("table record: ", record);
								this.props.history.push(`/orgs/${orgId}/agents/${record.id}`);
							}
						};
					}}
				/> */}

				{/* <Route exact path="/orgs/:orgId/agents/:agentId" component={AgentInfo} /> */}
			</Content>
		);
	}
}
