import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class ContactListMenu extends React.Component {
	render() {
		// console.log("ContactListMenu: this.props: ", this.props);
		const orgId = this.props.orgId;
		const listId = this.props.listId;

		return (
			<ul className={`ant-menu ant-menu-light ant-menu-root ant-menu-horizontal`}>
				<li className={`ant-menu-item ${this.props.selectedKey === "contacts" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/contactLists/${listId}/contacts`}>Contacts</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "import" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/contactLists/${listId}/import`}>Import</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "settings" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/contactLists/${listId}/settings`}>Settings</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "numbers" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/contactLists/${listId}/numbers`}>Numbers</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "capacity" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/contactLists/${listId}/capacity`}>Capacity</Link>
				</li>
			</ul>
			// <Menu onClick={this.handleClick} selectedKeys={[this.props.selectedKey]} mode="horizontal">
			// 	<Menu.Item key="contacts">
			// 		<Icon type="mail" />
			// 		<Link to={`/orgs/${orgId}/contactLists/${listId}/contacts`}>Contacts</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="import">
			// 		<Link to={`/orgs/${orgId}/contactLists/${listId}/import`}>Import</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="settings">
			// 		<Link to={`/orgs/${orgId}/contactLists/${listId}/settings`}>Settings</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="numbers">
			// 		<Link to={`/orgs/${orgId}/contactLists/${listId}/numbers`}>Numbers</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="capacity">
			// 		<Link to={`/orgs/${orgId}/contactLists/${listId}/capacity`}>Capacity</Link>
			// 	</Menu.Item>
			// </Menu>
		);
	}
}
