import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Divider, Avatar, Button, Tooltip, Icon, Spin, Input, Modal } from "antd";

import * as firebase from "firebase";
import moment from "moment";
import request from "request";

// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const Search = Input.Search;

// class App extends Component {
export class ChatsList extends React.Component {
	state = {
		isLoading: true,
		isSearchResultsVisible: false,
		searchText: "",
		chats: [],
		searchResults: [],
		isFirstLoaded: false,
		isLoadingMore: false,
		pageSize: 10,
		contactListsObj: {},
		realtimeListener: {},
		cursorDoc: "",
		isAllChatsLoaded: false,
		isAllowedToViewChats: true
	};

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.props.orgId;
		// set moment to use short forms with .fromNow()
		moment.updateLocale("en", {
			relativeTime: {
				future: "in %s",
				past: "%s ago",
				s: "seconds",
				ss: "%d sec",
				m: "1 min",
				mm: "%d min",
				h: "1 hr",
				hh: "%d hr",
				d: "1 day",
				dd: "%d days",
				M: "1 mo",
				MM: "%d mo",
				y: "1 yr",
				yy: "%d yr"
			}
		});
		this.getAgentRecord(orgId);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.currChatCol !== prevProps.currChatCol) {
			console.log("CHATS: NEW CHAT COLUMN: ", this.props.currChatCol);
			this.setState({
				isLoading: true,
				chats: [],
				isFirstLoaded: false
			});
			this.getChatsList(this.props.orgId);
		}
	}

	getListInfo(orgId, listId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						// console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						const contactListsObj = this.state.contactListsObj;
						contactListsObj[record.id] = record;
						this.setState({ contactListsObj: contactListsObj });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getAgentRecord(orgId) {
		// get authenticated userId
		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				// User is signed in.
				console.log("user token: ", user);
				firebase
					.firestore()
					.doc(`orgs/${orgId}/members/${user.uid}`)
					.get()
					.then(
						doc => {
							// check for agent record
							if (doc.exists) {
								// console.log("Document data:", doc.data());
								const record = doc.data();
								record.id = doc.id;
								console.log("agent record: ", record);
								this.setState({ agent: record }, () => {
									// get chat contacts & start realtime listener
									this.getChatsList(orgId);
									this.getRealtimeChatContacts(orgId);
								});
							} else {
								// doc.data() will be undefined in this case
								console.log("No org agent found with matching userId. Not allowed to view chats!");
								this.setState({ isLoading: false, isAllowedToViewChats: false });
								// get chat contacts & start realtime listener
								// this.getChatsList(orgId);
								// this.getRealtimeChatContacts(orgId);
							}
						},
						error => {
							console.log("firebase error: ", error);
							// this.setState({ isLoading: false });
						}
					);
			} else {
				// No user is signed in.
				console.log("get user token error: no user signed in");
			}
		});
		// .then(idToken => {

		// })
		// .catch(error => {
		// 	// Handle get user token error
		// 	console.log("get user token error: ", error);
		// 	// this.setState({ });
		// });
	}

	getChatsList(orgId) {
		let dbRef = firebase
			.firestore()
			.collection(`contacts`)
			.orderBy("lastSmsTime", "desc")
			.where("orgId", "==", orgId)
			.where("isOptedOut", "==", false)
			.where("isDeleted", "==", false)
			.limit(this.state.pageSize);
		const cursorDoc = this.state.cursorDoc;
		if (cursorDoc) {
			dbRef = dbRef.startAfter(cursorDoc);
			this.setState({ isLoadingMore: true });
		}

		// only get inbound sms msgs
		// if (this.props.currChatCol === "inbound") {
		// 	// dbRef = dbRef.where("lastSMSDirection", "==", "inbound");
		// 	dbRef = dbRef.where("lastSmsDirection", "==", "inbound");
		// }

		// search sms logs
		// if (this.props.currChatCol === "inbound") dbRef = dbRef.where("direction", "==", "inbound");

		dbRef.get().then(
			querySnapshot => {
				// const orgsList = this.state.orgsList;
				const records = [];
				let chats = this.state.chats;
				let isAllLoaded = false;
				querySnapshot.forEach(doc => {
					// console.log(`${doc.id} => ${doc.data()}`);
					const record = doc.data();
					record.id = doc.id;
					record.key = doc.id;
					// console.log("record: ", record);
					// record.created = doc.data().created.getTime();
					// record.updated = doc.data().updated.getTime();
					// if (!record.email) record.email = "sample@email.com";
					// console.log("record: ", record);

					// get contact list info if none exists
					if (!this.state.contactListsObj[record.id]) this.getListInfo(record.orgId, record.listId);

					// check list access before adding chat contact record
					if (this.state.agent.status === "rep") {
						if (this.state.agent.listAccess[record.listId]) {
							records.push(record);
						}
					} else if (this.state.agent.status === "owner" || this.state.agent.status === "admin") {
						records.push(record);
					}
				});
				console.log("smsLogs: ", records);
				// add additional records IF pagination cursor
				if (cursorDoc) {
					chats = chats.concat(records);
				} else {
					chats = records;
				}
				// console.log("records.length: ", records.length);
				// console.log("this.state.pageSize: ", this.state.pageSize);
				// set ALL loaded if records length is less than page size (meaning no additional records)
				if (records.length < this.state.pageSize) {
					isAllLoaded = true;
				}
				this.setState({
					chats: chats,
					isLoading: false,
					isFirstLoaded: true,
					cursorDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
					isLoadingMore: false,
					isAllChatsLoaded: isAllLoaded
				});
			},
			error => {
				console.log("firebase error: ", error);
				this.setState({ isLoading: false });
			}
		);
	}

	chatsConvoHeader(record) {
		return (
			<div className="">
				<span className={` ${record.lastSmsDirection === "inbound" ? "bold" : ""}`}>
					{/* {!record.isOptedOut && !record.isDeleted && (
						<Tooltip title="Subscribed">
							<Icon type="check" className="tgreen" />
						</Tooltip>
					)}
					{record.isOptedOut && !record.isDeleted && (
						<Tooltip title="Opted Out">
							<Icon type="stop" className="tgrey" />
						</Tooltip>
					)}
					{record.isDeleted && (
						<Tooltip title="Deleted">
							<Icon type="delete" className="tred" />
						</Tooltip>
					)}{" "} */}
					{record.firstName ? record.firstName : ""} {record.lastName ? " " + record.lastName : ""}
					{!record.firstName && !record.lastName ? record.international : ""}
				</span>{" "}
				<span className={`tgrey fwNormal`}>&middot; {this.state.contactListsObj[record.listId] ? this.state.contactListsObj[record.listId].name : "Loading.."}</span>{" "}
				{!record.lastSmsTime && (
					<span style={{ float: "right" }}>
						<Tooltip title={moment(record.created).format("LLLL")}>
							<span className="tgrey" style={{ fontWeight: "normal" }}>
								{moment(record.created).fromNow()}
							</span>
						</Tooltip>
					</span>
				)}
				{record.lastSmsTime && (
					<span style={{ float: "right" }}>
						<Tooltip title={moment(record.lastSmsTime).format("LLLL")}>
							<span className="tgrey" style={{ fontWeight: "normal" }}>
								{moment(record.lastSmsTime).fromNow()}
							</span>
						</Tooltip>
					</span>
				)}
			</div>
		);
	}

	getChatInitials(name) {
		if (!name) return;
		const splitName = name.toUpperCase().split(" ");
		// console.log("splitName: ", splitName);
		const initials = splitName[0].substring(0, 1) + splitName[1].substring(0, 1);
		// console.log("intials: ", initials);
		return initials;
	}

	customSpinner() {
		return <Spin spinning={this.state.isLoading} indicator={<Icon type="loading" className="fs24" />} />;
	}

	runSearch(orgId, searchText) {
		// alert("create contact list api");
		if (!orgId) {
			Modal.warning({
				title: "Missing Org Id",
				content: "Organization Id is required to search."
			});
			return;
		}
		if (!searchText) {
			Modal.warning({
				title: "Missing Search Text",
				content: "You didn't type anything into search!"
			});
			return;
		}
		this.setState({ isLoading: true });
		const requestForm = {
			orgId: orgId,
			query: searchText
			// lsitId: lsitId,
			// created: firebase.firestore.FieldValue.serverTimestamp()
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "search.contacts",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isLoading: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isLoading: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						// searchResults: [JSON.parse(body)].concat(this.state.contactLists)
						const searchResults = [];
						const reqResults = JSON.parse(body);
						console.log("reqResults.hits.hits: ", reqResults.hits.hits);
						for (let i = 0; i < reqResults.hits.hits.length; i++) {
							const record = reqResults.hits.hits[i]["_source"];
							searchResults.push(record);
							if (!this.state.contactListsObj[record.listId]) this.getListInfo(record.orgId, record.listId);
						}

						console.log("searchResults: ", searchResults);
						this.setState({ isLoading: false, searchResults: searchResults, isSearchResultsVisible: true });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	getRealtimeChatContacts(orgId) {
		if (this.state.isFirstLoaded) {
			return;
		}
		console.log("mounting realtime listener -- chat_contacts");
		let dbRef = firebase
			.firestore()
			.collection(`contacts`)
			.orderBy("lastSmsTime", "desc")
			.where("orgId", "==", orgId)
			.where("isOptedOut", "==", false)
			.where("isDeleted", "==", false)
			.limit(1);
		this.detatchChatContactsListener = dbRef.onSnapshot(
			querySnapshot => {
				const records = [];
				querySnapshot.forEach(doc => {
					const record = doc.data();
					record.id = doc.id;

					// check list access before adding chat contact record
					if (this.state.agent.status === "rep") {
						if (this.state.agent.listAccess[record.listId]) {
							records.push(record);
						}
					} else if (this.state.agent.status === "owner" || this.state.agent.status === "admin") {
						records.push(record);
					}

					// records.push(record);
				});

				// end function if no new records visible
				if (records.length === 0) return;

				console.log("contact records > realtime chat: ", records);
				const chats = this.state.chats;
				let isReplaced = false;

				// if new record visible, add or update chat contacts list
				for (let i = 0; i < chats.length; i++) {
					if (chats[i].id === records[0].id) {
						// console.log("matching existing chat: ", chats[i]);
						// console.log("replacing with chat: ", records[0]);
						// chats[i] = records[0].id;
						chats[i].lastSmsDirection = records[0].lastSmsDirection;
						chats[i].lastSmsText = records[0].lastSmsText;
						chats[i].lastSmsTime = records[0].lastSmsTime;
						isReplaced = true;
						break;
					}
				}
				if (!isReplaced) {
					chats.unshift(records[0]);
				}
				this.setState({ chats: chats });
			},
			error => {
				console.log("realtime listener error: ", error);
			}
		);
	}

	detachRealtimeListener() {
		if (this.detatchChatContactsListener) this.detatchChatContactsListener();
	}

	componentWillUnmount() {
		console.log("unmounting realtime listener -- chat_contacts");
		this.detachRealtimeListener();
		// set moment to use long forms with .fromNow()
		moment.updateLocale("en", {
			relativeTime: {
				future: "in %s",
				past: "%s ago",
				s: "a few seconds",
				ss: "%d seconds",
				m: "a minute",
				mm: "%d minutes",
				h: "an hour",
				hh: "%d hours",
				d: "a day",
				dd: "%d days",
				M: "a month",
				MM: "%d months",
				y: "a year",
				yy: "%d years"
			}
		});
	}

	render() {
		// console.log("ContactListMenu: this.props: ", this.props);
		const orgId = this.props.orgId;
		const currChatCol = this.props.currChatCol;
		const contactId = this.props.contactId;
		// const listId = this.props.listId;

		return (
			<div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
				{this.state.isLoading && (
					<div>
						<p className="taCenter mb0" style={{ padding: "40px 0px" }}>
							<Icon type="loading" style={{ fontSize: "28px" }} />
						</p>
					</div>
				)}

				{!this.state.isAllowedToViewChats && !this.state.isLoading && (
					<div>
						<p className="taCenter tred mb0" style={{ padding: "40px 0px" }}>
							{/* You do not have required permissions to view chats. */}
							{/* Required permissions to view chats not found. */}
							Missing required permissions to view chats.
						</p>
					</div>
				)}

				{this.state.isAllowedToViewChats && !this.state.isLoading && (
					<>
						{/* <Spin spinning={this.state.isLoading} indicator={<Icon type="loading" className="fs28" style={{ color: "inherit" }} />}> */}
						<p className="mb0 footer bt0" style={{ padding: "12px 12px", borderBottom: "1px solid #ccc" }}>
							<Search placeholder="Search Contacts by Name or Number" name="searchText" value={this.state.searchText} onChange={e => this.handleChange(e)} onSearch={searchText => this.runSearch(orgId, searchText)} enterButton />
						</p>
						{this.state.isSearchResultsVisible && !this.state.isLoading && (
							<p className="mb0 taCenter footer" style={{ padding: "12px 12px", borderTop: "0px", borderBottom: "1px solid #ccc" }}>
								<Button type="default" icon="close" onClick={() => this.setState({ isSearchResultsVisible: false, searchResults: [], searchText: "" })}>
									Clear Search Results
								</Button>
							</p>
						)}
						<List
							itemLayout="horizontal"
							dataSource={this.state.isSearchResultsVisible ? this.state.searchResults : this.state.chats}
							locale={{
								emptyText: (
									<div>
										<br />
										<p className="">No Chat Messages.</p>
										<p className="bold">Start a New Chat by searching for a Contact.</p>
										<br />
									</div>
								)
							}}
							// loading={this.state.isLoading}
							loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
							// loading={this.customSpinner()}
							renderItem={item => (
								<List.Item style={{ padding: 0, color: "#333" }} className={`${contactId && contactId === item.id ? "activeChat" : ""}`}>
									<Link to={`/orgs/${orgId}/chats/${currChatCol}/${item.id}/history`} className="pointer listLink" style={{ padding: 12, width: "100%", color: "inherit" }}>
										<List.Item.Meta title={this.chatsConvoHeader(item)} />
										{item.lastSmsText ? (
											<div className={`${item.lastSmsDirection === "inbound" ? "bold" : ""}`} style={{ maxHeight: "54px", overflow: "hidden" }}>
												{item.lastSmsDirection === "inbound" ? <Icon type="mail" theme="filled" className="tblue" /> : ""} {item.lastSmsText}
											</div>
										) : (
											<div className="tgrey">No chat history</div>
										)}
									</Link>
								</List.Item>
							)}
						/>
						{/* </Spin> */}
						{!this.state.isSearchResultsVisible && !this.state.isAllChatsLoaded && this.state.isFirstLoaded && (
							<div className="footer" style={{ padding: "12px", textAlign: "center" }}>
								<Button icon="arrow-down" onClick={() => this.getChatsList(orgId)} loading={this.state.isLoadingMore}>
									Load More
								</Button>
							</div>
						)}
						{!this.state.isSearchResultsVisible && this.state.isAllChatsLoaded && this.state.isFirstLoaded && (
							<div className="footer" style={{ padding: "12px", textAlign: "center" }}>
								<Button icon="check">All Chats Loaded</Button>
							</div>
						)}
					</>
				)}
			</div>
		);
	}
}
