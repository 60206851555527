import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Button, Card, Input, Modal, Divider } from "antd";

import * as firebase from "firebase";
import moment from "moment";
import request from "request";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class Optout extends React.Component {
	state = {
		isLoading: true,
		isLinkValid: true,
		// isLinkValid: false,
		contactInfo: {
			isOptedOut: false,
			// isOptedOut: true,
			// isDeleted: true
			isDeleted: false
		},
		linkObj: {},
		isOptOutModalVisible: false
	};

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	// static contextTypes = {
	// 	router: () => true // replace with PropTypes.object if you use them
	// };

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	console.log("menu.js next props: ", nextProps);
	// 	console.log("menu.js prevState: ", prevState);
	// 	return null;
	// }
	componentDidMount() {
		console.log("this.props 1 app.js: ", this.props);
		this.checkLink();
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log("this.props: ", this.props);
		// console.log("prevProps: ", prevProps);
	}

	checkLink() {
		console.log("checking opt out link");
		const urlObj = new URL(window.location.href);
		const linkId = urlObj.searchParams.get("linkId");
		if (!linkId) {
			console.log("no link id");
			this.setState({ isLinkValid: false, isLoading: false });
		} else {
			console.log("link id: ", linkId);
			request.post(
				{
					url: process.env.REACT_APP_CLOUD_FUNCS_URL + "checkOptOutLink",
					headers: {},
					form: { linkId: linkId }
				},
				(err, httpResponse, body) => {
					// if request error
					if (err) {
						console.log("http request error: ", err);
						return this.setState({ isLinkValid: false, isLoading: false, isUpdating: false });
					}
					// if request invalid
					if (httpResponse.statusCode === 400) {
						console.log("http request invalid: ", JSON.parse(body));
						return this.setState({ isLinkValid: false, isLoading: false, isUpdating: false });
					}
					// if request success
					console.log("http request success: ", JSON.parse(body));
					const reqResults = JSON.parse(body);
					if (!reqResults.isValid) {
						this.setState({ isLinkValid: false, isLoading: false });
					} else {
						this.setState({ isLinkValid: true, linkObj: reqResults, contactInfo: reqResults.contactInfo, isLoading: false, isUpdating: false });
					}
				}
			);
		}
	}

	optOutContact() {
		console.log("opting out contact");
		const urlObj = new URL(window.location.href);
		const linkId = urlObj.searchParams.get("linkId");
		if (!linkId) {
			console.log("no link id");
			this.setState({ isLinkValid: false, isLoading: false });
		} else {
			console.log("link id: ", linkId);
			const requestForm = {
				linkId: linkId,
				action: "optOut"
			};
			console.log("requestForm: ", requestForm);
			this.setState({ isUpdating: true });
			request.post(
				{
					url: process.env.REACT_APP_CLOUD_FUNCS_URL + "checkOptOutLink",
					headers: {},
					form: requestForm
				},
				(err, httpResponse, body) => {
					// if request error
					if (err) {
						console.log("http request error: ", err);
						return this.setState({ isUpdating: false });
					}
					// if request invalid
					if (httpResponse.statusCode === 400) {
						console.log("http request invalid: ", JSON.parse(body));
						return this.setState({ isUpdating: false });
					}
					// if request success
					console.log("http request success: ", JSON.parse(body));
					const reqResults = JSON.parse(body);
					if (!reqResults.isValid) {
						this.setState({ isLinkValid: false, isLoading: false });
					} else {
						this.setState({ isLinkValid: true, linkObj: reqResults, contactInfo: reqResults.contactInfo, isLoading: false, isUpdating: false, isOptOutModalVisible: false });
					}
				}
			);
		}
	}

	render() {
		// console.log("this.props: ", this.props);
		return (
			<div className="taCenter">
				<div className="taCenter" style={{ height: "64px", background: "#0b2cb3", color: "#fff", marginTop: "-64px", fontSize: "24px", lineHeight: "64px", fontWeight: 500 }}>
					VantageSMS
				</div>
				{/* <div className="" style={{ fontSize: "24px", marginBottom: "0px", marginTop: "24px", textShadow: "1px 1px 1px #fff" }}>
					<Icon type="safety" /> Opt Out Console
				</div> */}
				<div className="p24">
					{this.state.isLoading && (
						<Card className="card1" title="" style={{ maxWidth: 350, margin: "auto" }} bordered={false}>
							<p style={{ marginBottom: "0px" }}>
								<Icon type="loading" style={{ fontSize: "28px" }} />
								<br />
								Loading...
							</p>
						</Card>
					)}
					{!this.state.isLoading && !this.state.isLinkValid && (
						<Card className="card1 taCenter" title="" style={{ maxWidth: 350, margin: "auto" }} bordered={false}>
							<p className="taCenter fw500 fs16">
								Invalid Opt Out Link{" "}
								<span role="img" aria-label="emoji-scream">
									😱
								</span>
							</p>
							{/* <p className="taCenter tblue fw500">{this.state.email}</p> */}
							<p className="taCenter">The opt out link used to access this page is invalid or expired.</p>
							{/* <p className="taLeft" style={{ margin: "32px -24px -24px", padding: "10px", background: "#fafafa", borderTop: "1px solid #e8e8e8" }}>
								<Button icon="arrow-left" type="default" className="" onClick={() => this.setState({ isEmailLoginPage: false, isEmailSent: false })} loading={this.state.loading}>
									Change Email
								</Button>
							</p> */}
						</Card>
					)}
					{!this.state.isLoading && this.state.isLinkValid && (
						<Card title="Opt Out Console" className="cardTitleCenter cardBodyPB0 card1" bordered={false} style={{ maxWidth: 350, margin: "auto" }}>
							<div className="taLeft" style={{ margin: "-24px", marginBottom: "0px" }}>
								{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<p className="taCenter  p12 mb0">
										To opt out this person, <br />
										click the "Opt Out" button below.
									</p>
								)}
								{this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<p className="taCenter tgrey p12 mb0">
										This person has been opted out.
										<br />
										They will not receive any further communications from your organization.
									</p>
								)}
								{this.state.contactInfo.isDeleted && (
									<p className="taCenter tgrey p12 mb0">
										This person has been deleted from your contacts.
										<br />
										They will not receive any further communications from your organization.
									</p>
								)}
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Name</span> <br />
									{!this.state.contactInfo.firstName && !this.state.contactInfo.lastName && <span className="tgrey">Name not set</span>}
									{(this.state.contactInfo.firstName || this.state.contactInfo.lastName) && (
										<span className="">
											{this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
										</span>
									)}
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Mobile Number</span> <br />
									<span className="">{this.state.contactInfo.international}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Status</span> <br />
									<span className="">
										{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tgreen" type="check" /> Subscribed
											</span>
										)}
										{this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tgrey" type="stop" /> Opted Out
											</span>
										)}
										{this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tred" type="delete" /> Deleted
											</span>
										)}
									</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Contact List</span> <br />
									<span className="">{this.state.linkObj.contactListName}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Organization Name</span> <br />
									<span className="">{this.state.linkObj.orgName}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Created</span> <br />
									{/* <span className="">{moment(this.state.contactInfo.created).format("LLLL")}</span> */}
									<span className="">{moment(this.state.contactInfo.created["_seconds"] * 1000).format("LLLL")}</span>
								</p>
								<Divider className="m0" />
								{!this.state.contactInfo.lastSmsText && (
									<p className="p12 mb0">
										<span className="bold">Last Chat Message</span> <br />
										<span className="tgrey">No Chat History</span>
									</p>
								)}
								{this.state.contactInfo.lastSmsText && (
									<p className="p12 mb0">
										<span className="bold">Last Chat Message</span> <br />
										<span className="">{moment(this.state.contactInfo.lastSmsTime).format("LLLL")}</span> <br />
										<span className="">
											{this.state.contactInfo.direction === "inbound" ? "Inbound: " : "Outbound: "} <span className={`${this.state.contactInfo.direction === "inbound" ? "" : ""}`}>"{this.state.contactInfo.lastSmsText}"</span>
										</span>
									</p>
								)}

								{/* <Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Last Updated</span> <br />
									<span className="">{moment(this.state.contactInfo.updated).format("LLLL")}</span>
								</p> */}
							</div>
							<p className="taRight" style={{ margin: "0px -24px 0px", padding: "10px", background: "#fafafa", borderTop: "1px solid #ccc" }}>
								{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<Button icon="stop" type="primary" loading={this.state.loading} onClick={e => this.setState({ isOptOutModalVisible: true })}>
										Opt Out
									</Button>
								)}
								{this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<Button icon="check" type="default" className="successBtn" loading={this.state.loading}>
										Contact is Opted Out
									</Button>
								)}
								{this.state.contactInfo.isDeleted && (
									<Button icon="delete" type="danger" className="" loading={this.state.loading}>
										Contact is Deleted
									</Button>
								)}
							</p>
						</Card>
					)}
					<p className="" style={{ maxWidth: 350, margin: "auto", marginTop: 12, padding: "16px", borderRadius: 2 }}>
						{/* By using our service,
									<br />
									you agree to the following:
									<br /> */}
						<a className="" href="https://vantagesms.com/terms" target="_blank" rel="noopener noreferrer">
							Terms of Use
						</a>
						&nbsp;&middot;&nbsp;
						<a className="" href="https://vantagesms.com/use" target="_blank" rel="noopener noreferrer">
							Acceptable Use
						</a>
						&nbsp;&middot;&nbsp;
						<a className="" href="https://vantagesms.com/privacy" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
						<br />
						By using our service, you agree to the above terms.
					</p>
					<Modal
						className="taCenter"
						title="Opt Out Contact"
						visible={this.state.isOptOutModalVisible}
						onCancel={e => this.setState({ isOptOutModalVisible: false })}
						footer={[
							<Button key="back" icon="close" onClick={e => this.setState({ isOptOutModalVisible: false })}>
								Cancel
							</Button>,
							<Button key="submit" type="danger" icon="stop" loading={this.state.isUpdating} onClick={() => this.optOutContact()}>
								Yes, Opt Out
							</Button>
						]}>
						<p className="bold fs16">Are you sure you want to opt out this contact?</p>
						<div className="card1 mAuto p12 mb24 taLeft" style={{ maxWidth: "300px" }}>
							<p className="bold mb8">
								<Icon type="user" style={{}} /> &nbsp; {!this.state.contactInfo.firstName && !this.state.contactInfo.lastName ? "Name not set" : this.state.contactInfo.firstName + " " + this.state.contactInfo.lastName}
							</p>
							<p className="mb8">
								<Icon type="phone" style={{}} /> &nbsp; {this.state.contactInfo.international}
							</p>
							<p className="mb8">
								<Icon type="message" style={{}} /> &nbsp; {this.state.contactInfo.lastSmsText ? <span className={`${this.state.contactInfo.direction === "inbound" ? "bold" : ""}`}>"{this.state.contactInfo.lastSmsText}"</span> : <span className="tgrey">No chat history</span>}
							</p>
						</div>
						<p className="tgrey taLeft">Opting out this contact will prevent you from sending messages to this contact while they are opted out. </p>
						<p className="tgrey taLeft">You MUST opt out any contacts who ask to no longer receive messages from you.</p>
						{/* <p className="tgrey taLeft">To message this contact again after opt out, the contact must text START to any source number from their contact list.</p> */}
						{/* <p className="tgrey taLeft">To message this contact again after opt out, the contact must text START to their assigned source number: +1 647-699-4861. This number can be found under the info section for this contact.</p> */}
					</Modal>
				</div>
			</div>
		);
	}
}
