import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Row, Col, Tooltip, Card, Select } from "antd";
import * as firebase from "firebase";
import request from "request";
import moment from "moment";

import { BillingMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;
const Option = Select.Option;

// class App extends Component {
export class BillingNumbers extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		startDate: moment().format("YYYY-MM-DD"),
		endDate: moment().format("YYYY-MM-DD"),
		usageSms: 0,
		usageSourceNumbers: 0,

		contactLists: [],
		sourceNumbers: [],

		releaseType: "all",
		purchaseType: "all",

		selectedReleaseContactLists: [],
		selectedReleasePhoneSids: [],

		selectedPurchaseContactLists: [],
		selectedPurchaseManualContactList: [],
		selectedPurchaseManualNumbersTotal: 0
	};

	handleChange(e) {
		// console.log("e: ", e);
		// console.log(e.target.name, e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	changeDate(dateType, direction) {
		const currDate = moment(this.state[dateType], "YYYY-MM-DD");
		let newDate;
		if (direction === "back") {
			newDate = currDate.subtract(1, "day");
		} else {
			newDate = currDate.add(1, "day");
		}
		// this.setDateRows(this.state.totalSendDays, index, newDate.format("YYYY-MM-DD"));
		this.setState({
			[dateType]: newDate.format("YYYY-MM-DD")
		});
	}

	handleSelect(value, selectType) {
		console.log(`handleSelect: ${selectType}: ${value}`);
		switch (selectType) {
			// RELEASES
			case "releaseType":
				this.setState({ releaseType: value });
				break;
			case "releaseLists":
				this.setState({ selectedReleaseContactLists: value });
				break;
			case "releaseNumbers":
				this.setState({ selectedReleasePhoneSids: value });
				break;
			// PURCHASES
			case "purchaseType":
				this.setState({ purchaseType: value });
				break;
			case "purchaseLists":
				this.setState({ selectedPurchaseContactLists: value });
				break;
			case "purchaseManualList":
				this.setState({ selectedPurchaseManualContactList: value });
				break;
			default:
				break;
		}
	}

	updatePurchaseAmount(direction) {
		if (direction === "add") {
			let currAmount = this.state.selectedPurchaseManualNumbersTotal;
			currAmount += 1;
			if (currAmount > 100) currAmount = 100;
			this.setState({ selectedPurchaseManualNumbersTotal: currAmount });
		} else {
			let currAmount = this.state.selectedPurchaseManualNumbersTotal;
			currAmount -= 1;
			if (currAmount < 0) currAmount = 0;
			this.setState({ selectedPurchaseManualNumbersTotal: currAmount });
		}
	}

	componentDidMount() {
		const orgId = this.props.match.params.orgId;
		this.getContactLists(orgId);
		this.getSourceNumbers(orgId);
	}

	getContactLists(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/contactLists`)
			.orderBy("created", "desc")
			// .limit(20)
			.get()
			.then(
				querySnapshot => {
					// const agentsList = this.state.orgsList;
					const contactLists = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						// if (!record.email) record.email = "sample@email.com";
						// console.log("record: ", record);
						contactLists.push(record);
					});
					contactLists.sort((a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					});
					console.log("contactLists: ", contactLists);
					this.setState({
						contactLists: contactLists
						// isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getSourceNumbers(orgId) {
		firebase
			.firestore()
			.collection(`sourceNumbers`)
			.where("orgId", "==", orgId)
			// .orderBy("created", "desc")
			.where("isReleased", "==", false)
			// .limit(20)
			.get()
			.then(
				querySnapshot => {
					// const agentsList = this.state.orgsList;
					const sourceNumbers = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						// if (!record.email) record.email = "sample@email.com";
						// console.log("record: ", record);
						sourceNumbers.push(record);
					});
					sourceNumbers.sort((a, b) => {
						if (a.e164 < b.e164) return -1;
						if (a.e164 > b.e164) return 1;
						return 0;
					});
					console.log("sourceNumbers: ", sourceNumbers);
					this.setState({
						sourceNumbers: sourceNumbers
						// isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	releaseNumbers() {
		const orgId = this.props.match.params.orgId;
		const releaseType = this.state.releaseType;
		const releaseContactListIds = this.state.selectedReleaseContactLists;
		const releaseSourceNumberSids = this.state.selectedReleasePhoneSids;

		console.log("releasing source numbers!");
		if (!orgId) {
			Modal.error({
				title: "Missing Org Id"
				// content: `You must have an id for this agent.`
			});
			return;
		}
		if (!releaseType) {
			Modal.error({
				title: "Missing Release Type",
				content: `Release Type must be selected.`
			});
			return;
		}
		if (releaseType === "contactLists" && releaseContactListIds.length < 1) {
			Modal.error({
				title: "No Contact Lists Selected",
				content: `Must select at least one contact list when Release Type is "Contact List".`
			});
			return;
		}
		if (releaseType === "phoneIds" && releaseSourceNumberSids.length < 1) {
			Modal.error({
				title: "No Phone Ids Selected",
				content: `Must select at least one phone number when Release Type is "Phone Ids".`
			});
			return;
		}

		const requestForm = {
			orgId: orgId,
			releaseType: releaseType
		};
		if (releaseType === "contactLists") requestForm.contactListIds = releaseContactListIds;
		if (releaseType === "phoneIds") requestForm.sourceNumberIds = releaseSourceNumberSids;
		console.log("requestForm: ", requestForm);

		// return;
		this.setState({ isReleasing: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/admin.releaseNumbers",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						// form: requestForm
						// use { ..., body: dataObj, json:true } when you need to send json obj vs urlEncoded obj (which converts everything to strings!)
						body: requestForm,
						json: true
					},
					(err, httpResponse, body) => {
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isReleasing: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", body);
							return this.setState({ isReleasing: false });
						}
						// if request success
						console.log("http request success: ", body);
						const updatedAgentObj = body; // no need to use JSON.parse(body); when sending/receiving json
						Modal.success({
							title: "Successfully Released Source Numbers"
							// content: `Must select at least one contact list when Release Type is "Contact List".`
						});
						this.setState({ isReleasing: false });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingAgent: false });
			});
	}

	purchaseNumbers() {
		const orgId = this.props.match.params.orgId;
		const purchaseType = this.state.purchaseType;
		const purchaseContactListIds = this.state.selectedPurchaseContactLists;
		const purchaseManualAmount = this.state.selectedPurchaseManualNumbersTotal;
		const purchaseManualContactList = this.state.selectedPurchaseManualContactList;

		console.log("releasing source numbers!");
		if (!orgId) {
			Modal.error({
				title: "Missing Org Id"
				// content: `You must have an id for this agent.`
			});
			return;
		}
		if (!purchaseType) {
			Modal.error({
				title: "Missing Purchase Type",
				content: `Purchase Type must be selected.`
			});
			return;
		}
		if (purchaseType === "contactLists" && purchaseContactListIds.length < 1) {
			Modal.error({
				title: "No Contact Lists Selected",
				content: `Must select at least one contact list when Purchase Type is "Contact List".`
			});
			return;
		}
		if (purchaseType === "manual" && (purchaseManualAmount < 1 || purchaseManualAmount > 100)) {
			Modal.error({
				title: "Too many phone numbers selected for purchase",
				content: `Can only purchase between 1 and 100 new source numbers.`
			});
			return;
		}
		if (purchaseType === "manual" && !purchaseManualContactList) {
			Modal.error({
				title: "No contact List Selected",
				content: `You must select a contact list to assign the manually purchased source numbers to.`
			});
			return;
		}

		const requestForm = {
			orgId: orgId,
			purchaseType: purchaseType
		};
		if (purchaseType === "contactLists") requestForm.contactListIds = purchaseContactListIds;
		if (purchaseType === "manual") {
			requestForm.manualPurchasesAmount = purchaseManualAmount;
			requestForm.manualPurchasesListId = purchaseManualContactList;
		}
		console.log("requestForm: ", requestForm);

		// return;
		this.setState({ isPurchasing: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/admin.purchaseNumbers",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						// form: requestForm
						// use { ..., body: dataObj, json:true } when you need to send json obj vs urlEncoded obj (which converts everything to strings!)
						body: requestForm,
						json: true
					},
					(err, httpResponse, body) => {
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isPurchasing: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", body);
							return this.setState({ isPurchasing: false });
						}
						// if request success
						console.log("http request success: ", body);
						const updatedAgentObj = body; // no need to use JSON.parse(body); when sending/receiving json
						Modal.success({
							title: "Successfully Purchased Source Numbers"
							// content: `Must select at least one contact list when Release Type is "Contact List".`
						});
						this.setState({ isPurchasing: false });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingAgent: false });
			});
	}

	render() {
		// console.log("this.props: ", this.props);

		const columns = [
			{
				title: "Id",
				dataIndex: "id",
				key: "id",
				render: (text, record) => <Link to={`/orgs/${record.name}/contactLists/${record.id}/contacts`}>{text}</Link>
			},
			{
				title: "Amount",
				dataIndex: "amount",
				key: "amount"
			},
			{
				title: "Due Date",
				dataIndex: "dueDate",
				key: "dueDate"
			},
			{
				title: "Paid",
				dataIndex: "paidStatus",
				key: "paidStatus"
			},
			{
				title: "Download",
				dataIndex: "downloadUrl",
				key: "downloadUrl",
				render: (text, record) => (
					<Link to={`/orgs/${record.name}/contactLists/${record.id}/contacts`}>
						<Icon type="download" />
					</Link>
				)
			}
		];

		const data = [
			{
				key: "1",
				id: "..24kjhjkh",
				amount: "$127.06",
				paidStatus: "due",
				status: "subscribed",
				name: "Jane Smith",
				email: "2086",
				mobileNumber: "+16475552809",
				lastMessage: "hey, what time are you open to?",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				updated: 1538778093,
				dueDate: 1538778093
			},
			{
				key: "2",
				id: "..l546hjkhj",
				amount: "$189.34",
				paidStatus: "paid",
				name: "John Doe",
				email: "327",
				status: "optedOut",
				age: 42,
				mobileNumber: "+14165557835",
				address: "London No. 1 Lake Park",
				lastMessage: "Thanks for the info!",
				tags: ["loser"],
				updated: 1538778125,
				dueDate: 1538778125
			}
		];

		const orgId = this.props.match.params.orgId;

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Billing</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<b style={{ fontSize: "16px" }}>Billing</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<BillingMenu selectedKey="numbers" orgId={orgId} />
					</div>
				</div>

				{/* <div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>2087 Contacts</b>
					</div>
					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px" }}>
						<span style={{}}>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} style={{ marginRight: "8px" }}>
								Add Contact
							</Button>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal}>
								Import
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px", float: "right" }} enterButton />
					</div>
                </div> */}

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card
							className="card1 mb24 mAuto"
							title="Release Source Numbers"
							actions={[
								<Button className="mr12" type="danger" icon="minus" loading={this.state.isReleasing} onClick={e => this.releaseNumbers()}>
									Release Source Numbers
								</Button>
							]}>
							<p className="bold mb8">Release Type</p>

							{this.state.releaseType === "all" && <p className=" mb14">Release all source phone numbers for the entire organization.</p>}
							{this.state.releaseType === "contactLists" && <p className=" mb14">Release only source phone numbers for the selected contact lists.</p>}
							{this.state.releaseType === "phoneIds" && <p className=" mb14">Release only the selected source phone numbers.</p>}

							<div className="mb24">
								<Select defaultValue="all" className="maxW mw300" onChange={e => this.handleSelect(e, "releaseType")}>
									<Option value="all">All</Option>
									<Option value="contactLists">Contact Lists Ids</Option>
									<Option value="phoneIds">Phone Ids</Option>
								</Select>
							</div>
							{this.state.releaseType === "contactLists" && (
								<div className="">
									<Divider style={{ margin: "16px 0px" }} />
									<p className="bold mb8">Select Contact Lists</p>
									<p className=" mb14">The selected contact lists will have all their source numbers released.</p>
									<div className="mb24">
										<Select mode="multiple" placeholder="Select contact lists" value={this.state.selectedReleaseContactLists} className="maxW mw300" onChange={e => this.handleSelect(e, "releaseLists")}>
											{this.state.contactLists.map((list, index) => {
												return (
													<Option value={list.id} key={list.id}>
														{list.name} &middot; {list.totalContacts} Contacts
													</Option>
												);
											})}
										</Select>
									</div>
								</div>
							)}
							{this.state.releaseType === "phoneIds" && (
								<div className="">
									<Divider style={{ margin: "16px 0px" }} />
									<p className="bold mb8">Select Phone Ids</p>
									<p className=" mb14">The selected source numbers will be released.</p>
									<div className="mb24">
										<Select mode="multiple" placeholder="Select source numbers" value={this.state.selectedReleasePhoneSids} className="maxW mw300" onChange={e => this.handleSelect(e, "releaseNumbers")}>
											{this.state.sourceNumbers.map((phoneNum, index) => {
												return (
													<Option value={phoneNum.sid ? phoneNum.sid : phoneNum.e164} key={phoneNum.e164}>
														{phoneNum.international}
													</Option>
												);
											})}
										</Select>
									</div>
								</div>
							)}
						</Card>

						<Card className="card1" title="Current Broadcast Capacity" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>Current broadcast capacity (how long it will take to message each contact in this list). Additional contacts will require additional time.</p>
							<p>
								<b>N Days</b> to Complete Broadacst
							</p>
							<p className="bold">
								350 Contacts <span style={{ fontWeight: "normal" }}>per day</span>
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card
							className="card1 mb24 mAuto"
							title="Release Source Numbers"
							actions={[
								<Button className="mr12 " type="default" icon="plus" loading={this.state.isPurchasing} onClick={e => this.purchaseNumbers()}>
									Purchase Source Numbers
								</Button>
							]}>
							<p className="bold mb8">Purchase Type</p>

							{this.state.releaseType === "all" && <p className=" mb14">Purchase all required source phone numbers for the entire organization.</p>}
							{this.state.releaseType === "contactLists" && <p className=" mb14">Purchase only source phone numbers required for the selected contact lists.</p>}
							{this.state.releaseType === "manual" && <p className=" mb14">Purchase only the selected number of source phone numbers for a contact list.</p>}

							<div className="mb24">
								<Select defaultValue="all" className="maxW mw300" onChange={e => this.handleSelect(e, "purchaseType")}>
									<Option value="all">All</Option>
									<Option value="contactLists">Contact Lists Ids</Option>
									<Option value="manual">Manual</Option>
								</Select>
							</div>
							{this.state.purchaseType === "contactLists" && (
								<div className="">
									<Divider style={{ margin: "16px 0px" }} />
									<p className="bold mb8">Select Contact Lists</p>
									<p className=" mb14">The selected contact lists will have all required source numbers purchased.</p>
									<div className="mb24">
										<Select mode="multiple" placeholder="Select contact lists" value={this.state.selectedPurchaseContactLists} className="maxW mw300" onChange={e => this.handleSelect(e, "purchaseLists")}>
											{this.state.contactLists.map((list, index) => {
												return (
													<Option value={list.id} key={list.id}>
														{list.name} &middot; {list.totalContacts} Contacts
													</Option>
												);
											})}
										</Select>
									</div>
								</div>
							)}
							{this.state.purchaseType === "manual" && (
								<div className="">
									<Divider style={{ margin: "16px 0px" }} />
									<p className="bold mb8">Select Total Source Numbers to Purcahse</p>
									<p className=" mb14">Select the total amount of source numbers to purchase for a list.</p>
									<div className="mb24">
										<Button icon="minus" onClick={e => this.updatePurchaseAmount("subtract")} className="mr8" />
										<input type="number" name="selectedPurchaseManualNumbersTotal" value={this.state.selectedPurchaseManualNumbersTotal} onChange={e => this.handleChange(e)} min="0" max="100" style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} />
										<Button icon="plus" onClick={e => this.updatePurchaseAmount("add")} />
									</div>
									<Divider style={{ margin: "16px 0px" }} />
									<p className="bold mb8">Select Contact List</p>
									<p className=" mb14">The selected contact list will have the total amount of source numbers purchased assigned to it.</p>
									<div className="mb24">
										<Select placeholder="Select contact lists" value={this.state.selectedPurchaseManualContactList} className="maxW mw300" onChange={e => this.handleSelect(e, "purchaseManualList")}>
											{this.state.contactLists.map((list, index) => {
												return (
													<Option value={list.id} key={list.id}>
														{list.name} &middot; {list.totalContacts} Contacts
													</Option>
												);
											})}
										</Select>
									</div>
								</div>
							)}
						</Card>
						<Card className="card1" title="Capacity Calculator" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>Calculate how many days it will take to broadcast to this list:</p>
							<p>
								Total Contacts: <br />
								<Input type="number" placeholder="Total Contacts" style={{ maxWidth: "175px" }} />
							</p>
							<p>
								Total Source Numbers: <br />
								<Input type="number" placeholder="Total Source Numbers" style={{ maxWidth: "175px" }} />
							</p>
							<p>
								<b>N Days</b> to Complete Broadacst
							</p>
							<p>
								<b>NNNN Contacts</b> per day Broadcast Capacity
							</p>
						</Card>
					</Col>
				</Row>

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={data} /> */}
			</Content>
		);
	}
}
