import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Button, Card } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
class BottomNav extends React.Component {
	state = {
		collapsed: false
	};

	// static contextTypes = {
	// 	router: () => true // replace with PropTypes.object if you use them
	// };

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	console.log("menu.js next props: ", nextProps);
	// 	console.log("menu.js prevState: ", prevState);
	// 	return null;
	// }

	getMenuKey(pathname, menuList) {
		// console.log("getMenuKey: ", pathname, menuList);
		let menuListItem = "/other";
		for (let i = 0; i < menuList.length; i++) {
			if (pathname.indexOf(menuList[i]) !== -1) {
				console.log("menu item found: ", menuList[i], i);
				// return [i.toString()];
				// return [menuList[i]];
				menuListItem = menuList[i];
				break;
			}
		}
		return [menuListItem];
	}

	render() {
		// console.log("this.props: ", this.props);
		const personalMenuList = ["/allOrgs", "/myAccount"];
		// const orgMenuList = ["/allOrgs", "/samples", "/broadcasts", "/chats", "/contactLists", "/agents", "/history/", "/settings", "/billing"];
		const orgMenuList2 = ["/broadcasts", "/chats", "/contactLists", "/agents"];
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		return (
			<div className="bottomNav card1">
				{personalPages.indexOf(this.props.location.pathname) !== -1 && (
					<Menu selectedKeys={this.getMenuKey(this.props.location.pathname, personalMenuList)} mode="horizontal" style={{ height: 60, lineHeight: "22px", paddingTop: "8px", border: "0px" }}>
						<Menu.Item key="/allOrgs" className="taCenter" style={{ padding: "0px 0px 4px", width: "33.3%" }}>
							<Icon type="bank" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/allOrgs`} className="fs12">
								Organizations
							</Link>
						</Menu.Item>
						<Menu.Item key="/myAccount" className="taCenter" style={{ padding: "0px 0px 4px", width: "33.3%" }}>
							<Icon type="user" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/myAccount`} className="fs12">
								My Account
							</Link>
						</Menu.Item>
						<Menu.Item
							key="/other"
							className="taCenter"
							style={{ padding: "0px 0px 4px", width: "33.3%" }}
							onClick={() => {
								this.props.toggleSideMenu();
							}}>
							<Icon type="menu" style={{ fontSize: 18, margin: 0 }} />
							<div className="fs12">More</div>
						</Menu.Item>
					</Menu>
				)}
				{personalPages.indexOf(this.props.location.pathname) === -1 && (
					<Menu selectedKeys={this.getMenuKey(this.props.location.pathname, orgMenuList2)} mode="horizontal" style={{ height: 60, lineHeight: "22px", paddingTop: "8px", border: "0px" }}>
						<Menu.Item key="/broadcasts" className="taCenter" style={{ padding: "0px 0px 4px", width: "20%" }}>
							<Icon type="notification" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/orgs/${this.props.currentOrg.id}/broadcasts/history`} className="fs12">
								Broadcasts
							</Link>
						</Menu.Item>
						<Menu.Item key="/chats" className="taCenter" style={{ padding: "0px 0px 4px", width: "20%" }}>
							<Icon type="message" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/orgs/${this.props.currentOrg.id}/chats/inbound`} className="fs12">
								Chat
							</Link>
						</Menu.Item>
						<Menu.Item key="/contactLists" className="taCenter" style={{ padding: "0px 0px 4px", width: "20%" }}>
							<Icon type="solution" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/orgs/${this.props.currentOrg.id}/contactLists/all`} className="fs12">
								Contacts
							</Link>
						</Menu.Item>
						<Menu.Item key="/agents" className="taCenter" style={{ padding: "0px 0px 4px", width: "20%" }}>
							<Icon type="team" style={{ fontSize: 18, margin: 0 }} />
							<Link to={`/orgs/${this.props.currentOrg.id}/agents`} className="fs12">
								Agents
							</Link>
						</Menu.Item>
						<Menu.Item
							key="/other"
							className="taCenter"
							style={{ padding: "0px 0px 4px", width: "20%" }}
							onClick={() => {
								this.props.toggleSideMenu();
							}}>
							<Icon type="menu" style={{ fontSize: 18, margin: 0 }} />
							<div className="fs12">More</div>
						</Menu.Item>
					</Menu>
				)}
			</div>
		);
	}
}

export default withRouter(BottomNav);
