import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Button, Card } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class NoMatch extends React.Component {
	state = {
		collapsed: false
	};

	// static contextTypes = {
	// 	router: () => true // replace with PropTypes.object if you use them
	// };

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	console.log("menu.js next props: ", nextProps);
	// 	console.log("menu.js prevState: ", prevState);
	// 	return null;
	// }

	componentDidMount() {
		// redirect to all orgs if user navigates to root ('/') url WHILE authenticated
		if (this.props.location.pathname === "/") {
			this.props.history.push("/allOrgs");
		}
	}

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};
	goBack() {
		// this.context.router.goBack();
		this.props.history.goBack();
	}
	render() {
		// console.log("this.props: ", this.props);
		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "16px 0" }}>
					<Breadcrumb.Item>User</Breadcrumb.Item>
					<Breadcrumb.Item>Bill</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: 24, background: "#fff", minHeight: 360 }}>
					404 Page Not Found! :O
					<br />
					<br />
					<Button icon="arrow-left" onClick={() => this.goBack()}>
						Go Back!
					</Button>
				</div> */}

				<Card className="card1" title="Error 404 - Page Not Found!" style={{ maxWidth: 480, margin: "auto" }}>
					<p style={{ textAlign: "left" }}>
						<span style={{ fontSize: "32px" }}>👻</span> &nbsp; This page does not exist!
					</p>
					<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "32px" }}>
						<Button icon="arrow-left" style={{ marginRight: "12px" }} onClick={() => this.goBack()}>
							Go Back!
						</Button>
						<Button type="primary" icon="poweroff">
							Sign Out
						</Button>
					</p>
				</Card>
			</Content>
		);
	}
}
