import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Button, Card, Input, Modal } from "antd";
import * as firebase from "firebase";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class Login extends React.Component {
	state = {
		collapsed: false,
		currentUrl: "",
		isUserAuthenticated: false,
		isLoginLinkSent: false,
		email: "",
		password: "",
		isLoading: false,
		isPageLoaded: false,
		isEmailSent: false,
		isEmailLoginPageLoading: false,
		isEmailLoginPage: false,
		isPasswordLogin: false,
		isEmailLinkLogin: false,
		isPasswordBtnLoading: false
	};

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	// static contextTypes = {
	// 	router: () => true // replace with PropTypes.object if you use them
	// };

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	console.log("menu.js next props: ", nextProps);
	// 	console.log("menu.js prevState: ", prevState);
	// 	return null;
	// }
	componentDidMount() {
		console.log("this.props 1 app.js: ", this.props);
		// Confirm the link is a sign-in with email link.
		// if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
		// 	// Additional state parameters can also be passed via URL.
		// 	// This can be used to continue the user's intended action before triggering
		// 	// the sign-in operation.
		// 	// Get the email if available. This should be available if the user completes
		// 	// the flow on the same device where they started it.
		// 	var email = window.localStorage.getItem("emailForSignIn");
		// 	if (!email) {
		// 		// User opened the link on a different device. To prevent session fixation
		// 		// attacks, ask the user to provide the associated email again. For example:
		// 		email = window.prompt("Please provide your email for confirmation");
		// 	}
		// 	// The client SDK will parse the code from the link for you.
		// 	firebase
		// 		.auth()
		// 		.signInWithEmailLink(email, window.location.href)
		// 		.then(result => {
		// 			// Clear email from storage.
		// 			console.log("signed in with email link: ", result);
		// 			window.localStorage.removeItem("emailForSignIn");
		// 			this.setState({ isUserAuthenticated: true, user: result.user });

		// 			// You can access the new user via result.user
		// 			// Additional user info profile not available via:
		// 			// result.additionalUserInfo.profile == null
		// 			// You can check if the user is new or existing:
		// 			// result.additionalUserInfo.isNewUser
		// 		})
		// 		.catch(error => {
		// 			console.log("error signing in with email link: ", error.code);
		// 			// Some error occurred, you can inspect the code: error.code
		// 			// Common errors could be invalid email and invalid or expired OTPs.
		// 			// this.props.history.push("/");
		// 		});
		// }
		// firebase.auth().onAuthStateChanged(user => {
		// 	if (user) {
		// 		// User is signed in.
		// 		console.log("firebase user: ", user);
		// 		this.setState({ isUserAuthenticated: true, user: user, isPageLoaded: true });
		// 		window.localStorage.setItem("firebaseUser", user);
		// 	} else {
		// 		// No user is signed in.
		// 		console.log("NO USER AUTHENTICATED!");
		// 		this.setState({ isUserAuthenticated: false, user: user, isPageLoaded: true });
		// 		window.localStorage.removeItem("firebaseUser");
		// 		// this.props.history.push("/");
		// 	}
		// });
		if (localStorage.emailForSignIn) this.setState({ email: localStorage.emailForSignIn });
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log("this.props: ", this.props);
		// console.log("prevProps: ", prevProps);
		if (this.props.emailPromptAddress !== prevProps.emailPromptAddress) {
			console.log("props updated localStorage: ", localStorage.emailForSignIn);
			console.log("props updated emailPromptAddress: ", this.props.emailPromptAddress);
			// if (localStorage.emailForSignIn) this.setState({ email: localStorage.emailForSignIn });
			this.setState({ email: this.props.emailPromptAddress });
		}
	}

	emailLoginNext(e, email) {
		e.preventDefault();
		if (!email) {
			Modal.error({
				title: "Email cannot be empty!",
				content: "Please fill out your email to sign in."
			});
			return;
		}
		this.setState({ isEmailLoginPage: true, isEmailLoginPageLoading: true });
		this.getEmailSignInMethods(email);
	}

	getEmailSignInMethods(email) {
		firebase
			.auth()
			.fetchSignInMethodsForEmail(email)
			.then(signInMethods => {
				// This returns the same array as fetchProvidersForEmail but for email
				// provider identified by 'password' string, signInMethods would contain 2
				// different strings:
				// 'emailLink' if the user previously signed in with an email/link
				// 'password' if the user has a password.
				// A user could have both.
				console.log("signInMethods: ", signInMethods);
				let isPasswordLogin = false;
				let isEmailLinkLogin = false;
				if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
					// User can sign in with email/password.
					isPasswordLogin = true;
				}
				if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
					// User can sign in with email/link.
					isEmailLinkLogin = true;
				}
				this.setState({
					isPasswordLogin: isPasswordLogin,
					isEmailLinkLogin: isEmailLinkLogin,
					isEmailLoginPageLoading: false
				});
				//automatically send login link IF only login option for user inputted email
				if (isEmailLinkLogin && !isPasswordLogin) this.sendLoginLink(this.state.email);
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log("error getting email sign in methods: ", error.code);
			});
	}

	sendLoginLink(email) {
		if (!email) {
			Modal.error({
				title: "Email cannot be empty!",
				content: "Please fill out your email to sign in."
			});
			return;
		}
		console.log("location: ", window.location.href);
		const url = new URL(window.location.href);
		// alert("hostname: " + url.hostname);
		// alert("port: " + url.port);
		// alert("protocol: " + url.protocol);
		// alert("full path: " + url.protocol + "//" + url.hostname + ":" + url.port);
		let refUrl = url.protocol + "//" + url.hostname + (url.port === "443" ? "" : ":" + url.port) + "/allOrgs";
		// alert("refUrl: " + refUrl);
		// return;
		this.setState({ isEmailLoginPageLoading: true });
		const actionCodeSettings = {
			// URL you want to redirect back to. The domain (www.example.com) for this
			// URL must be whitelisted in the Firebase Console.
			// url: 'https://www.example.com/finishSignUp?cartId=1234',
			// url: "http://localhost:3000/allOrgs",
			url: refUrl,
			// This must be true.
			handleCodeInApp: true
			// iOS: {
			//   bundleId: 'com.example.ios'
			// },
			// android: {
			//   packageName: 'com.example.android',
			//   installApp: true,
			//   minimumVersion: '12'
			// }
		};
		firebase
			.auth()
			.sendSignInLinkToEmail(email, actionCodeSettings)
			.then(() => {
				console.log("sign in link sent successfully!");
				this.setState({ isLoginLinkSent: true });
				// The link was successfully sent. Inform the user.
				// Save the email locally so you don't need to ask the user for it again
				// if they open the link on the same device.
				window.localStorage.setItem("emailForSignIn", email);
				this.setState({ isEmailLoginPageLoading: false, isEmailSent: true });
				Modal.success({
					title: "Email login link has been sent!",
					content: "Click on the login link in your email to sign in."
				});
			})
			.catch(error => {
				// Some error occurred, you can inspect the code: error.code
				console.log("send login link error: ", error.code);
				if (error.code === "auth/invalid-email") {
					Modal.error({
						title: "Invalid Email!",
						content: "Please make sure your email is correct."
					});
					return;
				}
			});
	}

	googleSignIn() {
		this.setState({ isEmailLoginPageLoading: true });
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(result => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				var token = result.credential.accessToken;
				// The signed-in user info.
				var user = result.user;
				// ...
				console.log("success signing in with password! ", result.user);
				this.setState({ isEmailLoginPageLoading: false });
			})
			.catch(error => {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// The email of the user's account used.
				var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				var credential = error.credential;
				// ...
				console.log("error signing in with Google: ", error.code, error.message);
				this.setState({ isEmailLoginPageLoading: false });
			});
	}

	passwordSignIn(e, email, password) {
		e.preventDefault();
		this.setState({ isPasswordBtnLoading: true });
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(result => {
				console.log("success signing in with password! ", result.user);
				this.setState({ isEmailLoginPageLoading: false });
			})
			.catch(error => {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// ...
				console.log("error signing in with password: ", error.code, error.message);

				this.setState({ isPasswordBtnLoading: false });
				if (error.code === "auth/wrong-password") {
					Modal.error({
						title: "Wrong email or password!",
						content: "Please verify your email and password are correct."
					});
				}
				if (error.code === "auth/too-many-requests") {
					Modal.error({
						title: "Too many login attempts!",
						content: "Please try again later."
					});
				}
			});
	}

	render() {
		// console.log("this.props: ", this.props);
		return (
			<div className="taCenter">
				<div className="taCenter" style={{ height: "64px", background: "#0b2cb3", color: "#fff", marginTop: "-64px", fontSize: "24px", lineHeight: "64px", fontWeight: 500, boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)" }}>
					VantageSMS
				</div>
				{/* <div className="" style={{ fontSize: "24px", marginBottom: "0px", marginTop: "24px", textShadow: "1px 1px 1px #fff" }}>
					<Icon type="safety" /> VantageSMS &middot; Admin Console
					<Icon type="safety" /> Admin Console
				</div> */}
				<div className="p24">
					{!this.props.isUserAuthChecked && (
						<Card className="card1" title="" style={{ maxWidth: 350, margin: "auto" }} bordered={false}>
							<p style={{ marginBottom: "0px" }}>
								<Icon type="loading" style={{ fontSize: "28px" }} />
								<br />
								Loading...
							</p>
						</Card>
					)}
					{this.props.isUserAuthChecked && this.state.isEmailSent && (
						<Card className="card1 taCenter cardTitleCenter" title="Admin Console Login" style={{ maxWidth: 350, margin: "auto" }} bordered={false}>
							<p className="taCenter">A login link has been emailed to:</p>
							<p className="taCenter tblue fw500">{this.state.email}</p>
							<p className="taCenter">Click the link in your email to login.</p>
							<p className="taLeft" style={{ margin: "32px -24px -24px", padding: "10px", background: "#fafafa", borderTop: "1px solid #e8e8e8" }}>
								<Button icon="arrow-left" type="default" className="" onClick={() => this.setState({ isEmailLoginPage: false, isEmailSent: false })} loading={this.state.loading}>
									Change Email
								</Button>
							</p>
						</Card>
					)}
					{this.props.isUserAuthChecked && !this.state.isEmailSent && (
						<div className="">
							{!this.state.isEmailLoginPage && (
								<Card className="cardBodyPB0 card1 cardTitleCenter" bordered={false} title="Admin Console Login" style={{ maxWidth: 350, margin: "auto" }}>
									<p style={{ marginBottom: 32 }}>
										<Button className="fs15" icon="google" style={{ padding: "8px 20px", height: 40 }} onClick={() => this.googleSignIn()}>
											Sign in with Google
										</Button>
									</p>
									<p style={{ margin: "24px 0px", borderTop: "1px solid #e8e8e8", position: "relative" }}>
										<span style={{ position: "absolute", top: "-12px", background: "#fff", padding: "0px 8px", marginLeft: "-12px" }}>OR</span>
									</p>
									<p className="taCenter fs15">Enter your email to get started:</p>
									<form onSubmit={e => this.emailLoginNext(e, this.state.email)}>
										<p className="iaLight">
											{/* addonBefore="Http://" */}
											<Input
												type="email"
												id="username"
												addonBefore={<Icon type="mail" style={{}} />}
												placeholder="email@address.com"
												name="email"
												value={this.state.email}
												onChange={e => {
													this.handleChange(e);
												}}
											/>
											{/* <Input placeholder="email@address.com" prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)", marginRight: "12px" }} />} /> */}
										</p>
										<p className="taRight" style={{ margin: "32px -24px 0px", padding: "10px", background: "#fafafa", borderTop: "1px solid #e8e8e8" }}>
											<Button icon="arrow-right" type="primary" htmlType="submit" loading={this.state.loading}>
												Next
											</Button>
										</p>
									</form>
								</Card>
							)}
							{this.state.isEmailLoginPage && this.state.isEmailLoginPageLoading && (
								<Card className="cardTitleCenter card1" title={this.state.email} style={{ maxWidth: 350, margin: "auto" }} bordered={false}>
									<p className="taCenter">
										<Icon type="loading" style={{ fontSize: "28px" }} />
										{/* <br />
												Loading... */}
									</p>
								</Card>
							)}
							{this.state.isEmailLoginPage && !this.state.isEmailLoginPageLoading && (
								<Card className="cardTitleCenter cardBodyPB0 card1" bordered={false} title={this.state.email} style={{ maxWidth: 350, margin: "auto" }}>
									{/* {!this.state.isEmailSent && ( */}
									<div>
										{/* <p className="taCenter fs15">Send me a login link:</p> */}
										<p className="iaLight">
											{/* addonBefore="Http://" */}
											<Button type="primary" icon="mail" onClick={() => this.sendLoginLink(this.state.email)} loading={this.state.loading}>
												Email Login Link
											</Button>
											{/* <Input placeholder="email@address.com" prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)", marginRight: "12px" }} />} /> */}
										</p>
									</div>
									{/* )} */}

									{/* {this.state.isEmailSent && (
												<div>
													<p className="taCenter">A login link has been emailed to:</p>
													<p className="taCenter tblue fw500">{this.state.email}</p>
													<p className="taCenter">Click the link in your email to login.</p>
												</div>
											)} */}

									{this.state.isPasswordLogin && (
										<p style={{ margin: "24px 0px", borderTop: "1px solid #e8e8e8", position: "relative" }}>
											<span style={{ position: "absolute", top: "-12px", background: "#fff", padding: "0px 8px", marginLeft: "-12px" }}>OR</span>
										</p>
									)}
									{this.state.isPasswordLogin && (
										<div>
											<form onSubmit={e => this.passwordSignIn(e, this.state.email, this.state.password)}>
												<p className="taCenter fs15">Enter your password:</p>
												<p style={{ maxWidth: 240, margin: "auto", marginBottom: 32 }}>
													<Input
														type="password"
														id="password"
														addonBefore={<Icon type="lock" style={{}} />}
														placeholder="password"
														name="password"
														value={this.state.password}
														onChange={e => {
															this.handleChange(e);
														}}
													/>
												</p>
												<p className="taLeft" style={{ margin: "32px -24px 0px", padding: "10px", background: "#fafafa", borderTop: "1px solid #e8e8e8" }}>
													<Button icon="arrow-left" type="default" className="" onClick={() => this.setState({ isEmailLoginPage: false })} loading={this.state.loading}>
														Change Email
													</Button>
													<Button icon="arrow-right" type="primary" htmlType="submit" className="fright" loading={this.state.isPasswordBtnLoading}>
														Submit
													</Button>
												</p>
											</form>
										</div>
									)}

									{!this.state.isPasswordLogin && (
										<p className="taLeft" style={{ margin: "32px -24px 0px", padding: "10px", background: "#fafafa", borderTop: "1px solid #e8e8e8" }}>
											<Button icon="arrow-left" type="default" className="" onClick={() => this.setState({ isEmailLoginPage: false })} loading={this.state.loading}>
												Change Email
											</Button>
										</p>
									)}
								</Card>
							)}

							{/* <p className="fs15" style={{ maxWidth: 350, margin: "auto", marginTop: 20, padding: "16px", border: "1px solid #e8e8e8", borderRadius: 2, background: "#fff" }}>
									Don't have an account? &nbsp;{" "}
									<Link className="fw500" to="/signUp">
										Sign Up Here
									</Link>
								</p> */}
							<p className="" style={{ maxWidth: 350, margin: "auto", marginTop: 12, padding: "16px", borderRadius: 2 }}>
								{/* By using our service,
									<br />
									you agree to the following:
									<br /> */}
								<a className="" href="https://vantagesms.com/terms" target="_blank">
									Terms of Use
								</a>
								&nbsp;&middot;&nbsp;
								<a className="" href="https://vantagesms.com/use" target="_blank">
									Acceptable Use
								</a>
								&nbsp;&middot;&nbsp;
								<a className="" href="https://vantagesms.com/privacy" target="_blank">
									Privacy Policy
								</a>
								<br />
								By using our service, you agree to the above terms.
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}
