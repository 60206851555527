import React, { Component, createContext } from "react";

import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";
import * as firebase from "firebase";
import PropTypes from "prop-types";

// import logo from "./logo.svg";
import "./App.css";

import default_profile from "./_assets/default_profile.png";
import default_org from "./_assets/default_org.png";

import { Layout, Menu, Dropdown, Button, Breadcrumb, Icon, Divider, Avatar, Card, Input, Modal } from "antd";

//import page components
//personal pages
// import { GlobalContext } from "./_context/global.js";
// import { SharedSnackbarConsumer } from "./_context/global.js";
//PERSONAL PAGES
import SideMenu from "./_layout/menu.js";
import BottomNav from "./_layout/bottomNav.js";
import { Optout } from "./_layout/optout.js";
import { Login } from "./_layout/login.js";
import { SignOut } from "./personal/signout";
import { AllOrgs } from "./personal/allOrgs.js";
import { MyAccount } from "./personal/myAccount.js";
import { NoMatch } from "./_layout/noMatch.js";
//ORGANIZATION PAGES
//SMS Samples Pages
import { AllSamples } from "./samples/allSamples.js";
//Broadcast Pages
import { BroadcastHistory } from "./broadcasts/history.js";
import { NewBroadcast } from "./broadcasts/new.js";
import { BroadcastSettings } from "./broadcasts/settings.js";
import { BroadcastLogs } from "./broadcasts/logs.js";

//Broadcast Pages
import { ChatsHistory } from "./chats/history.js";
//Contact List Pages
import { AllContactLists } from "./contactLists/allLists.js";
import { ImportContacts } from "./contactLists/import.js";
import { ContactListSettings } from "./contactLists/settings.js";
import { ContactListCapacity } from "./contactLists/capacity.js";
import { ContactListNumbers } from "./contactLists/numbers.js";
import { Contacts } from "./contactLists/contacts.js";
//Agent Pages
import { AllAgents } from "./agents/allAgents.js";
//History Logs Pages
import { SmsHistory } from "./history/sms.js";
//Org Settings Pages
import { OrgSettings } from "./settings/orgSettings.js";
//Billing Pages
import { OrgBilling } from "./billing/orgBilling.js";
import { BillingUsage } from "./billing/usage.js";
import { BillingNumbers } from "./billing/numbers.js";
import { BillingLogs } from "./billing/logs.js";
import { BillingHistory } from "./billing/history.js";
import { BillingPlan } from "./billing/plan.js";
import { BillingCancel } from "./billing/cancel.js";
import { BillingPaymentInfo } from "./billing/paymentInfo.js";

// setup ant css components
const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// global context
// const { Provider, Consumer } = createContext();
// const { Provider, Consumer } = createContext("default value");

// class App extends Component {
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			currentUrl: "",
			isUserAuthenticated: false,
			isLoginLinkSent: false,
			email: "",
			user: {},
			isLoading: false,
			isUserAuthChecked: false,
			menuName: "BBBBBBBB",
			currentUser: {},
			currentOrg: {},
			isSideMenuOpen: false
			// updateValue: (key, value) => {
			// 	this.setState({ [key]: value });
			// }
			// currentUserEmail: "fake@email.com",
			// globals: {
			// 	menuName: "Moar Testing",
			// 	menuId: "GC_Id",
			// 	currentUser: { email: "aaaa" },
			// 	currentOrg: {},
			// 	updateGlobalState: newGlobalsObj => {
			// 		this.setState({ globals: newGlobalsObj });
			// 	}
			// }
		};
	}

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	static contextTypes = {
		router: PropTypes.object
	};

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};
	componentDidMount() {
		// console.log("this.props 1 app.js: ", this.props);
		// console.log("this.props.context 1 app.js: ", this.props.gcState);
		// console.log("this.props.context 1 app.js: ", this.props.updateUser);

		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				// User is signed in.
				console.log("firebase user: ", user);
				console.log("firebase user EMAIL: ", user.email);

				// this.setState({ isUserAuthenticated: true, user: user, isUserAuthChecked: true, currentUser: user, currentEmail: user.email });

				console.log("APP.JS ROUTER PROPS: ", this.props);
				// this.props.history.push('/allOrgs');

				// CHECK IF USER IS A SUPER ADMIN ACCOUNT
				firebase
					.firestore()
					.doc(`admins/${user.uid}`)
					.get()
					.then(
						doc => {
							if (doc.exists) {
								// user is super admin
								console.log("user is an admin");
								// set user authenticated status
								this.setState({ isUserAuthenticated: true, user: user, isUserAuthChecked: true, currentUser: user, currentEmail: user.email });
							} else {
								// doc.data() will be undefined in this case
								console.log("No admin record found for this user id!");
								// singing out user
								firebase
									.auth()
									.signOut()
									.then(() => {
										// set state to NOT authenticated
										this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
										// Sign-out successful.
										this.props.history.push("/");
										// clear localStorage
										localStorage.clear();
										// display invalid user error
										Modal.error({
											title: "Invalid User!",
											content: "Admin privileges required.",
											onOk() {
												// reload window
												window.location.reload();
											}
										});
										// reload window
										// window.location.reload();
									})
									.catch(error => {
										// An error happened.
										console.log("Error signing out of firebase: ", error);
									});
							}
						},
						error => {
							console.log("firebase error: ", error);
							// this.setState({ isLoading: false });
							// singing out user
							// firebase
							// 	.auth()
							// 	.signOut()
							// 	.then(() => {
							// 		console.log("signed out of firebase");
							// 		// set state to NOT authenticated
							// 		this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
							// 		// Sign-out successful.
							// 		this.props.history.push("/");
							// 		// clear localStorage
							// 		localStorage.clear();
							// 		// reload current page
							// 		// window.location.reload();
							// 	})
							// 	.catch(error => {
							// 		// An error happened.
							// 		console.log("Error signing out of firebase: ", error);
							// 	});
						}
					);
			} else {
				// No user is signed in.
				console.log("NO USER AUTHENTICATED!");
				// if viewing opt out page, ignore authentication reset
				if (this.props.location.pathname === "/optout") return;
				// this.setState({ isUserAuthenticated: false, user: {}, isUserAuthChecked: true });
				// window.localStorage.removeItem("firebaseUser");
				// this.props.history.push("/");
				// Confirm the link is a sign-in with email link.
				if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
					// Additional state parameters can also be passed via URL.
					// This can be used to continue the user's intended action before triggering
					// the sign-in operation.
					// Get the email if available. This should be available if the user completes
					// the flow on the same device where they started it.
					let email = window.localStorage.getItem("emailForSignIn");
					if (!email) {
						// User opened the link on a different device. To prevent session fixation
						// attacks, ask the user to provide the associated email again. For example:
						email = window.prompt("Please provide your email for confirmation");
					}
					this.setState({ currentEmail: email }); //pass prompted email addr to login component
					// The client SDK will parse the code from the link for you.
					firebase
						.auth()
						.signInWithEmailLink(email, window.location.href)
						.then(result => {
							// Clear email from storage.
							console.log("signed in with email link: ", result);
							// console.log("firebase result.user EMAAAAAAIIIIL: ", result.user.email);
							// window.localStorage.removeItem("emailForSignIn");

							// this.setState({ isUserAuthenticated: true, currentUser: result.user, currentEmail: result.user.email });
							const user = result.user;
							// this.setState({ isUserAuthenticated: true, user: user, isUserAuthChecked: true, currentUser: user, currentEmail: user.email });

							// CHECK IF USER IS A SUPER ADMIN ACCOUNT
							firebase
								.firestore()
								.doc(`admins/${user.uid}`)
								.get()
								.then(
									doc => {
										if (doc.exists) {
											// user is super admin
											console.log("user is an admin");
											// set user authenticated status
											this.setState({ isUserAuthenticated: true, user: user, isUserAuthChecked: true, currentUser: user, currentEmail: user.email });
										} else {
											// doc.data() will be undefined in this case
											console.log("No admin record found for this user id!");
											// singing out user
											firebase
												.auth()
												.signOut()
												.then(() => {
													// set state to NOT authenticated
													this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
													// Sign-out successful.
													this.props.history.push("/");
													// clear localStorage
													localStorage.clear();
													// display invalid user error
													Modal.error({
														title: "Invalid User!",
														content: "Admin privileges required.",
														onOk() {
															// reload window
															window.location.reload();
														}
													});
													// reload window
													// window.location.reload();
												})
												.catch(error => {
													// An error happened.
													console.log("Error signing out of firebase: ", error);
												});
										}
									},
									error => {
										console.log("firebase error: ", error);
										// this.setState({ isLoading: false });
										// singing out user
										// firebase
										// 	.auth()
										// 	.signOut()
										// 	.then(() => {
										// 		console.log("signed out of firebase");
										// 		// set state to NOT authenticated
										// 		this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
										// 		// Sign-out successful.
										// 		this.props.history.push("/");
										// 		// clear localStorage
										// 		localStorage.clear();
										// 		// reload current page
										// 		// window.location.reload();
										// 	})
										// 	.catch(error => {
										// 		// An error happened.
										// 		console.log("Error signing out of firebase: ", error);
										// 	});
									}
								);

							// show success modal on magic email link sign in
							// Modal.success({
							// 	title: "Login Link is Valid!",
							// 	content: `You are now logged in as: ${localStorage.emailForSignIn}`
							// });

							// You can access the new user via result.user
							// Additional user info profile not available via:
							// result.additionalUserInfo.profile == null
							// You can check if the user is new or existing:
							// result.additionalUserInfo.isNewUser
						})
						.catch(error => {
							console.log("error signing in with email link: ", error.code);
							if (error.code === "auth/invalid-action-code") {
								Modal.error({
									title: "Invalid Login Link!",
									content: (
										<div>
											<p>This login link may have been used already, or a newer link was emailed to you.</p>
											<p>Try sending yourself a new link and clicking only the *latest* login link.</p>
										</div>
									)
								});
							}
							if (error.code === "auth/invalid-email") {
								Modal.error({
									title: "Invalid Email!",
									content: "Please double check that your email is correct and try sending yourself a new login link."
								});
							}
							// Some error occurred, you can inspect the code: error.code
							// Common errors could be invalid email and invalid or expired OTPs.
							this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
							window.localStorage.setItem("emailForSignIn", email);
							// this.props.history.push("/");
							// window.localStorage.removeItem("cuName");
							// window.localStorage.removeItem("cuEmail");
							// window.localStorage.removeItem("cuUid");
							this.props.history.push("/");
						});
				} else {
					// this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
					// this.props.history.push("/");
					// // clear localStorage
					// localStorage.clear();
					firebase
						.auth()
						.signOut()
						.then(() => {
							// set state to NOT authenticated
							this.setState({ isUserAuthenticated: false, isUserAuthChecked: true });
							// Sign-out successful.
							this.props.history.push("/");
							// clear localStorage
							localStorage.clear();
						})
						.catch(error => {
							// An error happened.
							console.log("Error signing out of firebase: ", error);
						});
				}
			}
		});
	}
	componentDidUpdate() {
		// console.log("this.props 2 app.js: ", this.props);
	}

	updateState(key, value) {
		this.setState({
			[key]: value
		});
	}

	sendLoginLink(email) {
		this.setState({ isLoading: true });
		const actionCodeSettings = {
			// URL you want to redirect back to. The domain (www.example.com) for this
			// URL must be whitelisted in the Firebase Console.
			// url: 'https://www.example.com/finishSignUp?cartId=1234',
			url: "http://localhost:3000/allOrgs",
			// This must be true.
			handleCodeInApp: true
			// iOS: {
			//   bundleId: 'com.example.ios'
			// },
			// android: {
			//   packageName: 'com.example.android',
			//   installApp: true,
			//   minimumVersion: '12'
			// }
		};
		firebase
			.auth()
			.sendSignInLinkToEmail(email, actionCodeSettings)
			.then(() => {
				console.log("sign in link sent successfully!");
				this.setState({ isLoginLinkSent: true });
				// The link was successfully sent. Inform the user.
				// Save the email locally so you don't need to ask the user for it again
				// if they open the link on the same device.
				window.localStorage.setItem("emailForSignIn", email);
				this.setState({ isLoading: false });
				Modal.success({
					title: "Email login link has been sent!",
					content: "Click on the login link in your email to sign in."
				});
			})
			.catch(error => {
				// Some error occurred, you can inspect the code: error.code
				console.log("send login link error: ", error.code);
			});
	}

	toggleSideMenu() {
		const isSideMenuOpen = this.state.isSideMenuOpen;
		this.setState({ isSideMenuOpen: !isSideMenuOpen });
	}
	closeSideMenu() {
		this.setState({ isSideMenuOpen: false });
	}

	render() {
		const menu = (
			<Menu theme="dark">
				<Menu.Item style={{ padding: "12px" }} key="allOrgs">
					<Link to="/allOrgs">
						<Icon type="bank" /> &nbsp;
						<span>Organizations</span>
					</Link>
				</Menu.Item>
				<Menu.Item style={{ padding: "12px" }} key="myAccount">
					<Link to="/myAccount">
						<Icon type="user" /> &nbsp;
						<span>My Account</span>
					</Link>
				</Menu.Item>
				{/* <Divider style={{ margin: "6px 0px 4px 0px", background: "#253e56" }} /> */}
				<Menu.Divider style={{ margin: "6px 0px 4px 0px", background: "#253e56" }} />
				<Menu.Item style={{ padding: "12px" }} key="signOut">
					<Link to="/signout">
						<Icon type="poweroff" /> &nbsp;
						<span>Sign Out</span>
					</Link>
				</Menu.Item>
			</Menu>
		);
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		console.log("APP.JS this.props.location.pathname: ", this.props.location.pathname);
		return (
			// <Router>
			<Layout style={{ minHeight: "calc(100vh - 64px)", paddingTop: "64px" }}>
				{this.props.location.pathname === "/optout" && <Optout />}
				{!this.state.isUserAuthenticated && this.props.location.pathname !== "/optout" && <Login isUserAuthChecked={this.state.isUserAuthChecked} emailPromptAddress={this.state.currentEmail} />}
				{this.state.isUserAuthenticated && this.props.location.pathname !== "/optout" && (
					<div>
						{/* <GlobalContext.Provider gcState={this.state.menuName} /> */}
						{/* <Provider gcState={this.state.menuName} /> */}
						{/* <GlobalContext.Provider gcState={{ menuName: "AAASDFNASDFGNASDFGNJASDF" }} />
							<h1>{this.props.gcState.menuName}</h1>
							<GlobalContext.Consumer>{gcState => <h1>TEST 2: {gcState.menuName}</h1>}</GlobalContext.Consumer> */}

						<Header className="blue card1" style={{ background: "#0b2cb3", padding: "0px 22px", fontSize: "18px", zIndex: "1", position: "fixed", top: "0px", width: "100vw" }}>
							<Button
								icon="menu"
								type="primary"
								className="sideMenuIcon"
								onClick={() => {
									this.toggleSideMenu();
								}}
								style={{
									// position: "absolute",
									// bottom: "-86vh",
									// right: "1vh",
									fontSize: "20px",
									position: "fixed",
									// bottom: "24px",
									// right: "9px",
									bottom: "10px",
									right: "-5px",
									borderRadius: "100%",
									height: "48px",
									width: "48px",
									boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
								}}
							/>

							{personalPages.indexOf(this.props.location.pathname) !== -1 && (
								<span className="fw500 headerTitleText" style={{}}>
									{/* <Icon type="safety" />  */}
									Admin Console
								</span>
							)}
							{personalPages.indexOf(this.props.location.pathname) === -1 && (
								<div className="inlineBlock ellip headerTitleText" style={{}}>
									<span className="fw500 headerOrg">
										{/* <Icon type="safety" />  */}
										Admin Console
									</span>
									<span className="fw500 mobileHeaderOrg ellip">
										<Avatar src={this.state.currentOrg.photo === "/imgs/default_org.png" ? default_org : this.state.currentOrg.photo} style={{ marginRight: "8px" }} />
										{this.state.currentOrg.name}
									</span>
								</div>
							)}

							<Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
								<Button type="primary" className="headerProfileBtn">
									<Avatar src={this.state.currentUser.photoURL === "/imgs/default_profile.png" ? default_profile : this.state.currentUser.photoURL} size={32} />
									<span className="headerIcon"> &nbsp; {this.state.currentUser.displayName ? this.state.currentUser.displayName : this.state.currentUser.email}</span> <Icon type="down" />
									{/* <Avatar icon="user" size={32} /> &nbsp;
										<GlobalContext.Consumer>
											{gcState => {
												console.log("LAYOUT HEADER ", gcState);
												return <span>Harrison Krupnik {gcState.menuName}</span>;
											}}
										</GlobalContext.Consumer>{" "} */}
								</Button>
							</Dropdown>
						</Header>
						<Layout className="sideMenuLayout" style={{ marginBottom: "-10px" }}>
							{/* <GlobalContext.Consumer>{gcState => <SideMenu gcState={gcState} style={{}} />}</GlobalContext.Consumer> */}
							{/* <Consumer>{gcState => <SideMenu gcState={gcState} style={{}} />}</Consumer> */}
							{/* <GlobalContext.Provider gcState={this.state.globals}>
									<SideMenu style={{}} />
								</GlobalContext.Provider> */}
							{/* <Provider value={this.state.globals}>
									<Consumer>{gcState => <SideMenu gcState={gcState} />}</Consumer>
								</Provider> */}
							{/* <GlobalContext.Provider value={this.state.globals}>
									<GlobalContext.Consumer>{gcState => <SideMenu gcState={gcState} />}</GlobalContext.Consumer>
								</GlobalContext.Provider> */}
							{/* <Provider value={this.state.globals} />
								<Consumer>{gcState => <SideMenu gcState={gcState} />}</Consumer> */}

							{/* <SharedSnackbarConsumer>{message => <SideMenu message={message} />}</SharedSnackbarConsumer> */}
							<SideMenu isSideMenuOpen={this.state.isSideMenuOpen} closeSideMenu={() => this.closeSideMenu()} currentUser={this.state.currentUser} currentOrg={this.state.currentOrg} updateState={(key, value) => this.updateState(key, value)} />
							<Layout style={{ padding: "24px" }}>
								{/* <Header className="blue" style={{ background: "#0b2cb3", padding: "0px 12px", fontSize: "18px" }}>
						<Icon type="safety" /> Admin Console
					</Header> */}
								<Switch>
									{/* PASSING PROPS EXAMPLE */}
									{/* <Route path='/mybooks' render={routeProps => <Books {...routeProps} booksGetter={getMyBooks}/>} /> */}
									{/* PERSONAL PAGES */}
									{/* <Route exact path="/allOrgs" component={AllOrgs} />
										<Route
											path={`/allOrgs`}
											render={routeProps => (
												<AllOrgs
													{...routeProps}
													updateMainMenu={(childDataProperty, childDataValue) => {
														this.updateMainMenu(childDataProperty, childDataValue);
													}}
													testProp={"TEST_PROP"}
												/>
											)}
										/> */}
									<Route path="/allOrgs" render={props => <AllOrgs {...props} updateState={(key, value) => this.updateState(key, value)} />} />
									{/* <Route
											path="/allOrgs"
											render={routeProps => (
												<AllOrgs
													{...routeProps}
													updateMainMenu={(childDataProperty, childDataValue) => {
														this.updateMainMenu(childDataProperty, childDataValue);
													}}
												/>
											)}
										/> */}
									<Route exact path="/myAccount" component={MyAccount} />
									<Route exact path="/signout" component={SignOut} />
									{/* ORG PAGES */}
									{/* SMS SAMPLES PAGES */}
									<Route exact path="/orgs/:orgId/samples" component={AllSamples} />
									{/* BROADCAST PAGES */}
									<Route exact path="/orgs/:orgId/broadcasts/history" component={BroadcastHistory} />
									<Route exact path="/orgs/:orgId/broadcasts/new" component={NewBroadcast} />
									<Route exact path="/orgs/:orgId/broadcasts/details/:broadcastId/settings" component={BroadcastSettings} />
									<Route exact path="/orgs/:orgId/broadcasts/details/:broadcastId/logs" component={BroadcastLogs} />
									{/* CHAT PAGES */}

									<Route exact path="/orgs/:orgId/chats/:listTab" component={ChatsHistory} />
									<Route exact path="/orgs/:orgId/chats/:listTab/:contactId/:chatTab" component={ChatsHistory} />
									{/* <Route path="/orgs/:orgId/chats/inbound" component={ChatsHistory} />
									<Route path="/orgs/:orgId/chats/recent" component={ChatsHistory} />
									<Route path="/orgs/:orgId/chats/search" component={ChatsHistory} /> */}
									{/* CONTACT LIST PAGES */}
									<Redirect exact from="/orgs/:orgId/contactLists" to="/orgs/:orgId/contactLists/all" />
									<Route exact path="/orgs/:orgId/contactLists/all" component={AllContactLists} />
									<Route path="/orgs/:orgId/contactLists/:listId/contacts" component={Contacts} />
									<Route exact path="/orgs/:orgId/contactLists/:listId/import" component={ImportContacts} />
									<Route exact path="/orgs/:orgId/contactLists/:listId/settings" component={ContactListSettings} />
									<Route exact path="/orgs/:orgId/contactLists/:listId/capacity" component={ContactListCapacity} />
									<Route exact path="/orgs/:orgId/contactLists/:listId/numbers" component={ContactListNumbers} />
									{/* AGENT PAGES */}
									<Route path="/orgs/:orgId/agents" component={AllAgents} />
									{/* HISTORY LOGS PAGES */}
									<Route exact path="/orgs/:orgId/history/sms" component={SmsHistory} />
									{/* ORG SETTINGS PAGES */}
									<Route exact path="/orgs/:orgId/settings" component={OrgSettings} />
									{/* ORG BILLING PAGES */}
									<Route exact path="/orgs/:orgId/billing" component={OrgBilling} />
									<Route exact path="/orgs/:orgId/billing/usage" component={BillingUsage} />
									<Route exact path="/orgs/:orgId/billing/numbers" component={BillingNumbers} />
									<Route exact path="/orgs/:orgId/billing/logs" component={BillingLogs} />
									<Route exact path="/orgs/:orgId/billing/history" component={BillingHistory} />
									<Route exact path="/orgs/:orgId/billing/plan" component={BillingPlan} />
									<Route exact path="/orgs/:orgId/billing/paymentInfo" component={BillingPaymentInfo} />
									<Route exact path="/orgs/:orgId/billing/cancel" component={BillingCancel} />
									{/* NOT FOUND PAGES */}
									<Route component={NoMatch} />
								</Switch>

								{/* <Footer style={{ textAlign: "center" }}>VantageSMS &middot; ©2018 &middot; Admin Console</Footer> */}
							</Layout>
						</Layout>
						{/* <BottomNav toggleSideMenu={() => this.toggleSideMenu()} currentUser={this.state.currentUser} currentOrg={this.state.currentOrg} updateState={(key, value) => this.updateState(key, value)} /> */}
					</div>
				)}
			</Layout>
			// </Router>
		);
	}
}

// App.contextTypes = {
//     router: React.PropTypes.object
// }

// export default App;
export default withRouter(App);

// row_back Back
// wifi_tethering Broadcasts
// chat Chat
// layers Contact Lists
// group Agents
// storage History
// tune Settings
// card_membership Billing
// bookmark Feedback
// power_settings_new Sign Out
// VantageSMS.com
