import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, List, Card, Tag, Input, Button, Modal, Tooltip } from "antd";
import * as firebase from "firebase";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

// class App extends Component {
export class BroadcastHistory extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		broadcastsList: [],
		contactLists: {}
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	getDuration(durationArray) {
		let durationTime = 0;
		let durationText = "millisecond";
		// let plural = "";
		// console.log("durationArray:", durationArray);
		Object.keys(durationArray).forEach(key => {
			// console.log(key);          // the name of the current key.
			// console.log(durationArray[key]);   // the value of the current key.
			if (durationArray[key] > 0) {
				durationTime = durationArray[key];
				// durationText = key;
				durationText = key.charAt(0).toUpperCase() + key.slice(1);
			}
		});
		// if (durationArray.milliseconds > 0) {
		// 	durationTime = durationArray.milliseconds;
		// 	durationText = "Millisecond";
		// }
		// if (durationArray.seconds > 0) {
		// 	durationTime = durationArray.seconds;
		// 	durationText = "Second";
		// }
		// if (durationArray.minutes > 0) {
		// 	durationTime = durationArray.minutes;
		// 	durationText = "Minute";
		// }
		// if (durationArray.hours > 0) {
		// 	durationTime = durationArray.hours;
		// 	durationText = "Hour";
		// }
		// if (durationArray.days > 0) {
		// 	durationTime = durationArray.days;
		// 	durationText = "Day";
		// }
		// if (durationArray.months > 0) {
		// 	durationTime = durationArray.months;
		// 	durationText = "Month";
		// }
		// if (durationArray.years > 0) {
		// 	durationTime = durationArray.years;
		// 	durationText = "Year";
		// }
		if (durationTime === 1) durationText = durationText.substring(0, durationText.length - 1);
		return durationTime + " " + durationText;
	}

	async getBroadcastData() {
		console.log("getting broadcasts!");
		// get broadcasts
		const broadcasts = await this.getBroadcasts();

		// get unique contact list data
		const uniqueContactLists = [];
		broadcasts.forEach(broadcast => {
			if (broadcast.listIds) {
				for (let i = 0; i < broadcast.listIds.length; i++) {
					if (uniqueContactLists.indexOf(broadcast.listIds[i]) !== -1) continue;
					uniqueContactLists.push(broadcast.listIds[i]);
				}
			}
		});

		// get unique contact list objs from db
		const contactLists = {};
		for (let i = 0; i < uniqueContactLists.length; i++) {
			const listObj = await this.getContactList(uniqueContactLists[i]);
			if (listObj) contactLists[uniqueContactLists[i]] = listObj;
		}

		console.log("broadcasts: ", broadcasts);
		console.log("contactLists: ", contactLists);

		this.setState({
			broadcastsList: broadcasts,
			isLoading: false,
			contactLists: contactLists
		});
	}

	getBroadcasts() {
		return new Promise((resolve, reject) => {
			firebase
				.firestore()
				.collection(`orgs/${this.props.match.params.orgId}/broadcasts`)
				.orderBy("created", "desc")
				.limit(12)
				.get()
				.then(
					querySnapshot => {
						const broadcastsList = [];

						querySnapshot.forEach(doc => {
							// console.log(`${doc.id} => ${doc.data()}`);
							const record = doc.data();
							record.id = doc.id;
							record.key = doc.id;
							// record.created = doc.data().created.getTime();
							record.duration = doc.data().broadcastEndTime - doc.data().broadcastStartTime;
							// this.getContactList(record.listId);

							broadcastsList.push(record);
						});

						resolve(broadcastsList);
					},
					error => {
						console.log("firebase error: ", error);
						this.setState({ isLoading: false });
						reject();
					}
				);
		});
	}

	getContactList(listId) {
		return new Promise((resolve, reject) => {
			// const contactLists = this.state.contactLists;
			// if (contactLists[listId]) return resolve();
			firebase
				.firestore()
				.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
				.get()
				.then(
					doc => {
						if (doc.exists) {
							console.log("Document data:", doc.data());
							const record = doc.data();
							record.id = doc.id;
							record.created = doc.data().created.getTime();
							// contactLists[record.id] = record;
							// this.setState({ contactLists: contactLists });
							resolve(record);
						} else {
							// doc.data() will be undefined in this case
							console.log("No such document!");
							resolve(false);
						}
					},
					error => {
						console.log("firebase error: ", error);
						// this.setState({ isLoading: false });
						reject();
					}
				);
		});
	}

	getTotalRecipients(listIds) {
		let totalRecipients = 0;
		const contactLists = this.state.contactLists;
		for (let i = 0; i < listIds.length; i++) {
			if (contactLists[listIds[i]]) totalRecipients += contactLists[listIds[i]].totalContacts;
		}
		return totalRecipients;
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getBroadcastData();
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;

		const columns = [
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => {
					// <span>{moment(record.created).fromNow()}</span>
					return (
						<Tooltip title={moment(record.created).format("llll")}>
							<span>{moment(record.created).format("ll")}</span>
						</Tooltip>
					);

					// <Tooltip title={moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").format("lll")}>
					// 	<span>{moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").fromNow()}</span>
					// </Tooltip>
					// <Tooltip title={moment(record.sendDates[0], "YYYY-MM-DD").format("LLL")}>
					// 	<span>{moment(record.sendDates[0], "YYYY-MM-DD").fromNow()}</span>
					// </Tooltip>
					// <Tooltip title={moment(record.created).format("LLL")}>
					// 	<span>
					// 		{moment(record.created).fromNow()} {record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap}
					// 	</span>
					// </Tooltip>
				}
			},
			{
				title: "Contact Lists",
				dataIndex: "listId",
				key: "listId",
				// render: (text, record) => <span>{this.state.contactLists[record.listId] ? this.state.contactLists[record.listId].name : "Loading..."}</span>
				render: (text, record) => {
					if (record.listIds) {
						return <span>{record.listIds.length > 1 ? record.listIds.length + " Contact Lists" : this.state.contactLists[record.listIds[0]] ? this.state.contactLists[record.listIds[0]].name : "Loading..."}</span>;
					}
				}
			},
			{
				title: "Media",
				dataIndex: "mmsList",
				key: "mmsList",
				// render: (text, record) => <span>{this.state.contactLists[record.listId] ? this.state.contactLists[record.listId].name : "Loading..."}</span>
				render: (text, record) => <span>{record.mmsList ? <Icon type="picture" className="tblue2" /> : <Icon type="minus" className="tgrey" />}</span>
			},
			{
				title: "Name",
				dataIndex: "message",
				key: "message",
				render: (text, record) => (
					// <Link to={`/orgs/${this.props.match.params.orgId}/broadcasts/details/${record.id}/settings`}>
					<div style={{ maxWidth: "340px" }}>{text}</div>
					// </Link>
				)
			},

			{
				title: "Start Date",
				dataIndex: "sendDates",
				key: "startDate",
				render: (text, record) => {
					// <span>{moment(record.created).fromNow()}</span>
					if (record.sendDates) {
						return (
							<Tooltip title={moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").format("llll")}>
								<span>{moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").format("ll")}</span>
							</Tooltip>
						);
					}

					// <Tooltip title={moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").format("lll")}>
					// 	<span>{moment(record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap, "YYYY-MM-DD h:mm A").fromNow()}</span>
					// </Tooltip>
					// <Tooltip title={moment(record.sendDates[0], "YYYY-MM-DD").format("LLL")}>
					// 	<span>{moment(record.sendDates[0], "YYYY-MM-DD").fromNow()}</span>
					// </Tooltip>
					// <Tooltip title={moment(record.created).format("LLL")}>
					// 	<span>
					// 		{moment(record.created).fromNow()} {record.sendDates[0] + " " + record.dsh + ":" + record.dsm + " " + record.dsap}
					// 	</span>
					// </Tooltip>
				}
			},
			{
				title: "Duration",
				dataIndex: "duration",
				key: "duration",
				render: (text, record) => {
					if (record.listIds) {
						return (
							<span>
								{record.sendDates.length} Day{record.sendDates.length > 1 ? "s" : ""}
							</span>
						);
					}
					// <span>{this.getDuration(moment.duration(record.duration)._data)}</span>
				}

				// render: (text, record) => <span>{moment.duration(123123)}</span>
			},
			{
				title: "Recipeints",
				dataIndex: "totalRecipients",
				key: "totalRecipients",
				render: (text, record) => (
					<Link
						to={`/orgs/${orgId}/broadcasts/details/${record.id}/logs`}
						onClick={e => {
							// e.preventDefault();
							e.stopPropagation();
							this.props.history.push(`/orgs/${this.props.match.params.orgId}/broadcasts/details/${record.id}/logs`);
						}}>
						{record.totalRecipients ? record.totalRecipients : this.getTotalRecipients(record.listIds)} Contacts
					</Link>
					// <span>{record.totalRecipients ? record.totalRecipients : this.getTotalRecipients(record.listIds)} Contacts</span>
					// <span>{this.getDuration(moment.duration(record.duration)._data)}</span>
				)
				// onClick: e => {
				// 	console.log("cell record: ", record);
				// 	this.props.history.push(`/orgs/${this.props.match.params.orgId}/broadcasts/details/${record.id}/settings`);
				// }
			}
		];

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Broadcasts</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Broadcasts</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/${orgId}/broadcasts/history`}>History</Link>
							</Menu.Item>
							<Menu.Item key="plan">
								<Link to={`/orgs/${orgId}/broadcasts/new`}>New Broadcast</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div> */}
				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Broadcast History</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px 12px 4px" }}>
						<Link to={`/orgs/${orgId}/broadcasts/new`}>
							<Button type="primary" icon="plus" size={"default"} className="mr8 mb8" onClick={this.showModal}>
								New Broadcast
							</Button>
						</Link>
						<Search placeholder="Search Broadcasts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} className="mb8" enterButton />
					</div>
				</div>
				<Modal
					title="New Broadcast"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						// <Button key="submit" type="" className="successBtn2" icon="check" loading={this.state.loading} onClick={this.handleOk}>
						// 	Create Broadcast
						// </Button>,
						<Button key="submit" type="primary" className="" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create Broadcast
						</Button>,
						<Button key="submit" type="" className="successBtn" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create Broadcast
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							New Broadcast
						</Button>
					</span>
					<Search placeholder="Search Broadcasts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
				</div> */}
				{/* loading={this.state.isLoading}  */}

				{/* <List
					grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
					dataSource={this.state.broadcastsList}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					renderItem={item => (
						<List.Item>
							<Link to={`/orgs/${orgId}/broadcasts/details/${item.id}/settings`}>
								<Card title={moment(item.created).format("ddd, MMM DD, YYYY")} className="linkCard">
									<p className=" mb0 fw500 fs15">{this.state.contactLists[item.listId] ? this.state.contactLists[item.listId].name : "Loading..."}</p>
									<p className=" ">
										{item.totalRecipients} Recipients &middot; {this.getDuration(moment.duration(item.duration)._data)}
									</p>
									<Tooltip title={item.message}>
										<div className="  " style={{ height: 72, overflow: "hidden", border: "1px solid #e8e8e8", borderRadius: 4, padding: 4, position: "relative" }}>
											{item.message}
											<div style={{ position: "absolute", height: "24px", bottom: "0px", left: "0px", width: "100%", background: "linear-gradient(to top, #fff 0%,#fff 10%,#fff0 100%)" }} />
										</div>
									</Tooltip>
								</Card>
							</Link>
						</List.Item>
					)}
				/> */}

				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.broadcastsList}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					onRow={record => {
						return {
							onClick: e => {
								console.log("table record: ", record);
								this.props.history.push(`/orgs/${this.props.match.params.orgId}/broadcasts/details/${record.id}/settings`);
							}
						};
					}}
				/>
			</Content>
		);
	}
}
