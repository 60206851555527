import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Card, Button } from "antd";
import * as firebase from "firebase";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class SignOut extends React.Component {
	state = {
		collapsed: false
	};

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};
	goBack() {
		// this.context.router.goBack();
		this.props.history.goBack();
	}
	push() {
		this.props.history.push("/");
	}
	signOut() {
		firebase
			.auth()
			.signOut()
			.then(() => {
				// Sign-out successful.
				this.props.history.push("/");
				// clear localStorage
				localStorage.clear();
			})
			.catch(error => {
				// An error happened.
				console.log("Error signing out of firebase: ", error);
			});
	}
	render() {
		// console.log("this.props: ", this.props);
		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "16px 0" }}>
					<Breadcrumb.Item>User</Breadcrumb.Item>
					<Breadcrumb.Item>Bill</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: 24, background: "#fff", minHeight: 360 }}>
					Sign Out
				</div> */}

				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>Sign Out</Breadcrumb.Item>
				</Breadcrumb> */}

				<Card className="card1" title="Account Sign Out" style={{ maxWidth: 380, margin: "auto" }}>
					<p style={{ textAlign: "center", marginTop: "8px" }}>Are you sure you want to sign out?</p>
					<p style={{ textAlign: "center", marginBottom: "-8px", marginTop: "24px", marginBottom: "8px" }}>
						<Button icon="close" style={{ marginRight: "12px" }} onClick={() => this.goBack()}>
							Cancel
						</Button>
						<Button type="primary" icon="poweroff" onClick={() => this.signOut()}>
							Sign Out
						</Button>
					</p>
				</Card>
			</Content>
		);
	}
}
