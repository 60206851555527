import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Divider, Input, Button, Modal, Upload, Tooltip, Card, List, Collapse, Select } from "antd";
import * as firebase from "firebase";
// import moment from "moment";
import moment from "moment-timezone";
import request from "request";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { isDate } from "util";

const { Header, Content, Footer, Sider } = Layout;
const Option = Select.Option;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const { TextArea } = Input;
const Panel = Collapse.Panel;
// const timePickerFormat = "h:mm A";
const Dragger = Upload.Dragger;
const { Meta } = Card;

// class App extends Component {
export class NewBroadcast extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		isContactListsLoaded: false,
		isLoadingRequiredDays: false,
		isSchedulingBroadcast: false,
		broadcastsList: [],
		contactLists: [],
		contactListOptions: [],
		selectedContactLists: [],
		selectedContactListOptions: [],
		broadcastDates: [],
		currStep: 0,
		broadcastName: "",
		broadcastContactLists: [],
		broadcastMessage: "",
		messageCharCount: 23,
		availableMsgChars: 160,
		msgSmsParts: 1,
		is7Bit: true,
		isModalVariableVisible: false,
		isModalEmojiVisible: false,
		// isModalImageVisible: false,
		isModaPreviewVisible: false,
		dailyStartTime: moment("10:00", "h:mm A"),
		dailyEndTime: moment("19:00", "h:mm A"),
		dsh: "10", //broadcast daily start hour
		dsm: "00", //broadcast daily start minute
		dsap: "AM", //broadcast daily start am/pm
		deh: "7", //broadcast daily end hour
		dem: "00", //broadcast daily end minute
		deap: "PM", //broadcast daily end am/pm
		dsr: 10, //send 1 SMS every X minutes (minimum every 10 mins)
		totalSendDays: 1, //how many days reqd to complete broadcast
		broadcastSendDates: [],
		//img upload state
		previewVisible: false,
		previewImage: "",
		previewFile: {},
		mmsList: [],
		fileList: [],
		msgFilesList: []
	};

	handleImgPreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
			previewFile: file
		});
	};

	addEmoji = emoji => {
		console.log("selected emoji: ", emoji);
		let newBcastMsg = this.state.broadcastMessage + emoji.native;
		this.countMessageChars(newBcastMsg);
		this.setState({
			broadcastMessage: newBcastMsg,
			isModalEmojiVisible: false
		});
	};

	handleImgChange = ({ fileList }) => this.setState({ fileList });

	handleImgCancel = () => this.setState({ previewVisible: false });

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	totalImgMB(filesArray) {
		let totalBytes = 0;
		for (let i = 0; i < filesArray.length; i++) {
			totalBytes += filesArray[i].size;
		}
		return (totalBytes / 1024 / 1024).toFixed(2);
	}

	removeFile(index) {
		const msgFiles = this.state.msgFilesList;
		msgFiles.splice(index, 1);
		this.setState({ msgFilesList: msgFiles });
	}

	// getFileUrl(file, imgId, index) {
	// 	// const reader = new FileReader();
	// 	// reader.addEventListener(
	// 	// 	"load",
	// 	// 	() => {
	// 	// 		// return reader.result;
	// 	// 		const imgElement = document.getElementById(imgId);
	// 	// 		imgElement.src = reader.result;
	// 	// 		const msgFiles = this.state.msgFilesList;
	// 	// 		msgFiles[index].url = reader.result;
	// 	// 		this.setState({ msgFilesList: msgFiles });
	// 	// 	},
	// 	// 	false
	// 	// );
	// 	// reader.readAsDataURL(file);
	// 	const imgElement = document.getElementById(imgId);
	// 	imgElement.src = URL.createObjectURL(file);
	// 	const msgFiles = this.state.msgFilesList;
	// 	msgFiles[index].url = imgElement.src;
	// 	this.setState({ msgFilesList: msgFiles });
	// }

	handleOk = e => {
		console.log(e);
		// this.setState({ loading: true });
		// setTimeout(() => {
		// 	this.setState({ loading: false, visible: false, isModalVariableVisible: false, isModalEmojiVisible: false, isModalImageVisible: false });
		// }, 2000);
		this.setState({
			visible: false,
			isModalVariableVisible: false,
			isModalEmojiVisible: false,
			isModalImageVisible: false,
			isModaPreviewVisible: false
		});
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
			isModalVariableVisible: false,
			isModalEmojiVisible: false,
			isModalImageVisible: false,
			isModaPreviewVisible: false,
			fileList: []
		});
	};

	handleChange(e) {
		// console.log("e: ", e);
		// console.log(e.target.name, e.target.value);

		if (e.target.name === "broadcastMessage") this.countMessageChars(e.target.value);
		// if (e.target.name === "dsr") {
		// 	this.calcRequiredDays(e.target.value);
		// }
		if (e.target.name.startsWith("broadcastDate")) {
			console.log("updating bcast dates:");
			this.setDateRows(this.state.totalSendDays, e.target.name.split("broadcastDate")[1], e.target.value);
		}
		const targetName = e.target.name;
		this.setState(
			{
				[e.target.name]: e.target.value
			},
			() => {
				// dsh: "10", //broadcast daily start hour
				// dsm: "00", //broadcast daily start minute
				// dsap: "AM", //broadcast daily start am/pm
				// deh: "7", //broadcast daily end hour
				// dem: "00", //broadcast daily end minute
				// deap: "PM",
				if (targetName === "dsr" || targetName === "dsh" || targetName === "dsm" || targetName === "dsap" || targetName === "deh" || targetName === "dem" || targetName === "deap") {
					this.calcRequiredDays();
				}
			}
		);
	}

	handleStartTimeChange = (newMomentTime, timeString) => {
		console.log("handle start TimeChange: ", timeString);
		this.updateTimeValues(newMomentTime, timeString, "start");
	};
	handleEndTimeChange = (newMomentTime, timeString) => {
		console.log("handle end TimeChange: ", timeString);
		this.updateTimeValues(newMomentTime, timeString, "end");
	};
	updateTimeValues(newMomentTime, timeString, startOrEnd) {
		let hour = timeString.split(":");
		let min = hour[1].split(" ");
		let ampm = min[1];
		let timeType = startOrEnd === "start" ? "dailyStartTime" : "dailyEndTime";
		const updates = {};
		updates[timeType] = newMomentTime; //moment("10:00", "h:mm A");
		if (startOrEnd === "start") {
			updates.dsh = hour[0];
			updates.dsm = min[0];
			updates.dsap = ampm;
		} else {
			updates.deh = hour[0];
			updates.dem = min[0];
			updates.deap = ampm;
		}
		console.log("time updates: ", updates);
		this.setState(updates);
	}

	insertMsgVar(msgVar) {
		let bcastMsg = this.state.broadcastMessage;
		this.setState({
			broadcastMessage: bcastMsg + msgVar,
			isModalVariableVisible: false
		});
	}

	containsNonLatinCodepoints(string) {
		// https://stackoverflow.com/questions/147824/how-to-find-whether-a-particular-string-has-unicode-characters-esp-double-byte
		return /[^\u0000-\u00ff]/.test(string);
	}

	is7BitString(text) {
		// https://stackoverflow.com/questions/12673120/how-to-detect-non-gsm-7-bit-alphabet-characters-in-input-field
		// regex containing all chars of 7bit alphabet
		const regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
		//test if chat input text contains non 7bit characters
		let is7Bit = regexp.test(text);
		console.log("is7bit: ", is7Bit);
		// console.log("containsNonLatinCodepoints: ", this.containsNonLatinCodepoints(text));
		return is7Bit;
		// return regexp.test(text);
	}

	countMessageChars(broadcastMessage) {
		// messageCharCount: 23,
		// availableMsgChars: 160,
		// msgSmsParts: 1,
		const msgLength = broadcastMessage.length + 18; //18 characters === " Text STOP to end."
		const sms1PartLength = 160;
		const smsMultiPartLength = 153;
		const smsUnicode1PartLength = 70;
		const smsUnicodeMultiPartLength = 67;
		let availableChars = 160;
		let msgParts = 1;
		let is7Bit = this.is7BitString(broadcastMessage);
		// if 7bit
		if (is7Bit) {
			if (msgLength > sms1PartLength) {
				msgParts = Math.ceil(msgLength / smsMultiPartLength);
				availableChars = msgParts * smsMultiPartLength;
			}
			// else unicode msg
		} else {
			if (msgLength > smsUnicode1PartLength) {
				msgParts = Math.ceil(msgLength / smsUnicodeMultiPartLength);
				availableChars = msgParts * smsUnicodeMultiPartLength;
			} else {
				availableChars = smsUnicode1PartLength;
			}
		}
		// console.log("messageCharCount: ", msgLength);
		// console.log("availableMsgChars: ", availableChars);
		// console.log("msgSmsParts: ", msgParts);
		this.setState({
			is7Bit: is7Bit,
			messageCharCount: msgLength,
			availableMsgChars: availableChars,
			msgSmsParts: msgParts
		});
	}

	getDatesRequired(reqdSendDays) {
		let additionalDatesReqd = 0; //holds # of additional dates needed for broadcast

		const contactLists = this.state.contactLists;
		let mostTotalContacts = 0;
		for (let i = 0; i < contactLists.length; i++) {
			if (contactLists[i].isSelected && contactLists[i].totalContacts > mostTotalContacts) {
				mostTotalContacts = contactLists[i].totalContacts;
			}
		}

		// NEW CALC REQD DAYS BASED ON MAX CONTACTS PER SRC NUM
		// (to allow for adding additional contacts to bcast after it is scheduled, assume src nums have max contacts)
		// assume min 150 contacts (max contacts per source num)
		if (mostTotalContacts < 150) mostTotalContacts = 150;

		// calculate total source numbers in largest contact list
		const contactsPerSourceNum = 150; //curr hard coded (magic number) max contacts per source num
		let totalSourceNumbers = Math.ceil(mostTotalContacts / contactsPerSourceNum);

		// max outbound sms per day per source num
		const maxContactsMsgdPerDayPerSourceNum = 50; //curr hard coded (magic number) max source num outbound msgs per day

		// throttled sms send rate (1 sms every X minutes)
		const sendMinsPer1Sms = this.state.dsr; // 1 SMS every X Minutes
		const oneSmsEveryXSeconds = sendMinsPer1Sms * 60;

		// calc total daily send time
		const dailyStartTime = moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
		const dailyEndTime = moment((this.state.deap === "PM" ? parseInt(this.state.deh) + 12 : this.state.deh) + ":" + this.state.dem, "h:mm A");
		const dailySendDuration = moment.duration(dailyEndTime.diff(dailyStartTime));
		const dailyDurationSeconds = dailySendDuration.asSeconds();

		// calc send days reqd based on recipients per day per src number
		const maxRecipientsPerDayPerSourceNum = Math.floor(dailyDurationSeconds / oneSmsEveryXSeconds);
		const daysToFulfillMaxContactsPerSourceNum = Math.ceil(contactsPerSourceNum / maxRecipientsPerDayPerSourceNum);
		const maxContactsPerDayForAllSourceNums = maxRecipientsPerDayPerSourceNum * totalSourceNumbers;
		const minDaysToCompleteBroadcast = Math.ceil(mostTotalContacts / maxContactsPerDayForAllSourceNums);
		const requiredDaysToCompleteBroadcast = daysToFulfillMaxContactsPerSourceNum;

		return requiredDaysToCompleteBroadcast;

		// // throttled sms send rate (1 sms every X minutes)
		// const sendMinsPer1Sms = this.state.dsr; // 1 SMS every X Minutes

		// // calc total daily send time
		// let remainingBroadcastTimeToday = 0;
		// const dailyStartTime = moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
		// const dailyEndTime = moment((this.state.deap === "PM" ? parseInt(this.state.deh) + 12 : this.state.deh) + ":" + this.state.dem, "h:mm A");

		// // check for time remaining today (if bcast is scheduled to start today)
		// const todayStartTime = moment().add(20, "minutes");
		// const sendDurationToday = moment.duration(dailyEndTime.diff(todayStartTime));
		// remainingBroadcastTimeToday = sendDurationToday.asMinutes();
		// if (remainingBroadcastTimeToday < 0) remainingBroadcastTimeToday = 0; //can't have negative remaining send mins today
		// const fullSendDayDuration = moment.duration(dailyEndTime.diff(dailyStartTime));

		// // calc send days reqd based on recipients per day per src number
		// const recipientsPerSourceNum = Math.ceil(mostTotalContacts / totalSourceNumbers);
		// const reqdMinsToCompleteBroadcast = recipientsPerSourceNum * sendMinsPer1Sms;
		// const currSelectedBroadcastMins = remainingBroadcastTimeToday + fullSendDayDuration.asMinutes() * (reqdSendDays - 1);
		// let selectedVsReqdMinsDiff = reqdMinsToCompleteBroadcast - currSelectedBroadcastMins;

		// // display calc results
		// console.log("sendDurationToday: ", sendDurationToday.asMinutes());
		// console.log("remainingBroadcastTimeToday: ", remainingBroadcastTimeToday);
		// console.log("fullSendDayDuration: ", fullSendDayDuration.asMinutes());
		// console.log("reqdMinsToCompleteBroadcast: ", reqdMinsToCompleteBroadcast);
		// console.log("currSelectedBroadcastMins: ", currSelectedBroadcastMins);
		// console.log("selectedVsReqdMinsDiff: ", selectedVsReqdMinsDiff);
		// if (selectedVsReqdMinsDiff > 0) {
		// 	console.log("additional minutes reqd to send broadcast: ", selectedVsReqdMinsDiff);
		// 	additionalDatesReqd = Math.ceil(selectedVsReqdMinsDiff / fullSendDayDuration.asMinutes());
		// }
		// console.log("reqd additional send days: ", additionalDatesReqd);
		// return additionalDatesReqd;
	}

	calcRequiredDays(updatedSendMinsPer1SMS) {
		// sendActionDuration = 20; //20 min sending window - ie it takes 20 min to perform a (random time) send SMS action
		// totalSmsPerSendAction = 2; //how many SMS get sent per send action duration
		// maxContactsPerSourceNumber = 150; //maximum number of contacts a source number will send to (over the course of 1 broadcast)

		const contactLists = this.state.contactLists;
		let mostTotalContacts = 0;
		for (let i = 0; i < contactLists.length; i++) {
			if (contactLists[i].isSelected && contactLists[i].totalContacts > mostTotalContacts) {
				mostTotalContacts = contactLists[i].totalContacts;
			}
		}

		// NEW CALC REQD DAYS BASED ON MAX CONTACTS PER SRC NUM
		// (to allow for adding additional contacts to bcast after it is scheduled, assume src nums have max contacts)
		// assume min 150 contacts (max contacts per source num)
		if (mostTotalContacts < 150) mostTotalContacts = 150;

		// calculate total source numbers in largest contact list
		const contactsPerSourceNum = 150; //curr hard coded (magic number) max contacts per source num
		let totalSourceNumbers = Math.ceil(mostTotalContacts / contactsPerSourceNum);

		// max outbound sms per day per source num
		const maxContactsMsgdPerDayPerSourceNum = 50; //curr hard coded (magic number) max source num outbound msgs per day

		// throttled sms send rate (1 sms every X minutes)
		const sendMinsPer1Sms = updatedSendMinsPer1SMS ? updatedSendMinsPer1SMS : this.state.dsr; // 1 SMS every X Minutes
		const oneSmsEveryXSeconds = sendMinsPer1Sms * 60;

		// calc total daily send time
		const dailyStartTime = moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
		const dailyEndTime = moment((this.state.deap === "PM" ? parseInt(this.state.deh) + 12 : this.state.deh) + ":" + this.state.dem, "h:mm A");
		const dailySendDuration = moment.duration(dailyEndTime.diff(dailyStartTime));
		const dailyDurationSeconds = dailySendDuration.asSeconds();

		// calc send days reqd based on recipients per day per src number
		const maxRecipientsPerDayPerSourceNum = Math.floor(dailyDurationSeconds / oneSmsEveryXSeconds);
		const daysToFulfillMaxContactsPerSourceNum = Math.ceil(contactsPerSourceNum / maxRecipientsPerDayPerSourceNum);
		const maxContactsPerDayForAllSourceNums = maxRecipientsPerDayPerSourceNum * totalSourceNumbers;
		const minDaysToCompleteBroadcast = Math.ceil(mostTotalContacts / maxContactsPerDayForAllSourceNums);
		const requiredDaysToCompleteBroadcast = daysToFulfillMaxContactsPerSourceNum;

		// Min send days should always be at least 3, 4 is bcast starts today
		// if(requiredDaysToCompleteBroadcast < 3) requiredDaysToCompleteBroadcast = 3;

		// display calc results
		console.log("\n");
		console.log("mostTotalContacts: ", mostTotalContacts);
		console.log("totalSourceNumbers: ", totalSourceNumbers);
		console.log("dailySendDuration: ", dailySendDuration.asMinutes());
		console.log("sendMinsPer1Sms: ", sendMinsPer1Sms);
		console.log("maxRecipientsPerDayPerSourceNum: ", maxRecipientsPerDayPerSourceNum);
		console.log("maxContactsPerDayForAllSourceNums: ", maxContactsPerDayForAllSourceNums);
		console.log("minDaysToCompleteBroadcast: ", minDaysToCompleteBroadcast);
		console.log("requiredDaysToCompleteBroadcast: ", requiredDaysToCompleteBroadcast);
		console.log("maxContactsPerBroadcast: ", requiredDaysToCompleteBroadcast * maxContactsPerDayForAllSourceNums);
		console.log("\n");

		this.setState({
			totalSendDays: requiredDaysToCompleteBroadcast
		});

		this.setDateRows(requiredDaysToCompleteBroadcast);

		return requiredDaysToCompleteBroadcast;
	}

	async changeStep(currStep, direction) {
		let nextStep = 0 + currStep;
		if (direction === "forward") {
			nextStep += 1;
		} else {
			nextStep -= 1;
			this.setState({ currStep: nextStep });
			return;
		}
		// error checking
		// step 1 - select contact list(s)
		if (currStep === 0) {
			let isOneListSelected = false;
			for (let i = 0; i < this.state.contactLists.length; i++) {
				if (this.state.contactLists[i].isSelected) {
					isOneListSelected = true;
					break;
				}
			}
			if (!isOneListSelected) {
				Modal.error({
					title: "Must select at least 1 Contact List",
					content: "Cannot create a broadcast without selecting at least 1 Contact List."
				});
				return;
			}
			this.setState({ currStep: nextStep });
		}
		// step 2 - compose broadcast message
		if (currStep === 1) {
			let isInvalidMedia = false;
			const msgFiles = this.state.msgFilesList;
			const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"];
			for (let i = 0; i < msgFiles.length; i++) {
				if (allowedFileTypes.indexOf(msgFiles[i].type) === -1) {
					isInvalidMedia = true;
					break;
				}
			}
			if (isInvalidMedia) {
				Modal.error({
					title: "Invalid media type",
					content: "Only jpeg, png, and gif image formats allowed in broadcast messages."
				});
				return;
			}
			if (this.state.msgFilesList.length > 10) {
				Modal.error({
					title: "Too many images in this broadcast",
					content: "Maximum of 10 images allowed per broadcast. Having only 1 image per broadcast is recommended though."
				});
				return;
			}
			console.log("curr media size: ", this.totalImgMB(this.state.msgFilesList));
			if (this.totalImgMB(this.state.msgFilesList) > 4.8) {
				Modal.error({
					title: "Media size too large.",
					content: `Maximum of 5 MB allowed per broadcast. We limit media to a max of 4.8 MB to account for message text. Your current total is ${this.totalImgMB(this.state.msgFilesList)} MB.`
				});
				return;
			}
			if (this.state.broadcastMessage.length < 1) {
				Modal.error({
					title: "No broadcast message text",
					content: "You must input some message text for your broadcast."
				});
				return;
			}
			// calculate initial (defaults) required days to send broadcast:
			this.calcRequiredDays();
			this.setState({ currStep: nextStep });
		}

		// step 2 - set daily start/end broadcast times
		if (currStep === 2) {
			if (!this.state.dsh || !this.state.dsm || !this.state.dsap) {
				Modal.error({
					title: "Daily start time not set",
					content: "Must specify daily start time. Default is 10:00 AM."
				});
				return;
			}

			if (!this.state.deh || !this.state.dem || !this.state.deap) {
				Modal.error({
					title: "Daily end time not set",
					content: "Must specify daily start time. Default is 10:00 AM."
				});
				return;
			}

			// const dailyStartTime = moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
			// const dailyEndTime = moment((this.state.deap === "PM" ? parseInt(this.state.deh) + 12 : this.state.deh) + ":" + this.state.dem, "h:mm A");
			const dailyStartTime = moment(this.state.dsh + ":" + this.state.dsm + " " + this.state.dsap, "h:mm A");
			const dailyEndTime = moment(this.state.deh + ":" + this.state.dem + " " + this.state.deap, "h:mm A");

			if (!dailyStartTime.isValid()) {
				Modal.error({
					title: "Daily start time is invalid",
					content: "Must specify daily start time. Default is 10:00 AM."
				});
				return;
			}
			if (!dailyEndTime.isValid()) {
				Modal.error({
					title: "Daily end time is invalid",
					content: "Must specify daily end time. Default is 7:00 PM."
				});
				return;
			}
			if (this.state.dsh < 9 && this.state.dsap === "AM") {
				Modal.error({
					title: "Daily start is too early",
					content: "Cannot start broadcasting before 9:00 AM."
				});
				return;
			}
			console.log("deh: ", this.state.deh);
			console.log("dem: ", this.state.dem);
			if ((this.state.deh > 8 && this.state.deap === "PM") || (this.state.deh === "8" && this.state.dem !== "00" && this.state.deap === "PM")) {
				Modal.error({
					title: "Daily end time is too late",
					content: "Cannot broadcast past 8:00 PM."
				});
				return;
			}
			const duration = moment.duration(dailyEndTime.diff(dailyStartTime));
			console.log("moment diff duration: ", duration);
			console.log("moment diff duration seconds: ", duration.asSeconds());
			console.log("moment diff duration milliseconds: ", duration.asMilliseconds());
			if (duration.asSeconds() < 1) {
				Modal.error({
					title: "Daily end time must be after daily start time",
					content: "Cannot end a broadcast before it starts!"
				});
				return;
			}
			if (duration.asSeconds() < 3600) {
				Modal.error({
					title: "Daily broadcast duration must be at least an hour",
					content: "Must allocate at least 1 hour of broadcast time per day. Otherwise it takes too long to complete broadcast."
				});
				return;
			}
			this.setState({
				dailyStartTime: dailyStartTime,
				dailyEndTime: dailyEndTime,
				isLoadingRequiredDays: true
			});
			this.calcRequiredDays();
			// if (this.state.dsh === this.state.deh && this.state.dsap === this.state.deap) {
			// 	Modal.error({
			// 		title: "Daily start and end time must be at least an hour apart",
			// 		content: "Daily broadcast duration must be at least an hour long."
			// 	});
			// 	return;
			// }
			this.setState({ currStep: nextStep });
		}

		// step 3 - choose broadcast send dates
		if (currStep === 3) {
			console.log("== STEP 3 ==");
			const reqdDays = this.state.totalSendDays;
			console.log("total req days: ", reqdDays);
			const today = moment();
			let isDateToday = false;
			let todaysDatePos = 0;
			const broadcastDatesArray = [];
			for (let i = 0; i < reqdDays; i++) {
				const selectedDate = moment(this.state["broadcastDate" + i], "YYYY-MM-DD");
				broadcastDatesArray.push(this.state["broadcastDate" + i]);
				const daysDiffFromToday = today.diff(selectedDate, "days");
				console.log("bcast date: ", this.state["broadcastDate" + i]);
				console.log("daysDiffFromToday: ", daysDiffFromToday);
				// check if date is in the past
				if (daysDiffFromToday > 0) {
					Modal.error({
						title: `Date #${i + 1} is in the past!`,
						content: "Must select today or a date in the future as your broadcast date."
					});
					return;
				}
				// set isToday bool check
				if (today.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD")) {
					isDateToday = true;
					todaysDatePos = i;
				}
				// check if selected date has a duplicates selected date
				for (let j = 0; j < reqdDays; j++) {
					if (i === j) continue;
					if (this.state["broadcastDate" + i] === this.state["broadcastDate" + j]) {
						Modal.error({
							title: `Date #${i + 1} is a duplicate of Date #${j + 1}.`,
							content: "Each broadcast date must be unique. Cannot have duplicate dates."
						});
						return;
					}
				}
			}

			// check bcast start date TIMEZONE against UTC bcast start time to see if additional date is required
			// contact list timezone may cause bcast start date/time to be partial send day, which means 1 additional send day is required

			// 1. get earliest contact list timezone (largest offset hour above 0)
			const contactLists = this.state.contactLists;
			let bcastStartTimezone = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
			const sendDates = [];
			for (let i = 0; i < this.state.totalSendDays; i++) {
				console.log("send date: ", i, this.state["broadcastDate" + i]);
				sendDates.push(this.state["broadcastDate" + i]);
			}
			const now = moment().valueOf();
			let isStartsIn24Hours = false;
			sendDates.sort();
			for (let i = 0; i < contactLists.length; i++) {
				if (contactLists[i].isSelected) {
					// moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
					const listTimezoneStart = moment.tz(sendDates[0] + " " + (this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "YYYY-MM-DD h:mm A", contactLists[i].timezone);
					console.log("list tz bcast start: ", contactLists[i].name, "|", contactLists[i].timezone, "|", new Date(listTimezoneStart.valueOf()).toLocaleString());
					console.log("list tz bcast start MS: ", listTimezoneStart.valueOf());
					const timeFromNow = listTimezoneStart.valueOf() - now;
					if (timeFromNow < bcastStartTimezone) {
						bcastStartTimezone = timeFromNow;
						isStartsIn24Hours = true;
					}
				}
			}
			// 2. get bcast start time using that list's timezone (ie 10 am Sydney time)
			// 3. compare that start time to NOW, if less than 24 hrs require 1 extra send date
			// 3.5 api will use earliest timezone start (-1 hour) as schedule bcast logs event

			// if a selected date is Today (double check if additional send date is required)
			if (isDateToday || isStartsIn24Hours) {
				// const additionaReqdDates = this.isMoreDatesRequired(reqdDays);
				// ALWAYS ADD 1 DAY TO BCAST REQD DATES IF START DATE IS TODAY
				// (may not be enough time left today to complete 1 full bcast day of sending, so add another day to replace time lost from today)
				const currReqDates = this.getDatesRequired();
				const currTotalDates = this.state.totalSendDays;
				const newTotalReqdDates = currReqDates + 1;
				const additionaReqdDates = newTotalReqdDates - currTotalDates;
				if (additionaReqdDates > 0) {
					// set new total reqd bcast days
					const newTotalReqdDates = reqdDays + additionaReqdDates;
					console.log("NEW total send days (+1): ", newTotalReqdDates);
					this.setState({ totalSendDays: newTotalReqdDates });
					// add required date(s) to date selection
					let newDate = moment(this.state["broadcastDate" + (reqdDays - 1)], "YYYY-MM-DD");
					newDate.add(1, "days");
					if (newDate.day() === 0) newDate.add(1, "days");
					if (newDate.day() === 6) newDate.add(2, "days");
					broadcastDatesArray.push(newDate.format("YYYY-MM-DD"));
					this.setDateRows(newTotalReqdDates, reqdDays, newDate.format("YYYY-MM-DD"));
					// warn user about new date
					Modal.warning({
						title: `${additionaReqdDates} New broadcast date${additionaReqdDates > 1 ? "s" : ""} added.`,
						content: "Please review all dates before you proceed. Because you selected today as a broadcast date, an additional broadcast date has been added."
					});
					return;
				}
			}

			// add array of selected bcast dates to state
			this.setState({ broadcastDates: broadcastDatesArray });

			// check if broadcast will start within 15 minutes (or 20 mins?) > prevent user from sending if bcast will start w/in 15 mins!
			// xxx TODO

			this.setState({ currStep: nextStep });
		}

		// step 4 - schedule broadcast (send form data to api)
		if (currStep === 4) {
			console.log("== STEP 4 ==");
			this.setState({
				isSchedulingBroadcast: true
			});
			const broadcastFormData = {};

			broadcastFormData.orgId = this.props.match.params.orgId;
			// broadcastFormData.created = firebase.firestore.FieldValue.serverTimestamp();
			// broadcastFormData.created = firebase.firestore.Timestamp.now();

			// add selected contact list ids
			const selectedListIds = [];
			for (let i = 0; i < this.state.contactLists.length; i++) {
				if (this.state.contactLists[i].isSelected) {
					selectedListIds.push(this.state.contactLists[i].id);
				}
			}
			broadcastFormData.listIds = selectedListIds;

			// add broadcast selected dates
			const sendDates = [];
			for (let i = 0; i < this.state.totalSendDays; i++) {
				console.log("send date: ", i, this.state["broadcastDate" + i]);
				sendDates.push(this.state["broadcastDate" + i]);
			}
			broadcastFormData.sendDates = sendDates;

			// add daily start/end times
			broadcastFormData.dsh = this.state.dsh;
			broadcastFormData.dsm = this.state.dsm;
			broadcastFormData.dsap = this.state.dsap;
			broadcastFormData.deh = this.state.deh;
			broadcastFormData.dem = this.state.dem;
			broadcastFormData.deap = this.state.deap;

			// add send rate (1 SMS per X minutes)
			broadcastFormData.dsr = this.state.dsr;

			// add images (need to upload first? ...probably)
			// broadcastFormData.msgFilesList = this.state.msgFilesList;

			// add message text
			broadcastFormData.message = this.state.broadcastMessage;

			// upload selected images to firebase storage & retrieve public file urls
			// TODO
			const user = await this.getCurrentUser();
			const orgId = this.props.match.params.orgId;
			const mmsList = [];
			for (let i = 0; i < this.state.msgFilesList.length; i++) {
				const uniqueId = new Date().getTime(); //milliseconds since epoc, used so each filename is unique
				// const downloadUrl = await this.getDownloadUrl(broadcastFormData.msgFilesList[i], user.uid, orgId, uniqueId);
				// broadcastFormData.msgFilesList[i].downloadUrl = downloadUrl;
				const downloadUrl = await this.uploadMediaFile(this.state.msgFilesList[i], user.uid, orgId, uniqueId);
				mmsList.push({ uid: uniqueId, name: this.state.msgFilesList[i].name, url: downloadUrl });
			}
			broadcastFormData.mmsList = mmsList;
			console.log("broadcastFormData: ", broadcastFormData);

			// this.setState({
			// 	isSchedulingBroadcast: false
			// });
			// return;

			// get firebase auth token
			// const userToken = await this.getCurrentUserToken();
			// console.log("userToken.uid: ", userToken.uid);
			firebase
				.auth()
				//.currentUser.getIdToken(/* forceRefresh */ true)
				.currentUser.getIdToken()
				.then(uidToken => {
					// send form data using request library
					request.post(
						{
							url: process.env.REACT_APP_API + "v2/sms.scheduleBroadcast",
							headers: { authorization: "Bearer " + uidToken },
							form: broadcastFormData
						},
						(err, httpResponse, body) => {
							/* ... */
							// console.log("err: ", err);
							// console.log("httpResponse: ", httpResponse);
							// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
							// console.log("body: ", body);
							// if request error
							if (err) {
								console.log("http request error: ", err);
								// return this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "" });
								// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
								this.setState({
									isSchedulingBroadcast: false
								});
								Modal.error({
									title: `Http request error`,
									content: "Api request failed."
								});
								return;
							}
							// if request invalid
							if (httpResponse.statusCode === 500) {
								console.log("http request invalid: ", JSON.parse(body));
								// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
								// return this.setState({ isModalLoading: false, isStripeLoading: false });
								this.setState({
									isSchedulingBroadcast: false
								});
								Modal.error({
									title: `Invalid form data`,
									content: "The data you submitted is invalid."
								});
								return;
							}
							// if request success
							console.log("http request success: ", JSON.parse(body));
							// this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "", contactLists: [JSON.parse(body)].concat(this.state.contactLists) });
							// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
							this.setState({
								isSchedulingBroadcast: false
							});
							Modal.success({
								title: `Broadcast schedule success`,
								content: "You have successfully scheduled your broadcast!"
							});
							this.setState({ currStep: nextStep });
						}
					);
				})
				.catch(error => {
					// Handle get user token error
					console.log("get user token error: ", error);
					this.setState({ isListProcessing: false });
				});
		}

		// this.setState({ currStep: nextStep });
	}
	uploadMediaFile(file, userId, orgId, fileUid) {
		return new Promise((resolve, reject) => {
			const uploadTask = firebase
				.storage()
				.ref()
				.child(`mms/${userId}/${orgId}/${fileUid}-${file.name}`)
				.put(file);

			// Register three observers:
			// 1. 'state_changed' observer, called any time the state changes
			// 2. Error observer, called on failure
			// 3. Completion observer, called on successful completion
			uploadTask.on(
				"state_changed",
				snapshot => {
					// Observe state change events such as progress, pause, and resume
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log("Upload is paused");
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log("Upload is running");
							break;
						default:
							console.log("upload default state..");
							break;
					}
				},
				error => {
					// Handle unsuccessful uploads
					console.log("error uploading file: ", file.name, error);
					reject(false);
				},
				() => {
					// Handle successful uploads on complete
					console.log("success uploading file: ", file.name);
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
						console.log("File available at", downloadURL);
						resolve(downloadURL);
					});
				}
			);
		});
	}
	getCurrentUser() {
		return new Promise((resolve, reject) => {
			firebase.auth().onAuthStateChanged(user => {
				if (user) {
					// User is signed in.
					console.log("current user: ", user);
					resolve(user);
				} else {
					// No user is signed in.
					console.log("no current user found: ", user);
					reject();
				}
			});
		});
	}
	getCurrentUserToken() {
		return new Promise((resolve, reject) => {
			// to force token refresh, set getIdToken(true)
			// .currentUser.getIdToken(/* forceRefresh */ true)
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(idToken => {
					console.log("user token: ", idToken);
					return idToken;
				})
				.catch(error => {
					// Handle get user token error
					console.log("get user token error: ", error);
					reject();
				});
		});
	}
	toggleSelectedList(listId) {
		const contactLists = this.state.contactLists;
		for (let i = 0; i < contactLists.length; i++) {
			if (contactLists[i].id === listId) {
				if (contactLists[i].isSelected) {
					contactLists[i].isSelected = false;
				} else {
					contactLists[i].isSelected = true;
				}
				break;
			}
		}
		this.setState({ contactLists: contactLists });
		// const selectedLists = this.state.selectedContactLists;
		// let contactListPos = 0;
		// let isInSelectedList = false;
		// let selectedListPos = 0;
		// for (let i = 0; i < contactLists.length; i++) {
		// 	if (contactLists[i].id === listId) {
		// 		contactListPos = i;
		// 		break;
		// 	}
		// }
		// for (let i = 0; i < selectedLists.length; i++) {
		// 	if (selectedLists[i].id === listId) {
		// 		isInSelectedList = true;
		// 		selectedListPos = i;
		// 		break;
		// 	}
		// }
		// if (isInSelectedList) {
		// 	selectedLists.splice(selectedListPos, 1);
		// } else {
		// 	selectedLists.push(contactLists[contactListPos]);
		// }
		// this.setState({ selectedContactLists: selectedLists });
	}
	selectList(selectedArr) {
		console.log(`contact list selected: `, selectedArr);
		// const listOptions = [];
		const selectedContactLists = [];
		const contactLists = this.state.contactLists;
		for (let i = 0; i < selectedArr.length; i++) {
			const selectedListId = selectedArr[i].toString().split("--");
			// listOptions.push(selectedListId[1]);
			for (let j = 0; j < contactLists.length; j++) {
				if (contactLists[j].id === selectedListId[0]) {
					selectedContactLists.push(contactLists[j]);
					break;
				}
			}
		}
		console.log("updated arr of selected contact lists: ", selectedContactLists);
		this.setState({
			selectedContactLists: selectedContactLists,
			// selectedContactListOptions: listOptions
			selectedContactListOptions: selectedArr
		});
	}

	getTotalContacts(contactListsArr) {
		let count = 0;
		for (let i = 0; i < contactListsArr.length; i++) {
			// count += parseInt(contactListsArr[i].totalContacts);
			if (contactListsArr[i].isSelected) {
				count += parseInt(contactListsArr[i].totalContacts);
			}
		}
		return count;
	}

	getContactLists(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/contactLists`)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					const contactLists = [];
					const contactListOptions = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						contactLists.push(record);
					});
					contactLists.sort((a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return -1;
						return 0;
					});
					for (let i = 0; i < contactLists.length; i++) {
						contactListOptions.push(
							<Option key={contactLists[i].id + "--" + contactLists[i].name} style={{ borderBottom: "1px solid #e8e8e8" }}>
								{/* {contactLists[i].name} -- {contactLists[i].totalContacts} Contacts */}
								<div className="mb0 bold">{contactLists[i].name}</div>
								<div className="tgrey mt0 mb0 hideWhenSelectedOption">
									{contactLists[i].totalContacts} Contacts &middot;&nbsp;
									{moment(contactLists[i].created).format("ll")}
									{/* <Tooltip title={"Created: " + moment(contactLists[i].created).format("LLLL")}>
										<span>{moment(contactLists[i].created).fromNow()}</span>
									</Tooltip> */}
								</div>
							</Option>
						);
					}
					console.log("contactLists: ", contactLists);
					this.setState({
						contactLists: contactLists,
						contactListOptions: contactListOptions,
						isLoading: false,
						isContactListsLoaded: true
					});
					// if (contactLists.length < 1) this.showModal();
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						const contactLists = this.state.contactLists;
						contactLists[record.id] = record;
						this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getDuration(durationArray) {
		let durationTime = 0;
		let durationText = "millisecond";
		// let plural = "";
		console.log("durationArray:", durationArray);
		Object.keys(durationArray).forEach(key => {
			// console.log(key);          // the name of the current key.
			// console.log(durationArray[key]);   // the value of the current key.
			if (durationArray[key] > 0) {
				durationTime = durationArray[key];
				// durationText = key;
				durationText = key.charAt(0).toUpperCase() + key.slice(1);
			}
		});

		if (durationTime === 1) durationText = durationText.substring(0, durationText.length - 1);
		return durationTime + " " + durationText;
	}

	calcDailySendTime() {
		// calc total daily send time
		const dailyStartTime = moment((this.state.dsap === "PM" ? parseInt(this.state.dsh) + 12 : this.state.dsh) + ":" + this.state.dsm, "h:mm A");
		const dailyEndTime = moment((this.state.deap === "PM" ? parseInt(this.state.deh) + 12 : this.state.deh) + ":" + this.state.dem, "h:mm A");
		// const dailySendDuration = moment(moment.duration(dailyEndTime.diff(dailyStartTime))).format("HH:mm:ss");
		// const dailySendDuration = moment.duration(dailyEndTime.diff(dailyStartTime), "milliseconds").format("h [hrs], m [min]");
		// working, but not precise
		// const dailySendDuration = moment.duration(dailyEndTime.diff(dailyStartTime)).humanize();
		// return dailySendDuration;
		// precise hours & mins
		const dailySendDuration = moment.duration(dailyEndTime.diff(dailyStartTime));
		let dailyHours = Math.floor(dailySendDuration / (60 * 60 * 1000));
		let dailyMins = (dailySendDuration - dailyHours * 60 * 60 * 1000) / (60 * 1000);
		let preciseTimeString = `${dailyHours} Hours and ${dailyMins} minutes`;
		return preciseTimeString;
	}

	getBroadcasts() {
		// console.log("this.props: ", this.props);

		firebase
			.firestore()
			.collection(`orgs/${this.props.match.params.orgId}/broadcasts`)
			.orderBy("created", "desc")
			.limit(5)
			.get()
			.then(
				querySnapshot => {
					// const orgsList = this.state.orgsList;
					const broadcastsList = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.duration = doc.data().broadcastEndTime - doc.data().broadcastStartTime;
						this.getContactList(record.listId);
						// record.duration = 1000;
						console.log("record: ", record);
						console.log("DURATION: ", moment.duration(record.duration));
						broadcastsList.push(record);
					});
					console.log("orgsList: ", broadcastsList);
					this.setState({
						broadcastsList: broadcastsList,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	componentDidMount() {
		// this.setState({ isLoading: true });
		// this.getBroadcasts();
		const orgId = this.props.match.params.orgId;
		this.getContactLists(orgId);
	}

	titleWithBackBtn(orgId) {
		return (
			<div>
				<Link to={`/orgs/${orgId}/broadcasts/history`}>
					<Tooltip title={"Back to All Broadcasts"}>
						<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
					</Tooltip>
				</Link>
				<b style={{ fontSize: "16px" }}>Create New Broadcast</b>
			</div>
		);
	}

	onDateChange(date, dateString) {
		console.log("DATE PICKER: ", date, dateString);
	}

	setDateRows(reqdDays, startFrom, newDate) {
		const dates = [];
		// loop through state dates, automatically set dates for ones that are empty
		console.log("reqdDays: ", reqdDays);
		const dateUpdates = {};
		if (startFrom) console.log("start setDates from: ", startFrom);
		for (let i = 0; i < reqdDays; i++) {
			// || startFrom !== null
			if (!this.state["broadcastDate" + i] || i >= startFrom) {
				console.log('this.state["broadcastDate" + i]: ', this.state["broadcastDate" + i]);
				console.log("startFrom: ", startFrom);

				let currRowDate = moment();
				if (i === startFrom && newDate) {
					currRowDate = moment(newDate, "YYYY-MM-DD");
				} else {
					const previousStateDate = this.state["broadcastDate" + (i - 1)];
					const previousDateUpdate = dateUpdates["broadcastDate" + (i - 1)];
					if (previousStateDate || previousDateUpdate) {
						const previousDate = previousDateUpdate ? moment(previousDateUpdate, "YYYY-MM-DD") : moment(previousStateDate, "YYYY-MM-DD");
						console.log("previousDate: ", previousDate.format("ll"));
						// if date is a Saturday (6)
						if (previousDate.day() === 6) {
							currRowDate = previousDate.add(2, "days");
							// if date is a Friday (5)
						} else if (previousDate.day() === 5) {
							currRowDate = previousDate.add(3, "days");
						} else {
							currRowDate = previousDate.add(1, "days");
						}
					} else {
						currRowDate = currRowDate.add(i + 1, "days");
						// if date is a Sunday (0)
						if (currRowDate.day() === 0) currRowDate = currRowDate.add(1, "days");
						// if date is a Saturday (0)
						if (currRowDate.day() === 6) currRowDate = currRowDate.add(2, "days");
					}
				}
				console.log("#", i + 1, ": currRowDate: ", currRowDate.format("ll"), " dow: ", currRowDate.day());
				dateUpdates["broadcastDate" + i] = currRowDate.format("YYYY-MM-DD");
			} else {
				console.log("SKIP SETTING date for: date #", i);
			}
		}
		this.setState(dateUpdates);
	}

	changeDate(index, direction) {
		const currDate = moment(this.state["broadcastDate" + index], "YYYY-MM-DD");
		let newDate;
		if (direction === "back") {
			newDate = currDate.subtract(1, "day");
		} else {
			newDate = currDate.add(1, "day");
		}
		this.setDateRows(this.state.totalSendDays, index, newDate.format("YYYY-MM-DD"));
	}

	getSendDates(reqdDays) {
		// const reqdDays = this.state.totalSendDays;
		console.log("reqdDays: ", reqdDays);
		const dates = [];
		for (let i = 0; i < reqdDays; i++) {
			dates.push(i);
		}
		const dateRows = dates.map((rowDate, i) => {
			return (
				<div className="" key={"broadcastDate" + i}>
					{/* <div className="bold pb8">Date {i + 1}</div> */}
					<div className="pb8 ">
						Date {i + 1}: <b>{moment(this.state["broadcastDate" + i], "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}</b>
					</div>
					<Button icon="left" onClick={e => this.changeDate(i, "back")} className="mr8" />
					<input type="date" name={"broadcastDate" + i} value={this.state["broadcastDate" + i]} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} /> <Button icon="right" onClick={e => this.changeDate(i, "forward")} />
					<Divider style={{ margin: "16px 0px" }} />
				</div>
			);
		});
		// this.setState(stateDateUpdates);
		return dateRows;
	}

	// getHoursMenu(startOrEnd) {
	// 	const rows = [];
	// 	let keyText = startOrEnd === "start" ? "start-hour-" : "end-hour-";
	// 	for (let i = 0; i < 12; i++) {
	// 		rows.push(<Menu.Item key={keyText + (i + 1)}>{i + 1}</Menu.Item>);
	// 	}
	// 	return (
	// 		<Menu
	// 			onClick={e => {
	// 				console.log(e);
	// 				const keySplit = e.key.split("-");
	// 				let stateVar = "dsh";
	// 				if (keySplit[0] === "end") {
	// 					stateVar = "deh";
	// 				}
	// 				this.setState({
	// 					[stateVar]: keySplit[2]
	// 				});
	// 			}}>
	// 			{rows.map(item => {
	// 				return item;
	// 			})}
	// 		</Menu>
	// 	);
	// }

	// getMinsMenu(startOrEnd) {
	// 	const rows = [];
	// 	let keyText = startOrEnd === "start" ? "start-min-" : "end-min-";
	// 	for (let i = 0; i < 12; i++) {
	// 		let minVal = i * 5 < 10 ? "0" + i * 5 : i * 5;
	// 		rows.push(<Menu.Item key={keyText + minVal}>{minVal}</Menu.Item>);
	// 	}
	// 	return (
	// 		<Menu
	// 			onClick={e => {
	// 				console.log(e);
	// 				const keySplit = e.key.split("-");
	// 				let stateVar = "dsm";
	// 				if (keySplit[0] === "end") {
	// 					stateVar = "dem";
	// 				}
	// 				this.setState({
	// 					[stateVar]: keySplit[2]
	// 				});
	// 			}}>
	// 			{rows.map(item => {
	// 				return item;
	// 			})}
	// 		</Menu>
	// 	);
	// }

	// getAmPmMenu(startOrEnd) {
	// 	let keyText = startOrEnd === "start" ? "start-ampm-" : "end-ampm-";
	// 	return (
	// 		<Menu
	// 			onClick={e => {
	// 				console.log(e);
	// 				const keySplit = e.key.split("-");
	// 				let stateVar = "dsap";
	// 				if (keySplit[0] === "end") {
	// 					stateVar = "deap";
	// 				}
	// 				this.setState({
	// 					[stateVar]: keySplit[2]
	// 				});
	// 			}}>
	// 			<Menu.Item key={keyText + "AM"}>AM</Menu.Item>
	// 			<Menu.Item key={keyText + "PM"}>PM</Menu.Item>
	// 		</Menu>
	// 	);
	// }

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;

		const { previewVisible, previewImage, fileList } = this.state;

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload Img</div>
			</div>
		);

		// SAMPLE TEXT MESSAGE TO TEST VARS
		// Personal vars: 😀
		// $FIRST_NAME, $LAST_NAME

		// List vars: ✍️
		// $LIST_NAME, $LIST_CONTACT, $LIST_PHONE, $LIST_EMAIL, $LIST_SITE, $LIST_ADDR

		// Org vars: 🏢
		// $ORG_NAME, $ORG_CONTACT, $ORG_PHONE, $ORG_EMAIL, $ORG_SITE, $ORG_ADDR

		const contactVars = [
			// { title: "divider", description: "Recipient Variables", icon: "user" },
			{ title: "$FIRST_NAME", description: "Message recipient's first name." },
			{ title: "$LAST_NAME", description: "Message recipient's last name." }
			// { title: "$XXX_XXX", description: "yyy" },
		];
		const listVars = [
			// { title: "divider", description: "Contact List Variables", icon: "solution" },
			{ title: "$LIST_NAME", description: "Organization or Store name for a given contact list. Example: Store #124" },
			{ title: "$LIST_CONTACT", description: "The first and/or last name of the contact person set for a given contact list." },
			{ title: "$LIST_PHONE", description: "Contact phone number set for a given contact list." },
			{ title: "$LIST_EMAIL", description: "Contact email for a given contact list." },
			{ title: "$LIST_SITE", description: "Contact website for a given contact list." },
			{ title: "$LIST_ADDR", description: "Contact address for a given contact list." }
			// { title: "$XXX_XXX", description: "yyy" },
		];
		const orgVars = [
			// { title: "divider", description: "Organization Variables", icon: "bank" },
			{ title: "$ORG_NAME", description: "Name of your organization." },
			{ title: "$ORG_CONTACT", description: "The first and/or last name of the primary contact person set for your organization." },
			{ title: "$ORG_PHONE", description: "Contact phone number of your organization." },
			{ title: "$ORG_EMAIL", description: "Contact email of your organization." },
			{ title: "$ORG_SITE", description: "Website of your organization." },
			{ title: "$ORG_ADDR", description: "Address of your organization." }
			// { title: "$XXX_XXX", description: "yyy" },
		];

		const dailyHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		const dailyMinutes = ["00", "05", 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
		const dailyAP = ["AM", "PM"];
		const dailySR = [10, 12, 15, 18, 20, 22, 25, 27, 30, 35, 40, 45, 50, 55, 60];

		const contactListsMenu = (
			<Menu>
				{this.state.contactLists.map((list, i) => {
					return (
						<Menu.Item key={"list" + i}>
							{/* {list.name} -- {list.totalContacts} Contacts */}
							{/* {list.totalContacts} Contacts -- {list.name} */}
							<p className="mb0">{list.name}</p>
							<p className="tgrey mt0 mb0">{list.totalContacts} Contacts</p>
						</Menu.Item>
					);
				})}
			</Menu>
		);

		// const minsMenu = (
		// 	<Menu>
		// 		<Menu.Item key="0">
		// 			<a href="http://www.alipay.com/">1st menu item</a>
		// 		</Menu.Item>
		// 		<Menu.Item key="1">
		// 			<a href="http://www.taobao.com/">2nd menu item</a>
		// 		</Menu.Item>
		// 		<Menu.Divider />
		// 		<Menu.Item key="3">3rd menu item</Menu.Item>
		// 	</Menu>
		// );

		// const ampmMenu = (
		// 	<Menu>
		// 		<Menu.Item key="0">
		// 			<a href="http://www.alipay.com/">1st menu item</a>
		// 		</Menu.Item>
		// 		<Menu.Item key="1">
		// 			<a href="http://www.taobao.com/">2nd menu item</a>
		// 		</Menu.Item>
		// 		<Menu.Divider />
		// 		<Menu.Item key="3">3rd menu item</Menu.Item>
		// 	</Menu>
		// );

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Broadcasts</Breadcrumb.Item>
					<Breadcrumb.Item>New</Breadcrumb.Item>
				</Breadcrumb> */}

				<Card className="card1 cardHeaderSmall" bordered={false} title={this.titleWithBackBtn(orgId)} style={{ margin: "auto", marginBottom: "24px" }}>
					{this.state.currStep === 0 && (
						<div className="">
							<p className="bold fs15">Step 1. Choose Contact Lists</p>
							<Divider style={{ margin: "16px 0px" }} />
							{/* <p>
								<span className="bold">Name</span> <br />
								Choose a name for this broadcast, or leave the default generated name.
								<br />
								<Input name="agentStatus" placeholder="Broadcast Name" style={{ maxWidth: "325px" }} value={this.state.agentStatus} onChange={e => this.handleChange(e)} />
							</p>
							<Divider style={{ margin: "16px 0px" }} /> */}
							<div className="bold pb4 fs15">Contact Lists</div>
							<div className="pb12">Select one or more Contact Lists to broadcast to:</div>
							<div>
								{/* <span className="bold">Contact Lists</span> <br />
								Select one or more Contact Lists to broadcast to:
								<br />
								{this.state.selectedContactLists.map((record, index) => {
									return "selected list: " + record.name;
								})}
								<br /> */}
								{/* <Input name="agentStatus" placeholder="2249 -- Retail Customers" style={{ maxWidth: "325px" }} value={this.state.agentStatus} onChange={e => this.handleChange(e)} />
								<br /> */}
								{/* <Dropdown overlay={contactListsMenu} trigger={["click"]}>
									<Button>
										Select Contact List <Icon type="down" />
									</Button>
								</Dropdown>
								<br /> */}
								{this.state.contactLists.length < 1 && !this.state.isContactListsLoaded && (
									<div className="tgrey">
										<Icon type="loading" className="fs28 dataLoadingIcon" />
										{/* <br/>
									Loading Contact Lists... */}
									</div>
								)}
								{this.state.contactLists.length < 1 && this.state.isContactListsLoaded && <div className="tgrey">No Contact Lists Found.</div>}
								{this.state.contactLists.map((record, index) => {
									return (
										<div
											className="pointer"
											key={record.id}
											onClick={e => {
												this.toggleSelectedList(record.id);
											}}
											style={{ display: "inline-block", minWidth: "280px" }}>
											<Button icon={`${record.isSelected ? "check" : "plus"}`} type={`${record.isSelected ? "primary" : "default"}`} className="fleft mr12 mt4" />
											<div className="bold ">{record.name}</div>
											<div className={`tgrey pb16 ${record.isSelected ? "bold" : ""}`}>
												{record.totalContacts} Contacts &middot;&nbsp;
												{moment(record.created).format("ll")}
											</div>
										</div>
									);
								})}
								{/* <List
									itemLayout="horizontal"
									dataSource={this.state.contactLists}
									renderItem={list => (
										<List.Item>
											<div className="bold">{list.name}</div>
											<div className="tgrey">
												{list.totalContacts} Contacts &middot;&nbsp; {moment(list.created).format("ll")}
											</div>
										</List.Item>
									)}
								/> */}
								{/* <Select mode="multiple" style={{ width: "100%", maxWidth: 400 }} placeholder="Select one or more contact lists" showArrow={true} onChange={e => this.selectList(e)} value={this.state.selectedContactListOptions}>
									{this.state.contactListOptions}
								</Select> */}
							</div>
							{/* <Divider style={{ margin: "16px 0px" }} />
							<div className="bold pb8 fs15">Selected Contact Lists</div>
							<div className="">
								{this.state.selectedContactLists.length < 1 && <div className="tgrey">No Contact Lists Selected</div>}
								{this.state.selectedContactLists.map((record, index) => {
									return (
										<div className="" key={record.id}>
											<div className="bold ">{record.name}</div>
											<div className={`tgrey ${index === this.state.selectedContactLists.length - 1 ? "" : "pb8"}`}>
												{record.totalContacts} Contacts &middot;&nbsp;
												{moment(record.created).format("ll")}
											</div>
										</div>
									);
								})}
							</div> */}
							<Divider style={{ margin: "16px 0px" }} />
							<div className="bold pb8 fs15">Total Contacts in Broadcast</div>
							<div className="tgrey">
								{this.state.contactLists.length > 0 ? this.getTotalContacts(this.state.contactLists) + " Contacts" : "No Contact Lists Selected"}
								{/* {this.state.selectedContactLists.length > 0 ? this.getTotalContacts(this.state.selectedContactLists) + " Contacts" : "No Contact Lists Selected"} */}
								{/* {this.getTotalContacts(this.state.selectedContactLists)} Contacts */}
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							{/* <p>How many contacts can be broadcast to at once.</p> */}
							{/* <p style={{}}>
								<span className="tgrey">Selected File:</span> {this.state.selectedFile.name ? this.state.selectedFile.name : "No File Selected"}
                            </p> */}

							{/* <p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
						<Button type="" icon="edit">
							Edit List Settings
						</Button>
                    </p> */}
						</div>
					)}

					{this.state.currStep === 1 && (
						<div className="">
							<p className="bold fs15">Step 2. Compose Your Message</p>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="bold pb4 fs15">Broadcast Images</div>
							<div className="pb8 tgrey2">Max 10 images per broadcast. Max allowed size of entire message is 5 MB. Only png, gif, and jpeg formats allowed.</div>
							<div className="pb8">Total Images: {this.state.msgFilesList.length}</div>
							<div className="pb8">Total Media Size: {this.totalImgMB(this.state.msgFilesList)} MB</div>
							{this.state.msgFilesList.length > 0 && (
								<div className="pb8">
									{this.state.msgFilesList.map((file, index) => {
										return (
											<div className="" key={"msgFile" + index}>
												<div className="pictureCardCustom mb12">
													{/* src={this.getFileUrl(file, "msgFileImgId" + index, index)} */}
													<img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{ width: "240px", height: "240px" }} src={file.url} onClick={e => this.handleImgPreview(file)} />
													<div className="pb8 bold">
														#{index + 1}: {file.name}
													</div>
													<div className="">
														{(file.size / 1024 / 1024).toFixed(2)} MB &nbsp;&middot;&nbsp;
														<Button onClick={e => this.removeFile(index)}>
															<Icon type="close" /> Remove
														</Button>
													</div>
												</div>
											</div>

											// <Card hoverable style={{ width: 240 }} cover={<img id={"msgFileId" + index} alt={file.name} src={this.getFileUrl(file, "msgFileId" + index)} />} actions={[<Icon type="eye" />, <Icon type="delete" />]}>
											// 	<Meta title={file.name} description={(file.size / 1024 / 1024).toFixed(2) + " MB"} />
											// </Card>
										);
									})}
								</div>
							)}
							<div className="pb8 " style={{ maxWidth: "325px" }}>
								<Dragger
									style={{ padding: "8px 12px" }}
									action="//example.com/posts/"
									listType="picture"
									multiple={true}
									fileList={fileList}
									onPreview={this.handleImgPreview}
									onChange={this.handleImgChange}
									showUploadList={false}
									beforeUpload={file => {
										console.log("before file upload: ", file);
										file.url = URL.createObjectURL(file);
										const msgFiles = this.state.msgFilesList;
										msgFiles.push(file);
										this.setState({
											// fileList: [],
											msgFilesList: msgFiles
										});
										return false;
									}}>
									<p className="ant-upload-drag-icon" style={{ marginBottom: "4px" }}>
										<Icon type="picture" style={{ fontSize: "32px" }} />
									</p>
									<p className="ant-upload-text" style={{ fontSize: "15px" }}>
										Click or drag image files to this area to upload
									</p>
									{/* <p className="ant-upload-hint">Max total size limit for ALL images in broadcast is 5 MB &middot; Max 10 images allowed per broadcast</p> */}
								</Dragger>
								{/* <Upload
									action="//example.com/posts/"
									listType="picture-card"
									multiple={true}
									fileList={fileList}
									onPreview={this.handleImgPreview}
									onChange={this.handleImgChange}
									showUploadList={false}
									beforeUpload={file => {
										console.log("before file upload: ", file);
										file.url = URL.createObjectURL(file);
										const msgFiles = this.state.msgFilesList;
										msgFiles.push(file);
										this.setState({
											// fileList: [],
											msgFilesList: msgFiles
										});
										return false;
									}}>
									{fileList.length < 11 ? uploadButton : null}
								</Upload> */}
								<Modal
									visible={previewVisible}
									title={this.state.previewFile.name}
									onCancel={this.handleImgCancel}
									footer={[
										<Button key="back" onClick={this.handleImgCancel}>
											Return
										</Button>
									]}>
									<img alt="example" style={{ width: "100%" }} src={previewImage} />
								</Modal>
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="bold pb4 fs15">Broadcast Message</div>
							<div className="pb8">Compose your broadcast message below:</div>
							<div className="pb8">
								<TextArea placeholder="Your SMS Message goes here." name="broadcastMessage" value={this.state.broadcastMessage} onChange={e => this.handleChange(e)} autosize={{ minRows: 6, maxRows: 18 }} style={{ maxWidth: "325px", color: "#000" }} />
							</div>
							<div className="pb8">
								<Button className="mr8 mb8" onClick={e => this.setState({ isModalVariableVisible: true })}>
									<Icon type="build" className="fs16" /> Variable
								</Button>
								<Button className="mr8 mb8" onClick={e => this.setState({ isModalEmojiVisible: true })}>
									<Icon type="smile" className="fs16" /> Emoji
								</Button>
								<Button className=" mb8" onClick={e => this.setState({ isModaPreviewVisible: true })}>
									<Icon type="eye" className="fs16" /> Preview
								</Button>
							</div>
							<div className={`pb8 ${this.state.is7Bit ? "" : "tblue"}`}>
								{this.state.messageCharCount}/{this.state.availableMsgChars} Characters &middot; Approx {this.state.msgSmsParts} SMS Part{this.state.msgSmsParts > 1 ? "s" : ""} &middot; {this.state.msgFilesList.length} MMS Parts
							</div>
							<div className="tgrey2">NOTE: " Text STOP to end." (18 characters) is automatically added to the end of your message.</div>
							<Divider style={{ margin: "16px 0px" }} />
							{/* <p>
								<span className="bold">Message</span> <br />
								<span className="tgrey">Compose the message you would like to broadcast.</span> <br />
								<TextArea placeholder="Your SMS Message goes here." autosize={{ minRows: 6, maxRows: 6 }} style={{ maxWidth: "325px" }} />
								<br />
								<span className="tgrey">23/136 Characters &middot; About 1 Message Part</span> <br />
								<span className="tgrey">NOTE: "Text STOP to end." (23 characters) is automatically appended to the end of your message.</span> <br />
							</p> */}
							<Modal title="Add Message Variable" visible={this.state.isModalVariableVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
								<div>
									<Collapse bordered={false} defaultActiveKey={["1"]} accordion>
										<Panel
											header={
												<div className="bold fs15 tblack">
													<Icon type="user" className="fs18 tblue" /> &nbsp; Recipient Variables
												</div>
											}
											key="1">
											<List
												itemLayout="horizontal"
												dataSource={contactVars}
												renderItem={item => (
													<List.Item className={`${item.title !== "divider" ? "hideListContent" : ""}`}>
														<List.Item.Meta
															//   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
															avatar={<Button icon="plus" className=" fs16" onClick={e => this.insertMsgVar(item.title)} />}
															title={item.title}
															description={item.description}
														/>
													</List.Item>
												)}
											/>
										</Panel>
										<Panel
											header={
												<div className="bold fs15 tblack">
													<Icon type="solution" className="fs18 tblue" /> &nbsp; Contact List Variables
												</div>
											}
											key="2">
											<List
												itemLayout="horizontal"
												dataSource={listVars}
												renderItem={item => (
													<List.Item className={`${item.title !== "divider" ? "hideListContent" : ""}`}>
														<List.Item.Meta
															//   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
															avatar={<Button icon="plus" className=" fs16" onClick={e => this.insertMsgVar(item.title)} />}
															title={item.title}
															description={item.description}
														/>
													</List.Item>
												)}
											/>
										</Panel>
										<Panel
											header={
												<div className="bold fs15 tblack">
													<Icon type="solution" className="fs18 tblue" /> &nbsp; Organization Variables
												</div>
											}
											key="3">
											<List
												itemLayout="horizontal"
												dataSource={orgVars}
												renderItem={item => (
													<List.Item className={`${item.title !== "divider" ? "hideListContent" : ""}`}>
														<List.Item.Meta
															//   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
															avatar={<Button icon="plus" className=" fs16" onClick={e => this.insertMsgVar(item.title)} />}
															title={item.title}
															description={item.description}
														/>
													</List.Item>
												)}
											/>
										</Panel>
									</Collapse>
								</div>
							</Modal>
							<Modal className="taCenter" title="Add Emojis to Your Message" visible={this.state.isModalEmojiVisible} onOk={this.handleOk} onCancel={this.handleCancel} bodyStyle={{ padding: "0px" }}>
								<Picker set="emojione" title="Pick emoji…" emoji="point_up" onSelect={this.addEmoji} style={{ width: "100%" }} />
							</Modal>
							<Modal title="Preview Message" visible={this.state.isModaPreviewVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
								<p>Select Contact List</p>
								<p>Message Preview:</p>
							</Modal>
						</div>
					)}

					{this.state.currStep === 2 && (
						<div className="">
							<p className="bold fs15">Step 3. Set Daily Send Times</p>
							{/* <Divider style={{ margin: "16px 0px" }} /> */}
							{/* <p>
								<span className="bold">Daily Start Time</span> <br />
								<span className="tgrey">Select the time your broadcast begins sending each day:</span> <br />
								<TimePicker defaultValue={moment("10:00", "h:mm A")} minuteStep={5} use12Hours format={"h:mm A"} allowEmpty={false} name="dailyStartTime" value={this.state.dailyStartTime} onChange={this.handleStartTimeChange} />
							</p> */}
							<div className="pb8 bold">Daily Start Time</div>
							<div className="pb8 tgrey">Select the time your broadcast begins sending each day:</div>
							<div className="">
								{/* <Dropdown overlay={this.getHoursMenu("start")} trigger={["click"]}>
									<Button>
										{this.state.dsh} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="dsh" value={this.state.dsh} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyHours.map((item, index) => {
										return (
											<option key={"dsh" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
								&nbsp;:&nbsp;
								{/* <Dropdown overlay={this.getMinsMenu("start")} trigger={["click"]}>
									<Button>
										{this.state.dsm} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="dsm" value={this.state.dsm} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyMinutes.map((item, index) => {
										return (
											<option key={"dsm" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
								&nbsp;&nbsp;
								{/* <Dropdown overlay={this.getAmPmMenu("start")} trigger={["click"]}>
									<Button>
										{this.state.dsap} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="dsap" value={this.state.dsap} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyAP.map((item, index) => {
										return (
											<option key={"dsap" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							{/* <p>
								<span className="bold">Daily End Time</span> <br />
								<span className="tgrey">Select the time your broadcast stops sending each day:</span>
								<br />
								<TimePicker defaultValue={moment("18:00", "h:mm A")} minuteStep={5} use12Hours format={"h:mm A"} name="dailyEndTime" value={this.state.dailyEndTime} onChange={this.handleEndTimeChange} />
							</p> */}
							<div className="pb8 bold">Daily End Time</div>
							<div className="pb8 tgrey">Select the time your broadcast stops sending each day:</div>
							<div className="">
								{/* <Dropdown overlay={this.getHoursMenu("end")} trigger={["click"]}>
									<Button>
										{this.state.deh} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="deh" value={this.state.deh} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyHours.map((item, index) => {
										return (
											<option key={"deh" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
								&nbsp;:&nbsp;
								{/* <Dropdown overlay={this.getMinsMenu("end")} trigger={["click"]}>
									<Button>
										{this.state.dem} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="dem" value={this.state.dem} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyMinutes.map((item, index) => {
										return (
											<option key={"dem" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
								&nbsp;&nbsp;
								{/* <Dropdown overlay={this.getAmPmMenu("end")} trigger={["click"]}>
									<Button>
										{this.state.deap} <Icon type="down" />
									</Button>
								</Dropdown> */}
								<select name="deap" value={this.state.deap} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailyAP.map((item, index) => {
										return (
											<option key={"deap" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Total Daily Send Duration</div>
							<div className="pb8 tgrey">Duration of daily broadcast send time:</div>
							<div className="pb8 ">{this.calcDailySendTime()}</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Custom Send Rate</div>
							<div className="pb8 tgrey">
								Select how often a source number should send an outbound SMS message.
								<br />
								Default is 1 SMS every 10 minutes.
							</div>
							<div className="">
								Send 1 SMS every &nbsp;
								<select name="dsr" value={this.state.dsr} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff" }}>
									{dailySR.map((item, index) => {
										return (
											<option key={"dsr" + index} value={item}>
												{item}
											</option>
										);
									})}
								</select>
								&nbsp; Minutes
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Required Send Days</div>
							<div className="pb8 tgrey">Total days to complete broadcast:</div>
							<div className="">
								{this.state.totalSendDays} Day{this.state.totalSendDays === 1 ? "" : "s"}
							</div>
							<Divider style={{ margin: "16px 0px" }} />
						</div>
					)}

					{this.state.currStep === 3 && (
						<div className="">
							<p className="bold fs15">Step 4. Select Broadcast Dates</p>
							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Broadcast Dates</div>
							<div className="pb8 tgrey">
								{this.state.totalSendDays} full day{this.state.totalSendDays === 1 ? "" : "s"} required to complete broadcast.
							</div>
							<div className="pb8 tgrey">Select the dates on which your broadcast will be sent out:</div>
							{this.getSendDates(this.state.totalSendDays)}
							{/* <div>
								<span className="bold">Date 1</span> <br />
								<span className="tgrey">Tuesday, October 23 2018</span> <br />
								<DatePicker onChange={this.onDateChange} />
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div>
								<span className="bold">Date 2</span> <br />
								<span className="tgrey">Wednesday, October 24 2018</span> <br />
								<DatePicker onChange={this.onDateChange} />
							</div>
							<Divider style={{ margin: "16px 0px" }} />
							<div>
								<span className="bold">Date 3</span> <br />
								<span className="tgrey">Thursday, October 25 2018</span> <br />
								<DatePicker onChange={this.onDateChange} />
							</div> */}
						</div>
					)}

					{this.state.currStep === 4 && (
						<div className="">
							<p className="bold fs15">Step 5. Review Details</p>

							<Divider style={{ margin: "16px 0px" }} />
							{/* <p>
								<span className="bold">Name</span> <br />
								<span className="tgrey">2018-Oct-22--BN4031</span>
							</p>
							<Divider style={{ margin: "16px 0px" }} /> */}
							<div className="pb8 bold">Contact Lists</div>
							{this.state.contactLists.map((list, index) => {
								if (list.isSelected) {
									return (
										<div className="pb8" key={"reviewCLists" + index}>
											<div className="bold ">{list.name}</div>
											<div className={`tgrey  `}>
												{list.totalContacts} Contacts &middot;&nbsp;
												{moment(list.created).format("ll")}
											</div>
										</div>
									);
								}
							})}

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Total Recipients</div>
							<div className="pb8">{this.getTotalContacts(this.state.contactLists)} Contacts</div>

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Daily Time Span</div>
							<div className="pb8">
								{this.state.dsh}:{this.state.dsm} {this.state.dsap} -- {this.state.deh}:{this.state.dem} {this.state.deap}
							</div>
							<div className="pb8 ">{this.calcDailySendTime()}</div>

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Send Rate</div>
							<div className="">1 SMS message every {this.state.dsr} minutes</div>

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Send Dates</div>
							<div className="pb8 tgrey">
								{this.state.totalSendDays} day{this.state.totalSendDays === 1 ? "" : "s"} required to complete this broadcast.
							</div>
							{this.state.broadcastDates.map((date, index) => {
								return (
									<div className="pb8" key={"reviewDate" + index}>
										{moment(date, "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}
									</div>
								);
							})}

							<Divider style={{ margin: "16px 0px" }} />

							{this.state.msgFilesList.length > 0 && (
								<div className="">
									<div className="pb8 bold">Broadcast Images</div>
									<div className="pb8">Total Images: {this.state.msgFilesList.length}</div>
									<div className="pb8">Total Media Size: {this.totalImgMB(this.state.msgFilesList)} MB</div>
									<div className="pb8">
										{this.state.msgFilesList.map((file, index) => {
											return (
												<div className="" key={"msgFile" + index}>
													<div className="pictureCardCustom mb12">
														{/* src={this.getFileUrl(file, "msgFileImgId" + index, index)} */}
														<img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{ width: "240px", height: "240px" }} src={file.url} onClick={e => this.handleImgPreview(file)} />
														<div className="pb8 bold">
															#{index + 1}: {file.name}
														</div>
														<div className="">
															{(file.size / 1024 / 1024).toFixed(2)} MB
															{/* &nbsp;&middot;&nbsp; <Button onClick={e => this.removeFile(index)}>
																<Icon type="close" /> Remove
															</Button> */}
														</div>
													</div>
												</div>

												// <Card hoverable style={{ width: 240 }} cover={<img id={"msgFileId" + index} alt={file.name} src={this.getFileUrl(file, "msgFileId" + index)} />} actions={[<Icon type="eye" />, <Icon type="delete" />]}>
												// 	<Meta title={file.name} description={(file.size / 1024 / 1024).toFixed(2) + " MB"} />
												// </Card>
											);
										})}
									</div>
									<Modal
										visible={previewVisible}
										title={this.state.previewFile.name}
										onCancel={this.handleImgCancel}
										footer={[
											<Button key="back" onClick={this.handleImgCancel}>
												Return
											</Button>
										]}>
										<img alt="example" style={{ width: "100%" }} src={previewImage} />
									</Modal>
								</div>
							)}
							<div className="pb8 bold">Broadcast Message</div>
							<div className="broadcastMessageDisplay mb8" style={{ maxWidth: "258px", wordBreak: "break-word" }}>
								{this.state.broadcastMessage + " Text STOP to end."}
							</div>
							<div className={`pb8 ${this.state.is7Bit ? "tgrey" : "tblue"}`}>
								{this.state.messageCharCount}/{this.state.availableMsgChars} Characters &middot; Approx {this.state.msgSmsParts} SMS Part{this.state.msgSmsParts > 1 ? "s" : ""} &middot; {this.state.msgFilesList.length} MMS Parts
							</div>

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Start</div>
							<div className="pb8">
								{moment(this.state.broadcastDates[0], "YYYY-MM-DD").format("dddd, MMMM DD YYYY")} @ {this.state.dsh}:{this.state.dsm} {this.state.dsap}
							</div>

							<Divider style={{ margin: "16px 0px" }} />
							<div className="pb8 bold">Finish</div>
							<div className="pb8">
								{moment(this.state.broadcastDates[this.state.broadcastDates.length - 1], "YYYY-MM-DD").format("dddd, MMMM DD YYYY")} @ {this.state.deh}:{this.state.dem} {this.state.deap}
							</div>

							<Divider style={{ margin: "16px 0px" }} />
						</div>
					)}

					{this.state.currStep === 5 && (
						<div className="">
							<p className="bold fs15">Step 6. Your Broadcast has been Scheduled!</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>Your broadcast has been successfully scheduled. 🎉</p>
							<p>To cancel, or edit your broadcast message, click finished and select the broadcast from your broadcast history.</p>
						</div>
					)}

					<p style={{ marginBottom: "-8px", marginTop: "24px" }}>
						{this.state.currStep > 0 && this.state.currStep < 5 && (
							<Button type="default" icon="arrow-left" onClick={() => this.changeStep(this.state.currStep, "back")} style={{ marginRight: "16px" }}>
								Previous
							</Button>
						)}

						{this.state.currStep < 5 && (
							<Button type="primary" icon={this.state.currStep === 4 ? "check" : "arrow-right"} loading={this.state.currStep === 4 ? this.state.isSchedulingBroadcast : false} onClick={() => this.changeStep(this.state.currStep, "forward")}>
								{this.state.currStep === 4 ? "Schedule Broadcast" : "Next"}
							</Button>
						)}

						{this.state.currStep === 5 && (
							<Link to={`/orgs/${this.props.match.params.orgId}/broadcasts/history`}>
								<Button className="successBtn" type="" icon="check" style={{ marginRight: "16px" }}>
									Finished
								</Button>
							</Link>
						)}
					</p>
				</Card>
				<Modal
					title="New Broadcast"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						// <Button key="submit" type="" className="successBtn2" icon="check" loading={this.state.loading} onClick={this.handleOk}>
						// 	Create Broadcast
						// </Button>,
						<Button key="submit" type="primary" className="" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create Broadcast
						</Button>,
						<Button key="submit" type="" className="successBtn" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create Broadcast
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							New Broadcast
						</Button>
					</span>
					<Search placeholder="Search Broadcasts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
				</div> */}
				{/* loading={this.state.isLoading}  */}
				{/* <Table className="card1 bgWhite" columns={columns} dataSource={this.state.broadcastsList} loading={this.state.isLoading} /> */}
			</Content>
		);
	}
}
