import React, { Component } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Row, Col, Divider, List, Avatar, Button, Modal, Input, Tooltip, Card } from "antd";

import { ChatsList } from "./chatsList.js";
import { SearchChats } from "./searchChats.js";
import { ChatHistory } from "./chatHistory.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const { TextArea } = Input;

// class App extends Component {
export class ChatsHistory extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		currChatCol: ""
	};

	// this.handleChange = this.handleChange.bind(this);

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	// showModal = () => {
	// 	const currChatText = this.state.chatMessage;
	// 	this.setState({
	// 		visible: true,
	// 		chatMessageModal: currChatText
	// 	});
	// };

	// handleOk = e => {
	// 	console.log(e);
	// 	const chatText = this.state.chatMessageModal;
	// 	const chatMsg = {
	// 		name: "North Hill Paints",
	// 		description: chatText,
	// 		from: "org"
	// 	};
	// 	this.setState(prevState => ({ loading: true, chatMessage: "", chatMessageModal: "", chatData: [...prevState.chatData, chatMsg] }));
	// 	setTimeout(() => {
	// 		this.setState({ loading: false, visible: false });
	// 	}, 0);
	// };

	// handleCancel = e => {
	// 	console.log(e);
	// 	const chatMsg = this.state.chatMessageModal;
	// 	this.setState({
	// 		visible: false,
	// 		chatMessage: chatMsg,
	// 		chatMessageModal: ""
	// 	});
	// };

	// onCollapse = collapsed => {
	// 	console.log(collapsed);
	// 	this.setState({ collapsed });
	// };

	// chatsConvoHeader(name, listId, updated) {
	// 	return (
	// 		<div className="">
	// 			<span style={{}}>{name}</span> <span>&middot; {listId}</span> <span style={{ float: "right" }}>{updated}</span>
	// 		</div>
	// 	);
	// }

	// chatBubble(chatText, chatFrom, name) {
	// 	return (
	// 		<div>
	// 			<div className="" style={{ background: chatFrom === "contact" ? "#2962ff" : "#fff", color: chatFrom === "contact" ? "#fff" : "#777", border: chatFrom === "contact" ? "0px" : "1px solid #ccc", display: "inline-block", borderRadius: "4px", padding: "4px 8px " }}>
	// 				{chatText}
	// 			</div>
	// 			<div className="" style={{ color: "#aaa" }}>
	// 				{name} &middot; 32 min ago
	// 			</div>
	// 		</div>
	// 	);
	// }

	// getChatInitials(name) {
	// 	const splitName = name.toUpperCase().split(" ");
	// 	// console.log("splitName: ", splitName);
	// 	const initials = splitName[0].substring(0, 1) + splitName[1].substring(0, 1);
	// 	// console.log("intials: ", initials);
	// 	return initials;
	// }

	componentDidMount() {
		const orgId = this.props.match.params.orgId;
		const pathname = this.props.location.pathname;
		const chatCols = [{ uniqueUrl: "/inbound", menuKey: "inbound" }, { uniqueUrl: "/recent", menuKey: "recent" }, { uniqueUrl: "/search", menuKey: "search" }];
		let currChatCol = "notFound";
		for (let i = 0; i < chatCols.length; i++) {
			if (pathname.indexOf(chatCols[i].uniqueUrl) !== -1) {
				console.log("pathname match: ", i, chatCols[i].menuKey);
				// return [chatCols[i].menuKey];
				// return chatCols[i].menuKey;
				currChatCol = chatCols[i].menuKey;
				break;
			}
		}
		if (currChatCol === "notFound") {
			this.props.history.push(`/orgs/${orgId}/chats/inbound`);
		} else {
			this.setState({ currChatCol: currChatCol });
		}
	}

	getChatConvoColumn(pathname) {
		console.log("getChatConvoColumn: ", pathname);
		const chatCols = [{ uniqueUrl: "/inbound", menuKey: "inbound" }, { uniqueUrl: "/recent", menuKey: "recent" }, { uniqueUrl: "/search", menuKey: "search" }];
		for (let i = 0; i < chatCols.length; i++) {
			if (pathname.indexOf(chatCols[i].uniqueUrl) !== -1) {
				console.log("pathname match: ", i, chatCols[i].menuKey);
				// return [chatCols[i].menuKey];
				return chatCols[i].menuKey;
			}
		}
		return "notFound";
	}

	render() {
		// console.log("this.props: ", this.props);\

		const orgId = this.props.match.params.orgId;
		const pathname = this.props.location.pathname;
		const listTab = this.props.match.params.listTab;
		const contactId = this.props.match.params.contactId ? this.props.match.params.contactId : false;
		const chatTab = this.props.match.params.chatTab ? this.props.match.params.chatTab : false;

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "16px 0" }}>
					<Breadcrumb.Item>User</Breadcrumb.Item>
					<Breadcrumb.Item>Bill</Breadcrumb.Item>
				</Breadcrumb> */}
				<Row gutter={24}>
					<Col md={12} xs={24}>
						<div className={`card1 ${contactId ? "chatHide" : ""}`} style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
							<div className="bb" style={{ width: "100%", background: "", padding: "12px" }}>
								<b style={{ fontSize: "16px" }}>Chat Messages</b>
							</div>

							{/* <Divider style={{ margin: "0px" }} /> */}
							{/* <div className="" style={{ width: "100%" }}>
								<Menu onClick={this.handleClick} selectedKeys={[this.state.currChatCol]} mode="horizontal">
									<Menu.Item key="inbound" onClick={() => this.setState({ currChatCol: "inbound" })}>
										<Link to={`/orgs/${orgId}/chats/inbound${contactId && chatTab ? "/" + contactId + "/" + chatTab : ""}`}>Messages</Link>
									</Menu.Item>
									<Menu.Item key="recent" onClick={() => this.setState({ currChatCol: "recent" })}>
										<Link to={`/orgs/${orgId}/chats/recent${contactId && chatTab ? "/" + contactId + "/" + chatTab : ""}`}>Recent</Link>
									</Menu.Item>
									<Menu.Item key="search" onClick={() => this.setState({ currChatCol: "search" })}>
										<Link to={`/orgs/${orgId}/chats/search${contactId && chatTab ? "/" + contactId + "/" + chatTab : ""}`}>Search</Link>
									</Menu.Item>
								</Menu>
							</div> */}

							{/* {this.state.currChatCol === "inbound" && <ChatsList orgId={orgId} chatColumn={"inbound"} />}
							{this.state.currChatCol === "recent" && <ChatsList orgId={orgId} chatColumn={"recent"} />}
							{this.state.currChatCol === "search" && <SearchChats orgId={orgId} />} */}

							{this.props.match.params.listTab !== "search" && <ChatsList orgId={orgId} contactId={contactId} currChatCol={this.props.match.params.listTab} />}
							{this.props.match.params.listTab === "search" && <SearchChats orgId={orgId} />}

							{/* {this.state.currChatCol !== "search" && <ChatsList orgId={orgId} currChatCol={this.state.currChatCol} />}
							{this.state.currChatCol === "search" && <SearchChats orgId={orgId} />} */}

							{/* {this.getChatConvoColumn(pathname) === "inbound" && <ChatsList orgId={orgId} chatColumn={"inbound"} />}
							{this.getChatConvoColumn(pathname) === "recent" && <ChatsList orgId={orgId} chatColumn={"recent"} />}
							{this.getChatConvoColumn(pathname) === "search" && <SearchChats orgId={orgId} />}  */}
						</div>
						{/* <div className="card1" style={{ padding: 0, background: "#fff" }}>
							
						</div> */}
					</Col>
					<Col md={12} xs={24}>
						{/* <ChatHistory orgId={orgId} contactId={contactId} /> */}
						{/* <div className="card1" style={{ padding: 0, background: "#fff" }}>
						</div> */}
						<div className={`card1 ${contactId ? "" : "chatHide"}`}>
							{this.props.match.params.contactId && this.props.match.params.chatTab && <ChatHistory {...this.props} listTab={listTab} currChatCol={this.props.match.params.chatTab} />}

							{!this.props.match.params.contactId && !this.props.match.params.chatTab && (
								<Card title="Chat History" className="card1 taCenter cardHeaderSmall" bordered={false}>
									<p>
										<Icon type="message" className="fs24" />
									</p>
									<p className="tgrey">Select a contact to view chat history.</p>
								</Card>
							)}
						</div>

						{/* <Switch>
							<Route exact path={`/orgs/:orgId/chats/${this.state.currChatCol}/:contactId/:chatTab`} render={props => <ChatHistory {...props} currChatCol={this.state.currChatCol} key={this.props.match.params.contactId} />} />
							<Route
								component={() => {
									return (
										<Card title="Chat History" className="card1 taCenter cardHeaderSmall" bordered={false}>
											<p>
												<Icon type="message" className="fs24" />
											</p>
											<p className="tgrey">Select a contact to view chat history.</p>
										</Card>
									);
								}}
							/>
						</Switch> */}
					</Col>
				</Row>
			</Content>
		);
	}
}
