import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown, Card, Row, Col } from "antd";
import * as firebase from "firebase";
import moment from "moment";

import { ContactListMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class ContactListNumbers extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		contacts: [],
		pageSize: 100,
		selectedFile: {},
		contactList: { name: "Loading..." }
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getSourceNumbers(orgId, listId) {
		firebase
			.firestore()
			.collection(`sourceNumbers`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.where("isReleased", "==", false)
			.orderBy("created", "desc")
			.limit(this.state.pageSize)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const records = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						records.push(record);
					});
					console.log("sourceNumbers: ", records);
					this.setState({
						sourceNumbers: records,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactList: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		this.getSourceNumbers(orgId, listId);
		this.getContactList(listId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);

		const columns = [
			// {
			// 	title: "Status",
			// 	dataIndex: "status",
			// 	key: "status",
			// 	render: (text, record) => (
			// 		<Tooltip title={!record.isOptedOut ? "Subscribed" : "Opted Out"}>
			// 			<span className="taCenter dBlock">{!record.isOptedOut ? <Icon type="check" className="tgreen" /> : <Icon type="stop" className="tred" />}</span>
			// 		</Tooltip>
			// 	)
			// },
			// {
			// 	title: "Name",
			// 	dataIndex: "name",
			// 	key: "name",
			// 	render: (text, record) => (
			// 		<Link to={`/orgs/${record.orgId}/contactLists/${record.listId}/contacts`} className="fs15">
			// 			{record.firstName} {record.lastName}
			// 		</Link>
			// 	)
			// },

			// {
			// 	title: "Last Message",
			// 	dataIndex: "lastMessage",
			// 	key: "lastMessage"
			// },
			// {
			// 	title: "Updated",
			// 	dataIndex: "updated",
			// 	key: "updated",
			// 	render: (text, record) => (
			// 		<Tooltip title={moment(record.updated).format("LLLL")}>
			// 			<span>{moment(record.updated).fromNow()}</span>
			// 		</Tooltip>
			// 	)
			// },
			{
				title: "Number",
				dataIndex: "international",
				key: "international"
				// width: "250px"
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLLL")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const data = [
			{
				key: "1",
				status: "subscribed",
				name: "Jane Smith",
				email: "2086",
				mobileNumber: "+16475552809",
				lastMessage: "hey, what time are you open to?",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				updated: 1538778093,
				created: 1538778093
			},
			{
				key: "2",
				name: "John Doe",
				email: "327",
				status: "optedOut",
				age: 42,
				mobileNumber: "+14165557835",
				address: "London No. 1 Lake Park",
				lastMessage: "Thanks for the info!",
				tags: ["loser"],
				updated: 1538778125,
				created: 1538778125
			}
		];

		const pageSizeMenu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.updatePageSize(10)}>
					10 Rows Per Page
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.updatePageSize(15)}>
					15 Rows Per Page
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.updatePageSize(20)}>
					20 Rows Per Page
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.updatePageSize(50)}>
					50 Rows Per Page
				</Menu.Item>
				<Menu.Item key="4" onClick={() => this.updatePageSize(100)}>
					100 Rows Per Page
				</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>Retail Customers</Breadcrumb.Item>
					<Breadcrumb.Item>Contacts</Breadcrumb.Item>
				</Breadcrumb> */}

				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<Link to={`/orgs/${orgId}/contactLists/all`}>
							<Tooltip title={"Back to All Lists"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<ContactListMenu selectedKey="numbers" orgId={orgId} listId={listId} />
					</div>
				</div>

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.sourceNumbers}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					pagination={{ pageSize: this.state.pageSize }}
				/>
			</Content>
		);
	}
}
