import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown, Card, Row, Col } from "antd";
import * as firebase from "firebase";
import moment from "moment";

import { ContactListMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class ContactListCapacity extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		contacts: [],
		pageSize: 15,
		selectedFile: {},
		contactList: { name: "Loading..." }
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getContacts(orgId, listId) {
		firebase
			.firestore()
			.collection(`contacts`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const contacts = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						contacts.push(record);
					});
					console.log("orgsList: ", contacts);
					this.setState({
						contacts: contacts,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactList: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		// this.getContacts(orgId, listId);
		this.getContactList(listId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);

		const columns = [
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (text, record) => (
					<Tooltip title={!record.isOptedOut ? "Subscribed" : "Opted Out"}>
						<span className="taCenter dBlock">{!record.isOptedOut ? <Icon type="check" className="tgreen" /> : <Icon type="stop" className="tred" />}</span>
					</Tooltip>
				)
			},
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link to={`/orgs/${record.orgId}/contactLists/${record.listId}/contacts`} className="fs15">
						{record.firstName} {record.lastName}
					</Link>
				)
			},
			{
				title: "Number",
				dataIndex: "e164",
				key: "e164"
			},
			// {
			// 	title: "Last Message",
			// 	dataIndex: "lastMessage",
			// 	key: "lastMessage"
			// },
			{
				title: "Updated",
				dataIndex: "updated",
				key: "updated",
				render: (text, record) => (
					<Tooltip title={moment(record.updated).format("LLLL")}>
						<span>{moment(record.updated).fromNow()}</span>
					</Tooltip>
				)
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLLL")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const data = [
			{
				key: "1",
				status: "subscribed",
				name: "Jane Smith",
				email: "2086",
				mobileNumber: "+16475552809",
				lastMessage: "hey, what time are you open to?",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				updated: 1538778093,
				created: 1538778093
			},
			{
				key: "2",
				name: "John Doe",
				email: "327",
				status: "optedOut",
				age: 42,
				mobileNumber: "+14165557835",
				address: "London No. 1 Lake Park",
				lastMessage: "Thanks for the info!",
				tags: ["loser"],
				updated: 1538778125,
				created: 1538778125
			}
		];

		const pageSizeMenu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.updatePageSize(10)}>
					10 Rows Per Page
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.updatePageSize(15)}>
					15 Rows Per Page
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.updatePageSize(20)}>
					20 Rows Per Page
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.updatePageSize(50)}>
					50 Rows Per Page
				</Menu.Item>
				<Menu.Item key="4" onClick={() => this.updatePageSize(100)}>
					100 Rows Per Page
				</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>Retail Customers</Breadcrumb.Item>
					<Breadcrumb.Item>Contacts</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<Link to={`/orgs/${orgId}/contactLists/all`}>
							<Tooltip title={"Back to All Lists"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<ContactListMenu selectedKey="capacity" orgId={orgId} listId={listId} />
					</div>
				</div>

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="Current Broadcast Capacity" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>Current broadcast capacity (how long it will take to message each contact in this list). Additional contacts will require additional time.</p>
							<p>
								<b>N Days</b> to Complete Broadacst
							</p>
							<p className="bold">
								350 Contacts <span style={{ fontWeight: "normal" }}>per day</span>
							</p>
						</Card>
						<Card className="card1" title="Capacity Calculator" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>Calculate how many days it will take to broadcast to this list:</p>
							<p>
								Total Contacts: <br />
								<Input type="number" placeholder="Total Contacts" style={{ maxWidth: "175px" }} />
							</p>
							<p>
								Total Source Numbers: <br />
								<Input type="number" placeholder="Total Source Numbers" style={{ maxWidth: "175px" }} />
							</p>
							<p>
								<b>N Days</b> to Complete Broadacst
							</p>
							<p>
								<b>NNNN Contacts</b> per day Broadcast Capacity
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card className="card1" title="Purchase Source Numbers" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">How many source numbers to BUY:</span>
							</p>
							<p className="tgrey">
								New{" "}
								<Tooltip title={"Phone numbers that broadcast & outbound messages are sent from."}>
									<u className="pointer">Source Numbers</u>
								</Tooltip>{" "}
								will allow this contact list to be broadcast to in less time.
							</p>
							<p>
								<Input type="number" placeholder="3 Source Numbers" style={{ maxWidth: "325px" }} />
							</p>
							<Divider style={{ margin: "16px 0px" }} />

							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button> */}
								<Button type="default" icon="plus">
									Purchase Source Numbers
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Release Source Numbers" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">How many source numbers to RELEASE:</span>
							</p>
							<p className="tgrey">
								Releasing{" "}
								<Tooltip title={"Phone numbers that broadcast & outbound messages are sent from."}>
									<u className="pointer">Source Numbers</u>
								</Tooltip>{" "}
								will increase the time required to broadcast messages to this list.
							</p>
							<p>
								<Input type="number" placeholder="3 Source Numbers" style={{ maxWidth: "325px" }} />
							</p>
							<Divider style={{ margin: "16px 0px" }} />

							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button> */}
								<Button type="danger" icon="minus">
									Release Source Numbers
								</Button>
							</p>
						</Card>
					</Col>
				</Row>

				{/* <Row gutter={24}>
					<Col span={12}>
						<Card className="card1" title="Purchase Source Numbers" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">How many source numbers to buy:</span>
							</p>
							<p className="tgrey">
								New{" "}
								<Tooltip title={"Phone numbers that broadcast & outbound messages are sent from."}>
									<u className="pointer">Source Numbers</u>
								</Tooltip>{" "}
								will allow this contact list to be broadcast to in less time.
							</p>
							<p>
								<Input type="number" placeholder="3 Source Numbers" style={{ maxWidth: "325px" }} />
							</p>
							<Divider style={{ margin: "16px 0px" }} />

							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								<Button type="danger" icon="plus">
									Purchase Source Numbers
								</Button>
							</p>
						</Card>
					</Col>
					<Col span={12}>
						<Card className="card1" title="Release Source Numbers" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">How many source numbers to release:</span>
							</p>
							<p className="tgrey">
								Releasing{" "}
								<Tooltip title={"Phone numbers that broadcast & outbound messages are sent from."}>
									<u className="pointer">Source Numbers</u>
								</Tooltip>{" "}
								will increase the time required to broadcast messages to this list.
							</p>
							<p>
								<Input type="number" placeholder="3 Source Numbers" style={{ maxWidth: "325px" }} />
							</p>
							<Divider style={{ margin: "16px 0px" }} />

							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								<Button type="danger" icon="minus">
									Release Source Numbers
								</Button>
							</p>
						</Card>
					</Col>
				</Row> */}

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={this.state.contacts} loading={this.state.isLoading} pagination={{ pageSize: this.state.pageSize }} /> */}
			</Content>
		);
	}
}
