import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown } from "antd";
import * as firebase from "firebase";
import moment from "moment";
import request from "request";

import { ContactListMenu } from "./menu.js";
import { ContactInfo } from "./contactInfo.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class Contacts extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		isAdding: false,
		contacts: [],
		pageSize: 50,
		contactList: { name: "Loading..." },
		firstName: "",
		lastName: "",
		mobileNumber: ""
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
			firstName: "",
			lastName: "",
			mobileNumber: ""
		});
	};

	callback(key) {
		console.log(key);
	}

	getContacts(orgId, listId) {
		firebase
			.firestore()
			.collection(`contacts`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.where("isDeleted", "==", false)
			// .orderBy("created", "desc") //order by most recently created
			// .orderBy("e164", "desc") //order by phone number (largest > smallest)
			.orderBy("e164", "asc") //order by phone number (smallest > largest)
			.limit(this.state.pageSize)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const contacts = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						contacts.push(record);
					});
					console.log("orgsList: ", contacts);
					this.setState({
						contacts: contacts,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactList: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		this.getContacts(orgId, listId);
		this.getContactList(listId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	addContact() {
		if (!this.state.mobileNumber) {
			Modal.error({
				title: "Missing Mobile Number",
				content: `Mobile number is required.`
			});
			return;
		}
		if (!this.state.firstName) {
			Modal.confirm({
				title: "Missing First Name",
				content: `First name is strongly recommended, but not required.`,
				onOk: () => {
					// alert("ok!");
					return;
				},
				onCancel: () => {
					// alert("cancel!");
					this.sendContactToApi();
				},
				okText: "Add First Name",
				cancelText: "Skip this Step"
			});
		} else {
			this.sendContactToApi();
		}
	}

	sendContactToApi() {
		this.setState({ isAdding: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		const formData = {
			action: "add",
			columnProperties: {
				firstName: 0,
				lastName: 1,
				// workNumber: 2
				mobileNumber: 2
			},
			listId: listId,
			orgId: orgId,
			spreadSheetData: [
				[this.state.firstName, this.state.lastName, this.state.mobileNumber]
				// ["bob", "oneUpdate", 16475550001, 14165550001, "sample"]
			]
		};
		console.log("formData: ", formData);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.importBulkContacts",
						headers: { authorization: "Bearer " + idToken },
						form: formData
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							Modal.error({
								title: "Error importing contact.",
								content: "Unable to import contact. Check that your form data is valid."
							});
							return this.setState({ isAdding: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							Modal.error({
								title: "Error importing contact.",
								content: "Unable to import contact. Check that your form data is valid."
							});
							return this.setState({ isAdding: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						Modal.success({
							title: "Imported contact successfully!",
							content: "Your imported contact has successfully been processed."
						});
						this.setState({ isAdding: false, visible: false, firstName: "", lastName: "", mobileNumber: "" });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isAdding: false });
			});
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);

		const columns = [
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				align: "center",
				width: "90px",
				render: (text, record) => {
					if (!record.isOptedOut && !record.isDeleted) {
						return (
							<Tooltip title="Subscribed">
								<Icon type="check" className="tgreen" />
							</Tooltip>
						);
					}
					if (record.isOptedOut && !record.isDeleted) {
						return (
							<Tooltip title="Opted Out">
								<Icon type="stop" className="tgrey" />
							</Tooltip>
						);
					}
					if (record.isDeleted) {
						return (
							<Tooltip title="Deleted">
								<Icon type="delete" className="tred" />
							</Tooltip>
						);
					}
					// {!record.isOptedOut && !record.isDeleted && (
					// 	<Tooltip title="Subscribed">
					// 		<Icon type="check" className="tgreen" />
					// 	</Tooltip>
					// )}
					// {record.isOptedOut && !record.isDeleted && (
					// 	<Tooltip title="Opted Out">
					// 		<Icon type="stop" className="tgrey" />
					// 	</Tooltip>
					// )}
					// {record.isDeleted && (
					// 	<Tooltip title="Deleted">
					// 		<Icon type="delete" className="tred" />
					// 	</Tooltip>
					// )}
				}

				// <Tooltip title={!record.isOptedOut ? "Subscribed" : "Opted Out"}>
				// 	<span className="">{!record.isOptedOut ? <Icon type="check" className="tgreen" /> : <Icon type="stop" className="tgrey" />}</span>
				// </Tooltip>
				// )
			},
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link to={`/orgs/${record.orgId}/contactLists/${record.listId}/contacts/${record.id}`} className="fs15 fw500">
						{record.firstName} {record.lastName}
					</Link>
				)
			},
			{
				title: "Number",
				dataIndex: "international",
				key: "international"
			},
			// {
			// 	title: "Last Message",
			// 	dataIndex: "lastMessage",
			// 	key: "lastMessage"
			// },
			{
				title: "Updated",
				dataIndex: "updated",
				key: "updated",
				render: (text, record) => (
					<Tooltip title={moment(record.updated).format("llll")}>
						<span>{moment(record.updated).fromNow()}</span>
					</Tooltip>
				)
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("llll")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const pageSizeMenu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.updatePageSize(10)}>
					10 Rows Per Page
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.updatePageSize(15)}>
					15 Rows Per Page
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.updatePageSize(20)}>
					20 Rows Per Page
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.updatePageSize(50)}>
					50 Rows Per Page
				</Menu.Item>
				<Menu.Item key="4" onClick={() => this.updatePageSize(100)}>
					100 Rows Per Page
				</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>Retail Customers</Breadcrumb.Item>
					<Breadcrumb.Item>Contacts</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1 cardHeaderSmall" style={{ padding: "12px 12px 0px 12px", background: "#fff", marginBottom: "24px" }}>
					<Link to={`/orgs/${orgId}/contactLists/all`}>
						<Tooltip title={"Back to All Lists"}>
							<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
						</Tooltip>
					</Link>

					<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					<Divider style={{ margin: "12px -12px 0px -12px" }} />
					<ContactListMenu selectedKey="contacts" orgId={orgId} listId={listId} />
				</div> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<Link to={`/orgs/${orgId}/contactLists/all`}>
							<Tooltip title={"Back to All Lists"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<ContactListMenu selectedKey="contacts" orgId={orgId} listId={listId} />
					</div>
				</div>

				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						{!this.state.isLoading && (
							<b style={{ fontSize: "16px" }}>
								{this.state.contactList.totalContacts} Contact
								{this.state.contactList.totalContacts === 1 ? "" : "s"}
							</b>
						)}
						{this.state.isLoading && <b style={{ fontSize: "16px" }}>Loading...</b>}
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px 12px 4px" }}>
						<span style={{}}>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} className="mr8 mb8">
								Add Contact
							</Button>
							<Link to={`/orgs/${orgId}/contactLists/${listId}/import`}>
								<Button type="" icon="upload" size={"default"} className="mr8 mb8">
									Import
								</Button>
							</Link>
							<Button type="" icon="download" size={"default"} className="mr8 mb8">
								Export
							</Button>
							<Dropdown overlay={pageSizeMenu} trigger={["click"]} className="mr8 mb8">
								{/* <a className="ant-dropdown-link" href="#">
								Click me <Icon type="down" />
								</a> */}
								<Button>
									{this.state.pageSize} Rows <Icon type="down" />
								</Button>
							</Dropdown>
						</span>
						<Search placeholder="Search Contacts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} className="mb8" enterButton />
					</div>

					<Modal
						title="Add Contact"
						visible={this.state.visible}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						footer={[
							<Button key="back" icon="close" onClick={this.handleCancel}>
								Cancel
							</Button>,
							<Button key="submit" type="primary" icon="check" loading={this.state.isAdding} onClick={e => this.addContact()}>
								Add Contact
							</Button>
						]}>
						<p className="bold" style={{ marginBottom: "8px" }}>
							First Name:
						</p>
						<p style={{ marginBottom: "24px" }}>
							<Input name="firstName" placeholder="First Name" style={{ maxWidth: "325px" }} value={this.state.firstName} onChange={e => this.handleChange(e)} />
						</p>
						<p className="bold" style={{ marginBottom: "8px" }}>
							Last Name:
						</p>
						<p style={{ marginBottom: "24px" }}>
							<Input name="lastName" placeholder="Last Name" style={{ maxWidth: "325px" }} value={this.state.lastName} onChange={e => this.handleChange(e)} />
						</p>
						<p className="bold" style={{ marginBottom: "8px" }}>
							Mobile Number:
						</p>
						<p style={{ marginBottom: "24px" }}>
							<Input name="mobileNumber" placeholder="+1-234-555-6789" style={{ maxWidth: "325px" }} value={this.state.mobileNumber} onChange={e => this.handleChange(e)} />
						</p>
						{/* <p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p> */}
					</Modal>
				</div>

				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.contacts}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					pagination={{ pageSize: this.state.pageSize }}
					onRow={record => {
						return {
							onClick: e => {
								// console.log("table record: ", record);
								this.props.history.push(`/orgs/${orgId}/contactLists/${listId}/contacts/${record.id}`);
							}
						};
					}}
				/>

				<Route exact path="/orgs/:orgId/contactLists/:listId/contacts/:contactId" component={ContactInfo} />
			</Content>
		);
	}
}
