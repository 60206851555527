import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Card, Row, Col, Button, Divider, Avatar } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class MyAccount extends React.Component {
	state = {
		collapsed: false
	};

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};
	render() {
		// console.log("this.props: ", this.props);
		return (
			<Content>
				<Breadcrumb style={{ margin: "16px 0" }}>
					<Breadcrumb.Item>My Account</Breadcrumb.Item>
					<Breadcrumb.Item>Profile</Breadcrumb.Item>
				</Breadcrumb>
				{/* <div className="card1" style={{ padding: 24, background: "#fff", minHeight: 360 }}>
					My Account
				</div> */}

				<div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>My Account</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/North Hill Paints/billing/current`}>Profile</Link>
							</Menu.Item>
							<Menu.Item key="history">
								<Link to={`/orgs/North Hill Paints/billing/history`}>Password</Link>
							</Menu.Item>
							<Menu.Item key="plan">
								<Link to={`/orgs/North Hill Paints/billing/plan`}>Delete</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div>

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="Profile Photo" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Update or Reset your profile photo?</p>
							<p style={{ textAlign: "center", margin: "12px 0px" }}>
								<Avatar size={150} icon="user" />
							</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								<Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button>
								<Button type="primary" icon="upload">
									Upload Photo
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Change Password" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Enter a new password for your account login:</p>
							<p style={{}}>New Password:</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button type="primary" icon="check">
									Update Password
								</Button>
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card className="card1" title="Account Info" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Name: </p>
							<p style={{}}>Email: </p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button type="primary" icon="edit">
									Edit Info
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Forgot Password" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Send a password reset email to:</p>
							<p style={{ color: "#2962ff" }}>harrison.krupnik@gmail.com</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button type="primary" icon="mail">
									Send Reset Email
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Delete Account" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Are you sure you want to Delete Your Account?</p>
							<p style={{}}>Warning: This action cannot be undone.</p>
							<p style={{}}>Learn more about *what you will lose access to*.</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button type="danger" icon="delete">
									Delete Account?
								</Button>
							</p>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}
