import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown, Card, Upload, message } from "antd";
import * as firebase from "firebase";
import moment from "moment";
import XLSX from "xlsx";
import request from "request";

import { ContactListMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;
const Dragger = Upload.Dragger;

// class App extends Component {
export class ImportContacts extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		isAdding: false,
		isRemoving: false,
		contacts: [],
		pageSize: 15,
		selectedFile: {},
		contactList: { name: "Loading..." },
		fileList: [],
		isProcessing: false,
		isListProcessing: false,
		isParsed: false,
		spreadSheetData: [],
		data_firstName: null,
		data_lastName: null,
		data_mobileNumber: null,
		data_workNumber: null,
		data_homeNumber: null,
		data_email: null,
		data_address: null,
		data_city: null,
		data_state: null,
		data_country: null,
		data_zip: null
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getContacts(orgId, listId) {
		firebase
			.firestore()
			.collection(`contacts`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const contacts = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						contacts.push(record);
					});
					console.log("orgsList: ", contacts);
					this.setState({
						contacts: contacts,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactList: record, isListProcessing: record.isProcessing });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		// this.getContacts(orgId, listId);
		this.getContactList(listId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	setColumnState(totalColumns) {
		const colsObj = {};
		for (let i = 0; i < totalColumns; i++) {
			colsObj["col" + i] = null;
			// colsObj["col" + i] = " - ";
			// colsObj["col" + i] = "Column " + i;
		}
		this.setState(colsObj);
	}

	// set or clear selected spreadsheet column
	setDataColumn(colNumber, dataType) {
		const selectedCol = {};

		if (dataType === null) {
			selectedCol["col" + colNumber] = null;
			selectedCol["data_" + dataType] = colNumber;
		} else {
			selectedCol["col" + colNumber] = dataType;
			selectedCol["data_" + dataType] = colNumber;
		}
		this.setState(selectedCol);
		// this.setState({
		// 	["col" + colNumber]: dataType,
		// 	[dataType]: colNumber
		// });
	}
	clearDataCols() {
		const clearStateKeys = {
			fileList: [],
			isProcessing: false,
			isParsed: false,
			totalFileRecords: null,
			col0: null //setting 1st col to null triggers all cols reset on next file upload
		};

		const validColProperties = ["firstName", "lastName", "mobileNumber", "workNumber", "homeNumber", "email", "address", "city", "state", "country", "zip"];
		for (let i = 0; i < validColProperties.length; i++) {
			clearStateKeys["data_" + validColProperties[i]] = null;
		}

		for (let i = 0; i < this.state.spreadSheetData.length; i++) {
			clearStateKeys["col" + i] = null;
		}

		this.setState(clearStateKeys);
	}

	readSpreadsheet(file) {
		const isReadAsBinaryString = true; // true: readAsBinaryString ; false: readAsArrayBuffer
		const reader = new FileReader();
		reader.onload = e => {
			const data = e.target.result;

			if (!isReadAsBinaryString) data = new Uint8Array(data);
			const workbook = XLSX.read(data, { type: isReadAsBinaryString ? "binary" : "array" });
			/* convert from workbook to array of arrays */
			const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
			// use defval: "" option to account for empty cell values in json arrays
			// https://github.com/SheetJS/js-xlsx/issues/159#issuecomment-403228877
			const spreadSheetData = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, defval: "" });

			console.log("workbook: ", spreadSheetData);
			const displayCols = [];
			const displayData = [];
			const dropDownMenu = columnNumber => (
				<Menu>
					<Menu.Item key={"colHeader" + columnNumber} onClick={() => this.setDataColumn(columnNumber, null)}>
						Column {columnNumber}
					</Menu.Item>
					{this.state["col" + columnNumber] && (
						<Menu.Item key={"clearCol" + columnNumber} onClick={() => this.setDataColumn(columnNumber, null)}>
							Clear Selection
						</Menu.Item>
					)}
					<Menu.Divider />
					<Menu.Item key={"firstName" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "firstName")}>
						First Name
					</Menu.Item>
					<Menu.Item key={"lastName" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "lastName")}>
						Last Name
					</Menu.Item>
					<SubMenu title="Phone">
						<Menu.Item key={"mobileNumber" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "mobileNumber")}>
							Mobile Phone - Priority 1
						</Menu.Item>
						<Menu.Item key={"workNumber" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "workNumber")}>
							Work Phone - Priority 2
						</Menu.Item>
						<Menu.Item key={"homeNumber" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "homeNumber")}>
							Home Phone - Priority 3
						</Menu.Item>
					</SubMenu>

					<Menu.Divider />
					<SubMenu title="Details">
						<Menu.Item key={"email" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "email")}>
							Email
						</Menu.Item>
						<Menu.Item key={"address" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "address")}>
							Address
						</Menu.Item>
						<Menu.Item key={"city" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "city")}>
							City
						</Menu.Item>
						<Menu.Item key={"state" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "state")}>
							Province/State
						</Menu.Item>
						<Menu.Item key={"country" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "country")}>
							Country
						</Menu.Item>
						<Menu.Item key={"zip" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "zip")}>
							Zip
						</Menu.Item>
					</SubMenu>

					<SubMenu title="Custom Var">
						<Menu.Item key={"custom1" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "custom1")}>
							Custom 1
						</Menu.Item>
						<Menu.Item key={"custom2" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "custom2")}>
							Custom 2
						</Menu.Item>
						<Menu.Item key={"custom3" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "custom3")}>
							Custom 3
						</Menu.Item>
						<Menu.Item key={"custom4" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "custom4")}>
							Custom 4
						</Menu.Item>
						<Menu.Item key={"custom5" + columnNumber} onClick={() => this.setDataColumn(columnNumber, "custom5")}>
							Custom 5
						</Menu.Item>
					</SubMenu>
					{/* "email", "address", "city", "state", "country", "zip" */}
					{/* <Menu.Divider /> */}
				</Menu>
			);

			for (let i = 0; i < 8; i++) {
				const rowRecord = { key: i };
				console.log("spreadSheetData[i]: ", spreadSheetData[i]);
				this.setState({ totalFileRecords: spreadSheetData.length });
				if (spreadSheetData[i]) {
					if (!this.state.col0) {
						this.setColumnState(spreadSheetData[i].length);
					}
					for (let j = 0; j < spreadSheetData[i].length; j++) {
						if (i === 0) {
							displayCols.push({
								// title: "Column " + j,
								title: (text, record) => (
									<Dropdown overlay={dropDownMenu(j)} trigger={["click"]}>
										<Button>
											{/* {"Column " + j} <Icon type="down" /> */}
											{this.state["col" + j] ? <b>{this.state["col" + j]}</b> : " - "} <Icon type="down" />
										</Button>
									</Dropdown>
								),
								key: "col" + j,
								dataIndex: "col" + j,
								width: "300px"
								// render: (text, record) => (
								// 	<Dropdown overlay={dropDownMenu} trigger={["click"]}>
								// 		<Button>{"Column " + j}</Button>
								// 	</Dropdown>
								// )
							});
						}
						// displayData.push()
						rowRecord["col" + j] = spreadSheetData[i][j];
					}
					displayData.push(rowRecord);
				}
			}
			console.log("displayCols: ", displayCols);
			console.log("displayData: ", displayData);
			this.setState({ isProcessing: false, isParsed: true, spreadSheetData: spreadSheetData, displayCols: displayCols, displayData: displayData });
		};
		if (isReadAsBinaryString) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);
	}

	removeContacts(actionType) {
		Modal.confirm({
			title: "WARNING: Are you sure you want to REMOVE Contacts?",
			content: (
				<div>
					<p>This will REMOVE the imported Contacts from this list.</p>
				</div>
			),
			cancelText: "(Danger Zone) Continue",
			okText: "Cancel",
			onOk: () => {
				// alert("ok!");
				return;
			},
			onCancel: () => {
				// alert("cancel!");
				this.setState({ isRemoving: true });
				this.uploadContacts(actionType);
			}
		});
	}

	uploadContacts(actionType) {
		console.log("checking imported contacts!");
		if (!actionType || (actionType !== "add" && actionType !== "remove")) {
			Modal.error({
				title: "Missing or Invalid Action Type",
				content: "Must specify action type of either: add or remove."
			});
			return;
		}
		// const selectedCols = [];
		const selectedColsObj = {};
		for (let i = 0; i < this.state.spreadSheetData[0].length; i++) {
			// console.log(`this.state[col+${i}]: `, this.state["col" + i]);

			if (this.state["col" + i] == null) continue;

			//if no matching col propery found in selected cols obj, add current propery
			if (selectedColsObj[this.state["col" + i]] == null) {
				selectedColsObj[this.state["col" + i]] = i;
			} else {
				Modal.error({
					title: "Duplicate Columns Selected: " + this.state["col" + i],
					content: "Cannot select the same property for multiple columns."
				});
				return;
			}
		}
		console.log("selectedColsObj: ", selectedColsObj);

		// if no mobile number col selected
		if (selectedColsObj.mobileNumber == null) {
			Modal.error({
				title: "No mobile number column selected.",
				content: "You must select a column to be used for the contact's mobile number. "
			});
			return;
		}
		// if no first name selected
		if (selectedColsObj.firstName == null) {
			Modal.confirm({
				title: "No first name column selected.",
				content: (
					<div>
						<p>It is recommended to select a column for the contact's first name.</p>
					</div>
				),
				cancelText: "Continue",
				okText: "Set First Name Col",
				onOk: () => {
					// alert("ok!");
					return;
				},
				onCancel: () => {
					// alert("cancel!");
					if (actionType === "add") this.setState({ isAdding: true });
					this.submitUploadContacts(selectedColsObj, actionType);
				}
			});
		} else {
			if (actionType === "add") this.setState({ isAdding: true });
			this.submitUploadContacts(selectedColsObj, actionType);
		}
	}

	submitUploadContacts(selectedColsObj, actionType) {
		console.log("uploading imported contacts!");
		// alert("continuing 1");

		// console.log("spreadSheetData: ", this.state.spreadSheetData);
		const formData = {
			orgId: this.props.match.params.orgId,
			listId: this.props.match.params.listId,
			action: actionType,
			columnProperties: selectedColsObj,
			spreadSheetData: this.state.spreadSheetData
		};
		for (let i = 0; i < formData.spreadSheetData.length; i++) {}
		console.log("formData: ", formData);

		// alert("continuing 2");
		// return;
		// request.post(
		// 	{
		// 		url: "http://localhost:8080/import.bulkContacts",
		// 		form: { firstNameCol: this.state.data_firstName, lastNameCol: this.state.data_lastName, phone1Col: this.state.data_mobile1, phone2Col: this.state.data_mobile2, spreadSheetData: this.state.spreadSheetData }
		// 	},
		// 	(err, httpResponse, body) => {
		// 		/* ... */
		// 		console.log("err: ", err);
		// 		console.log("httpResponse: ", httpResponse);
		// 		console.log("httpResponse.statusCode: ", httpResponse.statusCode);
		// 		console.log("body: ", body);
		// 	}
		// );
		// return;
		firebase
			.auth()
			//.currentUser.getIdToken(/* forceRefresh */ true)
			.currentUser.getIdToken()
			.then(uidToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.importBulkContacts",
						headers: { authorization: "Bearer " + uidToken },
						form: formData
					},
					(err, httpResponse, body) => {
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							Modal.error({
								title: "Error importing contacts.",
								content: "Unable to import contacts. Check that your form data is valid."
							});
							return this.setState({ isListProcessing: false, isAdding: false, isRemoving: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							Modal.error({
								title: "Error importing contacts.",
								content: "Unable to import contacts. Check that your form data is valid."
							});
							return this.setState({ isListProcessing: false, isAdding: false, isRemoving: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						Modal.success({
							title: "Imported contacts successfully!",
							content: "Your imported contacts have successfully been processed."
						});
						return this.setState({ isListProcessing: false, isAdding: false, isRemoving: false });
						// this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "", contactLists: [JSON.parse(body)].concat(this.state.contactLists) });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isListProcessing: false, isAdding: false });
			});
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);

		const promise1 = new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve("foo");
			}, 300);
		});

		const props = {
			name: "file",
			multiple: false,
			showUploadList: false,
			accept: ".csv,.xls,.xlsx",
			// action: "//jsonplaceholder.typicode.com/posts/",
			action: file => {
				console.log("UPLOAD ACTION FILE: ", file);
				return promise1.then(response => {
					console.log("promise1 response!");
				});
			},
			// action: new Promise((resolve, reject) => {
			// 	setTimeout(function() {
			// 		resolve("foo");
			// 	}, 300);
			// }),
			onChange(info) {
				const status = info.file.status;
				if (status !== "uploading") {
					console.log("ON CHANGE:");
					console.log(info.file, info.fileList);
				}
				if (status === "done") {
					message.success(`${info.file.name} file uploaded successfully.`);
				} else if (status === "error") {
					message.error(`${info.file.name} file upload failed.`);
				}
			},
			beforeUpload: file => {
				// this.setState(({ fileList }) => ({
				// 	fileList: [...fileList, file]
				// }));
				this.setState({ fileList: [file], isProcessing: true });
				this.readSpreadsheet(file);
				return false;
			},
			fileList: this.state.fileList,
			onRemove: file => {
				// this.setState(({ fileList }) => {
				// 	const index = fileList.indexOf(file);
				// 	const newFileList = fileList.slice();
				// 	newFileList.splice(index, 1);
				// 	return {
				// 		fileList: newFileList
				// 	};
				// });
				this.setState({ fileList: [] });
			}
		};

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>Retail Customers</Breadcrumb.Item>
					<Breadcrumb.Item>Contacts</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<Link to={`/orgs/${orgId}/contactLists/all`}>
							<Tooltip title={"Back to All Lists"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<ContactListMenu selectedKey="import" orgId={orgId} listId={listId} />
					</div>
				</div>

				<Card className="card1" title="Import Contacts" style={{ margin: "auto", marginBottom: "24px" }}>
					{this.state.isListProcessing && (
						<div>
							<p className="taCenter">
								<Icon type="loading" style={{ fontSize: "28px" }} />
							</p>
							<p className="taCenter bold">List is currently processing imported contacts.</p>
							<p className="taCenter">You can safely leave this page and your import job will continue in the background.</p>
							<p className="taCenter">Please wait until processing is complete before starting next import job.</p>
						</div>
					)}
					{this.state.fileList.length === 0 && !this.state.isListProcessing && (
						<div style={{ marginBottom: "14px" }}>
							<p className="bold">Import Contacts from CSV, XLS, or XLSX File </p>
							<p>You can import up to 10,000 contacts into a single contact list. Mobile Number is the only required field.</p>
							<br />
							<Dragger {...props}>
								<p className="ant-upload-drag-icon">
									<Icon type="inbox" />
								</p>
								<p className="ant-upload-text">Click or drag Contacts File here to upload</p>
							</Dragger>
							<Divider />
						</div>
					)}

					{this.state.fileList.length > 0 && !this.state.isListProcessing && (
						<div>
							<p className="fs15">
								<span className="tgrey">Selected File:</span> {this.state.fileList.length > 0 ? <b>{this.state.fileList[0].name}</b> : "No File Selected"}
							</p>
							<p style={{}}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button
									icon="close"
									style={{ marginRight: "12px" }}
									onClick={() => {
										this.clearDataCols();
										// this.setState({
										// 	fileList: [],
										// 	isProcessing: false,
										// 	isParsed: false,
										// 	totalFileRecords: null,
										// 	col0: null, //setting 1st col to null triggers all cols reset on next file upload
										// 	data_firstName: null,
										// 	data_lastName: null,
										// 	data_mobileNumber: null,
										// 	data_workNumber: null,
										// 	data_homeNumber: null
										// });
									}}>
									Clear File
								</Button>

								{!this.state.isProcessing && (
									<Button
										type="danger"
										icon="delete"
										style={{ marginRight: "12px" }}
										loading={this.state.isRemoving}
										onClick={() => {
											this.removeContacts("remove");
										}}>
										Remove from List
									</Button>
								)}
								{!this.state.isProcessing && (
									<Button
										type="primary"
										icon="upload"
										loading={this.state.isAdding}
										onClick={() => {
											this.uploadContacts("add");
										}}>
										Add to List
									</Button>
								)}
							</p>

							{this.state.isProcessing && (
								<p className="taCenter">
									<br />
									<Icon type="loading" style={{ fontSize: "28px" }} />
									<br />
									Parsing Contacts...
								</p>
							)}
							{this.state.isParsed && !this.state.isProcessing && (
								<div className="">
									<Divider style={{ margin: "16px 0px" }} />
									<p className="fs15">
										Total Records in File:&nbsp;
										<b>
											{this.state.totalFileRecords} {this.state.totalFileRecords ? "Rows" : null}
										</b>
									</p>
									{/* First Name: <Input type="number" name="data_firstName" value={this.state.data_firstName} onChange={e => this.handleChange(e)} />
										<br />
										Last Name: <Input type="number" name="data_lastName" value={this.state.data_lastName} onChange={e => this.handleChange(e)} />
										<br />
										Phone 1: <Input type="number" name="data_mobile1" value={this.state.data_mobile1} onChange={e => this.handleChange(e)} />
										<br />
										Phone 2: <Input type="number" name="data_mobile2" value={this.state.data_mobile2} onChange={e => this.handleChange(e)} />
										<br /> */}
									<Table className="card1 bgWhite" columns={this.state.displayCols} dataSource={this.state.displayData} pagination={false} />
								</div>
							)}
						</div>
					)}
				</Card>

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={this.state.contacts} loading={this.state.isLoading} pagination={{ pageSize: this.state.pageSize }} /> */}
			</Content>
		);
	}
}
