import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Divider, Avatar, Button, Modal, Upload, Menu, Input, Tooltip, Icon } from "antd";

import * as firebase from "firebase";
import moment from "moment";
import request from "request";
import { Picker } from "emoji-mart";

// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { TextArea } = Input;
const Dragger = Upload.Dragger;

// class App extends Component {
export class ChatHistory extends React.Component {
	state = {
		isLoading: true,
		isContactInfoLoading: true,
		isSrcNumLoading: true,
		isLoadingMore: false,
		isChatsLoading: true,
		isMoreChatsLoading: false,
		isSendingChat: false,
		isUpdatingContact: false,
		isModalVisible: false,
		isModalOptOutVisible: false,
		isModalDeleteVisible: false,
		isModalEmojiVisible: false,
		isModalEditVisible: false,
		isAddPhoto: false,
		contactInfo: {},
		contactList: {},
		contactListSourceNumbers: [],
		chatMessages: [],
		pageSize: 15, // how many chat msgs to load
		cursorDoc: "",
		chatMessage: "",
		chatMessageModal: "",
		orgInfo: {},
		editFirstName: "",
		editLastName: "",
		editMobileNumber: "",
		messageCharCount: 0,
		availableMsgChars: 160,
		msgSmsParts: 0,
		is7Bit: true,
		//img upload state
		previewVisible: false,
		previewImage: "",
		previewFile: {},
		mmsList: [],
		fileList: [],
		msgFilesList: []
	};

	// {this.state.messageCharCount}/{this.state.availableMsgChars} Characters &middot; Approx {this.state.msgSmsParts} SMS Part{this.state.msgSmsParts > 1 ? "s" : ""} &middot; {this.state.msgFilesList.length} MMS Parts

	// =============================
	// image upload funcs: START
	handleImgPreview = file => {
		console.log("file: ", file);
		// this.setState({
		// 	previewImage: file.url || file.thumbUrl,
		// 	previewVisible: true,
		// 	previewFile: file
		// });
		// adding check if NO file.ulr, as "file" may be submitted as only a url when displaying chat history item.mediaUrl
		if (file.url) {
			this.setState({
				previewImage: file.url || file.thumbUrl,
				previewVisible: true,
				previewFile: file
			});
		} else {
			this.setState({
				previewImage: file,
				previewVisible: true,
				previewFile: file
			});
		}
	};

	handleImgChange = ({ fileList }) => this.setState({ fileList });

	handleImgCancel = () => this.setState({ previewVisible: false });

	handleChange(e) {
		// console.log("e: ", e);
		if (e.target.name === "chatMessageModal") {
			const countMessage = this.countMessageChars(e.target.value);
			// console.log("counteMessage: ", countMessage);
			this.setState({
				[e.target.name]: e.target.value,
				is7Bit: countMessage.is7Bit,
				messageCharCount: countMessage.messageCharCount,
				availableMsgChars: countMessage.availableMsgChars,
				msgSmsParts: countMessage.msgSmsParts
			});
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	}

	totalImgMB(filesArray) {
		let totalBytes = 0;
		for (let i = 0; i < filesArray.length; i++) {
			totalBytes += filesArray[i].size;
		}
		return (totalBytes / 1024 / 1024).toFixed(2);
	}

	removeFile(index) {
		const msgFiles = this.state.msgFilesList;
		msgFiles.splice(index, 1);
		this.setState({ msgFilesList: msgFiles });
	}
	// image upload funcs: END
	// =============================

	showModal = () => {
		const currChatText = this.state.chatMessage;
		this.setState({
			isModalVisible: true,
			chatMessageModal: currChatText
		});
	};

	handleOk = e => {
		console.log(e);
		const chatText = this.state.chatMessageModal;
		const chatMsg = {
			name: "North Hill Paints",
			description: chatText,
			from: "org",
			direction: "outbound",
			sendTime: moment().valueOf(),
			text: chatText
		};
		const chatHistory = this.state.chatMessages;
		chatHistory.push(chatMsg);
		this.setState({ loading: true, chatMessage: "", chatMessageModal: "", chatMessages: chatHistory }, () => this.scrollChatToBottom());
		setTimeout(() => {
			this.setState({ loading: false, isModalVisible: false });
		}, 0);
	};

	handleCancel = e => {
		console.log(e);
		const chatMsg = this.state.chatMessageModal;
		this.setState({
			isModalVisible: false,
			chatMessage: chatMsg,
			chatMessageModal: ""
		});
	};

	handleSubOk = e => {
		console.log(e);
		this.setState({
			isModalEmojiVisible: false,
			isModalOptOutVisible: false,
			isModalDeleteVisible: false,
			isModalEditVisible: false
		});
	};

	handleSubCancel = e => {
		console.log(e);
		this.setState({
			isModalEmojiVisible: false,
			isModalOptOutVisible: false,
			isModalDeleteVisible: false,
			isModalEditVisible: false
		});
	};

	addEmoji = emoji => {
		console.log("selected emoji: ", emoji);
		const chatMsg = this.state.chatMessageModal;
		let newchatMsg = chatMsg + emoji.native;
		const countMessage = this.countMessageChars(newchatMsg);
		// console.log("counteMessage: ", countMessage);
		this.setState({
			chatMessageModal: newchatMsg,
			isModalEmojiVisible: false,
			is7Bit: countMessage.is7Bit,
			messageCharCount: countMessage.messageCharCount,
			availableMsgChars: countMessage.availableMsgChars,
			msgSmsParts: countMessage.msgSmsParts
		});
	};

	componentDidMount() {
		const orgId = this.props.match.params.orgId;
		const contactId = this.props.match.params.contactId;
		this.getOrgInfo(orgId);
		this.getContactInfo(contactId);
		// if (this.props.match.params.chatTab === "history")
		this.getChatHistory(orgId, contactId);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log("CHAT HISTORY prevProps: ", prevProps);
		// console.log("CHAT HISTORY this.props: ", this.props);
		// console.log("CHAT HISTORY prevState: ", prevState);
		// console.log("CHAT HISTORY snapshot: ", snapshot);
		if (this.props.match.params.contactId !== prevProps.match.params.contactId) {
			console.log("CHAT HISTORY prevProps: ", prevProps);
			console.log("CHAT HISTORY this.props: ", this.props);
			// alert("new contact id");
			console.log("CHAT HISTORY: NEW CONTACT ID: ", this.props.match.params.contactId);
			this.setState(
				{
					isLoading: true,
					isChatsLoading: true,
					isContactInfoLoading: true,
					isSrcNumLoading: true,
					cursorDoc: "",
					// clear chat input modal:
					chatMessage: "",
					chatMessageModal: "",
					isAddPhoto: false,
					previewVisible: false,
					previewImage: "",
					previewFile: {},
					mmsList: [],
					fileList: [],
					msgFilesList: []
				},
				() => {
					this.getContactInfo(this.props.match.params.contactId);
					this.getChatHistory(this.props.match.params.orgId, this.props.match.params.contactId);
				}
			);

			// if (this.props.match.params.chatTab === "history"){
			// 	this.getChatHistory(this.props.match.params.orgId, this.props.match.params.contactId);
			// }
		}
	}

	getOrgInfo(orgId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						this.setState({ orgInfo: record });
					} else {
						// doc.data() will be undefined in this case
						console.log("No org record document!");
						// this.props.history.push(`/orgs/${this.props.match.params.orgId}/chats/inbound`);
						// Modal.error({
						// 	title: "Invalid Chat Contact Id",
						// 	content: "This contact does not exist."
						// });
						// this.setState({ isLoading: false });
					}
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getListInfo(orgId, listId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("CONTACT LIST data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// const contactListsObj = this.state.contactListsObj;
						// contactListsObj[record.id] = record;
						this.setState({ contactList: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getContactInfo(contactId) {
		firebase
			.firestore()
			.doc(`contacts/${contactId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						this.setState({ contactInfo: record, isLoading: false, isContactInfoLoading: false });
						this.getListInfo(record.orgId, record.listId);
						this.getContactListSourceNumbers(record.orgId, record.listId);
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No contact record document: Invalid chat history! Redirecting...");
						this.props.history.push(`/orgs/${this.props.match.params.orgId}/chats/inbound`);
						Modal.error({
							title: "Invalid Chat Contact Id",
							content: "This contact does not exist."
						});
						this.setState({ isLoading: false });
					}
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactListSourceNumbers(orgId, listId) {
		firebase
			.firestore()
			.collection(`sourceNumbers`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.where("isReleased", "==", false)
			.orderBy("created", "desc")
			// .limit(350)
			.limit(1)
			.get()
			.then(
				querySnapshot => {
					const records = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						// record.key = doc.id;
						// record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// // if (!record.email) record.email = "sample@email.com";
						// console.log("src num record: ", record);
						records.push(record);
					});
					console.log("sourceNumbers: ", records);
					this.setState({
						contactListSourceNumbers: records,
						isSrcNumLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getChatHistory(orgId, contactId) {
		console.log("GETTING CHAT HISTORY for contactId: ", contactId);
		let dbRef = firebase
			.firestore()
			.collection(`/smsLogs/`)
			.where("contactId", "==", contactId)
			.where("orgId", "==", orgId)
			.orderBy("sendTime", "desc")
			.limit(this.state.pageSize);
		const cursorDoc = this.state.cursorDoc;
		if (cursorDoc) {
			dbRef = dbRef.startAfter(cursorDoc);
			this.setState({ isLoadingMore: true });
		}
		const chatWindow = document.getElementById("chatMessagesList");
		const originalScrollHeight = chatWindow ? chatWindow.scrollHeight : 0;

		dbRef.get().then(
			querySnapshot => {
				// const agentsList = this.state.orgsList;
				const records = [];
				let chats = this.state.chatMessages;
				let isAllLoaded = false;
				querySnapshot.forEach(doc => {
					// console.log(`${doc.id} => ${doc.data()}`);
					const record = doc.data();
					record.id = doc.id;
					record.key = doc.id;
					// record.added = doc.data().added.getTime();
					// if (!record.email) record.email = "sample@email.com";
					// console.log("record: ", record);
					// records.push(record);
					records.unshift(record);
				});
				console.log("getChatHistory: ", records);
				// add additional records IF pagination cursor
				if (cursorDoc) {
					chats = records.concat(chats);
				} else {
					chats = records;
				}
				// set ALL loaded if records length is less than page size (meaning no additional records)
				if (records.length < this.state.pageSize) {
					isAllLoaded = true;
				}
				this.setState(
					{
						chatMessages: chats,
						isChatsLoading: false,
						cursorDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
						isAllChatsLoaded: isAllLoaded,
						isLoadingMore: false
					},
					() => {
						if (!cursorDoc) {
							console.log("FIRST LOAD OF CHAT HISTORY");
							this.scrollChatToBottom();
							this.getRealtimeChats(orgId, contactId);
						} else {
							// scroll to previous chat height position (so user scrolls UP to display additional chat history)
							const chatWindow = document.getElementById("chatMessagesList");
							const newScrollHeight = chatWindow.scrollHeight - originalScrollHeight;
							// this.scrollChatToBottom(newScrollHeight);
							chatWindow.scrollTo(0, newScrollHeight);
						}
					}
				);
			},
			error => {
				console.log("firebase error: ", error);
				this.setState({ isLoading: false });
			}
		);
	}

	getChatInitials(contactInfo) {
		// const splitName = name.toUpperCase().split(" ");
		// console.log("splitName: ", splitName);
		// const initials = splitName[0].substring(0, 1) + splitName[1].substring(0, 1);
		let initials = "";
		if (contactInfo.firstName) {
			initials = contactInfo.firstName.substring(0, 1).toUpperCase();
			if (contactInfo.lastName) {
				initials += contactInfo.lastName.substring(0, 1).toUpperCase();
			}
		} else {
			initials = contactInfo.substring(0, 1).toUpperCase();
		}
		// console.log("intials: ", initials);
		return initials;
	}

	// handleImgPreview = file => {
	// 	console.log("file: ", file);
	// 	this.setState({
	// 		// previewImage: file.url || file.thumbUrl,
	// 		previewImage: file,
	// 		previewVisible: true,
	// 		previewFile: file
	// 	});
	// };

	chatBubble(item) {
		return (
			<div>
				{/* mediaUrl vs mmsList */}
				{item.mediaUrl
					? item.mediaUrl.map((fileUrl, index) => {
							return (
								<div className="" key={item.id + "_msgFile" + index}>
									<div className="pictureCardCustom mb12" style={{ border: "1px solid #ccc" }}>
										<img id={"msgFileImgId" + index} className="pointer pb0" alt="file name" style={{}} src={fileUrl} onClick={e => this.handleImgPreview(fileUrl)} />
										{/* <img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{}} src={file.url} onClick={e => this.handleImgPreview(file)} /> */}
									</div>
								</div>
							);
					  })
					: null}

				{item.MediaUrl0 ? (
					<div className="" key={item.id + "_msgFile0"}>
						<div className="pictureCardCustom mb12" style={{ border: "1px solid #ccc" }}>
							<img id={item.id + "_msgFileImgId)"} className="pointer pb0" alt="file name" style={{}} src={item.MediaUrl0} onClick={e => this.handleImgPreview(item.MediaUrl0)} />
							{/* <img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{}} src={file.url} onClick={e => this.handleImgPreview(file)} /> */}
						</div>
					</div>
				) : null}

				<div className="" style={{ whiteSpace: "pre-wrap", background: item.direction === "inbound" ? "#2962ff" : "#fff", color: item.direction === "inbound" ? "#fff" : "#333", border: item.direction === "inbound" ? "0px" : "1px solid #ccc", display: "inline-block", borderRadius: "4px", padding: "4px 8px ", maxWidth: "260px" }}>
					{item.text}
				</div>
				<div className="" style={{ color: "#aaa" }}>
					{item.direction === "inbound" ? this.state.contactInfo.firstName + " " + this.state.contactInfo.lastName : this.state.orgInfo.name} &middot;{" "}
					{item.broadcastId && (
						<span>
							<Tooltip title="Broadcast">
								<Icon className="pointer tblue" type="notification" />
							</Tooltip>{" "}
							&middot;{" "}
						</span>
					)}
					<span>
						<Tooltip title="Send Status">
							{item.pipelineStatus === "broadcastCancelled" && <span className="pointer tred">Msg Cancelled</span>}
							{item.pipelineStatus === "failed" && <span className="pointer tred">{item.pipelineStatus}</span>}
							{item.pipelineStatus !== "broadcastCancelled" && item.pipelineStatus !== "failed" && <span className="pointer tgrey">{item.pipelineStatus}</span>}
						</Tooltip>{" "}
						&middot;{" "}
					</span>
					<Tooltip title={moment(item.sendTime).format("LLLL")}>
						<span className={`pointer ${item.sendTime > moment().valueOf() ? "tblue " : ""}`}>
							{item.sendTime > moment().valueOf() ? "scheduled " : ""} {moment(item.sendTime).fromNow()}
						</span>
					</Tooltip>
				</div>
			</div>
		);
	}

	chatsConvoHeader(name, listId, updated) {
		return (
			<div className="">
				<span style={{}}>{name}</span> <span>&middot; {listId}</span> <span style={{ float: "right" }}>{updated}</span>
			</div>
		);
	}

	scrollChatToBottom(timeDelay, attempts) {
		console.log("chat scroll to bottom");
		const timeoutMult = 1.5;
		setTimeout(
			() => {
				const chatMessagesList = document.getElementById("chatMessagesList");
				if (chatMessagesList) {
					chatMessagesList.scrollTop = chatMessagesList.scrollHeight;
				} else {
					if (!attempts || attempts < 10) {
						this.scrollChatToBottom(timeDelay ? timeDelay * timeoutMult : 250, attempts ? attempts + 1 : 1);
					}
				}
			},
			timeDelay ? timeDelay : 0
		);
	}

	is7BitString(text) {
		// https://stackoverflow.com/questions/12673120/how-to-detect-non-gsm-7-bit-alphabet-characters-in-input-field
		// regex containing all chars of 7bit alphabet
		const regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
		//test if chat input text contains non 7bit characters
		let is7Bit = regexp.test(text);
		console.log("is7bit: ", is7Bit);
		// console.log("containsNonLatinCodepoints: ", this.containsNonLatinCodepoints(text));
		return is7Bit;
		// return regexp.test(text);
	}

	countMessageChars(messageText) {
		// messageCharCount: 23,
		// availableMsgChars: 160,
		// msgSmsParts: 1,
		const msgLength = messageText.length;
		const sms1PartLength = 160;
		const smsMultiPartLength = 153;
		const smsUnicode1PartLength = 70;
		const smsUnicodeMultiPartLength = 67;
		let availableChars = 160;
		let msgParts = 1;
		let is7Bit = this.is7BitString(messageText);
		// if 7bit
		if (is7Bit) {
			if (msgLength > sms1PartLength) {
				msgParts = Math.ceil(msgLength / smsMultiPartLength);
				availableChars = msgParts * smsMultiPartLength;
			}
			// else unicode msg
		} else {
			if (msgLength > smsUnicode1PartLength) {
				msgParts = Math.ceil(msgLength / smsUnicodeMultiPartLength);
				availableChars = msgParts * smsUnicodeMultiPartLength;
			} else {
				availableChars = smsUnicode1PartLength;
			}
		}
		// console.log("messageCharCount: ", msgLength);
		// console.log("availableMsgChars: ", availableChars);
		// console.log("msgSmsParts: ", msgParts);
		// this.setState({
		// 	is7Bit: is7Bit,
		// 	messageCharCount: msgLength,
		// 	availableMsgChars: availableChars,
		// 	msgSmsParts: msgParts
		// });
		const response = {
			is7Bit: is7Bit,
			messageCharCount: msgLength,
			availableMsgChars: availableChars,
			msgSmsParts: msgParts
		};
		return response;
	}

	sendInboundChatMessage() {
		const contactInfo = this.state.contactInfo;
		const contactList = this.state.contactList;
		const message = this.state.chatMessageModal;
		if (!contactInfo.e164) {
			Modal.error({
				title: "Missing Contact e164",
				content: `Contact e164 required.`
			});
			return;
		}
		if (!message) {
			Modal.error({
				title: "No Chat Message",
				content: `You cannot send an empty chat.`
			});
			return;
		}
		// alert("create contact list api");
		this.setState({ isSendingChat: true });
		let numSegments = this.countMessageChars(message);
		numSegments = numSegments.msgSmsParts.toString();
		const requestForm = {
			ApiVersion: "2010-04-01",
			SmsStatus: "received",
			NumSegments: numSegments,
			From: contactInfo.e164,
			SmsSid: "MMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
			SmsMessageSid: "MMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
			MessageSid: "MMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
			AccountSid: "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
			MessagingServiceSid: "MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
			To: "+12898070245",
			Body: message,
			// MMS PARAMS:
			NumMedia: "0"
			// MediaContentType0: "image/jpeg",
			// MediaUrl0: "https://api.twilio.com/2010-04-01/Accounts/ACf8a14a06aab0347d5e35316143b69af3/Messages/MMca80bd07d5140e0ddadf0ddcd8eed655/Media/ME86c8584d9c0a380c528e406083047991",
		};
		const reqData = { orgId: contactInfo.orgId, listId: contactInfo.listId, message: requestForm };
		console.log("requestUrl: ", process.env.REACT_APP_API + "v2/sms.devInbound");
		console.log("reqData: ", reqData);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/sms.devInbound",
						headers: { authorization: "Bearer " + idToken },
						form: reqData
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isSendingChat: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isSendingChat: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({ isSendingChat: false, isModalVisible: false, chatMessage: "", chatMessageModal: "", is7Bit: true });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isSendingChat: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
		// request.post(
		// 	{
		// 		url: process.env.REACT_APP_CLOUD_FUNCS_URL + "vantageTwilioCallback",
		// 		headers: { "X-Twilio-Signature": "random-x-twilio-signature", "Content-Type": "application/json" },
		// 		form: requestForm
		// 	},
		// 	(err, httpResponse, body) => {
		// 		/* ... */
		// 		// console.log("err: ", err);
		// 		// console.log("httpResponse: ", httpResponse);
		// 		// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
		// 		// console.log("body: ", body);
		// 		// if request error
		// 		if (err) {
		// 			console.log("http request error: ", err);
		// 			return this.setState({ isSendingChat: false });
		// 			// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
		// 		}
		// 		// if request invalid
		// 		if (httpResponse.statusCode === 500) {
		// 			// console.log("http request invalid: ", JSON.parse(body));
		// 			console.log("http request invalid: ", body);
		// 			// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
		// 			return this.setState({ isSendingChat: false });
		// 		}
		// 		// if request success
		// 		console.log("http request success: ", JSON.parse(body));
		// 		this.setState({ isSendingChat: false, isModalVisible: false, chatMessage: "", chatMessageModal: "" });
		// 		// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
		// 	}
		// );
	}

	uploadMediaFile(file, userId, orgId, fileUid) {
		return new Promise((resolve, reject) => {
			const uploadTask = firebase
				.storage()
				.ref()
				.child(`mms/${userId}/${orgId}/${fileUid}-${file.name}`)
				.put(file);

			// Register three observers:
			// 1. 'state_changed' observer, called any time the state changes
			// 2. Error observer, called on failure
			// 3. Completion observer, called on successful completion
			uploadTask.on(
				"state_changed",
				snapshot => {
					// Observe state change events such as progress, pause, and resume
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log("Upload is paused");
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log("Upload is running");
							break;
						default:
							console.log("upload default state..");
							break;
					}
				},
				error => {
					// Handle unsuccessful uploads
					console.log("error uploading file: ", file.name, error);
					reject(false);
				},
				() => {
					// Handle successful uploads on complete
					console.log("success uploading file: ", file.name);
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
						console.log("File available at", downloadURL);
						resolve(downloadURL);
					});
				}
			);
		});
	}

	getCurrentUser() {
		return new Promise((resolve, reject) => {
			firebase.auth().onAuthStateChanged(user => {
				if (user) {
					// User is signed in.
					console.log("current user: ", user);
					resolve(user);
				} else {
					// No user is signed in.
					console.log("no current user found: ", user);
					reject();
				}
			});
		});
	}

	async sendChatMessage() {
		const contactInfo = this.state.contactInfo;
		const message = this.state.chatMessageModal;
		if (!contactInfo.orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must have an Organization Id.`
			});
			return;
		}
		if (!contactInfo.listId) {
			Modal.error({
				title: "Missing List Id",
				content: `You must have a List Id.`
			});
			return;
		}
		if (!contactInfo.id) {
			Modal.error({
				title: "Missing Contact Id",
				content: `You must have a Contact Id.`
			});
			return;
		}
		if (!message) {
			Modal.error({
				title: "No Chat Message",
				content: `You cannot send an empty chat.`
			});
			return;
		}
		// mms image error checks
		if (this.state.msgFilesList.length > 10) {
			Modal.error({
				title: "Too many images in this message",
				content: `Maximum of 10 images allowed per message. Having only 1 image per broadcast is recommended though.`
			});
			return;
		}
		if (this.totalImgMB(this.state.msgFilesList) > 4.8) {
			Modal.error({
				title: "Media size too large",
				content: `Maximum of 5 MB allowed per broadcast. We limit media to a max of 4.8 MB to account for message text. Your current total is ${this.totalImgMB(this.state.msgFilesList)} MB.`
			});
			return;
		}
		// alert("create contact list api");
		this.setState({ isSendingChat: true });
		const requestForm = {
			orgId: contactInfo.orgId,
			listId: contactInfo.listId,
			contactId: contactInfo.id,
			message: message
		};
		const user = await this.getCurrentUser();
		const orgId = this.props.match.params.orgId;
		const mmsList = [];
		for (let i = 0; i < this.state.msgFilesList.length; i++) {
			const uniqueId = new Date().getTime(); //milliseconds since epoc, used so each filename is unique
			// const downloadUrl = await this.getDownloadUrl(broadcastFormData.msgFilesList[i], user.uid, orgId, uniqueId);
			// broadcastFormData.msgFilesList[i].downloadUrl = downloadUrl;
			const downloadUrl = await this.uploadMediaFile(this.state.msgFilesList[i], user.uid, orgId, uniqueId);
			mmsList.push({ uid: uniqueId, name: this.state.msgFilesList[i].name, url: downloadUrl });
		}
		requestForm.mmsList = mmsList;
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/sms.quickSend",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isSendingChat: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							const errorObj = JSON.parse(body);
							if (errorObj.error && errorObj.error.message) {
								Modal.error({
									title: errorObj.error.code,
									content: errorObj.error.message
								});
							}
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isSendingChat: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({
							isSendingChat: false,
							isModalVisible: false,
							chatMessage: "",
							chatMessageModal: "",
							is7Bit: true,
							//img upload state
							isAddPhoto: false,
							previewVisible: false,
							previewImage: "",
							previewFile: {},
							mmsList: [],
							fileList: [],
							msgFilesList: []
						});
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isSendingChat: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	updateContact(actionType) {
		const contactInfo = this.state.contactInfo;
		if (!contactInfo.orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must an Organization Id.`
			});
			return;
		}
		if (!contactInfo.listId) {
			Modal.error({
				title: "Missing List Id",
				content: `You must an List Id.`
			});
			return;
		}
		if (!contactInfo.id) {
			Modal.error({
				title: "Missing Contact Id",
				content: `You must an Contact Id.`
			});
			return;
		}
		if (!actionType) {
			Modal.error({
				title: "Missing Action Type",
				content: `You must specify an update action.`
			});
			return;
		}
		if (actionType !== "optOut" && actionType !== "delete") {
			Modal.error({
				title: "Invalid Action Type",
				content: `You must specify a valid action type.`
			});
			return;
		}
		// alert("create contact list api");
		this.setState({ isUpdatingContact: true });
		const requestForm = {
			orgId: contactInfo.orgId,
			listId: contactInfo.listId,
			contactId: contactInfo.id,
			action: actionType
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.updateContact",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingContact: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdatingContact: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({ isUpdatingContact: false, isModalOptOutVisible: false, isModalDeleteVisible: false });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isSendingChat: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	optOutContact() {
		const contactInfo = this.state.contactInfo;
		if (!contactInfo.orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must an Organization Id.`
			});
			return;
		}
		if (!contactInfo.listId) {
			Modal.error({
				title: "Missing List Id",
				content: `You must an List Id.`
			});
			return;
		}
		if (!contactInfo.id) {
			Modal.error({
				title: "Missing Contact Id",
				content: `You must an Contact Id.`
			});
			return;
		}
		this.setState({ isUpdatingContact: true });
		const requestForm = {
			orgId: contactInfo.orgId,
			listId: contactInfo.listId,
			contactId: contactInfo.id
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.optOutContact",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingContact: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdatingContact: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({ isUpdatingContact: false });
						this.handleSubCancel();
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingContact: false });
				this.handleSubCancel();
			});
	}

	deleteContact() {
		const contactInfo = this.state.contactInfo;
		if (!contactInfo.orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must an Organization Id.`
			});
			return;
		}
		if (!contactInfo.listId) {
			Modal.error({
				title: "Missing List Id",
				content: `You must an List Id.`
			});
			return;
		}
		if (!contactInfo.id) {
			Modal.error({
				title: "Missing Contact Id",
				content: `You must an Contact Id.`
			});
			return;
		}
		this.setState({ isUpdatingContact: true });
		const requestForm = {
			orgId: contactInfo.orgId,
			listId: contactInfo.listId,
			contactId: contactInfo.id
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.deleteContact",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingContact: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdatingContact: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({ isUpdatingContact: false });
						this.handleSubCancel();
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingContact: false });
				this.handleSubCancel();
			});
	}

	editContact() {
		const contactInfo = this.state.contactInfo;
		if (!contactInfo.orgId) {
			Modal.error({
				title: "Missing Organization Id",
				content: `You must an Organization Id.`
			});
			return;
		}
		if (!contactInfo.listId) {
			Modal.error({
				title: "Missing List Id",
				content: `You must an List Id.`
			});
			return;
		}
		if (!contactInfo.id) {
			Modal.error({
				title: "Missing Contact Id",
				content: `You must an Contact Id.`
			});
			return;
		}
		if (!this.state.editMobileNumber) {
			Modal.error({
				title: "No Mobile Number",
				content: `You must always specify a mobile number for this contact.`
			});
			return;
		}
		const update = {};
		if (this.state.editFirstName) update.firstName = this.state.editFirstName;
		if (this.state.editLastName) update.lastName = this.state.editLastName;
		if (this.state.editMobileNumber) update.mobileNumber = this.state.editMobileNumber;
		this.setState({ isUpdatingContact: true });
		const requestForm = {
			orgId: contactInfo.orgId,
			listId: contactInfo.listId,
			contactId: contactInfo.id,
			update: update
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.editContact",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingContact: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdatingContact: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						this.setState({ isUpdatingContact: false, editFirstName: "", editLastName: "", editMobileNumber: "" });
						this.handleSubCancel();
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingContact: false });
				this.handleSubCancel();
			});
	}

	getRealtimeChats(orgId, contactId) {
		console.log("mounting realtime listener -- chat_messages");
		let dbRef = firebase
			.firestore()
			.collection(`/smsLogs/`)
			.where("contactId", "==", contactId)
			.where("orgId", "==", orgId)
			.orderBy("sendTime", "desc")
			.limit(2); //must be >1
		// if msg is scheduled in the future, this RT listener will only get that msg and not other new ones btw now and the future scheduled msg
		this.detatchChatHistoryListener = dbRef.onSnapshot(
			querySnapshot => {
				const records = [];
				querySnapshot.forEach(doc => {
					const record = doc.data();
					record.id = doc.id;
					records.push(record);
				});
				// if NO real time char records found, do not push anything to chat messages array
				if (records.length === 0) {
					// return this.setState({ chatMessages: chats }, () => this.scrollChatToBottom());
					return this.scrollChatToBottom();
				}
				// else, update chat messages with latest real time record found
				console.log("chat history > realtime chat: ", records);
				const chats = this.state.chatMessages;
				// const newChatMsgs = [];
				// let isMatchFound = false;
				for (let i = 0; i < records.length; i++) {
					let isMatch = false;
					for (let j = 0; j < chats.length; j++) {
						if (chats[j].id === records[i].id) {
							isMatch = true;
							// chats[j] = records[i];
							// update chat status (pipelineStatus): scheduled > queued > sent > delivered / undelivered / failed
							const currStatus = chats[j].pipelineStatus;
							const newStatus = records[i].pipelineStatus;
							switch (currStatus) {
								case "processing":
								case "scheduled":
									if (newStatus !== "processing" && newStatus !== "scheduled") {
										chats[j].pipelineStatus = records[i].pipelineStatus;
									}
									break;
								case "queued":
									if (newStatus !== "processing" && newStatus !== "scheduled" && newStatus !== "queued") {
										chats[j].pipelineStatus = records[i].pipelineStatus;
									}
									break;
								case "sent":
									if (newStatus !== "processing" && newStatus !== "scheduled" && newStatus !== "queued" && newStatus !== "sent") {
										chats[j].pipelineStatus = records[i].pipelineStatus;
									}
									break;
								default:
									break;
							}
							break;
						}
					}
					if (!isMatch) {
						chats.push(records[i]);
					}
				}
				// for (let i = 0; i < chats.length; i++) {
				// 	for (let j = 0; j < records.length; j++) {
				// 		if (chats[i].id === records[j].id) {
				// 			// console.log("matching existing chat: ", chats[i]);
				// 			// console.log("replacing with chat: ", records[0]);
				// 			// isMatchFound = true;
				// 			// console.log("chat match found, updating chat: ", chats[i]);
				// 			chats[i] = records[j];
				// 			// break;
				// 			continue;
				// 		} else {
				// 			// console.log("NEW REAL TIME CHAT RECORD: ", records[0]);
				// 			// console.log("no match - new chats msg: ", records[j]);
				// 			newChatMsgs.push(records[j]);
				// 		}
				// 	}
				// }
				// for (let i = 0; i < newChatMsgs.length; i++) {
				// 	chats.push(newChatMsgs[i]);
				// }
				// sort chat history by time sendTime
				chats.sort((a, b) => {
					// Compare the 2 dates
					if (a.sendTime < b.sendTime) return -1;
					if (a.sendTime > b.sendTime) return 1;
					return 0;
				});

				// if (!isMatchFound) {
				// 	// console.log("NEW REAL TIME CHAT RECORD: ", records[0]);
				// 	chats.push(records[0]);
				// 	console.log("no match - new chats history: ", chats);
				// }
				this.setState({ chatMessages: chats }, () => this.scrollChatToBottom());
			},
			error => {
				console.log("realtime listener error: ", error);
			}
		);
	}

	detachRealtimeListener() {
		if (this.detatchChatHistoryListener) this.detatchChatHistoryListener();
	}

	componentWillUnmount() {
		console.log("unmounting realtime listener -- chat_messages");
		this.detachRealtimeListener();
	}

	render() {
		// console.log("ContactListMenu: this.props: ", this.props);
		const orgId = this.props.match.params.orgId;
		const currChatCol = this.props.currChatCol;
		const listTab = this.props.location.listTab;
		const contactId = this.props.match.params.contactId;
		const chatTab = this.props.match.params.chatTab;
		// const listId = this.props.listId;
		// alert("test");
		// const { previewVisible, previewImage } = this.state;
		// const { previewVisible, previewImage, fileList } = this.state;

		return (
			<div className="" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px", maxHeight: "100vh" }}>
				{this.state.isLoading && (
					<div className="taCenter" style={{ padding: 24 }}>
						<br />
						<Icon type="loading" style={{ fontSize: "28px" }} />
						<br />
						{/* Loading... */}
					</div>
				)}

				{!this.state.isLoading && (
					<div>
						<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
							<p className="mb0">
								<b style={{ fontSize: "16px" }}>{`${this.state.contactInfo.firstName} ${this.state.contactInfo.lastName}`}</b>
								<Link to={`/orgs/${orgId}/chats/${this.props.match.params.listTab}`}>
									<Button className="fright " icon="close" />
								</Link>
								{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<Button className="fright mr12" type="primary" icon="stop" onClick={() => this.setState({ isModalOptOutVisible: true })}>
										Opt Out
									</Button>
								)}
								{this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<Button className="fright mr12" type="default" icon="stop">
										Opted Out
									</Button>
								)}
							</p>
							<p className="mb0 tgrey">
								{this.state.contactInfo.international}
								{/* &middot; <Link to={`/orgs/${orgId}/contactLists/${this.state.contactInfo.listId}`}>{this.state.contactList.name ? this.state.contactList.name : "Loading..."}</Link> */}
							</p>
							<p className="mb0 tgrey">
								<Link to={`/orgs/${orgId}/contactLists/${this.state.contactInfo.listId}/contacts`}>{this.state.contactList.name ? this.state.contactList.name : "Loading..."}</Link>
							</p>
						</div>

						<Divider style={{ margin: "0px" }} />
						<div className="" style={{ width: "100%" }}>
							<Menu onClick={this.handleClick} selectedKeys={[chatTab]} mode="horizontal">
								<Menu.Item key="history">
									<Link to={`/orgs/${orgId}/chats/${this.props.listTab}/${contactId}/history`} onClick={() => this.scrollChatToBottom()}>
										History
									</Link>
								</Menu.Item>
								<Menu.Item key="info">
									<Link to={`/orgs/${orgId}/chats/${this.props.listTab}/${contactId}/info`}>Info</Link>
								</Menu.Item>
							</Menu>
						</div>
						{chatTab === "info" && (
							<div>
								<p className="p12 mb0">
									<span className="bold">Name</span> <br />
									<span className="tgrey">
										{this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
									</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">List</span> <br />
									<span className="tgrey">{this.state.contactList.name ? <Link to={`/orgs/${this.state.contactInfo.orgId}/contactLists/${this.state.contactList.id}/contacts`}>{this.state.contactList.name}</Link> : "Loading.."}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Mobile Number</span> <br />
									<span className="tgrey">{this.state.contactInfo.international}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Created</span> <br />
									<span className="tgrey">{moment(this.state.contactInfo.created).format("LLLL")}</span>
								</p>
								<Divider className="m0" />
								<p className="p12 mb0">
									<span className="bold">Status</span> <br />
									<span className="tgrey">
										{/* {this.state.contactInfo.isOptedOut ? (
											<span>
												<Icon className="tgrey" type="stop" /> Opted Out
											</span>
										) : (
											<span>
												<Icon className="tgreen" type="check" /> Subscribed
											</span>
										)} */}
										{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tgreen" type="check" /> Subscribed
											</span>
										)}
										{this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tgrey" type="stop" /> Opted Out
											</span>
										)}
										{this.state.contactInfo.isDeleted && (
											<span>
												<Icon className="tred" type="delete" /> Deleted
											</span>
										)}
									</span>
								</p>
								<div className="footer" style={{ padding: "12px", textAlign: "right" }}>
									{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
										<Button icon="edit" className="mr12" onClick={() => this.setState({ isModalEditVisible: true, editFirstName: this.state.contactInfo.firstName, editLastName: this.state.contactInfo.lastName, editMobileNumber: this.state.contactInfo.e164 })}>
											Edit Info
										</Button>
									)}

									{!this.state.contactInfo.isDeleted && (
										<Button type="danger" icon="delete" onClick={() => this.setState({ isModalDeleteVisible: true })}>
											Delete
										</Button>
									)}
									{this.state.contactInfo.isDeleted && (
										<Button type="default" icon="delete">
											Deleted
										</Button>
									)}
								</div>
							</div>
						)}

						{chatTab === "history" && this.state.isChatsLoading && (
							<div className="taCenter" style={{ padding: 24 }}>
								<br />
								<Icon type="loading" style={{ fontSize: "28px" }} />
								<br />
								Loading Chat History
							</div>
						)}
						{chatTab === "history" && !this.state.isChatsLoading && (
							<div>
								<div id="chatMessagesList" style={{ overflowY: "auto", height: "calc(100vh - 370px)" }}>
									<div className="" style={{ padding: "12px", textAlign: "center", background: "#fff" }}>
										{!this.state.isAllChatsLoaded && (
											<Button icon="arrow-up" loading={this.state.isLoadingMore} onClick={() => this.getChatHistory(orgId, contactId)}>
												Load More
											</Button>
										)}
										{this.state.isAllChatsLoaded && <Button icon="check">All Chat History Loaded</Button>}
									</div>
									{/* <Divider style={{ margin: "0px", marginBottom: "12px" }} /> */}
									{/* {this.state.chatMessages.length > 0 && console.log("CHAT MESSAGES: ", this.state.chatMessages)}
									{this.state.chatMessages.length > 0 && alert("chat messages length: " + this.state.chatMessages.length)} */}
									{this.state.chatMessages.length > 0 && (
										<List
											itemLayout="horizontal"
											// dataSource={this.state.chatData}
											dataSource={this.state.chatMessages}
											style={{ paddingBottom: "12px" }}
											renderItem={item => (
												<List.Item style={{ padding: "12px 12px", border: "0px" }}>
													<List.Item.Meta avatar={<Avatar className={`${item.direction === "inbound" ? "chatBubbleAvatarContact" : ""}`}>{this.getChatInitials(item.direction === "inbound" ? this.state.contactInfo : this.state.orgInfo.name)}</Avatar>} description={this.chatBubble(item)} />
													{/* 32 min ago */}
												</List.Item>
											)}
										/>
									)}
								</div>
								<div className="footer" style={{ padding: "10px 12px", borderTop: "1px solid #ccc" }}>
									{/* <Input placeholder="Type your message here" onClick={this.showModal} /> */}
									{(this.state.isChatsLoading || this.state.isContactInfoLoading || this.state.isSrcNumLoading) && (
										<div className="taCenter">
											<Icon type="loading" className="fs28 dataLoadingIcon" />
										</div>
									)}

									{!this.state.isChatsLoading && !this.state.isContactInfoLoading && !this.state.isSrcNumLoading && (this.state.contactInfo.isOptedOut || this.state.contactInfo.isDeleted) && (
										<div className="taCenter">
											<span role="img" aria-label="zipper-mouth face" style={{ color: "#333", fontSize: "22px" }}>
												🤐
											</span>
											<br />
											You cannot message this contact (opted out or deleted status).
										</div>
									)}

									{!this.state.isChatsLoading && !this.state.isContactInfoLoading && !this.state.isSrcNumLoading && this.state.contactListSourceNumbers.length === 0 && (
										<div className="taCenter">
											<span role="img" aria-label="zipper-mouth face" style={{ color: "#333", fontSize: "22px" }}>
												🤐
											</span>
											<br />
											No Source Numbers assigned to this list.
											<br />
											You cannot send OR receive text messages without Source Numbers.
											{/* You cannot send OR receive text messages (No Source Numbers assigned to this list). */}
											{/* No Source Numbers assigned to this list. */}
										</div>
									)}
									{!this.state.isChatsLoading && !this.state.isContactInfoLoading && !this.state.isSrcNumLoading && !this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && this.state.contactListSourceNumbers.length > 0 && (
										<div className="pointer" style={{ padding: "6px 12px", border: "1px solid #ccc", borderRadius: "4px", background: "#fff", color: this.state.chatMessage ? "#111" : "#999", whiteSpace: "pre-wrap", height: "40px", overflowY: "auto" }} onClick={this.showModal}>
											{this.state.chatMessage ? this.state.chatMessage : "Type your message here"}
										</div>
									)}
								</div>
								{/* <div style={{ padding: "0px 12px", paddingBottom: "8px", textAlign: "right", background: "#f5f5f5" }}>
								<Button key="back" icon="smile" style={{ marginRight: "12px" }} />
								<Button key="submit" type="primary" icon="check">
									Send
								</Button>
							</div> */}
								<Modal
									title="SMS Chat Message"
									visible={this.state.isModalVisible}
									// onOk={() => this.sendChatMessage()}
									onCancel={this.handleCancel}
									style={{ maxWidth: "400px", margin: "auto" }}
									bodyStyle={{ padding: "0px" }}
									footer={[
										<Button key="back" icon="close" onClick={this.handleCancel}>
											Back to Chat
										</Button>,
										process.env.NODE_ENV !== "production" ? (
											<Button key="inboundSubmit" type="default" className="successBtn" icon="arrow-down" loading={this.state.isSendingChat} onClick={() => this.sendInboundChatMessage()}>
												INBOUND
											</Button>
										) : (
											""
										),
										<Button key="submit" type="primary" icon="arrow-up" loading={this.state.isSendingChat} onClick={() => this.sendChatMessage()}>
											Send
										</Button>
									]}>
									{/* <p style={{ color: "#999", marginBottom: "8px" }}>Shift + Enter to send chat message</p> */}
									{this.state.isAddPhoto && (
										<div className="" style={{ padding: "16px 16px 0px" }}>
											<div className="bold pb4 fs15">
												<Button icon="close" onClick={e => this.setState({ isAddPhoto: false })} className="fright" />
												Add Images
											</div>
											<div className="pb8 tgrey2">Max 10 images per message. Max allowed size of entire message is 5 MB. Only png, gif, and jpeg formats allowed.</div>
											<div className="pb8">Total Images: {this.state.msgFilesList.length}</div>
											<div className="pb8">Total Media Size: {this.totalImgMB(this.state.msgFilesList)} MB</div>
											{this.state.msgFilesList.length > 0 && (
												<div className="pb8">
													{this.state.msgFilesList.map((file, index) => {
														return (
															<div className="" key={"msgFile" + index}>
																<div className="pictureCardCustom mb12">
																	{/* src={this.getFileUrl(file, "msgFileImgId" + index, index)} */}
																	<img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{ width: "240px", height: "240px" }} src={file.url} onClick={e => this.handleImgPreview(file)} />
																	<div className="pb8 bold">
																		#{index + 1}: {file.name}
																	</div>
																	<div className="">
																		{(file.size / 1024 / 1024).toFixed(2)} MB &nbsp;&middot;&nbsp;
																		<Button onClick={e => this.removeFile(index)}>
																			<Icon type="close" /> Remove
																		</Button>
																	</div>
																</div>
															</div>

															// <Card hoverable style={{ width: 240 }} cover={<img id={"msgFileId" + index} alt={file.name} src={this.getFileUrl(file, "msgFileId" + index)} />} actions={[<Icon type="eye" />, <Icon type="delete" />]}>
															// 	<Meta title={file.name} description={(file.size / 1024 / 1024).toFixed(2) + " MB"} />
															// </Card>
														);
													})}
												</div>
											)}
											<Dragger
												style={{ padding: "8px 12px" }}
												action="//example.com/posts/"
												listType="picture"
												multiple={true}
												fileList={this.state.fileList}
												onPreview={this.handleImgPreview}
												onChange={this.handleImgChange}
												showUploadList={false}
												beforeUpload={file => {
													console.log("before file upload: ", file);
													file.url = URL.createObjectURL(file);
													const msgFiles = this.state.msgFilesList;
													msgFiles.push(file);
													this.setState({
														// fileList: [],
														msgFilesList: msgFiles
													});
													return false;
												}}>
												<p className="ant-upload-drag-icon" style={{ marginBottom: "4px" }}>
													<Icon type="picture" style={{ fontSize: "32px" }} />
												</p>
												<p className="ant-upload-text" style={{ fontSize: "15px" }}>
													Click or drag image files here to upload
												</p>
												{/* <p className="ant-upload-hint">Max total size limit for ALL images in broadcast is 5 MB &middot; Max 10 images allowed per broadcast</p> */}
											</Dragger>
											<Modal
												visible={this.state.previewVisible}
												title={this.state.previewFile.name}
												onCancel={this.handleImgCancel}
												footer={[
													<Button key="back" onClick={this.handleImgCancel}>
														Return
													</Button>
												]}>
												<img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
											</Modal>
											<div className="bold pb0 mt12 fs15">Add Message Text</div>
										</div>
									)}

									<p className="taCenter mb0" style={{ padding: "16px 16px 0px" }}>
										<TextArea
											placeholder="Type your message here"
											autosize={{ minRows: 5 }}
											name="chatMessageModal"
											value={this.state.chatMessageModal}
											onChange={e => {
												this.handleChange(e);
											}}
											ref={input => input && input.focus()} //focus input on open modal
											style={{ color: "#333", padding: "12px 12px", maxWidth: "100%", borderRadius: "4px" }}
										/>
									</p>
									<div className={`taLeft pb8 ${this.state.is7Bit ? "tgrey" : "tblue"}`} style={{ padding: "8px 20px" }}>
										{this.state.messageCharCount}/{this.state.availableMsgChars} Characters &middot; Approx {this.state.msgSmsParts} SMS Part{this.state.msgSmsParts > 1 ? "s" : ""} &middot; {this.state.msgFilesList.length} MMS Parts
									</div>
									<p className="taLeft" style={{ padding: "0px 20px" }}>
										<Tooltip title={""}>
											<Button key="emoji" style={{ marginRight: "12px", padding: "0px 8px" }} onClick={() => this.setState({ isModalEmojiVisible: true })}>
												<span role="img" aria-label="emoji-smile">
													😄
												</span>{" "}
												Add Emoji
											</Button>
										</Tooltip>
										<Tooltip title={""}>
											<Button key="picture" style={{ marginRight: "12px", padding: "0px 8px" }} onClick={e => this.setState({ isAddPhoto: true })}>
												<span role="img" aria-label="emoji-camera">
													📷
												</span>{" "}
												Add Image
											</Button>
										</Tooltip>
										{/* <Tooltip title={"Message Variables"}>
											<Button key="templates" icon="bars" style={{ marginRight: "12px", padding: "0px 8px" }} />
										</Tooltip> */}
										{/* <Tooltip title={"Schedule Message"}>
											<Button key="schedule" icon="clock-circle-o" style={{ marginRight: "12px" }} />
										</Tooltip> */}
										{/* {process.env.NODE_ENV !== "production" && (
											<Button key="submit" type="primary" icon="arrow-left" loading={this.state.isSendingChat} onClick={() => this.sendChatMessage()}>
												INBOUND Send
											</Button>
										)} */}

										<Modal className="taCenter" title="Add Emojis to Message" visible={this.state.isModalEmojiVisible} onOk={this.handleSubOk} onCancel={this.handleSubCancel} bodyStyle={{ padding: "0px" }}>
											<Picker set="emojione" title="Pick emoji…" emoji="point_up" onSelect={this.addEmoji} style={{ width: "100%" }} />
										</Modal>
									</p>
								</Modal>
							</div>
						)}

						<Modal
							visible={this.state.previewVisible}
							title={this.state.previewFile && this.state.previewFile.name ? this.state.previewFile.name : "Image Title"}
							onCancel={() => this.setState({ previewVisible: false })}
							footer={[
								<Button key="back" onClick={() => this.setState({ previewVisible: false })}>
									Return
								</Button>
							]}>
							<img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
						</Modal>

						<Modal
							className="taCenter"
							title="Opt Out Contact"
							visible={this.state.isModalOptOutVisible}
							onCancel={this.handleSubCancel}
							footer={[
								<Button key="back" icon="close" onClick={this.handleSubCancel}>
									Cancel
								</Button>,
								<Button key="submit" type="danger" icon="stop" loading={this.state.isUpdatingContact} onClick={() => this.optOutContact(this.state.contactInfo.id)}>
									Yes, Opt Out
								</Button>
							]}>
							<p className="bold fs16">Are you sure you want to opt out this contact?</p>
							<div className="card1 mAuto p12 mb24 taLeft" style={{ maxWidth: "300px" }}>
								<p className="bold mb8">
									<Icon type="user" style={{}} /> &nbsp; {this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
								</p>
								<p className="mb8">
									<Icon type="phone" style={{}} /> &nbsp; {this.state.contactInfo.international}
								</p>
								<p className="mb8">
									<Icon type="message" style={{}} /> &nbsp; {this.state.contactInfo.lastSmsText ? <span className={`${this.state.contactInfo.direction === "inbound" ? "bold" : ""}`}>"{this.state.contactInfo.lastSmsText}"</span> : <span className="tgrey">No chat history</span>}
								</p>
							</div>
							<p className="tgrey taLeft">Opting out this contact will prevent you from sending messages to this contact while they are opted out. </p>
							<p className="tgrey taLeft">You MUST opt out any contacts who ask to no longer receive messages from you.</p>
							<p className="tgrey taLeft">To message this contact again after opt out, the contact must text START to any source number from their contact list.</p>
							{/* <p className="tgrey taLeft">To message this contact again after opt out, the contact must text START to their assigned source number: +1 647-699-4861. This number can be found under the info section for this contact.</p> */}
						</Modal>

						<Modal
							className="taCenter"
							title="Delete Contact"
							visible={this.state.isModalDeleteVisible}
							onCancel={this.handleSubCancel}
							footer={[
								<Button key="back" icon="close" onClick={this.handleSubCancel}>
									Cancel
								</Button>,
								<Button key="submit" type="danger" icon="delete" loading={this.state.isUpdatingContact} onClick={() => this.deleteContact(this.state.contactInfo.id)}>
									Yes, Delete
								</Button>
							]}>
							<p className="bold fs16">Are you sure you want to delete this contact?</p>
							<div className="card1 mAuto p12 mb24 taLeft" style={{ maxWidth: "300px" }}>
								<p className="bold mb8">
									<Icon type="user" style={{}} /> &nbsp; {this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
								</p>
								<p className="mb8">
									<Icon type="phone" style={{}} /> &nbsp; {this.state.contactInfo.international}
								</p>
								<p className="mb8">
									<Icon type="message" style={{}} /> &nbsp; {this.state.contactInfo.lastSmsText ? <span className={`${this.state.contactInfo.direction === "inbound" ? "bold" : ""}`}>"{this.state.contactInfo.lastSmsText}"</span> : <span className="tgrey">No chat history</span>}
								</p>
							</div>
							<p className="tgrey taLeft">Deleting this contact will prevent you from sending messages to, AND receiving messages from, this contact in future.</p>
							<p className="tgrey taLeft">To communicate with this contact again after deletion, if they are not opted out, you will have to add or import a new contact with the same mobile number.</p>
						</Modal>

						<Modal
							className=""
							title="Edit Contact Info"
							visible={this.state.isModalEditVisible}
							onCancel={this.handleSubCancel}
							footer={[
								<Button key="back" icon="close" onClick={this.handleSubCancel}>
									Cancel
								</Button>,
								<Button key="submit" type="primary" icon="edit" loading={this.state.isUpdatingContact} onClick={() => this.editContact(this.state.contactInfo.id)}>
									Update Info
								</Button>
							]}>
							<p className="bold mb8">First Name</p>
							<p className="fs16">
								<Input className="mw300" placeholder="First Name" name="editFirstName" value={this.state.editFirstName} onChange={e => this.handleChange(e)} />
							</p>
							<p className="bold mb8">Last Name</p>
							<p className="fs16">
								<Input className="mw300" placeholder="Last Name" name="editLastName" value={this.state.editLastName} onChange={e => this.handleChange(e)} />
							</p>
							<p className="bold mb8">Mobile Number</p>
							<p className="fs16">
								<Input className="mw300" placeholder="Mobile Number" name="editMobileNumber" value={this.state.editMobileNumber} onChange={e => this.handleChange(e)} />
							</p>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}
