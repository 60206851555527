import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { Layout, Icon, Table, Divider, Input, Checkbox, Button, Modal, Tooltip, Menu, Dropdown } from "antd";
import * as firebase from "firebase";
import request from "request";
import moment from "moment";

import { AgentInfo } from "./agentInfo.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

// class App extends Component {
export class AllAgents extends React.Component {
	state = {
		collapsed: false,
		isAddAgentVisible: false,
		isModalDeleteAgentVisible: false,
		isModalEditAgentVisible: false,
		loading: false,
		isLoading: false,
		isAddingAgent: false,
		agentEmail: "",
		agentStatus: "rep",
		agentsList: [],
		contactLists: [],
		isReceiveBcastEmails: true,
		isReceiveInboundSmsEmails: true,
		agentInfo: { emailPreferences: {} }, //holds info about selected agent to display in view/edit/delete modal
		editAgent: { emailPreferences: {} }, //holds a copy of agentInfo to be used for updating selected agent's preferences
		isViewAgentVisible: false
	};

	handleChange(e) {
		// console.log("e: ", e);
		console.log(e.target.name, e.target.value);

		if (e.target.name === "isReceiveBcastEmails" || e.target.name === "isReceiveInboundSmsEmails") {
			this.setState({
				[e.target.name]: e.target.checked
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value
			});
		}
	}

	handleEditChange(e) {
		// console.log("handleEditChange: ", e);
		console.log(e.target.name, e.target.checked);
		const editAgent = this.state.editAgent;
		if (e.target.name === "isReceiveBcastEmails") {
			editAgent.isReceiveBcastEmails = e.target.checked;
		} else if (e.target.name === "isReceiveInboundSmsEmails") {
			editAgent.isReceiveInboundSmsEmails = e.target.checked;
		}
		this.setState({
			editAgent: editAgent
		});
	}

	showModal = () => {
		const contactLists = this.state.contactLists;
		for (let i = 0; i < contactLists.length; i++) {
			contactLists[i].isSelected = false;
		}
		this.setState({
			agentEmail: "",
			agentStatus: "rep",
			isReceiveBcastEmails: true,
			isReceiveInboundSmsEmails: true,
			contactLists: contactLists,
			isAddAgentVisible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, isAddAgentVisible: false, isViewAgentVisible: false });
		}, 2000);
	};

	handleCancel = e => {
		// console.log(e);
		this.setState({
			isAddAgentVisible: false,
			isViewAgentVisible: false
		});
	};

	handleSubOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, isModalDeleteAgentVisible: false, isModalEditAgentVisible: false });
		}, 2000);
	};

	handleSubCancel = e => {
		// console.log(e);
		this.setState({
			isModalDeleteAgentVisible: false,
			isModalEditAgentVisible: false
		});
	};

	getAgents(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/members`)
			.orderBy("added", "desc")
			// .where("emailPreferences.isReceiveBcastEmails", "==", true)
			// .where("emailPreferences.isReceiveInboundSmsEmails", "==", true)
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					// const agentsList = this.state.orgsList;
					const agentsList = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.added = doc.data().added.getTime();
						// if (!record.email) record.email = "sample@email.com";
						// console.log("record: ", record);
						agentsList.push(record);
					});
					console.log("agentsList: ", agentsList);
					this.setState({
						agentsList: agentsList,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactListData(listId) {
		// console.log("LIST ID for name retrieval: ", listId);
		const lists = this.state.contactLists;
		for (let i = 0; i < lists.length; i++) {
			if (lists[i].id === listId) {
				// console.log("matching list found: ", lists[i].name);
				return lists[i];
			}
		}
	}

	getContactLists(orgId) {
		firebase
			.firestore()
			.collection(`orgs/${orgId}/contactLists`)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					// const agentsList = this.state.orgsList;
					const contactLists = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						// if (!record.email) record.email = "sample@email.com";
						// console.log("record: ", record);
						contactLists.push(record);
					});
					console.log("contactLists: ", contactLists);
					this.setState({
						contactLists: contactLists
						// isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		this.getAgents(orgId);
		this.getContactLists(orgId);
	}

	capitalizeFirstLetter(string) {
		return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
	}

	toggleSelectedList(listId) {
		const contactLists = this.state.contactLists;
		for (let i = 0; i < contactLists.length; i++) {
			if (contactLists[i].id === listId) {
				if (contactLists[i].isSelected) {
					contactLists[i].isSelected = false;
				} else {
					contactLists[i].isSelected = true;
				}
				break;
			}
		}
		this.setState({ contactLists: contactLists });
	}

	// SAMPLE AGENT DB OBJ
	// {
	// 	email: "bob@example.com",
	// 	role: "admin", // owner / admin / rep
	// 	listAccess: {
	// 		"listIdA123": true,
	// 		"listIdB456": true,
	// 	},
	// 	emailPreferences:{
	// 		isBroadcastRecipient: true,
	// 		isInboundSmsRecipient: true,
	// 	}
	// }

	addAgent() {
		console.log("creating new org");
		if (!this.state.agentEmail) {
			Modal.error({
				title: "Missing Agent Email",
				content: `You must enter an email for this agent.`
			});
			return;
		}
		if (!this.state.agentStatus) {
			Modal.error({
				title: "Missing Agent Role",
				content: `You must enter a role for this agent.`
			});
			return;
		}
		const requestForm = {
			orgId: this.props.match.params.orgId,
			agentEmail: this.state.agentEmail,
			agentStatus: this.state.agentStatus,
			isReceiveBcastEmails: this.state.isReceiveBcastEmails,
			isReceiveInboundSmsEmails: this.state.isReceiveInboundSmsEmails
		};
		const contactLists = this.state.contactLists;
		if (this.state.agentStatus === "rep") {
			const listIds = [];

			for (let i = 0; i < contactLists.length; i++) {
				if (contactLists[i].isSelected) {
					listIds.push(contactLists[i].id);
				}
			}
			requestForm.listIds = listIds;
		}
		console.log("requestForm: ", requestForm);
		// return;
		this.setState({ isAddingAgent: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/org.addAgent",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						// form: requestForm
						// use { ..., body: dataObj, json:true } when you need to send json obj vs urlEncoded obj (which converts everything to strings!)
						body: requestForm,
						json: true
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isAddingAgent: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", body);
							return this.setState({ isAddingAgent: false });
						}
						// if request success
						console.log("http request success: ", body);
						const newAgentObj = body; // no need to use JSON.parse(body); when sending/receiving json
						newAgentObj.key = newAgentObj.id;
						for (let i = 0; i < contactLists.length; i++) {
							contactLists[i].isSelected = false;
						}
						this.setState({ isAddingAgent: false, isAddAgentVisible: false, agentEmail: "", agentStatus: "rep", isBroadcastRecipient: true, isInboundSmsRecipient: true, contactLists: contactLists, agentsList: [newAgentObj].concat(this.state.agentsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isAddingAgent: false });
			});
	}

	editAgent() {
		console.log("editing agent");
		if (!this.state.editAgent.id) {
			Modal.error({
				title: "Missing Agent Id",
				content: `You must have an id for this agent.`
			});
			return;
		}
		if (!this.state.editAgent.email) {
			Modal.error({
				title: "Missing Agent Email",
				content: `You must have an email for this agent.`
			});
			return;
		}
		if (!this.state.editAgent.status) {
			Modal.error({
				title: "Missing Agent Role",
				content: `You must select a role for this agent.`
			});
			return;
		}
		const requestForm = {
			orgId: this.props.match.params.orgId,
			agentId: this.state.agentInfo.id,
			agentEmail: this.state.editAgent.email,
			agentStatus: this.state.editAgent.status,
			isReceiveBcastEmails: this.state.editAgent.isReceiveBcastEmails,
			isReceiveInboundSmsEmails: this.state.editAgent.isReceiveInboundSmsEmails
		};
		const contactLists = this.state.contactLists;
		if (this.state.editAgent.status === "rep") {
			const listIds = [];

			for (let i = 0; i < contactLists.length; i++) {
				if (contactLists[i].isSelected) {
					listIds.push(contactLists[i].id);
				}
			}
			requestForm.listIds = listIds;
		}
		console.log("requestForm: ", requestForm);
		// return;
		this.setState({ isUpdatingAgent: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/org.editAgent",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						// form: requestForm
						// use { ..., body: dataObj, json:true } when you need to send json obj vs urlEncoded obj (which converts everything to strings!)
						body: requestForm,
						json: true
					},
					(err, httpResponse, body) => {
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingAgent: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", body);
							return this.setState({ isUpdatingAgent: false });
						}
						// if request success
						console.log("http request success: ", body);
						const updatedAgentObj = body; // no need to use JSON.parse(body); when sending/receiving json
						updatedAgentObj.key = updatedAgentObj.id;
						for (let i = 0; i < contactLists.length; i++) {
							contactLists[i].isSelected = false;
						}
						const agents = this.state.agentsList;
						for (let i = 0; i < agents.length; i++) {
							if (agents[i].id === updatedAgentObj.id) {
								agents[i] = updatedAgentObj;
							}
						}
						this.setState({ isUpdatingAgent: false, isModalEditAgentVisible: false, contactLists: contactLists, agentsList: agents, agentInfo: updatedAgentObj });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingAgent: false });
			});
	}

	deleteAgent() {
		console.log("editing agent");
		if (!this.state.agentInfo.id) {
			Modal.error({
				title: "Missing Agent Id",
				content: `You must have an id for this agent.`
			});
			return;
		}

		const requestForm = {
			orgId: this.props.match.params.orgId,
			agentId: this.state.agentInfo.id
		};
		console.log("requestForm: ", requestForm);
		// return;
		this.setState({ isUpdatingAgent: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/org.deleteAgent",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						// form: requestForm
						// use { ..., body: dataObj, json:true } when you need to send json obj vs urlEncoded obj (which converts everything to strings!)
						body: requestForm,
						json: true
					},
					(err, httpResponse, body) => {
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdatingAgent: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", body);
							return this.setState({ isUpdatingAgent: false });
						}
						// if request success
						console.log("http request success: ", body);
						const updatedAgentObj = body; // no need to use JSON.parse(body); when sending/receiving json
						const agents = this.state.agentsList;
						let agentListPos = 0;
						for (let i = 0; i < agents.length; i++) {
							if (agents[i].id === updatedAgentObj.id) {
								agentListPos = i;
							}
						}
						agents.splice(agentListPos, 1);
						this.setState({ isUpdatingAgent: false, isModalDeleteAgentVisible: false, isViewAgentVisible: false, agentsList: agents });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdatingAgent: false });
			});
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.getOrgId(this.props.location.pathname);

		const columns = [
			{
				title: "Role",
				dataIndex: "status",
				key: "status",
				render: (text, record) => (
					// {record.}
					<span>{this.capitalizeFirstLetter(record.status)}</span>
				)
			},
			// {
			// 	title: "Name",
			// 	dataIndex: "name",
			// 	key: "name",
			// 	render: (text, record) => (
			// 		<Link to={`/orgs/${orgId}/agents/${record.id}`} className="fs15 fw500">
			// 			{text}
			// 		</Link>
			// 	)
			// },
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "List Access",
				dataIndex: "contactLists",
				key: "contactLists",
				render: (text, record) => (
					// {record.}

					// <span>
					// 	{!record.listAccess
					// 		? "ALL LISTS"
					// 		: record.listAccess.map((listId, index) => {
					// 				return listId;
					// 		  })}
					// </span>
					<span>
						{record.status === "owner" || record.status === "admin" ? (
							<span className="chipGrey">
								<Icon type="global" className="tblue" /> Access to All Lists
							</span>
						) : (
							Object.keys(record.listAccess).map((key, index) => {
								const list = this.getContactListData(key);
								return (
									<span className="chipGrey" key={key}>
										{list ? list.name : "Loading.."}
									</span>
								);
							})
						)}
					</span>
				)
			},
			{
				title: "Added",
				dataIndex: "added",
				key: "added",
				render: (text, record) => (
					<Tooltip title={moment(record.added).format("LLL")}>
						<span>{moment(record.added).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const agentStatusMenu = (
			<Menu
				onClick={e => {
					console.log(e);
					this.setState({ agentStatus: e.key });
				}}>
				{/* Owner: Full access. <br />
				Admin: Full access without billing info access. <br />
				Rep: Only chat support access on selected lists. */}
				<Menu.Item key="owner">Owner - Full access</Menu.Item>
				<Menu.Item key="admin">Admin - Full access without billing info access</Menu.Item>
				<Menu.Item key="rep">Rep - Only chat support access on selected lists</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);
		const agentEditStatusMenu = (
			<Menu
				onClick={e => {
					console.log(e);
					const editAgent = this.state.editAgent;
					editAgent.status = e.key;
					this.setState({ editAgent: editAgent });
				}}>
				{/* Owner: Full access. <br />
				Admin: Full access without billing info access. <br />
				Rep: Only chat support access on selected lists. */}
				<Menu.Item key="owner">Owner - Full access</Menu.Item>
				<Menu.Item key="admin">Admin - Full access without billing info access</Menu.Item>
				<Menu.Item key="rep">Rep - Only chat support access on selected lists</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Agents</Breadcrumb.Item>
					<Breadcrumb.Item>All</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>All Agents</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px 12px 4px" }}>
						<span style={{}}>
							<Button type="primary" icon="plus" size={"default"} onClick={this.showModal} className="mr8 mb8">
								Add Agent
							</Button>
							{/* <Button type="" icon="download" size={"default"} className="mr8 mb8">
								Export
							</Button> */}
						</span>
						<Search placeholder="Search Agents" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} className="mb8" enterButton />
					</div>
					{/* <div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px" }}>
						<span style={{}}>
							<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
								Add Agent
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px", float: "right" }} enterButton />
					</div> */}
				</div>
				<Modal
					title="Add Agent"
					visible={this.state.isAddAgentVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isAddingAgent} onClick={e => this.addAgent()}>
							Add Agent
						</Button>
					]}>
					{/* <p className="bold" style={{ marginBottom: "8px" }}>
						Agent Name:
					</p>
					<p>Enter the name of the person you would like to add to your organization.</p>
					<p style={{ marginBottom: "24px" }}>
						<Input className="maxW mw300" name="agentName" placeholder="First & Last Name" value={this.state.agentName} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} /> */}
					<p className="bold" style={{ marginBottom: "8px" }}>
						Agent Email:
					</p>
					<p>Enter the email of the person you would like to add to your organization.</p>
					<p style={{ marginBottom: "24px" }}>
						<Input className="maxW mw300" name="agentEmail" placeholder="person@example.com" value={this.state.agentEmail} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Agent Role:
					</p>
					<p>
						{this.state.agentStatus === "owner" && <span>Owner: Full access.</span>}
						{this.state.agentStatus === "admin" && <span>Admin: Full access without billing info access.</span>}
						{this.state.agentStatus === "rep" && <span>Rep: Only chat support access on selected lists.</span>}
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Dropdown overlay={agentStatusMenu} trigger={["click"]}>
							<Button className="maxW mw300">
								{this.capitalizeFirstLetter(this.state.agentStatus)} <Icon type="down" />
							</Button>
						</Dropdown>
						{/* <Input name="agentStatus" placeholder="owner/admin/representative/?" style={{ maxWidth: "325px" }} value={this.state.agentStatus} onChange={e => this.handleChange(e)} /> */}
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Email Preferences:
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Checkbox name="isReceiveBcastEmails" checked={this.state.isReceiveBcastEmails} value={this.state.isReceiveBcastEmails} onChange={e => this.handleChange(e)}>
							Receive Scheduled Broadcast Notifications via Email?
						</Checkbox>
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Checkbox name="isReceiveInboundSmsEmails" checked={this.state.isReceiveInboundSmsEmails} value={this.state.isReceiveInboundSmsEmails} onChange={e => this.handleChange(e)}>
							Receive Inbound SMS Notifications via Emails?
						</Checkbox>
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						List Access:
					</p>
					{(this.state.agentStatus === "owner" || this.state.agentStatus === "admin") && (
						<div className="">
							<p>
								An agent with status {this.capitalizeFirstLetter(this.state.agentStatus)} has access to <b>ALL</b> contact lists.
							</p>
						</div>
					)}
					{this.state.agentStatus === "rep" && (
						<div className="">
							<p>Select the Contact Lists this agent can access.</p>
							{/* <p style={{ marginBottom: "24px" }}>
						<Input className="maxW mw300" name="agentListAccess" placeholder="Contact Lists" value={this.state.agentListAccess} onChange={e => this.handleChange(e)} />
					</p> */}
							<div className="mb24">
								{this.state.contactLists.map((list, index) => {
									return (
										<Checkbox className="maxW  ml0 mb8 mr8 pb8 bb" key={list.id} name={list.id} checked={list.isSelected} onChange={e => this.toggleSelectedList(list.id)}>
											<div className="" style={{ display: "inline-block" }}>
												<span className={`bold`}>{list.name}</span>
												<br />
												<span className={`tgrey ${list.isSelected ? "bold" : ""}`}>{list.totalContacts} Contacts</span>
											</div>
										</Checkbox>
									);
								})}
							</div>
						</div>
					)}
				</Modal>

				{/* VIEW AGENT INFO MODAL */}
				<Modal
					title="Agent Information"
					visible={this.state.isViewAgentVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Close
						</Button>,
						<Button
							key="edit"
							type="primary"
							icon="edit"
							loading={this.state.isAddingAgent}
							onClick={() => {
								// const agentInfo = Object.assign({}, this.state.agentInfo);
								// const agentInfo = this.state.agentInfo;
								const editAgent = { ...this.state.agentInfo };
								// move nested objs to top level obj vals
								editAgent.isReceiveBcastEmails = editAgent.emailPreferences ? editAgent.emailPreferences.isReceiveBcastEmails : false;
								editAgent.isReceiveInboundSmsEmails = editAgent.emailPreferences ? editAgent.emailPreferences.isReceiveInboundSmsEmails : false;
								editAgent.listAccess = { ...this.state.agentInfo.listAccess };
								// console.log("editAgent.listAccess: ", editAgent.listAccess);
								// update contact list isSelected status
								const contactLists = this.state.contactLists;
								for (let i = 0; i < contactLists.length; i++) {
									if (editAgent.listAccess[contactLists[i].id]) {
										contactLists[i].isSelected = true;
									} else {
										contactLists[i].isSelected = false;
									}
								}
								this.setState({ isModalEditAgentVisible: true, editAgent: editAgent, contactLists: contactLists });
							}}>
							Edit Agent
						</Button>,
						<Button key="delete" type="danger" icon="delete" loading={this.state.isAddingAgent} onClick={() => this.setState({ isModalDeleteAgentVisible: true })}>
							Delete Agent
						</Button>
					]}>
					<div className="">
						<p className="fs15">
							{this.state.agentInfo.name ? this.state.agentInfo.name : <span className="tgrey">Name not set</span>}
							<br />
							<b>{this.state.agentInfo.email}</b>
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						<p className="">
							<b>Role</b> <br />
							{this.capitalizeFirstLetter(this.state.agentInfo.status)}
						</p>
						<p className="">
							<b>Added</b> <br />
							<Tooltip title={moment(this.state.agentInfo.added).fromNow()}>
								<span>{moment(this.state.agentInfo.added).format("LLL")}</span>
							</Tooltip>
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						<p className="bold" style={{ marginBottom: "8px" }}>
							Email Preferences:
						</p>
						<p style={{ marginBottom: "24px" }}>
							{/* <Checkbox checked={this.state.agentInfo.emailPreferences ? this.state.agentInfo.emailPreferences.isReceiveBcastEmails : false}>Receive Scheduled Broadcast Notifications via Email?</Checkbox> */}
							{this.state.agentInfo.emailPreferences && this.state.agentInfo.emailPreferences.isReceiveBcastEmails ? <Icon className="tgreen fs16" type="check" /> : <Icon className="tgrey" type="stop" />} Broadcast Notifications via Email?
						</p>
						<p style={{ marginBottom: "24px" }}>
							{/* <Checkbox name="isReceiveInboundSmsEmails" checked={this.state.agentInfo.emailPreferences ? this.state.agentInfo.emailPreferences.isReceiveInboundSmsEmails : false}>
							Receive Inbound SMS Notifications via Emails?
							</Checkbox> */}
							{this.state.agentInfo.emailPreferences && this.state.agentInfo.emailPreferences.isReceiveInboundSmsEmails ? <Icon className="tgreen fs16" type="check" /> : <Icon className="tgrey" type="stop" />} Receive Inbound SMS Notifications via Emails?
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						<p className="bold" style={{ marginBottom: "8px" }}>
							List Access:
						</p>
						{(this.state.agentInfo.status === "owner" || this.state.agentInfo.status === "admin") && (
							<div className="">
								<p>
									{/* An agent with status {this.capitalizeFirstLetter(this.state.status)} has access to <b>ALL</b> contact lists. */}
									<span className="chipGrey">
										<Icon type="global" className="tblue" /> Access to All Lists
									</span>
								</p>
							</div>
						)}
						{this.state.agentInfo.status === "rep" && (
							<div className="">
								<p>The following Contact Lists are accessible to this agent:</p>
								<div className="mb24">
									{Object.keys(this.state.agentInfo.listAccess).map((key, index) => {
										const list = this.getContactListData(key);
										return (
											<span className="chipGrey" key={key}>
												{list ? list.name : "Loading.."}
											</span>
										);
									})}
									{Object.keys(this.state.agentInfo.listAccess).length === 0 && (
										<span className="tgrey">
											<Icon type="stop" /> No Lists Accessible by this Agent.
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</Modal>

				{/* DELETE AGENT CONFIRMATION MODAL */}
				<Modal
					className="taCenter"
					title="Delete Agent"
					visible={this.state.isModalDeleteAgentVisible}
					onCancel={this.handleSubCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleSubCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="danger" icon="delete" loading={this.state.isUpdatingAgent} onClick={() => this.deleteAgent()}>
							Yes, Delete
						</Button>
					]}>
					<p className="bold fs16">Are you sure you want to delete this agent?</p>
					<div className="card1 mAuto p12 mb24 taLeft" style={{ maxWidth: "300px" }}>
						<p className="mb0 fs16">{this.state.agentInfo.name ? this.state.agentInfo.name : <span className="tgrey">Name not set</span>}</p>
						<p className="mb0 bold fs16">{this.state.agentInfo.email}</p>
						<p className="mb0">
							Added:{" "}
							<Tooltip title={moment(this.state.agentInfo.added).fromNow()}>
								<span className="pointer">{moment(this.state.agentInfo.added).format("LLL")}</span>
							</Tooltip>
						</p>
					</div>
					<p className="tgrey taLeft mb8">Deleting this agent will remove all their access to this organization.</p>
					<p className="tgrey taLeft">You can always add an agent to your organization again after removing them.</p>
				</Modal>

				{/* EDIT AGENT MODAL */}
				<Modal
					className=""
					title="Edit Agent"
					visible={this.state.isModalEditAgentVisible}
					onCancel={this.handleSubCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleSubCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isUpdatingAgent} onClick={() => this.editAgent()}>
							Update
						</Button>
					]}>
					<p className="fs15">
						{this.state.agentInfo.name ? this.state.agentInfo.name : <span className="tgrey">Name not set</span>}
						<br />
						<b>{this.state.agentInfo.email}</b>
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Agent Role:
					</p>
					<p>
						{this.state.editAgent.status === "owner" && <span>Owner: Full access.</span>}
						{this.state.editAgent.status === "admin" && <span>Admin: Full access without billing info access.</span>}
						{this.state.editAgent.status === "rep" && <span>Rep: Only chat support access on selected lists.</span>}
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Dropdown overlay={agentEditStatusMenu} trigger={["click"]}>
							<Button className="maxW mw300">
								{this.capitalizeFirstLetter(this.state.editAgent.status)} <Icon type="down" />
							</Button>
						</Dropdown>
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Email Preferences:
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Checkbox name="isReceiveBcastEmails" checked={this.state.editAgent.isReceiveBcastEmails} onChange={e => this.handleEditChange(e)}>
							Receive Scheduled Broadcast Notifications via Email?
						</Checkbox>
					</p>
					<p style={{ marginBottom: "24px" }}>
						<Checkbox name="isReceiveInboundSmsEmails" checked={this.state.editAgent.isReceiveInboundSmsEmails} onChange={e => this.handleEditChange(e)}>
							Receive Inbound SMS Notifications via Emails?
						</Checkbox>
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						List Access:
					</p>
					{/* <p>
						{this.state.editAgent.listAccess &&
							Object.keys(this.state.editAgent.listAccess).map((key, index) => {
								const list = this.getContactListData(key);
								return (
									<span className="chipGrey" key={key}>
										{list ? list.name : "Loading.."}
									</span>
								);
							})}
					</p> */}
					{(this.state.editAgent.status === "owner" || this.state.editAgent.status === "admin") && (
						<div className="">
							<p>
								An agent with status {this.capitalizeFirstLetter(this.state.editAgent.status)} has access to <b>ALL</b> contact lists.
							</p>
						</div>
					)}
					{this.state.editAgent.status === "rep" && (
						<div className="">
							<p>Select the Contact Lists this agent can access.</p>
							<div className="mb24">
								{this.state.contactLists.map((list, index) => {
									return (
										<Checkbox className="maxW  ml0 mb8 mr8 pb8 bb" key={list.id} name={list.id} checked={list.isSelected} onChange={e => this.toggleSelectedList(list.id)}>
											<div className="" style={{ display: "inline-block" }}>
												<span className={`bold`}>{list.name}</span>
												<br />
												<span className={`tgrey ${list.isSelected ? "bold" : ""}`}>{list.totalContacts} Contacts</span>
											</div>
										</Checkbox>
									);
								})}
							</div>
						</div>
					)}
				</Modal>

				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.agentsList}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					onRow={record => {
						return {
							onClick: e => {
								// console.log("table record: ", record);
								// this.props.history.push(`/orgs/${orgId}/agents/${record.id}`);
								this.setState({ agentInfo: record, isViewAgentVisible: true });
							}
						};
					}}
				/>

				<Route exact path="/orgs/:orgId/agents/:agentId" component={AgentInfo} />
			</Content>
		);
	}
}
