import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown } from "antd";
import * as firebase from "firebase";
import moment from "moment";
import request from "request";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class ContactInfo extends React.Component {
	state = {
		collapsed: false,
		visible: true,
		loading: false,
		isLoading: false,
		isOptingOut: false,
		isDeleting: false,
		isEditing: false,
		contactInfo: { firstName: "Loading..." },
		contacts: [],
		pageSize: 15,
		contactList: { name: "Loading..." }
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getContactInfo(contactId) {
		firebase
			.firestore()
			.doc(`contacts/${contactId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactInfo: record, isLoading: false });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}
	getContactId(pathname) {
		return pathname.split("/")[6];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		const contactId = this.getContactId(this.props.location.pathname);
		// this.getContacts(orgId, listId);
		this.getContactInfo(contactId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	deleteContact(orgId, listId, contactId) {
		if (!orgId) {
			Modal.error({
				title: "Missing Org Id",
				content: `Org Id required.`
			});
			return;
		}
		if (!listId) {
			Modal.error({
				title: "Missing List Id",
				content: `List Id required.`
			});
			return;
		}
		if (!contactId) {
			Modal.error({
				title: "Missing Contact Id",
				content: `Contact Id required.`
			});
			return;
		}
		const formData = {
			orgId: orgId,
			listId: listId,
			contactId: contactId
		};
		this.setState({ isDeleting: true });
		firebase
			.auth()
			//.currentUser.getIdToken(/* forceRefresh */ true)
			.currentUser.getIdToken()
			.then(uidToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/contactList.deleteContact",
						headers: { authorization: "Bearer " + uidToken },
						form: formData
					},
					(err, httpResponse, body) => {
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							Modal.error({
								title: "Error importing contacts.",
								content: "Unable to import contacts. Check that your form data is valid."
							});
							return this.setState({ isDeleting: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							Modal.error({
								title: "Error deleting contact.",
								content: "Unable to delete contact. Check that your form data is valid."
							});
							return this.setState({ isDeleting: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						Modal.success({
							title: "Contact deleted successfully!",
							content: "This contact has been deleted."
						});
						return this.setState({ isDeleting: false, visible: false });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isDeleting: false });
			});
	}

	showDeleteConfirm(orgId, listId, contactId) {
		Modal.confirm({
			title: "Are you sure delete this contact?",
			// content: `Name: ${this.state.contactInfo.firstName} ${this.state.contactInfo.lastName} -- Mobile: ${this.state.contactInfo.international}`,
			content: (
				<div>
					<p className="mb0">
						Name: {this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
					</p>
					<p>Mobile: {this.state.contactInfo.international}</p>
				</div>
			),
			okText: "Yes, Delete",
			okType: "danger",
			cancelText: "No, Cancel",
			onOk: () => {
				console.log("Yes, deleting contact..");
				this.deleteContact(orgId, listId, contactId);
			},
			onCancel: () => {
				console.log("Cancel, do not delete contact");
			}
		});
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.getOrgId(this.props.location.pathname);
		const listId = this.getListId(this.props.location.pathname);
		const contactId = this.getContactId(this.props.location.pathname);

		return (
			<Content>
				<Modal
					title="Contact Information"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					afterClose={() => {
						this.props.history.push(`/orgs/${orgId}/contactLists/${listId}/contacts`);
					}}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Close
						</Button>,
						!this.state.contactInfo.isDeleted && !this.state.contactInfo.isOptedOut && (
							<Button key="edit" icon="edit" loading={this.state.isEditing} onClick={this.handleCancel}>
								Edit Info
							</Button>
						),
						!this.state.contactInfo.isDeleted && (
							<Button key="delete" type="" className="dangerBtn" icon="delete" loading={this.state.isDeleting} onClick={e => this.showDeleteConfirm(orgId, listId, contactId)}>
								Delete
							</Button>
						)
					]}>
					{this.state.isLoading && (
						<div className="taCenter" style={{ padding: "20px" }}>
							<Icon type="loading" style={{ fontSize: "28px" }} />
							{/* <br />
							Loading... */}
						</div>
					)}
					{!this.state.isLoading && (
						<div className="">
							<p className="fs15">
								{!this.state.contactInfo.isOptedOut && !this.state.contactInfo.isDeleted && (
									<Button key="optOut" type="primary" icon="stop" style={{ float: "right" }} loading={this.state.isOptingOut} onClick={this.handleOk}>
										Opt Out
									</Button>
								)}

								<b>
									{this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
								</b>
								<br />
								{this.state.contactInfo.international}
							</p>
							<Divider />
							<p className="fs15">
								<Link to={`/orgs/${orgId}/chats/inbound/${contactId}/history`}>
									<Button key="optOut" type="default" icon="message">
										Send Chat Message
									</Button>
								</Link>
							</p>
							<p className="fs15">
								{" "}
								<b>Name</b> <br />
								{this.state.contactInfo.firstName} {this.state.contactInfo.lastName}
							</p>
							<p className="fs15">
								{" "}
								<b>Mobile Number</b> <br />
								{this.state.contactInfo.international}
							</p>
							<p className="fs15">
								{" "}
								<b>Added</b> <br />
								<Tooltip title={moment(this.state.contactInfo.created).fromNow()}>
									<span>{moment(this.state.contactInfo.created).format("LLL")}</span>
								</Tooltip>
							</p>
							<p className="fs15">
								{" "}
								<b>Status</b> <br />
								{this.state.contactInfo.isDeleted ? (
									<span>
										{" "}
										<Icon type="delete" className="tred" /> Deleted
									</span>
								) : this.state.contactInfo.isOptedOut ? (
									<span>
										{" "}
										<Icon type="stop" className="tgrey" /> Opted Out
									</span>
								) : (
									<span>
										{" "}
										<Icon type="check" className="tgreen" /> Subscribed
									</span>
								)}
							</p>
						</div>
					)}
				</Modal>
			</Content>
		);
	}
}
