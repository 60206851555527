import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import GlobalContext from "./_context/global.js";
// import { SharedSnackbarConsumer } from "./_context/global.js";
import * as serviceWorker from "./serviceWorker";
import * as firebaseConfig from "./firebase.js";
import * as firebase from "firebase";
import { BrowserRouter as Router } from "react-router-dom";

console.log("firebaseConfig: ", firebaseConfig);

firebase.initializeApp(firebaseConfig);
// firestore timestamp updated:
// firebase.firestore().settings({ timestampsInSnapshots: true });

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById("root")
);
// ReactDOM.render(<GlobalContext.Consumer>{gcState => <App gcState={gcState} />}</GlobalContext.Consumer>, document.getElementById("root"));
// ReactDOM.render(<SharedSnackbarConsumer>{({ message, updateValue }) => <App message={message} updateValue={updateValue} />}</SharedSnackbarConsumer>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
