import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Icon, Avatar, Divider } from "antd";

import * as firebase from "firebase";

import default_profile from "../_assets/default_profile.png";
import default_org from "../_assets/default_org.png";

// import GlobalContext from "../_context/global.js";
// import { SharedSnackbarConsumer } from "./_context/global.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
class SideMenu extends React.Component {
	state = {
		collapsed: false,
		menuName: "",
		menuId: "",
		prevPathname: ""
		// gcState: {},
		// gcStateEmail: ""
		// currentPathname: "",
		// menuIndex: 0,
		// personalPageIndex: ["0"],
		// orgPageIndex: ["0"]
	};
	// componentWillMount() {
	// 	console.log("this.props 1 menu.js: ", this.props);
	// }
	// componentWillUpdate() {
	// 	console.log("this.props 2 menu.js: ", this.props);
	// }
	componentDidMount() {
		console.log("this.props did mount 0 MENUMENUMENU .js: ", this.props);
		console.log("this.props did mount  0 conext MENUMENUMENU.js: ", this.props.gcState);
		// this.setState({ gcState: this.props.gcState });
		// const gcState = this.props.gcState;
		// gcState.menuName = "222222";
		// this.props.gcState.updateGlobalState(gcState);
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		// console.log("CURRENT OBJ LENGTH: ", Object.keys(this.props.currentOrg).length);
		// console.log("CURRENT PATHNAME: ", this.props.location.pathname);
		// console.log("CURRENT ORGS IN PATHNAME: ", personalPages.indexOf(this.props.location.pathname));
		if (Object.keys(this.props.currentOrg).length === 0 && personalPages.indexOf(this.props.location.pathname) === -1) {
			// alert("GET ORG DATA! :O");
			const urlArray = this.props.location.pathname.split("/");
			this.setOrgData(urlArray[2]);
		}
	}
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	console.log("menu.js getDerivedStateFromProps next props: ", nextProps);
	// 	console.log("menu.js getDerivedStateFromProps prevState: ", prevState);
	// 	// this.setMenuData();
	// 	return null;
	// }

	setOrgData(orgId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
						// return record;
						this.props.updateState("currentOrg", record);
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	// componentDidUpdate(nextProps, prevState) {
	// 	console.log("menu.js componentDidUpdate 0 MENUMENUMENU next props: ", nextProps);
	// 	console.log("this.props componentDidUpdate  0 conext MENUMENUMENU.js: ", this.props.gcState);
	// 	console.log("this.props componentDidUpdate  0 next conext MENUMENUMENU.js: ", nextProps.gcState);
	// 	console.log("menu.js componentDidUpdate MENUMENUMENU prevState: ", prevState);
	// 	// if (nextProps.location.pathname && !prevState.prevPathname) {
	// 	// 	this.setMenuData(nextProps.location.pathname);
	// 	// 	this.setState({ prevPathname: nextProps.location.pathname });
	// 	// } else if (nextProps.location.pathname !== prevState.prevPathname) {
	// 	// 	this.setMenuData(nextProps.location.pathname);
	// 	// 	this.setState({ prevPathname: nextProps.location.pathname });
	// 	// }
	// 	// this.setMenuData();
	// }

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};

	getMenuKey(pathname, menuList) {
		// console.log("getMenuKey: ", pathname, menuList);
		for (let i = 0; i < menuList.length; i++) {
			if (pathname.indexOf(menuList[i]) !== -1) {
				// console.log("menu item found: ", menuList[i], i);
				// return [i.toString()];
				return [menuList[i]];
			}
		}
	}
	setMenuKey(pathname) {
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		const personalMenuList = ["/allOrgs", "/myAccount", "/orgs", "/signout"];
		const orgMenuList = ["/allOrgs", "/samples", "/broadcasts", "/chats", "/contactLists", "/agents", "/history/", "/settings", "/billing"];

		// console.log("setMenuKey: ", pathname);

		if (this.state.currentPathname === pathname) return;

		let menuIndex = 0;
		let personalPageIndex = 0;
		let orgPageIndex = 0;

		menuIndex = personalPages.indexOf(pathname);

		if (menuIndex !== -1) {
			for (let i = 0; i < personalMenuList.length; i++) {
				if (pathname.indexOf(personalMenuList[i]) !== -1) {
					personalPageIndex = [i.toString()];
					break;
				}
			}
		} else {
			for (let i = 0; i < orgMenuList.length; i++) {
				if (pathname.indexOf(orgMenuList[i]) !== -1) {
					orgPageIndex = [i.toString()];
					break;
				}
			}
		}

		console.log("setMenuKey: ", pathname, menuIndex, personalPageIndex, orgPageIndex);
		this.setState({
			currentPathname: pathname,
			menuIndex: menuIndex,
			personalPageIndex: personalPageIndex,
			orgPageIndex: orgPageIndex
		});
	}

	setMenuData(urlPathname) {
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		if (personalPages.indexOf(urlPathname) !== -1) {
			//currently in personal section, set personal menu data
			console.log("SET MENU DATA: ", window.localStorage.getItem("cuEmail"), window.localStorage.getItem("cuUid"));
			this.setState({
				menuName: window.localStorage.getItem("cuEmail"),
				menuId: window.localStorage.getItem("cuUid")
			});
		} else {
			console.log("SET MENU DATA: ", window.localStorage.getItem("cuOrgName"), window.localStorage.getItem("cuOrgId"));
			this.setState({
				menuName: window.localStorage.getItem("cuOrgName"),
				menuId: window.localStorage.getItem("cuOrgId")
			});
		}
	}

	render() {
		console.log("this.props MENU: ", this.props);
		const pathname = this.props.location.pathname;
		// this.setMenuKey(pathname);
		// console.log("this.props MENU: ", this.props.routerProps);
		const personalMenuList = ["/allOrgs", "/myAccount", "/orgs", "/signout"];
		const orgMenuList = ["/allOrgs", "/samples", "/broadcasts", "/chats", "/contactLists", "/agents", "/history/", "/settings", "/billing"];
		const personalPages = ["/allOrgs", "/myAccount", "/signout"];
		return (
			// style={{ position: "fixed", top: "64px", bottom: "0px", overflowY: "auto", zIndex: 1 }}
			// breakpoint="lg" collapsedWidth="0"
			//breakpoint="lg" collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}
			<div>
				<div
					style={{ display: this.props.isSideMenuOpen ? "block" : "none" }}
					className="sideMenuScreen"
					onClick={() => {
						this.props.closeSideMenu();
					}}
				/>
				<Sider
					className={`card1 sideMenu ${this.props.isSideMenuOpen ? "sideMenuOpen" : ""}`}
					onClick={() => {
						this.props.closeSideMenu();
					}}>
					{/* <div className="logo" /> */}
					{/* {personalPages.indexOf(this.props.location.pathname) !== -1 && ( */}
					{personalPages.indexOf(this.props.location.pathname) !== -1 && (
						<div className="taCenter tWhite ">
							<Avatar size={80} src={this.props.currentUser.photoURL === "/imgs/default_profile.png" ? default_profile : this.props.currentUser.photoURL} style={{ marginTop: "20px", marginBottom: "12px", width: this.state.collapsed ? "40px" : "80px", height: this.state.collapsed ? "40px" : "80px", lineHeight: this.state.collapsed ? "40px" : "80px", fontSize: this.state.collapsed ? "20px" : "40px", transition: "0.3s" }} />
							{/* {!this.state.collapsed && <div style={{ marginBottom: "12px" }}>LS: {window.localStorage.getItem("cuEmail")}</div>} */}
							{!this.state.collapsed && <div className=" mb12"> {this.props.currentUser.uid ? (this.props.currentUser.displayName ? this.props.currentUser.displayName : this.props.currentUser.email) : "Loading..."}</div>}
							{/* <br />
						context: {this.props.gcState.menuName}
						<br />
						context: {this.props.gcState.currentUser ? this.props.gcState.currentUser.email : "no curr user"} */}
							{/* {!this.state.collapsed && (
							<div style={{ marginBottom: "12px" }}>
								{this.state.gcState.currentUser.displayName ? this.state.gcState.currentUser.displayName : this.state.gcState.currentUser.email}
								{this.state.gcState.currentUser.email}
								{this.state.gcStateEmail}
								test
							</div>
						)} */}
							{!this.state.collapsed && (
								<div style={{ marginBottom: "12px" }}>
									{/* {this.props.gcState} */}
									{/* <br /> */}
									{/* {this.props.gcState.currentUser ? this.props.gcState.currentUser.email : "no curr user :O"} */}
									{/* GC: {this.state.menuName} - {this.props.gcState.menuName} */}
									{/* <GlobalContext.Consumer>
									{gcState => {
										console.log("testing consumer gcState: ", gcState);
										console.log("testing consumer gcState email: ", gcState.menuName);
										return (
											<span>
												GC: {gcState.menuName} {gcState.currentUser ? gcState.currentUser.email : "No email :("} {this.state.menuName}
											</span>
										);
									}}
								</GlobalContext.Consumer> */}
								</div>
							)}
						</div>
					)}
					{personalPages.indexOf(this.props.location.pathname) === -1 && (
						<div className="taCenter tWhite ">
							<Avatar size={80} src={this.props.currentOrg.photo === "/imgs/default_org.png" ? default_org : this.props.currentOrg.photo} style={{ marginTop: "20px", marginBottom: "12px", width: this.state.collapsed ? "40px" : "80px", height: this.state.collapsed ? "40px" : "80px", lineHeight: this.state.collapsed ? "40px" : "80px", fontSize: this.state.collapsed ? "20px" : "40px", transition: "0.3s" }} />
							{/* {!this.state.collapsed && <div style={{ marginBottom: "12px" }}>{window.localStorage.getItem("cuOrgName")}</div>} */}
							{!this.state.collapsed && (
								<div className="fw500 mb12">
									{this.props.currentOrg.name ? this.props.currentOrg.name : "Loading..."}
									{/* {this.props.currentOrg.photo} */}
								</div>
							)}

							{/* {!this.state.collapsed && (
							<div style={{ marginBottom: "12px" }}>
								<GlobalContext.Consumer>{val => val.menuName}</GlobalContext.Consumer>
							</div>
						)} */}
						</div>
					)}

					{/* <Menu.Item key="0">
                                <Icon type="safety" />
                                <span>Admin Console</span>
							</Menu.Item> */}
					{personalPages.indexOf(this.props.location.pathname) !== -1 && (
						<Menu theme="dark" selectedKeys={this.getMenuKey(this.props.location.pathname, personalMenuList)} mode="inline" style={{ paddingTop: "0px" }}>
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="23421" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="/allOrgs">
								<Link to="/allOrgs">
									<Icon type="bank" />
									<span>Organizations</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/myAccount">
								<Link to="/myAccount">
									<Icon type="user" />
									<span>My Account</span>
								</Link>
							</Menu.Item>
							{/* <Menu.Item key="2">
							<Link to="/orgs/123/broadcasts/history">
								<Icon type="step-forward" />
								<span>Org 123</span>
							</Link>
						</Menu.Item> */}
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="23112342" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="/signout">
								<Link to="/signout">
									<Icon type="poweroff" />
									<span>Sign Out</span>
								</Link>
							</Menu.Item>
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="341324" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="4">
								<Link to="https://vantagesms.com" />
								<Icon type="mobile" />
								<span>VantageSMS</span>
							</Menu.Item>
						</Menu>
					)}

					{personalPages.indexOf(this.props.location.pathname) === -1 && (
						<Menu theme="dark" selectedKeys={this.getMenuKey(this.props.location.pathname, orgMenuList)} mode="inline" style={{ paddingTop: "0px" }}>
							{/* <Menu theme="dark" selectedKeys={this.state.orgPageIndex} mode="inline" style={{ paddingTop: "0px" }}> */}
							{/*<hr className="hrBlue" /> */}
							{/* <div className="hrBlue">aaaa</div> */}
							<Menu.Item key="2312341" className="borderTopBlue" style={{ height: "0px" }} />

							<Menu.Item key="/allOrgs" className="">
								<Link to="/allOrgs">
									<Icon type="arrow-left" />
									<span>Back</span>
								</Link>
							</Menu.Item>
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="231341" className="borderTopBlue" style={{ height: "0px" }} />

							<Menu.Item key="/broadcasts" className="">
								<Link to={`/orgs/${this.props.currentOrg.id}/broadcasts/history`}>
									<Icon type="notification" />
									<span>Broadcasts</span>
								</Link>
							</Menu.Item>
							{/* <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <Icon type="user" />
                                        <span>User</span>
                                    </span>
                                }>
                                <Menu.Item key="3">Tom</Menu.Item>
                                <Menu.Item key="4">Bill</Menu.Item>
                                <Menu.Item key="5">Alex</Menu.Item>
                </SubMenu> */}
							<Menu.Item key="/chats">
								<Link to={`/orgs/${this.props.currentOrg.id}/chats/inbound`}>
									<Icon type="message" />
									<span>Chats</span>
								</Link>
							</Menu.Item>
							{/* <Menu.Item key="/samples" className="">
								<Link to={`/orgs/${this.props.currentOrg.id}/samples`}>
									<Icon type="file-text" />
									<span>Templates</span>
								</Link>
							</Menu.Item> */}
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="2234341" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="/contactLists" className="">
								<Link to={`/orgs/${this.props.currentOrg.id}/contactLists/all`}>
									<Icon type="solution" />
									<span>Contact Lists</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/agents">
								<Link to={`/orgs/${this.props.currentOrg.id}/agents`}>
									<Icon type="team" />
									<span>Agents</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/history/">
								<Link to={`/orgs/${this.props.currentOrg.id}/history/sms`}>
									<Icon type="bars" />
									<span>History</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/settings">
								<Link to={`/orgs/${this.props.currentOrg.id}/settings`}>
									<Icon type="setting" />
									<span>Settings</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/billing">
								<Link to={`/orgs/${this.props.currentOrg.id}/billing/usage`}>
									<Icon type="credit-card" />
									<span>Billing</span>
								</Link>
							</Menu.Item>
							{/* <Menu.Item key="14">
                                <Icon type="team" />
                                <span>Feedback</span>
                            </Menu.Item> */}
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="23423421" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="/signout" className="">
								<Link to="/signout">
									<Icon type="poweroff" />
									<span>Sign Out</span>
								</Link>
							</Menu.Item>
							{/*<hr className="hrBlue" /> */}
							<Menu.Item key="234555341" className="borderTopBlue" style={{ height: "0px" }} />
							<Menu.Item key="9" className="">
								<Link to="https://vantagesms.com" />
								<Icon type="mobile" />
								<span>VantageSMS</span>
							</Menu.Item>
						</Menu>
					)}
					<br />
					<br />
					<br />
				</Sider>
			</div>
		);
	}
}

export default withRouter(SideMenu);
