import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, List, Card, Tag, Input, Button, Modal, Tooltip } from "antd";
import * as firebase from "firebase";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;

// class App extends Component {
export class BroadcastLogs extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		smsLogs: [],
		pageLimit: 100,
		contactLists: {},
		scheduledEvents: [],
		scheduledLogs: {},
		scheduledNotify: {},
		isModalVisible: false,
		modalData: {}
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		// this.setState({ loading: true });
		// setTimeout(() => {
		// 	this.setState({ loading: false, isModalVisible: false });
		// }, 2000);
		this.setState({ loading: false, isModalVisible: false });
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			isModalVisible: false
		});
	};

	getBroadcastEvent(broadcastId) {
		firebase
			.firestore()
			.collection(`scheduledEvents`)
			.where("broadcastId", "==", broadcastId)
			.limit(2)
			.get()
			.then(
				querySnapshot => {
					const event = [];
					let logs = {};
					let notify = {};
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						// record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						if (record.action === "generateBroadcastSmsLogs") logs = record;
						if (record.action === "broadcastNotification") notify = record;
						event.push(record);
					});
					console.log("broadcast scheulded event: ", event);
					// console.log("event scheduled: ", moment(event[0].sendTime).format("llll"));
					// console.log("event scheduled: ", moment(event[1].sendTime).format("llll"));
					this.setState({
						scheduledLogs: logs,
						scheduledNotify: notify
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getBroadcastLogs(broadcastId) {
		firebase
			.firestore()
			.collection(`smsLogs`)
			.where("broadcastId", "==", broadcastId)
			.orderBy("sendTime", "asc")
			.limit(this.state.pageLimit)
			.get()
			.then(
				querySnapshot => {
					const broadcastsList = [];

					const records = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						// record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						records.push(record);
					});
					console.log("smsLogs: ", records);
					this.setState({
						smsLogs: records,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getBroadcastEvent(this.props.match.params.broadcastId);
		this.getBroadcastLogs(this.props.match.params.broadcastId);
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;
		const broadcastId = this.props.match.params.broadcastId;

		const columns = [
			{
				title: "",
				dataIndex: "direction",
				key: "direction",
				render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Outbound" : "Inbound"}>{record.direction === "outbound" ? <Icon type="arrow-right" className="tgrey fs16" /> : <Icon type="arrow-left" className="tblue fs16" />}</Tooltip>,
				// size: "100px",
				width: 60,
				align: "center"
			},
			// {
			// 	title: "From",
			// 	dataIndex: "src",
			// 	key: "src",
			// 	width: 150,
			// 	render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Source Number" : "Contact Number"}>{record.src}</Tooltip>
			// },
			{
				title: "To",
				dataIndex: "dst",
				key: "dst",
				width: 150,
				render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Contact Number" : "Source Number"}>{record.dst}</Tooltip>
			},
			{
				title: "Message",
				dataIndex: "text",
				key: "text",
				render: (text, record) => (
					// <Tooltip title={record.text}>
					// 	<div className="ellip pointer" style={{ maxWidth: "500px" }}>
					// 		{record.text}
					// 	</div>
					// </Tooltip>
					<div className="ellip pointer" style={{ maxWidth: "500px" }}>
						{record.text}
					</div>
				),
				width: 250
				// clasName: "ellip"
				// fixed: true
			},

			{
				title: "Msg Status",
				dataIndex: "pipelineStatus",
				key: "pipelineStatus",
				width: 150,
				align: "center"
				// render: (text, record) => <span>{this.getDuration(moment.duration(record.duration)._data)}</span>
				// render: (text, record) => <span>{moment.duration(123123)}</span>
			},
			{
				title: "Send Time",
				dataIndex: "sendTime",
				key: "sendTime",
				width: 200,
				align: "center",
				render: (text, record) => (
					<Tooltip title={moment(record.sendTime).format("llll")}>
						<span>{moment(record.sendTime).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Broadcasts</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Broadcasts</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/${orgId}/broadcasts/history`}>History</Link>
							</Menu.Item>
							<Menu.Item key="plan">
								<Link to={`/orgs/${orgId}/broadcasts/new`}>New Broadcast</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div> */}
				<div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<Link to={`/orgs/${orgId}/broadcasts/history`}>
							<Tooltip title={"Back to All Broadcasts"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>Broadcast Details</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["logs"]} mode="horizontal">
							<Menu.Item key="settings">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/settings`}>Settings</Link>
							</Menu.Item>
							<Menu.Item key="logs">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/logs`}>Logs</Link>
							</Menu.Item>
							<Menu.Item key="stats">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/stats`}>Stats?</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div>

				<Card title="Scheduled Event" className="card1 mb24 ">
					SMS Logs to be generated on: <b>{this.state.scheduledLogs.sendTime ? moment(this.state.scheduledLogs.sendTime).format("llll") : ""}</b>
					<br />
					Broadcast Notification to be generated on: <b>{this.state.scheduledNotify.sendTime ? moment(this.state.scheduledNotify.sendTime).format("llll") : ""}</b>
				</Card>

				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							New Broadcast
						</Button>
					</span>
					<Search placeholder="Search Broadcasts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
				</div> */}
				{/* loading={this.state.isLoading}  */}

				{/* <List
					grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
					dataSource={this.state.broadcastsList}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					renderItem={item => (
						<List.Item>
							<Link to={`/orgs/${orgId}/broadcasts/details/${item.id}/settings`}>
								<Card title={moment(item.created).format("ddd, MMM DD, YYYY")} className="linkCard">
									<p className=" mb0 fw500 fs15">{this.state.contactLists[item.listId] ? this.state.contactLists[item.listId].name : "Loading..."}</p>
									<p className=" ">
										{item.totalRecipients} Recipients &middot; {this.getDuration(moment.duration(item.duration)._data)}
									</p>
									<Tooltip title={item.message}>
										<div className="  " style={{ height: 72, overflow: "hidden", border: "1px solid #e8e8e8", borderRadius: 4, padding: 4, position: "relative" }}>
											{item.message}
											<div style={{ position: "absolute", height: "24px", bottom: "0px", left: "0px", width: "100%", background: "linear-gradient(to top, #fff 0%,#fff 10%,#fff0 100%)" }} />
										</div>
									</Tooltip>
								</Card>
							</Link>
						</List.Item>
					)}
				/> */}

				<Table
					className="card1 bgWhite"
					columns={columns}
					dataSource={this.state.smsLogs}
					pagination={{ pageSize: this.state.pageLimit }}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
					onRow={(record, rowIndex) => {
						return {
							onClick: event => {
								// alert("test!");
								console.log("modalData: ", record);
								this.setState({
									isModalVisible: true,
									modalData: record
								});
							} // click row
						};
					}}
				/>

				<Modal
					title="SMS Log Record"
					visible={this.state.isModalVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isModalLoading} onClick={this.handleOk}>
							Close
						</Button>
					]}>
					<p className="bold" style={{ marginBottom: "8px" }}>
						Send Time:
					</p>
					<p className="">
						{moment(this.state.modalData.sendTime).format("llll")} {moment(this.state.modalData.sendTime).fromNow()}
					</p>
					<Divider style={{ margin: "16px 0px" }} />

					<p className="bold" style={{ marginBottom: "8px" }}>
						Direction:
					</p>
					<p className="">
						{this.state.modalData.direction === "outbound" ? <Icon type="arrow-right" className="tgrey fs16" /> : <Icon type="arrow-left" className="tblue fs16" />}
						&nbsp; {this.state.modalData.direction === "outbound" ? "Outbound" : "Inbound"}
					</p>
					<Divider style={{ margin: "16px 0px" }} />

					<p className="bold" style={{ marginBottom: "8px" }}>
						From:
					</p>
					<p className="">{this.state.modalData.src}</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						To:
					</p>
					<p className="">{this.state.modalData.dst}</p>

					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Contact:
					</p>
					<p className="">Load Contact Info</p>

					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Message Body:
					</p>
					<p className=" broadcastMessageDisplay">{this.state.modalData.text}</p>

					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						Status:
					</p>
					<p className="">{this.state.modalData.pipelineStatus}</p>

					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold" style={{ marginBottom: "8px" }}>
						SMS/MMS Parts:
					</p>
					<p className="">
						{this.state.modalData.units} SMS Parts &middot; {this.state.modalData.unitsMedia} MMS
					</p>
				</Modal>
			</Content>
		);
	}
}
