import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Checkbox, Input, Button, Modal, Tabs, Tooltip, Dropdown } from "antd";
import * as firebase from "firebase";
import request from "request";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class AgentInfo extends React.Component {
	state = {
		collapsed: false,
		visible: true,
		loading: false,
		isLoading: false,
		isEditingAgent: false,
		agentInfo: { firstName: "Loading..." },
		contacts: [],
		pageSize: 15,
		contactList: { name: "Loading..." }
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getAgentInfo(orgId, agentId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}/members/${agentId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.added = doc.data().added.getTime();
						this.setState({ agentInfo: record, isLoading: false });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getUrlId(pathname, position) {
		return pathname.split("/")[position];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		// const orgId = this.getUrlId(this.props.location.pathname, 2);
		// const agentId = this.getUrlId(this.props.location.pathname, 4);
		const orgId = this.props.match.params.orgId;
		const agentId = this.props.match.params.agentId;
		// this.getContacts(orgId, listId);
		this.getAgentInfo(orgId, agentId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	capitalizeFirstLetter(string) {
		return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
	}

	// SAMPLE AGENT DB OBJ
	// {
	// 	email: "bob@example.com",
	// 	role: "admin", // owner / admin / rep
	// 	listAccess: {
	// 		"listIdA123": true,
	// 		"listIdB456": true,
	// 	},
	// 	emailPreferences:{
	// 		isBroadcastRecipient: true,
	// 		isInboundSmsRecipient: true,
	// 	}
	// }

	editAgent() {
		console.log("creating new org");
		if (!this.state.agentEmail) {
			Modal.error({
				title: "Missing Agent Email",
				content: `You must enter an email for this agent.`
			});
			return;
		}
		if (!this.state.agentRole) {
			Modal.error({
				title: "Missing Agent Role",
				content: `You must enter a role for this agent.`
			});
			return;
		}
		this.setState({ isEditingAgent: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/org.createOrg",
						// url: "http://localhost:8080/" + "v2/org.createOrg",

						headers: { authorization: "Bearer " + idToken },
						form: {}
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isOrgModalLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							return this.setState({ isOrgModalLoading: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						const newOrgObj = JSON.parse(body);
						newOrgObj.key = newOrgObj.id;
						this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "", orgEmail: "", orgsList: [newOrgObj].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isOrgModalLoading: false, isVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.props.match.params.orgId;
		const agentId = this.props.match.params.agentId;

		return (
			<Modal
				title="Agent Information"
				visible={this.state.visible}
				onOk={this.handleOk}
				onCancel={this.handleCancel}
				afterClose={() => {
					this.props.history.push(`/orgs/${orgId}/agents`);
				}}
				footer={[
					<Button key="back" icon="close" onClick={this.handleCancel}>
						Cancel
					</Button>,
					<Button key="edit" icon="edit" onClick={this.handleCancel} loading={this.state.isEditingAgent}>
						Edit
					</Button>,
					<Button key="delete" type="" className="dangerBtn" icon="delete" loading={this.state.loading} onClick={this.handleOk}>
						Delete
					</Button>
				]}>
				{this.state.isLoading && (
					<div className="taCenter" style={{ padding: "20px" }}>
						<Icon type="loading" style={{ fontSize: "28px" }} />
						{/* <br />
							Loading... */}
					</div>
				)}
				{!this.state.isLoading && (
					<div className="">
						<p className="fs15">
							{/* <Button key="optOut" type="primary" icon="stop" style={{ float: "right" }} loading={this.state.loading} onClick={this.handleOk}>
									Opt Out
								</Button> */}
							<b>{this.state.agentInfo.name ? this.state.agentInfo.name : <span className="tgrey">Name not set</span>}</b>
							<br />
							{this.state.agentInfo.email}
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						{/* <p className="fs15">
							{" "}
							<b>Name</b> <br />
							{this.state.agentInfo.name}
						</p>
						<p className="fs15">
							{" "}
							<b>Email</b> <br />
							{this.state.agentInfo.email}
						</p> */}
						<p className="">
							{" "}
							<b>Role</b> <br />
							{this.capitalizeFirstLetter(this.state.agentInfo.status)}
						</p>
						<p className="">
							<b>Added</b> <br />
							<Tooltip title={moment(this.state.agentInfo.added).fromNow()}>
								<span>{moment(this.state.agentInfo.added).format("LLL")}</span>
							</Tooltip>
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						<p className="bold" style={{ marginBottom: "8px" }}>
							Email Preferences:
						</p>
						<p style={{ marginBottom: "24px" }}>
							<Checkbox name="isReceiveBcastEmails" checked={this.state.isReceiveBcastEmails} value={this.state.isReceiveBcastEmails} onChange={e => this.handleChange(e)}>
								Receive Scheduled Broadcast Notifications via Email?
							</Checkbox>
						</p>
						<p style={{ marginBottom: "24px" }}>
							<Checkbox name="isReceiveInboundSmsEmails" checked={this.state.isReceiveInboundSmsEmails} value={this.state.isReceiveInboundSmsEmails} onChange={e => this.handleChange(e)}>
								Receive Inbound SMS Notifications via Emails?
							</Checkbox>
						</p>
						<Divider style={{ margin: "16px 0px" }} />
						<p className="bold" style={{ marginBottom: "8px" }}>
							List Access:
						</p>
						{(this.state.agentStatus === "owner" || this.state.agentStatus === "admin") && (
							<div className="">
								<p>
									An agent with status {this.capitalizeFirstLetter(this.state.agentStatus)} has access to <b>ALL</b> contact lists.
								</p>
							</div>
						)}
						{this.state.agentStatus === "rep" && (
							<div className="">
								<p>Select the Contact Lists this agent can access.</p>
								{/* <p style={{ marginBottom: "24px" }}>
						<Input className="maxW mw300" name="agentListAccess" placeholder="Contact Lists" value={this.state.agentListAccess} onChange={e => this.handleChange(e)} />
					</p> */}
								<div className="mb24">
									{this.state.contactLists.map((list, index) => {
										return (
											<Checkbox className="maxW  ml0 mb8 mr8 pb8 bb" key={list.id} name={list.id} checked={list.isSelected} onChange={e => this.toggleSelectedList(list.id)}>
												<div className="" style={{ display: "inline-block" }}>
													<span className={`bold`}>{list.name}</span>
													<br />
													<span className={`tgrey ${list.isSelected ? "bold" : ""}`}>{list.totalContacts} Contacts</span>
												</div>
											</Checkbox>
											// <div
											// 	className="pointer"
											// 	key={list.id}
											// 	onClick={e => {
											// 		this.toggleSelectedList(list.id);
											// 	}}
											// 	style={{ display: "inline-block", minWidth: "280px" }}>
											// 	<Button icon={`${list.isSelected ? "check" : "plus"}`} type={`${list.isSelected ? "primary" : "default"}`} className="fleft mr12 mt4" />
											// 	{list.isSelected && <Icon type="check-square" className="tblue" />}
											// 	{!list.isSelected && <Icon type="plus-square" />}
											// 	<div className="bold ">{list.name}</div>
											// 	<div className={`tgrey pb16 ${list.isSelected ? "bold" : ""}`}>
											// 		{list.totalContacts} Contacts &middot;&nbsp;
											// 		{moment(list.created).format("ll")}
											// 	</div>
											// </div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				)}
			</Modal>
		);
	}
}
