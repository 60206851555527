import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Divider, Avatar, Button, Icon, Input, Tooltip } from "antd";

import * as firebase from "firebase";
import moment from "moment";
import request from "request";

// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const Search = Input.Search;

// class App extends Component {
export class SearchChats extends React.Component {
	state = {
		isLoading: false,
		chats: [],
		searchResults: [],
		contactListsObj: {}
	};

	// chatsConvoHeader(name, listId, updated) {
	// 	return (
	// 		<div className="">
	// 			<span style={{}}>{name}</span> <span>&middot; {listId}</span> <span style={{ float: "right" }}>{updated}</span>
	// 		</div>
	// 	);
	// }

	runSearch(orgId, searchText) {
		// alert("create contact list api");
		this.setState({ isLoading: true });
		const requestForm = {
			orgId: orgId,
			query: searchText
			// lsitId: lsitId,
			// created: firebase.firestore.FieldValue.serverTimestamp()
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "search.contacts",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isLoading: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isLoading: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						// searchResults: [JSON.parse(body)].concat(this.state.contactLists)
						const searchResults = [];
						const reqResults = JSON.parse(body);
						console.log("reqResults.hits.hits: ", reqResults.hits.hits);
						for (let i = 0; i < reqResults.hits.hits.length; i++) {
							const record = reqResults.hits.hits[i]["_source"];
							searchResults.push(record);
							if (!this.state.contactListsObj[record.listId]) this.getListInfo(record.orgId, record.listId);
						}

						console.log("searchResults: ", searchResults);
						this.setState({ isLoading: false, searchResults: searchResults });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
			});
	}

	getListInfo(orgId, listId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						// console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						const contactListsObj = this.state.contactListsObj;
						contactListsObj[record.id] = record;
						this.setState({ contactListsObj: contactListsObj });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	chatsConvoHeader(record) {
		return (
			<div className="">
				<span style={{}}>
					{record.firstName} {record.lastName}
				</span>{" "}
				<span className="tgrey fwNormal">&middot; {this.state.contactListsObj[record.listId] ? this.state.contactListsObj[record.listId].name : "Loading.."}</span>{" "}
				{!record.lastSmsTime && (
					<span style={{ float: "right" }}>
						<Tooltip title={moment(record.created).format("LLLL")}>
							<span className="tgrey" style={{ fontWeight: "normal" }}>
								{moment(record.created).fromNow()}
							</span>
						</Tooltip>
					</span>
				)}
				{record.lastSmsTime && (
					<span style={{ float: "right" }}>
						<Tooltip title={moment(record.lastSmsTime).format("LLLL")}>
							<span className="tgrey" style={{ fontWeight: "normal" }}>
								{moment(record.lastSmsTime).fromNow()}
							</span>
						</Tooltip>
					</span>
				)}
			</div>
		);
	}

	render() {
		// console.log("ContactListMenu: this.props: ", this.props);
		const orgId = this.props.orgId;
		const currChatCol = this.props.currChatCol;
		const contactId = this.props.contactId;
		// const listId = this.props.listId;

		return (
			<div>
				<p className="mb0" style={{ padding: "12px 12px", borderBottom: "1px solid #e8e8e8", background: "#f5f5f5" }}>
					<Search placeholder="Search by Name or Number" onSearch={searchText => this.runSearch(orgId, searchText)} enterButton />
				</p>

				{this.state.isLoading && (
					<div className="ant-card-body taCenter">
						<p className="taCenter" style={{ marginBottom: "0px", width: "100%" }}>
							<Icon type="loading" style={{ fontSize: "28px" }} />
						</p>
					</div>
				)}
				{!this.state.isLoading && this.state.searchResults.length === 0 && (
					<div className="ant-card-body taCenter">
						<p>
							<Icon type="search" className="fs24" />
						</p>
						<p className="tgrey">Search for Contacts by Name or Mobile Number</p>
					</div>
				)}
				{!this.state.isLoading && this.state.searchResults.length > 0 && (
					<List
						itemLayout="horizontal"
						dataSource={this.state.searchResults}
						// loading={this.state.isLoading}
						// loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
						// loading={this.customSpinner()}
						renderItem={item => (
							<List.Item style={{ padding: 0 }} className={`${contactId && contactId === item.id ? "activeChat" : ""}`}>
								<Link to={`/orgs/${orgId}/chats/inbound/${item.id}/history`} className="pointer listLink" style={{ padding: 12, width: "100%", color: "inherit" }}>
									<List.Item.Meta title={this.chatsConvoHeader(item)} />
									{item.lastSmsText ? item.lastSmsText : <span className="tgrey">No chat history</span>}
								</Link>
							</List.Item>
						)}
					/>
				)}
			</div>
		);
	}
}
