import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Tooltip, Dropdown, Card, Row, Col, Select } from "antd";
import * as firebase from "firebase";
// import moment from "moment";
import moment from "moment-timezone";

import { ContactListMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;
const Option = Select.Option;

// class App extends Component {
export class ContactListSettings extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		isModalVariablesVisible: false,
		isModalSettingsVisible: false,
		isUpdatingList: false,
		contacts: [],
		pageSize: 15,
		selectedFile: {},
		contactList: { name: "Loading..." }
	};

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
			isModalVariablesVisible: false,
			isModalSettingsVisible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	getContacts(orgId, listId) {
		firebase
			.firestore()
			.collection(`contacts`)
			.where("orgId", "==", orgId)
			.where("listId", "==", listId)
			.orderBy("created", "desc")
			.limit(20)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const contacts = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						record.created = doc.data().created.getTime();
						record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						contacts.push(record);
					});
					console.log("orgsList: ", contacts);
					this.setState({
						contacts: contacts,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(orgId, listId) {
		firebase
			.firestore()
			.doc(`orgs/${orgId}/contactLists/${listId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ contactList: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	getOrgId(pathname) {
		return pathname.split("/")[2];
	}
	getListId(pathname) {
		return pathname.split("/")[4];
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.props.match.params.orgId;
		const listId = this.props.match.params.listId;
		// this.getContacts(orgId, listId);
		this.getContactList(orgId, listId);
	}

	updatePageSize(rows) {
		this.setState({ pageSize: rows });
	}

	updateListSettings(orgId, listId) {
		if (!this.state.editName) {
			Modal.error({
				title: "Missing List Name",
				content: `Contact list name is required.`
			});
			return;
		}
		if (!this.state.editCountry) {
			Modal.error({
				title: "Missing List Country",
				content: `Contact list country is required.`
			});
			return;
		}
		if (!this.state.editTimezone) {
			Modal.error({
				title: "Missing List Timezone",
				content: `Contact list timezone is required.`
			});
			return;
		}
		if (this.state.editPrefix1 && isNaN(this.state.editPrefix1)) {
			Modal.error({
				title: "Area Code 1 is invalid!",
				content: `Area Code 1 must be 3 digits (between 100 - 999).`
			});
			return;
		}
		if (this.state.editPrefix2 && isNaN(this.state.editPrefix2)) {
			Modal.error({
				title: "Area Code 2 is invalid!",
				content: `Area Code 2 must be 3 digits (between 100 - 999).`
			});
			return;
		}
		if (!this.state.editPrefix1 && !this.state.editPrefix2) {
			Modal.confirm({
				title: "Missing Area Code Preferences",
				// content: `Submitting phone number prefixes helps improve message deliverability.`,
				content: (
					<div>
						<p>Submitting phone number Area Codes preferences helps improve message deliverability.</p>
						<p>Area Codes preferences are not required, but they are recommended.</p>
					</div>
				),
				cancelText: "Skip this Step",
				okText: "Set Area Codes",
				onOk: () => {
					// alert("ok!");
					return;
				},
				onCancel: () => {
					// alert("cancel!");
					this.submitListUpdate(orgId, listId);
				}
			});

			// return;
		} else {
			if (this.state.editPrefix1 && (this.state.editPrefix1 > 999 || this.state.editPrefix1 < 100)) {
				Modal.error({
					title: "Invalid Area Code 1",
					content: `Area Code 1 must be 3 digits (between 100 - 999).`
				});
				return;
			}
			if (this.state.editPrefix2 && (this.state.editPrefix2 > 999 || this.state.editPrefix2 < 100)) {
				Modal.error({
					title: "Invalid Area Code 2",
					content: `Area Code 2 must be 3 digits (between 100 - 999).`
				});
				return;
			}
			this.submitListUpdate(orgId, listId);
		}
	}

	submitListUpdate(orgId, listId) {
		this.setState({ isUpdatingList: true });
		const updateObj = {
			name: this.state.editName,
			country: this.state.editCountry,
			timezone: this.state.editTimezone,
			prefix1: this.state.editPrefix1 ? this.state.editPrefix1 : null,
			prefix2: this.state.editPrefix2 ? this.state.editPrefix2 : null
		};
		firebase
			.firestore()
			.doc(`/orgs/${orgId}/contactLists/${listId}`)
			.update(updateObj)
			.then(() => {
				console.log("update document success");
				const contactList = this.state.contactList;
				this.setState({ isModalSettingsVisible: false, isUpdatingList: false, contactList: { ...contactList, ...updateObj } });
			})
			.catch(error => {
				// Handle get user token error
				console.log("update document error: ", error);
				this.setState({ isUpdatingList: false });
			});
	}

	updateListVariables(orgId, listId) {
		this.setState({ isUpdatingList: true });
		const updateObj = {
			varListName: this.state.editListName ? this.state.editListName : null,
			varListContact: this.state.editListContact ? this.state.editListContact : null,
			varListPhone: this.state.editListPhone ? this.state.editListPhone : null,
			varListEmail: this.state.editListEmail ? this.state.editListEmail : null,
			varListSite: this.state.editListSite ? this.state.editListSite : null,
			varListAddr: this.state.editListAddr ? this.state.editListAddr : null
		};

		firebase
			.firestore()
			.doc(`/orgs/${orgId}/contactLists/${listId}`)
			.update(updateObj)
			.then(() => {
				console.log("update document success");
				const contactList = this.state.contactList;
				this.setState({ isModalVariablesVisible: false, isUpdatingList: false, contactList: { ...contactList, ...updateObj } });
			})
			.catch(error => {
				// Handle get user token error
				console.log("update document error: ", error);
				this.setState({ isUpdatingList: false });
			});
	}

	getCountryName(countryCode) {
		let countryName = "";
		console.log("COUNTRY NAME: ", countryCode);
		if (countryCode) countryCode = countryCode.toLowerCase();
		switch (countryCode) {
			case "ca":
				countryName = "Canada";
				break;
			case "us":
				countryName = "United States";
				break;
			default:
				countryName = "Country Name Not Found";
				break;
		}
		return countryName;
	}

	handleChangeTZ(value) {
		console.log(`selected ${value}`);
		this.setState({ editTimezone: value });
	}

	handleBlur() {
		console.log("blur");
	}

	handleFocus() {
		console.log("focus");
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.props.match.params.orgId;
		const listId = this.props.match.params.listId;

		const columns = [
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (text, record) => (
					<Tooltip title={!record.isOptedOut ? "Subscribed" : "Opted Out"}>
						<span className="taCenter dBlock">{!record.isOptedOut ? <Icon type="check" className="tgreen" /> : <Icon type="stop" className="tgrey" />}</span>
					</Tooltip>
				)
			},
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<Link to={`/orgs/${record.orgId}/contactLists/${record.listId}/contacts`} className="fs15">
						{record.firstName} {record.lastName}
					</Link>
				)
			},
			{
				title: "Number",
				dataIndex: "e164",
				key: "e164"
			},
			// {
			// 	title: "Last Message",
			// 	dataIndex: "lastMessage",
			// 	key: "lastMessage"
			// },
			{
				title: "Updated",
				dataIndex: "updated",
				key: "updated",
				render: (text, record) => (
					<Tooltip title={moment(record.updated).format("LLLL")}>
						<span>{moment(record.updated).fromNow()}</span>
					</Tooltip>
				)
			},
			{
				title: "Created",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLLL")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const countryListMenu = (
			<Menu
				onClick={e => {
					console.log(e);
					this.setState({ listCountryCode: e.key });
				}}>
				<Menu.Item key="ca">Canada</Menu.Item>
				{/* <Menu.Item key="us">United States</Menu.Item> */}
			</Menu>
		);

		const pageSizeMenu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.updatePageSize(10)}>
					10 Rows Per Page
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.updatePageSize(15)}>
					15 Rows Per Page
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.updatePageSize(20)}>
					20 Rows Per Page
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.updatePageSize(50)}>
					50 Rows Per Page
				</Menu.Item>
				<Menu.Item key="4" onClick={() => this.updatePageSize(100)}>
					100 Rows Per Page
				</Menu.Item>
				{/* <Menu.Divider /> */}
			</Menu>
		);

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Contact Lists</Breadcrumb.Item>
					<Breadcrumb.Item>Retail Customers</Breadcrumb.Item>
					<Breadcrumb.Item>Contacts</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<Link to={`/orgs/${orgId}/contactLists/all`}>
							<Tooltip title={"Back to All Lists"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>{this.state.contactList.name}</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<ContactListMenu selectedKey="settings" orgId={orgId} listId={listId} />
					</div>
				</div>

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="List Settings" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">List Name</span> <br /> {this.state.contactList.name}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">Created</span> <br /> {moment(this.state.contactList.created).format("lll")}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">Total Contacts</span> <br /> {this.state.contactList.totalContacts}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">List Country</span> <br /> {this.state.contactList.country}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">List Timezone -05:00</span> <br /> {"(UTC" + moment.tz(this.state.contactList.timezone).format("Z") + ") " + this.state.contactList.timezone}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">Area Code 1</span> <br /> {this.state.contactList.prefix1 ? this.state.contactList.prefix1 : <span className="tgrey">Area code not set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">Area Code 2</span> <br /> {this.state.contactList.prefix2 ? this.state.contactList.prefix2 : <span className="tgrey">Area code not set.</span>}
							</p>

							{/* <p>How many contacts can be broadcast to at once.</p> */}
							{/* <p style={{}}>
								<span className="tgrey">Selected File:</span> {this.state.selectedFile.name ? this.state.selectedFile.name : "No File Selected"}
							</p> */}
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button> */}
								<Button
									type=""
									icon="edit"
									onClick={() =>
										this.setState({
											isModalSettingsVisible: true,
											editName: this.state.contactList.name,
											editCountry: this.state.contactList.country,
											editTimezone: this.state.contactList.timezone,
											editPrefix1: this.state.contactList.prefix1,
											editPrefix2: this.state.contactList.prefix2
										})
									}>
									Edit List Settings
								</Button>
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card className="card1" title="List Variables" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">$LIST_NAME</span> <br /> {this.state.contactList.varListName ? this.state.contactList.varListName : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$LIST_CONTACT</span> <br /> {this.state.contactList.varListContact ? this.state.contactList.varListContact : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$LIST_PHONE</span> <br /> {this.state.contactList.varListPhone ? this.state.contactList.varListPhone : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$LIST_EMAIL</span> <br /> {this.state.contactList.varListEmail ? this.state.contactList.varListEmail : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$LIST_ADDR</span> <br /> {this.state.contactList.varListAddr ? this.state.contactList.varListAddr : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$LIST_SITE</span> <br /> {this.state.contactList.varListSite ? this.state.contactList.varListSite : <span className="tgrey">No variable value set.</span>}
							</p>
							{/* <p>How many contacts can be broadcast to at once.</p> */}
							{/* <p style={{}}>
								<span className="tgrey">Selected File:</span> {this.state.selectedFile.name ? this.state.selectedFile.name : "No File Selected"}
							</p> */}
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button> */}
								<Button
									type=""
									icon="edit"
									onClick={() =>
										this.setState({
											isModalVariablesVisible: true,
											editListName: this.state.contactList.varListName,
											editListContact: this.state.contactList.varListContact,
											editListPhone: this.state.contactList.varListPhone,
											editListEmail: this.state.contactList.varListEmail,
											editListAddr: this.state.contactList.varListAddr,
											editListSite: this.state.contactList.varListSite
										})
									}>
									Edit Variables
								</Button>
							</p>
						</Card>
					</Col>
				</Row>

				{/* EDIT LIST SETTINTGS MODAL */}
				<Modal
					className=""
					title="Edit Contact List Settings"
					visible={this.state.isModalSettingsVisible}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isUpdatingList} onClick={() => this.updateListSettings(orgId, listId)}>
							Update Settings
						</Button>
					]}>
					<p className="bold mb8">List Name </p>

					<p className="fs16">
						<Input className="maxW mw300" placeholder="List Name" name="editName" value={this.state.editName} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">List Country </p>
					{/* <p className="tgrey mb8">The country your contact list recipients are primarily based. Ie, if your contact list recipients are in Canada, you should set this field to Canada so that your text message broadcasts will be sent from Canadaian phone numbers. </p> */}
					<p className="tgrey mb8">Your text messages will be sent from phone numbers associated with this country. </p>
					<p className="mb24">
						<Dropdown overlay={countryListMenu} trigger={["click"]}>
							<Button className="maxW mw300">
								{this.getCountryName(this.state.editCountry)} <Icon type="down" />
							</Button>
						</Dropdown>
					</p>
					{/* <p className="fs16">
						<Input className="mw300" placeholder="List Country" name="editListCountry" value={this.state.editListCountry} onChange={e => this.handleChange(e)} />
					</p> */}
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">Timezone "-05:00"</p>
					<p className="tgrey mb8">Select the timezone of your contact list recipeints. This allows scheduled messages are received in their local time.</p>
					{/* <p className="fs16">
						<Input className="mw300" placeholder="List Timezone" name="editTimezone" value={this.state.editTimezone} onChange={e => this.handleChange(e)} />
					</p> */}
					<div className="mb24">
						<Select showSearch className="maxW mw300" name="timezone" placeholder="Select a timezone" optionFilterProp="children" onChange={e => this.handleChangeTZ(e)} value={this.state.editTimezone} onFocus={e => this.handleFocus(e)} onBlur={e => this.handleBlur(e)} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
							{moment.tz.names().map((tz, index) => {
								// console.log("timezone: ", tz);
								return (
									<Option key={"timezone" + index} value={tz}>
										{" (UTC" + moment.tz(tz).format("Z") + ") " + tz}
									</Option>
								);
							})}
						</Select>
					</div>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">Area Code Preferences:</p>
					<p className="tgrey mb8">The preferred area code of source numbers used to send outbound messages to your contact list. Try to pick one your contact list recipients will recognize.</p>
					<p className="tgrey mb8">Area Code 1:</p>
					<p className="fs16">
						<Input className="maxW mw300" placeholder="Source Number Prefix 1 (647)" name="editPrefix1" value={this.state.editPrefix1} onChange={e => this.handleChange(e)} />
					</p>
					<p className="tgrey mb8">Area Code 2:</p>
					<p className="fs16">
						<Input className="maxW mw300" placeholder="Source Number Prefix 2 (416)" name="editPrefix2" value={this.state.editPrefix2} onChange={e => this.handleChange(e)} />
					</p>
				</Modal>

				{/* EDIT LIST VARIABLES MODAL */}
				<Modal
					className=""
					title="Edit Contact List Variables"
					visible={this.state.isModalVariablesVisible}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.isUpdatingList} onClick={() => this.updateListVariables(orgId, listId)}>
							Update Variables
						</Button>
					]}>
					<p className="bold mb8">$LIST_NAME </p>
					<p className="tgrey mb8">The name of this list. For example, Store #114.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Name" name="editListName" value={this.state.editListName} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$LIST_CONTACT </p>
					<p className="tgrey mb8">First and/or Last Name of the primary contact person for this list.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Contact First Name" name="editListContact" value={this.state.editListContact} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$LIST_PHONE </p>
					<p className="tgrey mb8">Primary contact phone number for this list.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Contact Phone Number" name="editListPhone" value={this.state.editListPhone} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$LIST_EMAIL </p>
					<p className="tgrey mb8">Primary contact email for this list.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Contact Email" name="editListEmail" value={this.state.editListEmail} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$LIST_ADDR </p>
					<p className="tgrey mb8">Primary contact address for this list.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Contact Address" name="editListAddr" value={this.state.editListAddr} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$LIST_SITE </p>
					<p className="tgrey mb8">Primary contact website for this list.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="List Contact Website" name="editListSite" value={this.state.editListSite} onChange={e => this.handleChange(e)} />
					</p>
				</Modal>

				{/* <Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal> */}

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={this.state.contacts} loading={this.state.isLoading} pagination={{ pageSize: this.state.pageSize }} /> */}
			</Content>
		);
	}
}
