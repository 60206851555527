import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Card, Row, Col, Button, Divider, Avatar, Input, Modal } from "antd";

import * as firebase from "firebase";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class OrgSettings extends React.Component {
	state = {
		collapsed: false,
		orgInfo: { name: "Loading..." }
		// e164: "",
		// copilotId: ""
	};

	handleChange(e) {
		// console.log("e: ", e);
		this.setState({ [e.target.name]: e.target.value });
	}

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
			isModalVariablesVisible: false,
			isModalSettingsVisible: false
		});
	};

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.props.match.params.orgId;
		this.getOrgInfo(orgId);
	}

	getOrgInfo(orgId) {
		firebase
			.firestore()
			.doc(`orgs/${this.props.match.params.orgId}`)
			.get()
			.then(
				doc => {
					if (doc.exists) {
						console.log("Document data:", doc.data());
						const record = doc.data();
						record.id = doc.id;
						record.created = doc.data().created.getTime();
						this.setState({ orgInfo: record });
						// const contactLists = this.state.contactLists;
						// contactLists[record.id] = record;
						// this.setState({ contactLists: contactLists });
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
					}
				},
				error => {
					console.log("firebase error: ", error);
					// this.setState({ isLoading: false });
				}
			);
	}

	updateListVariables(orgId) {
		this.setState({ isUpdatingList: true });
		const updateObj = {
			varOrgName: this.state.editOrgName ? this.state.editOrgName : null,
			varOrgContact: this.state.editOrgContact ? this.state.editOrgContact : null,
			varOrgPhone: this.state.editOrgPhone ? this.state.editOrgPhone : null,
			varOrgEmail: this.state.editOrgEmail ? this.state.editOrgEmail : null,
			varOrgSite: this.state.editOrgSite ? this.state.editOrgSite : null,
			varOrgAddr: this.state.editOrgAddr ? this.state.editOrgAddr : null
		};

		firebase
			.firestore()
			.doc(`/orgs/${orgId}`)
			.update(updateObj)
			.then(() => {
				console.log("update document success");

				let orgInfo = this.state.orgInfo;
				this.setState({ isModalVariablesVisible: false, isUpdatingList: false, orgInfo: { ...orgInfo, ...updateObj } });
			})
			.catch(error => {
				// Handle get user token error
				console.log("update document error: ", error);
				this.setState({ isUpdatingList: false });
			});
	}

	// searchLogs() {
	// 	const e164 = this.state.e164;
	// 	const copilotId = this.state.copilotId;
	// 	firebase
	// 		.firestore()
	// 		.collection(`smsLogs`)
	// 		.where("dst", "==", e164)
	// 		.where("messagingServiceSid", "==", copilotId)
	// 		// .orderBy("created", "desc")
	// 		// .limit(20)
	// 		.get()
	// 		.then(
	// 			querySnapshot => {
	// 				const recordIds = [];
	// 				querySnapshot.forEach(doc => {
	// 					// console.log(`${doc.id} => ${doc.data()}`);
	// 					const record = doc.data();
	// 					record.id = doc.id;
	// 					recordIds.push(record.id);
	// 					console.log("record: ", record);
	// 				});
	// 				console.log("recordIds: ", recordIds);
	// 			},
	// 			error => {
	// 				console.log("firebase error: ", error);
	// 			}
	// 		);
	// }

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;
		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Settings</Breadcrumb.Item>
					<Breadcrumb.Item>Profile</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: 24, background: "#fff", minHeight: 360 }}>
					My Account
				</div> */}

				<div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Org Settings</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/North Hill Paints/billing/current`}>Profile</Link>
							</Menu.Item>
							{/* <Menu.Item key="history">
								<Link to={`/orgs/North Hill Paints/billing/history`}>Password</Link>
							</Menu.Item> */}
							<Menu.Item key="plan">
								<Link to={`/orgs/North Hill Paints/billing/plan`}>Delete</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div>

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="Profile Photo" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Update or Reset your profile photo?</p>
							<p style={{ textAlign: "center", margin: "12px 0px" }}>
								<Avatar size={150} icon="user" />
							</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								<Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button>
								<Button type="primary" icon="upload">
									Upload Photo
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Organization Variables" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								<span className="bold">$ORG_NAME</span> <br /> {this.state.orgInfo.varOrgName ? this.state.orgInfo.varOrgName : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$ORG_CONTACT</span> <br /> {this.state.orgInfo.varOrgContact ? this.state.orgInfo.varOrgContact : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$ORG_PHONE</span> <br /> {this.state.orgInfo.varOrgPhone ? this.state.orgInfo.varOrgPhone : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$ORG_EMAIL</span> <br /> {this.state.orgInfo.varOrgEmail ? this.state.orgInfo.varOrgEmail : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$ORG_ADDR</span> <br /> {this.state.orgInfo.varOrgAddr ? this.state.orgInfo.varOrgAddr : <span className="tgrey">No variable value set.</span>}
							</p>
							<Divider style={{ margin: "16px 0px" }} />
							<p>
								<span className="bold">$ORG_SITE</span> <br /> {this.state.orgInfo.varOrgSite ? this.state.orgInfo.varOrgSite : <span className="tgrey">No variable value set.</span>}
							</p>
							{/* <p>How many contacts can be broadcast to at once.</p> */}
							{/* <p style={{}}>
								<span className="tgrey">Selected File:</span> {this.state.selectedFile.name ? this.state.selectedFile.name : "No File Selected"}
							</p> */}
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="undo" style={{ marginRight: "12px" }}>
									Reset
								</Button> */}
								<Button
									type=""
									icon="edit"
									onClick={() =>
										this.setState({
											isModalVariablesVisible: true,
											editOrgName: this.state.orgInfo.varOrgName,
											editOrgContact: this.state.orgInfo.varOrgContact,
											editOrgPhone: this.state.orgInfo.varOrgPhone,
											editOrgEmail: this.state.orgInfo.varOrgEmail,
											editOrgAddr: this.state.orgInfo.varOrgAddr,
											editOrgSite: this.state.orgInfo.varOrgSite
										})
									}>
									Edit Variables
								</Button>
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card className="card1" title="Account Info" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>Name: </p>
							<p style={{}}>Phone: </p>
							<p style={{}}>Email: </p>
							<p style={{}}>Website: </p>
							<p style={{}}>Address: </p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button icon="close" style={{ marginRight: "12px" }}>
									Cancel
								</Button> */}
								<Button type="primary" icon="edit" style={{ marginRight: "12px" }}>
									Edit Info
								</Button>
								<Button type="primary" icon="environment">
									Edit Address
								</Button>
							</p>
						</Card>
						<Card className="card1" title="Delete Organization" style={{ margin: "auto", marginBottom: "24px" }}>
							<p style={{}}>
								You can only delete this organization from the Cancel Billing section. Learn more about <u className="pointer">what you will lose access to</u>.
							</p>
							<p style={{}}>Warning: This action cannot be undone.</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								{/* <Button type="danger" icon="delete">
									Delete Account?
								</Button> */}
								<Link to={`/orgs/${orgId}/billing/cancel`}>
									<Button type="danger" icon="arrow-right">
										Go to Cancel Billing
									</Button>
								</Link>
							</p>
							{/* <p>
								dst: <br />
								<Input name="e164" value={this.state.e164} placeholder="dst" onChange={e => this.handleChange(e)} />
								<br />
								<br />
								copilotId (messaging service): <br />
								<Input name="copilotId" value={this.state.copilotId} placeholder="MessagingServiceSid" onChange={e => this.handleChange(e)} />
								<br />
								<br />
								<Button onClick={e => this.searchLogs()}>search</Button>
							</p> */}
						</Card>
					</Col>
				</Row>

				<Modal
					className=""
					title="Edit Organization Variables"
					visible={this.state.isModalVariablesVisible}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="edit" loading={this.state.isUpdatingList} onClick={() => this.updateListVariables(orgId)}>
							Update Variables
						</Button>
					]}>
					<p className="bold mb8">$ORG_NAME </p>
					<p className="tgrey mb8">The name of your organization.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Name" name="editOrgName" value={this.state.editOrgName} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$ORG_CONTACT </p>
					<p className="tgrey mb8">First and/or Last Name of the primary contact person for your organization.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Contact Name" name="editOrgContact" value={this.state.editOrgContact} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$ORG_PHONE </p>
					<p className="tgrey mb8">Your organization's primary phone number.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Contact Phone Number" name="editOrgPhone" value={this.state.editOrgPhone} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$ORG_EMAIL </p>
					<p className="tgrey mb8">Your organization's primary email.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Contact Email" name="editOrgEmail" value={this.state.editOrgEmail} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$ORG_ADDR </p>
					<p className="tgrey mb8">Your organization's primary address.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Contact Address" name="editOrgAddr" value={this.state.editOrgAddr} onChange={e => this.handleChange(e)} />
					</p>
					<Divider style={{ margin: "16px 0px" }} />
					<p className="bold mb8">$ORG_SITE </p>
					<p className="tgrey mb8">Your organization's primary website.</p>
					<p className="fs16">
						<Input className="mw300" placeholder="Org Contact Website" name="editOrgSite" value={this.state.editOrgSite} onChange={e => this.handleChange(e)} />
					</p>
				</Modal>
			</Content>
		);
	}
}
