import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tooltip } from "antd";

import * as firebase from "firebase";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

// class App extends Component {
export class SmsHistory extends React.Component {
	state = {
		collapsed: false,
		isLoading: false,
		smsLogs: [],
		pageSize: 15
	};

	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState({ collapsed });
	};

	getSMSLogs(orgId) {
		firebase
			.firestore()
			.collection(`smsLogs`)
			.where("orgId", "==", orgId)
			// .where("listId", "==", listId)
			.orderBy("sendTime", "desc")
			.limit(this.state.pageSize)
			.get()
			.then(
				querySnapshot => {
					const orgsList = this.state.orgsList;
					const records = [];
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						// record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						records.push(record);
					});
					console.log("smsLogs: ", records);
					this.setState({
						smsLogs: records,
						isLoading: false
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const orgId = this.props.match.params.orgId;
		this.getSMSLogs(orgId);
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;

		const columns = [
			{
				title: "",
				dataIndex: "direction",
				key: "direction",
				render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Outbound" : "Inbound"}>{record.direction === "outbound" ? <Icon type="arrow-right" className="tgrey fs16" /> : <Icon type="arrow-left" className="tblue fs16" />}</Tooltip>,
				// size: "100px",
				width: 60,
				align: "center"
			},
			{
				title: "From",
				dataIndex: "src",
				key: "src",
				width: 150,
				render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Source Number" : "Contact Number"}>{record.src}</Tooltip>
			},
			{
				title: "To",
				dataIndex: "dst",
				key: "dst",
				width: 150,
				render: (text, record) => <Tooltip title={record.direction === "outbound" ? "Contact Number" : "Source Number"}>{record.dst}</Tooltip>
			},
			{
				title: "Message",
				dataIndex: "text",
				key: "text",
				render: (text, record) => (
					<Tooltip title={record.text}>
						<div className="ellip pointer">{record.text}</div>
					</Tooltip>
				),
				width: 250
				// clasName: "ellip"
				// fixed: true
			},

			{
				title: "Status",
				dataIndex: "pipelineStatus",
				key: "pipelineStatus",
				width: 150,
				align: "center"
				// render: (text, record) => <span>{this.getDuration(moment.duration(record.duration)._data)}</span>
				// render: (text, record) => <span>{moment.duration(123123)}</span>
			},
			{
				title: "Updated",
				dataIndex: "sendTime",
				key: "sendTime",
				width: 200,
				align: "center",
				render: (text, record) => (
					<Tooltip title={moment(record.sendTime).format("LLLL")}>
						<span>{moment(record.sendTime).fromNow()}</span>
					</Tooltip>
				)
			}
		];

		const data = [
			{
				key: "1",
				name: "Retail Customers",
				email: "2086",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				created: 1538778093
			},
			{
				key: "2",
				name: "Contractors",
				email: "327",
				age: 42,
				address: "London No. 1 Lake Park",
				tags: ["loser"],
				created: 1538778125
			},
			{
				key: "3",
				name: "Retail Customers",
				email: "2200",
				age: 32,
				address: "Sidney No. 1 Lake Park",
				tags: ["cool", "teacher"],
				created: 1538778130
			}
		];

		return (
			<Content>
				<Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
					<Breadcrumb.Item>SMS</Breadcrumb.Item>
				</Breadcrumb>
				<div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Logs</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/${orgId}/history/sms`}>SMS</Link>
							</Menu.Item>
							{/* <Menu.Item key="plan">
								<Link to={`/orgs/${orgId}/broadcasts/new`}>New Broadcast</Link>
							</Menu.Item> */}
						</Menu>
					</div>
				</div>
				{/* <div className="card1" style={{ padding: 24, background: "#fff", minHeight: 360 }}>
					Sms History
				</div> */}
				<Table
					className="card1 bgWhite tableFixed"
					columns={columns}
					dataSource={this.state.smsLogs}
					// loading={this.state.isLoading}
					loading={{ spinning: this.state.isLoading, indicator: <Icon type="loading" className="fs28 dataLoadingIcon" /> }}
				/>
			</Content>
		);
	}
}
