import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Modal, Tabs, Row, Col, Tooltip, Card } from "antd";

import { BillingMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class BillingCancel extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false
	};

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	render() {
		// console.log("this.props: ", this.props);

		const columns = [
			{
				title: "Id",
				dataIndex: "id",
				key: "id",
				render: (text, record) => <Link to={`/orgs/${record.name}/contactLists/${record.id}/contacts`}>{text}</Link>
			},
			{
				title: "Amount",
				dataIndex: "amount",
				key: "amount"
			},
			{
				title: "Due Date",
				dataIndex: "dueDate",
				key: "dueDate"
			},
			{
				title: "Paid",
				dataIndex: "paidStatus",
				key: "paidStatus"
			},
			{
				title: "Download",
				dataIndex: "downloadUrl",
				key: "downloadUrl",
				render: (text, record) => (
					<Link to={`/orgs/${record.name}/contactLists/${record.id}/contacts`}>
						<Icon type="download" />
					</Link>
				)
			}
		];

		const data = [
			{
				key: "1",
				id: "..24kjhjkh",
				amount: "$127.06",
				paidStatus: "due",
				status: "subscribed",
				name: "Jane Smith",
				email: "2086",
				mobileNumber: "+16475552809",
				lastMessage: "hey, what time are you open to?",
				age: 32,
				address: "New York No. 1 Lake Park",
				tags: ["nice", "developer"],
				updated: 1538778093,
				dueDate: 1538778093
			},
			{
				key: "2",
				id: "..l546hjkhj",
				amount: "$189.34",
				paidStatus: "paid",
				name: "John Doe",
				email: "327",
				status: "optedOut",
				age: 42,
				mobileNumber: "+14165557835",
				address: "London No. 1 Lake Park",
				lastMessage: "Thanks for the info!",
				tags: ["loser"],
				updated: 1538778125,
				dueDate: 1538778125
			}
		];

		const orgId = this.props.match.params.orgId;

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Billing</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<b style={{ fontSize: "16px" }}>Billing</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<BillingMenu selectedKey="cancel" orgId={orgId} />
					</div>
				</div>

				{/* <div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>2087 Contacts</b>
					</div>
					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px" }}>
						<span style={{}}>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} style={{ marginRight: "8px" }}>
								Add Contact
							</Button>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal}>
								Import
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px", float: "right" }} enterButton />
					</div>
                </div> */}

				<Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="Cancel Subscription" style={{ margin: "auto", marginBottom: "24px" }}>
							<p>
								Once you delete your account, all <u className="pointer">data associated with your account</u> will be deleted forever.
							</p>
							<p className="">This action cannot be undone.</p>
							<p style={{ textAlign: "right", marginBottom: "-8px", marginTop: "24px" }}>
								<Button type="danger" icon="warning">
									Delete Account
								</Button>
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24} />
				</Row>

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={data} /> */}
			</Content>
		);
	}
}
