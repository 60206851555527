import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Table, Divider, Tag, Input, Button, Row, Col, Modal, Tooltip, Card } from "antd";
import * as firebase from "firebase";
import moment from "moment";
import request from "request";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const { TextArea } = Input;

// class App extends Component {
export class BroadcastSettings extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		isEditMessageVisible: false,
		isStopBroadcastVisible: false,
		isStartBroadcastVisible: false,
		isEditMediaVisible: false,
		loading: false,
		isUpdating: false,
		isLoading: true,
		broadcast: {},
		scheduledLogs: {},
		scheduledNotify: {},
		contactLists: [],
		messageCharCount: 23,
		availableMsgChars: 160,
		msgSmsParts: 1,
		is7Bit: true,
		msgFilesList: [],
		previewVisible: false,
		previewImage: "",
		previewFile: {},
		editBroadcastMessage: ""
	};

	handleChange(e) {
		// console.log("e: ", e);
		// console.log(e.target.name, e.target.value);

		if (e.target.name === "editBroadcastMessage") {
			const msgDetails = this.countMessageChars(e.target.value);
			this.setState({
				is7Bit: msgDetails.is7Bit,
				messageCharCount: msgDetails.messageCharCount,
				availableMsgChars: msgDetails.availableMsgChars,
				msgSmsParts: msgDetails.msgSmsParts
			});
		}
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false, isEditMessageVisible: false, isStopBroadcastVisible: false, isStartBroadcastVisible: false, isEditMediaVisible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
			isEditMessageVisible: false,
			isStopBroadcastVisible: false,
			isStartBroadcastVisible: false,
			isEditMediaVisible: false
		});
	};

	// handleImgChange = ({ fileList }) => this.setState({ fileList });

	handleImgCancel = () => this.setState({ previewVisible: false });

	getDuration(durationArray) {
		let durationTime = 0;
		let durationText = "millisecond";
		// let plural = "";
		console.log("durationArray:", durationArray);
		Object.keys(durationArray).forEach(key => {
			// console.log(key);          // the name of the current key.
			// console.log(durationArray[key]);   // the value of the current key.
			if (durationArray[key] > 0) {
				durationTime = durationArray[key];
				// durationText = key;
				durationText = key.charAt(0).toUpperCase() + key.slice(1);
			}
		});

		if (durationTime === 1) durationText = durationText.substring(0, durationText.length - 1);
		return durationTime + " " + durationText;
	}

	async getBroadcastData() {
		console.log("getting broadcasts!");
		// get broadcasts
		const broadcast = await this.getBroadcast(this.props.match.params.broadcastId);

		// get msg length details
		const msgDetails = this.countMessageChars(broadcast.message);

		// get contact list objs from db
		const contactLists = [];
		if (broadcast.listIds) {
			for (let i = 0; i < broadcast.listIds.length; i++) {
				const listObj = await this.getContactList(broadcast.listIds[i]);
				contactLists.push(listObj);
			}
		}

		console.log("broadcast: ", broadcast);
		console.log("contactLists: ", contactLists);

		this.setState({
			broadcast: broadcast,
			isLoading: false,
			contactLists: contactLists,
			is7Bit: msgDetails.is7Bit,
			messageCharCount: msgDetails.messageCharCount,
			availableMsgChars: msgDetails.availableMsgChars,
			msgSmsParts: msgDetails.msgSmsParts
		});
	}

	getBroadcast(broadcastId) {
		return new Promise((resolve, reject) => {
			firebase
				.firestore()
				.doc(`orgs/${this.props.match.params.orgId}/broadcasts/${broadcastId}`)
				.get()
				.then(
					doc => {
						if (doc.exists) {
							console.log("Document data:", doc.data());
							const record = doc.data();
							record.id = doc.id;
							record.created = doc.data().created.getTime();
							// contactLists[record.id] = record;
							// this.setState({ contactLists: contactLists });
							resolve(record);
						} else {
							// doc.data() will be undefined in this case
							console.log("No such document!");
							resolve(false);
						}
					},
					error => {
						console.log("firebase error: ", error);
						// this.setState({ isLoading: false });
						reject();
					}
				);
		});
	}

	getBroadcastEvents(broadcastId) {
		firebase
			.firestore()
			.collection(`scheduledEvents`)
			.where("broadcastId", "==", broadcastId)
			.limit(2)
			.get()
			.then(
				querySnapshot => {
					const event = [];
					let logs = {};
					let notify = {};
					querySnapshot.forEach(doc => {
						// console.log(`${doc.id} => ${doc.data()}`);
						const record = doc.data();
						record.id = doc.id;
						record.key = doc.id;
						// record.created = doc.data().created.getTime();
						// record.updated = doc.data().updated.getTime();
						// if (!record.email) record.email = "sample@email.com";
						console.log("record: ", record);
						if (record.action === "generateBroadcastSmsLogs") logs = record;
						if (record.action === "broadcastNotification") notify = record;
						event.push(record);
					});
					console.log("broadcast scheulded event: ", event);
					// console.log("event scheduled: ", moment(event[0].sendTime).format("llll"));
					// console.log("event scheduled: ", moment(event[1].sendTime).format("llll"));
					this.setState({
						scheduledLogs: logs,
						scheduledNotify: notify
					});
				},
				error => {
					console.log("firebase error: ", error);
					this.setState({ isLoading: false });
				}
			);
	}

	getContactList(listId) {
		return new Promise((resolve, reject) => {
			// const contactLists = this.state.contactLists;
			// if (contactLists[listId]) return resolve();
			firebase
				.firestore()
				.doc(`orgs/${this.props.match.params.orgId}/contactLists/${listId}`)
				.get()
				.then(
					doc => {
						if (doc.exists) {
							console.log("Document data:", doc.data());
							const record = doc.data();
							record.id = doc.id;
							record.created = doc.data().created.getTime();
							// contactLists[record.id] = record;
							// this.setState({ contactLists: contactLists });
							resolve(record);
						} else {
							// doc.data() will be undefined in this case
							console.log("No such document!");
							resolve(false);
						}
					},
					error => {
						console.log("firebase error: ", error);
						// this.setState({ isLoading: false });
						reject();
					}
				);
		});
	}

	getTotalRecipients(contactLists) {
		let totalRecipients = 0;
		for (let i = 0; i < contactLists.length; i++) {
			totalRecipients += contactLists[i].totalContacts;
		}
		return totalRecipients;
	}

	is7BitString(text) {
		// https://stackoverflow.com/questions/12673120/how-to-detect-non-gsm-7-bit-alphabet-characters-in-input-field
		// regex containing all chars of 7bit alphabet
		const regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
		//test if chat input text contains non 7bit characters
		let is7Bit = regexp.test(text);
		console.log("is7bit: ", is7Bit);
		// console.log("containsNonLatinCodepoints: ", this.containsNonLatinCodepoints(text));
		return is7Bit;
		// return regexp.test(text);
	}

	countMessageChars(broadcastMessage) {
		// messageCharCount: 23,
		// availableMsgChars: 160,
		// msgSmsParts: 1,
		const msgLength = broadcastMessage.length + 18; //18 characters === " Text STOP to end."
		const sms1PartLength = 160;
		const smsMultiPartLength = 153;
		const smsUnicode1PartLength = 70;
		const smsUnicodeMultiPartLength = 67;
		let availableChars = 160;
		let msgParts = 1;
		let is7Bit = this.is7BitString(broadcastMessage);
		// if 7bit
		if (is7Bit) {
			if (msgLength > sms1PartLength) {
				msgParts = Math.ceil(msgLength / smsMultiPartLength);
				availableChars = msgParts * smsMultiPartLength;
			}
			// else unicode msg
		} else {
			if (msgLength > smsUnicode1PartLength) {
				msgParts = Math.ceil(msgLength / smsUnicodeMultiPartLength);
				availableChars = msgParts * smsUnicodeMultiPartLength;
			} else {
				availableChars = smsUnicode1PartLength;
			}
		}
		// console.log("messageCharCount: ", msgLength);
		// console.log("availableMsgChars: ", availableChars);
		// console.log("msgSmsParts: ", msgParts);
		return {
			is7Bit: is7Bit,
			messageCharCount: msgLength,
			availableMsgChars: availableChars,
			msgSmsParts: msgParts
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		const broadcastId = this.props.match.params.broadcastId;
		this.getBroadcastData();
		this.getBroadcastEvents(broadcastId);
	}

	handleImgPreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
			previewFile: file
		});
	};

	updateMessageText() {
		const msgTxt = this.state.editBroadcastMessage;
		if (!msgTxt) {
			Modal.error({
				title: "Message Text is Empty!",
				content: "Cannot update broadcast message with empty message text."
			});
			return;
		}
		const msgDetails = this.countMessageChars(msgTxt);
		if (msgDetails.msgSmsParts > 10) {
			Modal.error({
				title: "Message Text is too long!",
				content: "Updated message text must be less than 10 SMS Parts. 3 to 4 Sms Parts is recommended."
			});
			return;
		}
		const orgId = this.props.match.params.orgId;
		const broadcastId = this.props.match.params.broadcastId;
		this.setState({ isUpdating: true });
		// firebase
		// 	.firestore()
		// 	.doc(`/orgs/${orgId}/broadcasts/${broadcastId}`)
		// 	.update({ message: msgTxt, updated: firebase.firestore.Timestamp.fromDate(new Date()) })
		// 	.then(() => {
		// 		console.log("update document success");

		// 		const broadcastObj = this.state.broadcast;
		// 		const updatedBroadcastObj = { message: msgTxt, updated: new Date().getTime() };
		// 		this.setState({ isEditMessageVisible: false, isUpdating: false, broadcast: { ...broadcastObj, ...updatedBroadcastObj } });
		// 	})
		// 	.catch(error => {
		// 		// Handle get user token error
		// 		console.log("update document error: ", error);
		// 		this.setState({ isUpdating: false });
		// 	});
		const requestForm = {
			orgId: orgId,
			broadcastId: broadcastId,
			updateAction: "updateText",
			newBroadcastText: msgTxt
		};
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/sms.updateBroadcast",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdating: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							const errorObj = JSON.parse(body);
							if (errorObj.error && errorObj.error.message) {
								Modal.error({
									title: errorObj.error.code,
									content: errorObj.error.message
								});
							}
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdating: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						const broadcast = this.state.broadcast;
						broadcast.message = msgTxt;
						this.setState({
							isUpdating: false,
							isEditMessageVisible: false,
							broadcast: broadcast
						});
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdating: false });
			});
	}

	setBroadcastCancelled(isCancelled) {
		if (isCancelled === null) {
			Modal.error({
				title: "Missing Broadcast Cancelled Status!",
				content: "Must set isCancelled status to either true or false."
			});
			return;
		}
		const orgId = this.props.match.params.orgId;
		const broadcastId = this.props.match.params.broadcastId;
		this.setState({ isUpdating: true });
		// firebase
		// 	.firestore()
		// 	.doc(`/orgs/${orgId}/broadcasts/${broadcastId}`)
		// 	.update({ isCancelled: isCancelled, updated: firebase.firestore.Timestamp.fromDate(new Date()) })
		// 	.then(() => {
		// 		console.log("update document success");

		// 		const broadcastObj = this.state.broadcast;
		// 		const updatedBroadcastObj = { isCancelled: isCancelled, updated: new Date().getTime() };
		// 		this.setState({ isStopBroadcastVisible: false, isStartBroadcastVisible: false, isUpdating: false, broadcast: { ...broadcastObj, ...updatedBroadcastObj } });
		// 	})
		// 	.catch(error => {
		// 		// Handle get user token error
		// 		console.log("update document error: ", error);
		// 		this.setState({ isUpdating: false });
		// 	});
		const requestForm = {
			orgId: orgId,
			broadcastId: broadcastId
		};
		if (isCancelled) {
			requestForm.updateAction = "stopBroadcast"; // stopBroadcast, resumeBroadcast
		} else {
			requestForm.updateAction = "resumeBroadcast"; // stopBroadcast, resumeBroadcast
		}
		console.log("requestForm: ", requestForm);
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/sms.updateBroadcast",
						headers: { authorization: "Bearer " + idToken },
						form: requestForm
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							return this.setState({ isUpdating: false });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							// console.log("http request invalid: ", JSON.parse(body));
							console.log("http request invalid: ", body);
							const errorObj = JSON.parse(body);
							if (errorObj.error && errorObj.error.message) {
								Modal.error({
									title: errorObj.error.code,
									content: errorObj.error.message
								});
							}
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							return this.setState({ isUpdating: false });
						}
						// if request success
						console.log("http request success: ", JSON.parse(body));
						const broadcast = this.state.broadcast;
						broadcast.isCancelled = isCancelled;
						this.setState({
							isUpdating: false,
							isStopBroadcastVisible: false,
							isStartBroadcastVisible: false,
							broadcast: broadcast
						});
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isUpdating: false });
			});
	}

	render() {
		// console.log("this.props: ", this.props);
		const orgId = this.props.match.params.orgId;
		const broadcastId = this.props.match.params.broadcastId;
		const { previewVisible, previewImage } = this.state;

		const columns = [
			{
				title: "Start Date",
				dataIndex: "created",
				key: "created",
				render: (text, record) => (
					<Tooltip title={moment(record.created).format("LLL")}>
						<span>{moment(record.created).fromNow()}</span>
					</Tooltip>
				)
			},
			{
				title: "Name",
				dataIndex: "message",
				key: "message",
				render: (text, record) => (
					<Link to={`/orgs/${this.props.match.params.orgId}/broadcasts/history/${record.id}`}>
						<div style={{ maxWidth: "340px" }}>{text}</div>
					</Link>
				)
			},
			{
				title: "Contact List",
				dataIndex: "listId",
				key: "listId",
				render: (text, record) => <span>{this.state.contactLists[record.listId] ? this.state.contactLists[record.listId].name : "Loading..."}</span>
			},
			{
				title: "Recipeints",
				dataIndex: "totalRecipients",
				key: "totalRecipients"
			},

			{
				title: "Duration",
				dataIndex: "duration",
				key: "duration",
				render: (text, record) => <span>{this.getDuration(moment.duration(record.duration)._data)}</span>
				// render: (text, record) => <span>{moment.duration(123123)}</span>
			}
		];

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Broadcasts</Breadcrumb.Item>
					<Breadcrumb.Item>New</Breadcrumb.Item>
				</Breadcrumb> */}
				{/* <div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>Broadcasts</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["current"]} mode="horizontal">
							<Menu.Item key="current">
								<Link to={`/orgs/${orgId}/broadcasts/history`}>History</Link>
							</Menu.Item>
							<Menu.Item key="plan">
								<Link to={`/orgs/${orgId}/broadcasts/new`}>New Broadcast</Link>
							</Menu.Item>
						</Menu>
					</div>
                </div> */}
				<div className="card1" style={{ padding: " 0px ", background: "#fff", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<Link to={`/orgs/${orgId}/broadcasts/history`}>
							<Tooltip title={"Back to All Broadcasts"}>
								<Button type="" icon="arrow-left" style={{ marginRight: "8px" }} />
							</Tooltip>
						</Link>
						<b style={{ fontSize: "16px" }}>Broadcast Details</b>
					</div>

					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%" }}>
						<Menu onClick={this.handleClick} selectedKeys={["settings"]} mode="horizontal">
							<Menu.Item key="settings">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/settings`}>Settings</Link>
							</Menu.Item>
							<Menu.Item key="logs">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/logs`}>Logs</Link>
							</Menu.Item>
							<Menu.Item key="stats">
								<Link to={`/orgs/${orgId}/broadcasts/details/${broadcastId}/stats`}>Stats?</Link>
							</Menu.Item>
						</Menu>
					</div>
				</div>

				{this.state.isLoading && (
					<p className="taCenter">
						<Icon type="loading" className="fs28 dataLoadingIcon" />
					</p>
				)}

				{!this.state.isLoading && (
					<Row gutter={24}>
						<Col sm={24} md={12}>
							{/* <Card title="Actions" className="card1 mb24">
							<p>Edit or Pause Broadcast while it is still running</p>
							<p className="taRight">
								<Button className="" type="primary" icon="edit" style={{ marginRight: "12px" }}>
									Edit Message
								</Button>
								<Button className="dangerBtn" type="" icon="stop">
									Pause Broadcast
								</Button>
							</p>
							<p className="taRight">
								<Button className="successBtn" type="" icon="check">
									Broadcast Complete
								</Button>
							</p>
						</Card> */}
							<Card title="Broadcast Media" className="card1 mb24 cbpb12">
								<span className="bold">Media</span> <br />
								<div className="mb12">{this.state.broadcast.mmsList ? this.state.broadcast.mmsList.length : 0} MMS Parts</div>
								{this.state.broadcast.mmsList && this.state.broadcast.mmsList.length > 0 && (
									<div className="pb8">
										{this.state.broadcast.mmsList.map((file, index) => {
											return (
												<div className="" key={"msgFile" + index}>
													<div className="pictureCardCustom mb12">
														{/* src={this.getFileUrl(file, "msgFileImgId" + index, index)} */}
														<img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{}} src={file.url} onClick={e => this.handleImgPreview(file)} />
														<div className="pb8 bold">
															#{index + 1}: {file.name}
														</div>
														<div className="">
															{(file.size / 1024 / 1024).toFixed(2)} MB &nbsp;&middot;&nbsp;
															<Button onClick={e => this.removeFile(index)}>
																<Icon type="close" /> Remove
															</Button>
														</div>
													</div>
												</div>

												// <Card hoverable style={{ width: 240 }} cover={<img id={"msgFileId" + index} alt={file.name} src={this.getFileUrl(file, "msgFileId" + index)} />} actions={[<Icon type="eye" />, <Icon type="delete" />]}>
												// 	<Meta title={file.name} description={(file.size / 1024 / 1024).toFixed(2) + " MB"} />
												// </Card>
											);
										})}
									</div>
								)}
								{!this.state.broadcast.isCompleted && (
									<p className="mb0 taRight">
										<Button className=" mb12" type="primary" icon="edit" style={{}} onClick={() => this.setState({ isEditMediaVisible: true })}>
											Edit Media
										</Button>
									</p>
								)}
								<Modal
									visible={previewVisible}
									title={this.state.previewFile.name}
									onCancel={this.handleImgCancel}
									footer={[
										<Button key="back" onClick={this.handleImgCancel}>
											Return
										</Button>
									]}>
									<img alt="example" style={{ width: "100%" }} src={previewImage} />
								</Modal>
							</Card>
							<Card title="Broadcast Message" className="card1 mb24 cbpb12">
								<span className="bold">Message</span> <br />
								<div className="broadcastMessageDisplay">
									{this.state.broadcast.message} {this.state.broadcast.optOutText}
								</div>
								{/* <div className="">214 characters &middot; About 4 sms messages</div> */}
								<div className={` ${this.countMessageChars(this.state.broadcast.message).is7Bit ? "" : "tblue"}`}>
									{this.countMessageChars(this.state.broadcast.message).messageCharCount}/{this.countMessageChars(this.state.broadcast.message).availableMsgChars} Characters &middot; Approx {this.countMessageChars(this.state.broadcast.message).msgSmsParts} SMS Part{this.countMessageChars(this.state.broadcast.message).msgSmsParts > 1 ? "s" : ""}
								</div>
								<br />
								{(!this.state.broadcast.broadcastEndTime || new Date().getTime() <= this.state.broadcast.broadcastEndTime) && (
									<p className="mb0 taRight">
										<Button className="mr12 mb12" type="primary" icon="edit" style={{}} onClick={() => this.setState({ isEditMessageVisible: true, editBroadcastMessage: this.state.broadcast.message })}>
											Edit Message
										</Button>
										{this.state.broadcast.isCancelled && (
											<Button className="successBtn  mb12" type="" icon="arrow-right" onClick={() => this.setState({ isStartBroadcastVisible: true })}>
												Resume Broadcast
											</Button>
										)}
										{!this.state.broadcast.isCancelled && (
											<Button className="dangerBtn  mb12" type="" icon="stop" onClick={() => this.setState({ isStopBroadcastVisible: true })}>
												Stop Broadcast
											</Button>
										)}
									</p>
								)}
								{new Date().getTime() > this.state.broadcast.broadcastEndTime && (
									<p className="mb0 taRight">
										<Button className="successBtn  mb12" type="" icon="check">
											Broadcast Complete
										</Button>
									</p>
								)}
							</Card>
						</Col>
						<Col sm={24} md={12}>
							<Card title="Details" className="card1 mb24 ">
								<p>
									<span className="bold">Status</span> <br />
									{new Date().getTime() > this.state.broadcast.broadcastEndTime ? <span className="tgreen">Broadcast Complete</span> : this.state.broadcast.isCancelled ? <span className="tred">Broadcast Cancelled</span> : !this.state.broadcast.broadcastEndTime ? <span>Broadcast Scheduled</span> : <span className="tblue">Broadcast In Progress</span>}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Start Time</span> <br /> {this.state.broadcast.broadcastStartTime ? moment(this.state.broadcast.broadcastStartTime).format("llll") : <span className="tgrey">Logs not yet generated.</span>}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">End Time</span> <br /> {this.state.broadcast.broadcastEndTime ? moment(this.state.broadcast.broadcastEndTime).format("llll") : <span className="tgrey">Logs not yet generated.</span>}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Created</span> <br /> {moment(this.state.broadcast.created).format("llll")}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<div>
									<span className="bold">Notification</span> <br />
									Emailed: sample@example.com
									<br />
									As of: {moment(this.state.scheduledNotify.sendTime).format("llll")}
								</div>
								<Divider style={{ margin: "16px 0px" }} />
								<div>
									<span className="bold">SMS Logs</span> <br />
									Generated as of: {moment(this.state.scheduledLogs.sendTime).format("llll")}
								</div>
								<Divider style={{ margin: "16px 0px" }} />
								<div>
									<span className="bold">Send Rate</span> <br />
									{this.state.broadcast.dsr} SMS per hour per source number
								</div>
							</Card>
							<Card title="Recipients" className="card1 mb24 cbpb12">
								<p>
									<span className="bold">Total Recipients</span> <br /> {this.getTotalRecipients(this.state.contactLists)} Contacts
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<div>
									<span className="bold">Contact Lists</span> <br />
									{this.state.contactLists.map(list => (
										<p key={list.id}>
											{list.name} &nbsp;&middot;&nbsp; {list.totalContacts} Contact{list.totalContacts !== 1 ? "s" : ""}
										</p>
									))}
								</div>
								{!this.state.broadcast.isCompleted && (
									<p className="mb0 taRight">
										<Button className=" mb12" type="primary" icon="edit" style={{}}>
											Edit Recipients
										</Button>
									</p>
								)}
							</Card>
							<Card title="Send Dates & Times" className="card1 mb24 cbpb12">
								<p>
									<span className="bold">Daily Time Span</span> <br /> {this.state.broadcast.dsh}:{this.state.broadcast.dsm} {this.state.broadcast.dsap} — {this.state.broadcast.deh}:{this.state.broadcast.dem} {this.state.broadcast.deap}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<div>
									<span className="bold">Send Dates</span> <br />
									{/* Mon, Jun 18, 2018
									<br />
									Tue, Jun 19, 2018 */}
									{this.state.broadcast.sendDates && this.state.broadcast.sendDates.map(date => <p key={date}>{moment(date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")}</p>)}
									{/* {this.state.broadcast.sendDates.map(date => (
										<p key={date}>{moment(date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")}</p>
									))} */}
								</div>
								{/* <Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Start Time</span> <br /> Monday, June 18, 2018 8:20 PM
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Finish Time</span> <br /> Tuesday, June 19, 2018 6:18 PM
								</p> */}
								{!this.state.broadcast.isCompleted && (
									<p className="mb0 taRight">
										<Button className=" mb12" type="primary" icon="edit" style={{ marginRight: "12px" }}>
											Edit Send Dates & Time
										</Button>
									</p>
								)}
							</Card>
						</Col>
					</Row>
				)}
				{/* EDIT BROADCAST MEDIA MODAL */}
				<Modal
					title="Edit Broadcast Media"
					visible={this.state.isEditMediaVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" className="" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Update Message Media
						</Button>
					]}>
					<p className="bold">Message Text: </p>
					{this.state.broadcast.mmsList && this.state.broadcast.mmsList.length > 0 && (
						<div className="pb8">
							{this.state.broadcast.mmsList.map((file, index) => {
								return (
									<div className="" key={"msgFile" + index}>
										<div className="pictureCardCustom mb12">
											{/* src={this.getFileUrl(file, "msgFileImgId" + index, index)} */}
											<img id={"msgFileImgId" + index} className="pointer pb8" alt={file.name} style={{}} src={file.url} onClick={e => this.handleImgPreview(file)} />
											<div className="pb8 bold">
												#{index + 1}: {file.name}
											</div>
											<div className="">
												{(file.size / 1024 / 1024).toFixed(2)} MB &nbsp;&middot;&nbsp;
												<Button onClick={e => this.removeFile(index)}>
													<Icon type="close" /> Remove
												</Button>
											</div>
										</div>
									</div>

									// <Card hoverable style={{ width: 240 }} cover={<img id={"msgFileId" + index} alt={file.name} src={this.getFileUrl(file, "msgFileId" + index)} />} actions={[<Icon type="eye" />, <Icon type="delete" />]}>
									// 	<Meta title={file.name} description={(file.size / 1024 / 1024).toFixed(2) + " MB"} />
									// </Card>
								);
							})}
						</div>
					)}
				</Modal>
				{/* EDIT BROADCAST MESSAGE MODAL */}
				<Modal
					title="Edit Broadcast Message"
					visible={this.state.isEditMessageVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" className="" icon="check" loading={this.state.isUpdating} onClick={e => this.updateMessageText()}>
							Update Message Text
						</Button>
					]}>
					<p className="bold mb0">Message Text: </p>
					<p className="">Update the broadcast message text below: </p>
					{/* <div className="broadcastMessageDisplay">
						{this.state.broadcast.message} {this.state.broadcast.optOutText}
					</div> */}
					<div className="pb8">
						<TextArea placeholder="Your SMS Message goes here." name="editBroadcastMessage" value={this.state.editBroadcastMessage} onChange={e => this.handleChange(e)} autosize={{ minRows: 6, maxRows: 18 }} style={{ maxWidth: "325px", color: "#000" }} />
					</div>
					{/* <div className="pb8">
						<Button className="mr8 mb8" onClick={e => this.setState({ isModalVariableVisible: true })}>
							<Icon type="build" className="fs16" /> Variable
						</Button>
						<Button className="mr8 mb8" onClick={e => this.setState({ isModalEmojiVisible: true })}>
							<Icon type="smile" className="fs16" /> Emoji
						</Button>
						<Button className=" mb8" onClick={e => this.setState({ isModaPreviewVisible: true })}>
							<Icon type="eye" className="fs16" /> Preview
						</Button>
					</div> */}
					<div className={`pb8 ${this.state.is7Bit ? "" : "tblue"}`}>
						{this.state.messageCharCount}/{this.state.availableMsgChars} Characters &middot; Approx {this.state.msgSmsParts} SMS Part{this.state.msgSmsParts > 1 ? "s" : ""}
					</div>
					<div className="tgrey2">NOTE: " Text STOP to end." (18 characters) is automatically added to the end of your message.</div>
				</Modal>
				{/* STOP BROADCAST MODAL */}
				<Modal
					title="Stop Broadcast Message"
					visible={this.state.isStopBroadcastVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="danger" className="" icon="stop" loading={this.state.isUpdating} onClick={e => this.setBroadcastCancelled(true)}>
							Stop Broadcast
						</Button>
					]}>
					<p className="bold fs16">Are you sure you want to stop sending out messages for this broadcast? </p>
					<p className="tgrey">Any yet to be delivered scheduled messages for this broadcast will not receive the broadcast message.</p>
					<p className="tgrey">Messages that have already been sent out or delivered will not be affected.</p>
				</Modal>
				<Modal
					title="Resume Broadcast Message"
					visible={this.state.isStartBroadcastVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="" className="successBtn" icon="arrow-right" loading={this.state.isUpdating} onClick={e => this.setBroadcastCancelled(false)}>
							Resume Broadcast
						</Button>
					]}>
					<p className="bold fs16">Are you sure you want to resume sending out messages for this broadcast again? </p>
					<p className="tgrey">Any yet to be delivered scheduled messages for this broadcast will resume receiving the broadcast message.</p>
					<p className="tgrey">Messages that are past their scheduled send time will not be affected.</p>
				</Modal>
				{/* <div className="card1" style={{ padding: "16px 12px", background: "#fff", textAlign: "right", marginBottom: "24px" }}>
					<span style={{ float: "left" }}>
						<Button type="primary" icon="plus" size={"default"} onClick={this.showModal}>
							New Broadcast
						</Button>
					</span>
					<Search placeholder="Search Broadcasts" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px" }} enterButton />
				</div> */}
				{/* loading={this.state.isLoading}  */}
				{/* <Table className="card1 bgWhite" columns={columns} dataSource={this.state.broadcastsList} loading={this.state.isLoading} /> */}
			</Content>
		);
	}
}
