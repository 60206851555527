import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Divider, Collapse, Input, Button, Modal, Tabs, Row, Col, Tooltip, Card } from "antd";
import * as firebase from "firebase";
// import moment from "moment";
import moment from "moment-timezone";
import request from "request";

import { BillingMenu } from "./menu.js";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const TabPane = Tabs.TabPane;
const MenuItemGroup = Menu.ItemGroup;
const Panel = Collapse.Panel;

// class App extends Component {
export class BillingUsage extends React.Component {
	state = {
		collapsed: false,
		visible: false,
		loading: false,
		isLoading: false,
		isUsageLoading: false,
		isLogsLoading: false,
		isUsageLoaded: false,
		isLogsLoaded: false,
		activePanel: ["1"],
		startDate: moment().format("YYYY-MM-DD"),
		endDate: moment().format("YYYY-MM-DD"),
		usageSms: 0,
		usageSourceNumbers: 0,
		usageRecords: {
			sms: { count: 0 },
			"sms-inbound": { count: 0 },
			"sms-outbound": { count: 0 },
			mms: { count: 0 },
			phonenumbers: { count: 0 },
			calls: { count: 0 },
			totalprice: { price: 0 }
		},
		logRecords: {
			contactListLogs: {
				downloadUrl: ""
			},
			contactLogs: {
				downloadUrl: ""
			},
			smsLogs: {
				downloadUrl: ""
			},
			sourceNumberLogs: {
				downloadUrl: ""
			}
		}
	};

	handleChange(e) {
		// console.log("e: ", e);
		// console.log(e.target.name, e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = e => {
		console.log(e);
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false, visible: false });
		}, 2000);
	};

	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	callback(key) {
		console.log(key);
	}

	changeDate(dateType, direction) {
		const currDate = moment(this.state[dateType], "YYYY-MM-DD");
		let newDate;
		switch (direction) {
			case "now":
				newDate = moment();
				break;
			case "forward":
				newDate = currDate.add(1, "day");
				break;
			case "back":
				newDate = currDate.subtract(1, "day");
				break;
			case "back1Week":
				newDate = currDate.subtract(1, "week");
				break;
			case "back1Month":
				newDate = currDate.subtract(1, "month");
				break;
			default:
				break;
		}
		// this.setDateRows(this.state.totalSendDays, index, newDate.format("YYYY-MM-DD"));
		this.setState({
			[dateType]: newDate.format("YYYY-MM-DD")
		});
	}

	handleCollapse(key) {
		console.log("handleCollapse: ", key);
		this.setState({ activePanel: key });
	}

	getUsageLogs() {
		const orgId = this.props.match.params.orgId;
		if (!orgId) {
			Modal.error({
				title: "Missing Org Id!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		if (!this.state.startDate) {
			Modal.error({
				title: "Missing Start Date!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		if (!this.state.endDate) {
			Modal.error({
				title: "Missing End Date!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		const start = moment(this.state.startDate, "YYYY-MM-DD").valueOf();
		const end = moment(this.state.endDate, "YYYY-MM-DD").valueOf();
		const d = new Date();
		const now = new Date(`${d.getFullYear()},${d.getMonth() + 1},${d.getDate()}`).getTime();
		if (start > now) {
			Modal.error({
				title: "Start date error!",
				content: `Start date cannot be in the future.`
			});
			return;
		}
		if (end > now) {
			Modal.error({
				title: "End date error!",
				content: `End date cannot be in the future.`
			});
			return;
		}
		if (start > end) {
			Modal.error({
				title: "Start date error!",
				content: `Start date cannot be after end date.`
			});
			return;
		}
		const formData = {
			orgId: orgId,
			startDate: this.state.startDate,
			endDate: this.state.endDate
		};
		this.setState({ isLoading: true, isUsageLoading: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/admin.usageData",
						headers: { authorization: "Bearer " + idToken },
						form: formData
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							// return this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "" });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
							Modal.error({
								title: "Error getting usage data!"
								// content: `You must an Organization Id.`
							});
							return this.setState({ isLoading: false, isUsageLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							// return this.setState({ isModalLoading: false, isStripeLoading: false });
							Modal.error({
								title: "Error bad request!"
								// content: `You must an Organization Id.`
							});
							return this.setState({ isLoading: false, isUsageLoading: false });
						}
						// if request success
						const returnObj = JSON.parse(body);
						console.log("http request success: ", returnObj);
						console.log("usageRecords: ", returnObj.usageRecords);
						console.log("smsLogs: ", returnObj.smsLogs);
						// Modal.success({
						// 	title: "Success getting usage data!"
						// 	// content: `You must an Organization Id.`
						// });
						// const newListObj = JSON.parse(body);
						// newListObj.key = newListObj.id;
						// this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "", contactLists: [newListObj].concat(this.state.contactLists) });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
						this.setState({ isLoading: false, isUsageLoading: false, isUsageLoaded: true, activePanel: "", usageRecords: returnObj.usageRecords });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isLoading: false });
			});
	}

	getLogExports() {
		const orgId = this.props.match.params.orgId;
		if (!orgId) {
			Modal.error({
				title: "Missing Org Id!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		if (!this.state.startDate) {
			Modal.error({
				title: "Missing Start Date!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		if (!this.state.endDate) {
			Modal.error({
				title: "Missing End Date!"
				// content: `You must an Organization Id.`
			});
			return;
		}
		const start = moment(this.state.startDate, "YYYY-MM-DD").valueOf();
		const end = moment(this.state.endDate, "YYYY-MM-DD").valueOf();
		const d = new Date();
		const now = new Date(`${d.getFullYear()},${d.getMonth() + 1},${d.getDate()}`).getTime();
		if (start > now) {
			Modal.error({
				title: "Start date error!",
				content: `Start date cannot be in the future.`
			});
			return;
		}
		if (end > now) {
			Modal.error({
				title: "End date error!",
				content: `End date cannot be in the future.`
			});
			return;
		}
		if (start > end) {
			Modal.error({
				title: "Start date error!",
				content: `Start date cannot be after end date.`
			});
			return;
		}
		const formData = {
			orgId: orgId,
			startDate: this.state.startDate,
			endDate: this.state.endDate
		};
		this.setState({ isLoading: true, isLogsLoading: true });
		firebase
			.auth()
			.currentUser.getIdToken(/* forceRefresh */ true)
			.then(idToken => {
				request.post(
					{
						url: process.env.REACT_APP_API + "v2/admin.usageLogs",
						headers: { authorization: "Bearer " + idToken },
						form: formData
					},
					(err, httpResponse, body) => {
						/* ... */
						// console.log("err: ", err);
						// console.log("httpResponse: ", httpResponse);
						// console.log("httpResponse.statusCode: ", httpResponse.statusCode);
						// console.log("body: ", body);
						// if request error
						if (err) {
							console.log("http request error: ", err);
							// return this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "" });
							// return this.setState({ isOrgModalLoading: false, isStripeLoading: false });
							Modal.error({
								title: "Error getting usage data!"
								// content: `You must an Organization Id.`
							});
							return this.setState({ isLoading: false, isLogsLoading: false });
						}
						// if request invalid
						if (httpResponse.statusCode === 500) {
							console.log("http request invalid: ", JSON.parse(body));
							// return this.setState({ isOrgModalLoading: false, isModalVisible: false, isStripeLoading: false, stripeToken: {}, orgName: "" });
							// return this.setState({ isModalLoading: false, isStripeLoading: false });
							Modal.error({
								title: "Error bad request!"
								// content: `You must an Organization Id.`
							});
							return this.setState({ isLoading: false, isLogsLoading: false });
						}
						// if request success
						const returnObj = JSON.parse(body);
						// console.log("http request success: ", returnObj);
						console.log("get log records: ", returnObj);
						// console.log("usageRecords: ", returnObj.usageRecords);
						// console.log("smsLogs: ", returnObj.smsLogs);
						// Modal.success({
						// 	title: "Success getting usage data!"
						// 	// content: `You must an Organization Id.`
						// });
						// const newListObj = JSON.parse(body);
						// newListObj.key = newListObj.id;
						// this.setState({ isModalLoading: false, isModalVisible: false, listName: "", listCountryCode: "ca", areaCode1: "", areaCode2: "", contactLists: [newListObj].concat(this.state.contactLists) });
						// this.setState({ isOrgModalLoading: false, isStripeLoading: false, orgsList: [JSON.parse(body)].concat(this.state.orgsList) });
						this.setState({ isLoading: false, isLogsLoading: false, isLogsLoaded: true, activePanel: "", logRecords: returnObj });
					}
				);
			})
			.catch(error => {
				// Handle get user token error
				console.log("get user token error: ", error);
				this.setState({ isLoading: false });
			});
	}

	downloadFileToBrowser(downloadUrl) {
		if (!downloadUrl) {
			Modal.error({
				title: "No download url for this file!",
				content: `No logs available for export.`
			});
			return;
		}
		// if download url available, download file:
		window.location.href = downloadUrl;
	}

	render() {
		// console.log("this.props: ", this.props);

		const orgId = this.props.match.params.orgId;

		const smsLogs = this.state.logRecords.smsLogs;
		const { totalSms, totalInbound, totalOutbound, totalMms, totalInboundMms, totalOutboundMms, totalOutboundSent, totalOutboundDelivered, totalOutboundUndelivered, totalOutboundFailed } = smsLogs;

		const smsPrice = 0.05;
		const mmsPrice = 0.08;
		const srcNumPrice = 2.0;
		const usdToCad = 1.33;

		return (
			<Content>
				{/* <Breadcrumb style={{ margin: "0px 0 16px 0" }}>
					<Breadcrumb.Item>All Orgs</Breadcrumb.Item>
					<Breadcrumb.Item>North Hill Paints</Breadcrumb.Item>
					<Breadcrumb.Item>Billing</Breadcrumb.Item>
					<Breadcrumb.Item>History</Breadcrumb.Item>
				</Breadcrumb> */}
				<div className="card1  p0" style={{ background: "#fff", marginBottom: "24px" }}>
					<div className="maxW p12">
						<b style={{ fontSize: "16px" }}>Billing</b>
					</div>

					<Divider className="m0" />
					<div className="maxW p0 autoX">
						<BillingMenu selectedKey="usage" orgId={orgId} />
					</div>
				</div>

				{/* <div className="card1" style={{ background: "#fff", textAlign: "left", marginBottom: "24px" }}>
					<div className="" style={{ width: "100%", background: "", padding: "12px" }}>
						<b style={{ fontSize: "16px" }}>2087 Contacts</b>
					</div>
					<Divider style={{ margin: "0px" }} />
					<div className="" style={{ width: "100%", background: "#f5f5f5", padding: "12px" }}>
						<span style={{}}>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal} style={{ marginRight: "8px" }}>
								Add Contact
							</Button>
							<Button type="" icon="plus" size={"default"} onClick={this.showModal}>
								Import
							</Button>
						</span>
						<Search placeholder="Search Lists" onSearch={value => console.log(value)} style={{ width: "100%", maxWidth: "300px", float: "right" }} enterButton />
					</div>
				</div> */}

				<Collapse activeKey={this.state.activePanel} onChange={e => this.handleCollapse(e)} className="mb24 card1">
					<Panel header="Select Usage Dates" key="1" className="">
						<p className="bold">Start Date (UTC):</p>
						<div className="pb8 ">
							Date: <b>{moment(this.state.startDate, "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}</b>
						</div>
						<p className="">
							<Button icon="left" onClick={e => this.changeDate("startDate", "back")} className="mr8" />
							<input type="date" name="startDate" value={this.state.startDate} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} />
							<Button icon="right" onClick={e => this.changeDate("startDate", "forward")} />
						</p>
						<p>
							<Button icon="" onClick={e => this.changeDate("startDate", "back1Week")} className="mr8">
								-1 Week
							</Button>
							<Button icon="" onClick={e => this.changeDate("startDate", "back1Month")} className="mr8">
								-1 Month
							</Button>
							<Button icon="" onClick={e => this.changeDate("startDate", "now")} className="mr8">
								Today
							</Button>
						</p>
						<Divider style={{ margin: "32px 0px 24px" }} />
						<p className="bold">End Date (UTC):</p>
						<div className="pb8 ">
							Date: <b>{moment(this.state.endDate, "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}</b>
						</div>
						<p className="">
							<Button icon="left" onClick={e => this.changeDate("endDate", "back")} className="mr8" />
							<input type="date" name="endDate" value={this.state.endDate} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} />
							<Button icon="right" onClick={e => this.changeDate("endDate", "forward")} />
						</p>
						<Divider style={{ margin: "32px 0px 24px" }} />
						<p>
							<Button
								className="mr8 mb8"
								icon="search"
								type="primary"
								loading={this.state.isLoading}
								onClick={e => {
									this.getUsageLogs();
									this.getLogExports();
								}}>
								Get Both Usage & Logs
							</Button>
							<br />
							<Button className="mr8" icon="search" type="primary" loading={this.state.isUsageLoading} onClick={e => this.getUsageLogs()}>
								Get Usage Totals
							</Button>
							<Button className="mr8" icon="search" type="primary" loading={this.state.isLogsLoading} onClick={e => this.getLogExports()}>
								Get Log Exports
							</Button>
						</p>
					</Panel>
				</Collapse>

				{/* <Row gutter={24}>
					<Col md={12} xs={24}>
						<Card className="card1" title="Start Date (UTC)" style={{ margin: "auto", marginBottom: "24px" }}>
							<div className="pb8 ">
								Date: <b>{moment(this.state.startDate, "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}</b>
							</div>
							<p className="">
								<Button icon="left" onClick={e => this.changeDate("startDate", "back")} className="mr8" />
								<input type="date" name="startDate" value={this.state.startDate} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} />
								<Button icon="right" onClick={e => this.changeDate("startDate", "forward")} />
							</p>
						</Card>
					</Col>
					<Col md={12} xs={24}>
						<Card className="card1" title="End Date (UTC)" style={{ margin: "auto", marginBottom: "24px" }}>
							<div className="pb8 ">
								Date: <b>{moment(this.state.endDate, "YYYY-MM-DD").format("dddd, MMMM DD YYYY")}</b>
							</div>
							<p className="">
								<Button icon="left" onClick={e => this.changeDate("endDate", "back")} className="mr8" />
								<input type="date" name="endDate" value={this.state.endDate} onChange={e => this.handleChange(e)} style={{ padding: "4px 8px", border: "1px solid #d9d9d9", borderRadius: "4px", background: "#fff", marginRight: "4px" }} />
								<Button icon="right" onClick={e => this.changeDate("endDate", "forward")} />
							</p>
						</Card>
					</Col>
				</Row> */}

				<Row gutter={24}>
					<Col md={12} xs={24}>
						{this.state.isUsageLoading && (
							<Card className="card1" title="Usage Totals" style={{ margin: "auto", marginBottom: "24px" }}>
								<p style={{ marginBottom: "0px" }}>
									<Icon type="loading" style={{ fontSize: "28px" }} />
									<br />
									Loading...
								</p>
							</Card>
						)}
						{this.state.isUsageLoaded && !this.state.isUsageLoading && (
							<Card className="card1" title="Usage Totals" style={{ margin: "auto", marginBottom: "24px" }}>
								{/* <p>
							<span className="bold">Totals</span>
							<br />${this.state.usageRecords.sms.usage * 0.05} &middot; {this.state.usageRecords.sms.usage} Total SMS
							<br />${this.state.usageRecords.mms.usage * 0.08} &middot; {this.state.usageRecords.mms.usage} Total MMS
							<br />${this.state.usageRecords.phonenumbers.count * 2.0} &middot; {this.state.usageRecords.phonenumbers.count} Total Source Numbers
							<br />${this.state.usageRecords.sms.count * 0.05} &middot; {this.state.usageRecords.calls.count} Total Calls
							<br />${this.state.usageRecords.totalprice.price} Total Price
						</p> */}
								<p>
									<span className="bold">SMS</span>
									<br />
									{parseInt(this.state.usageRecords.sms.usage).toFixed(0)} Total SMS Parts
									<br />${(this.state.usageRecords.sms.usage * smsPrice).toFixed(2)} CAD
								</p>
								<p>
									<span className="bold">MMS</span>
									<br />
									{parseInt(this.state.usageRecords.mms.usage).toFixed(0)} Total MMS Parts
									<br />${(this.state.usageRecords.mms.usage * mmsPrice).toFixed(2)} CAD
								</p>
								<p>
									<span className="bold">Source Numbers</span>
									<br />
									{parseInt(this.state.usageRecords.phonenumbers.count).toFixed(0)} Total Source Numbers
									<br />${(this.state.usageRecords.phonenumbers.count * srcNumPrice).toFixed(2)} CAD
								</p>
								<p>
									<span className="bold">Total Price</span>
									<br />${(this.state.usageRecords.sms.usage * smsPrice + this.state.usageRecords.mms.usage * mmsPrice + this.state.usageRecords.phonenumbers.count * srcNumPrice).toFixed(2)} CAD
									{/* <br />${(this.state.usageRecords.totalprice.price * usdToCad).toFixed(2)} CAD Cost */}
								</p>
								{/* <p>
							<Button className="" icon="search" type="primary">
								Get Usage Totals
							</Button>
						</p> */}
								<Divider style={{ margin: "16px 0px 0px", backgroundColor: "#d9d9d9" }} />
								<Collapse bordered={false} defaultActiveKey={[""]}>
									<Panel header="Pricing Rates" key="1">
										<p>
											<span className="bold">SMS Rates:</span>
											<br />${smsPrice} CAD per SMS Part
											<br />${mmsPrice} CAD per MMS Part
										</p>
										<p>
											<span className="bold">Phone Rates:</span>
											<br />${srcNumPrice} CAD per Source Number per Month
										</p>
									</Panel>
								</Collapse>
								{/* <Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">SMS Rates:</span>
									<br />${smsPrice} CAD per SMS Part
									<br />${mmsPrice} CAD per MMS Part
								</p>
								<p>
									<span className="bold">Phone Rates:</span>
									<br />${srcNumPrice} CAD per Source Number per Month
								</p> */}
								{/* <Divider style={{ margin: "16px 0px" }} /> */}
								<br />
								<Button className="mr8" icon="credit-card" type="">
									Email Invoice
								</Button>
							</Card>
						)}
					</Col>
					<Col md={12} xs={24}>
						{this.state.isLogsLoading && (
							<Card className="card1" title="Log History" style={{ margin: "auto", marginBottom: "24px" }}>
								<p style={{ marginBottom: "0px" }}>
									<Icon type="loading" style={{ fontSize: "28px" }} />
									<br />
									Loading...
								</p>
							</Card>
						)}
						{this.state.isLogsLoaded && !this.state.isLogsLoading && (
							<Card className="card1" title="Log History" style={{ margin: "auto", marginBottom: "24px" }}>
								<p>
									<span className="bold">Totals</span>
									<br />
									{totalSms} SMS
									<br />
									{totalMms} MMS
								</p>
								{/* <p>{this.state.logRecords.smsLogs.totalMms} Total MMS</p> */}
								{/* {totalSms,totalInbound,totalOutbound,totalMms,totalInboundMms,totalOutboundMms,totalOutboundSent,totalOutboundDelivered,totalOutboundUndelivered,totalOutboundFailed,aaa,aaa,aaa,aaa,aaa,} */}
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Inbound vs Outbound</span>
									<br />
									{totalInbound} SMS In &middot;&nbsp;
									{totalInbound && totalSms ? ((totalInbound / totalSms) * 100).toFixed(1) : 0}%
									<br />
									{totalOutbound} SMS Out &middot;&nbsp;
									{totalInbound && totalSms ? ((totalOutbound / totalSms) * 100).toFixed(1) : 0}%
								</p>
								<p>
									{totalInboundMms} MMS In &middot;&nbsp;
									{totalInboundMms && totalMms ? ((totalInboundMms / totalMms) * 100).toFixed(1) : 0}%
									<br />
									{totalOutboundMms} MMS Out &middot;&nbsp;
									{totalOutboundMms && totalMms ? ((totalOutboundMms / totalMms) * 100).toFixed(1) : 0}%
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Outbound Stats</span>
									<br />
									{totalOutboundSent ? totalOutboundSent : 0} Sent &middot;&nbsp;
									{totalOutboundSent && totalSms ? ((totalOutboundSent / totalOutbound) * 100).toFixed(1) : 0}% &nbsp;
									<Tooltip title="Sent but no delivery confirmation">
										<Icon type="info-circle" className="tgrey pointer" />
									</Tooltip>
									{/* <br />
									<span className="tgrey">Sent but no delivery confirmation</span> */}
								</p>
								<p>
									{totalOutboundDelivered ? totalOutboundDelivered : 0} Delivered &middot;&nbsp;
									{totalOutboundDelivered && totalSms ? ((totalOutboundDelivered / totalOutbound) * 100).toFixed(1) : 0}% &nbsp;
									<Tooltip title="Sent with delivery confirmation">
										<Icon type="info-circle" className="tgrey pointer" />
									</Tooltip>
									{/* <br />
									<span className="tgrey">Sent with delivery confirmation</span> */}
								</p>
								<p>
									{totalOutboundUndelivered ? totalOutboundUndelivered : 0} Undelivered &middot;&nbsp;
									{totalOutboundUndelivered && totalSms ? ((totalOutboundUndelivered / totalOutbound) * 100).toFixed(1) : 0}% &nbsp;
									<Tooltip title="Sent with undelivered confirmation [carrier filtering, unavailable handset, etc]">
										<Icon type="info-circle" className="tgrey pointer" />
									</Tooltip>
									{/* <br />
									<span className="tgrey">Sent with undelivered confirmation [carrier filtering, unavailable handset, etc]</span> */}
								</p>
								<p>
									{totalOutboundFailed ? totalOutboundFailed : 0} Failed &middot;&nbsp;
									{totalOutboundFailed && totalSms ? ((totalOutboundFailed / totalOutbound) * 100).toFixed(1) : 0}% &nbsp;
									<Tooltip title="Not sent [twilio queue overflow, accnt suspensions, mms/media errors] - You are NOT charged for failed messages">
										<Icon type="info-circle" className="tgrey pointer" />
									</Tooltip>
									{/* <br />
									<span className="tgrey">Not sent [twilio queue overflow, accnt suspensions, mms/media errors] - You are NOT charged for failed messages</span> */}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<p>
									<span className="bold">Source Numbers</span>
									<br />
									{this.state.logRecords.sourceNumberLogs.totalSourceNumbers ? this.state.logRecords.sourceNumberLogs.totalSourceNumbers : 0} Total Source Numbers
									{/* <br />${((this.state.logRecords.sourceNumberLogs.totalSourceNumbers ? this.state.logRecords.sourceNumberLogs.totalSourceNumbers : 0) * srcNumPrice).toFixed(2)} CAD */}
								</p>
								<Divider style={{ margin: "16px 0px" }} />
								<Button className="mr8 mb8" icon="mail" type="">
									Email Logs To Address
								</Button>
								<Divider style={{ margin: "16px 0px" }} />
								{this.state.logRecords.smsLogs.downloadUrl && (
									<Button
										className="mr8 mb8"
										icon="bars"
										type=""
										onClick={e => {
											this.downloadFileToBrowser(this.state.logRecords.smsLogs.downloadUrl);
										}}>
										Download SMS Logs
									</Button>
								)}
								{this.state.logRecords.contactLogs.downloadUrl && (
									<Button
										className="mr8 mb8"
										icon="team"
										type=""
										onClick={e => {
											this.downloadFileToBrowser(this.state.logRecords.contactLogs.downloadUrl);
										}}>
										Download Contact Logs
									</Button>
								)}

								{this.state.logRecords.sourceNumberLogs.downloadUrl && (
									<Button
										className="mr8 mb8"
										icon="phone"
										type=""
										onClick={e => {
											this.downloadFileToBrowser(this.state.logRecords.sourceNumberLogs.downloadUrl);
										}}>
										Download Source Number Logs
									</Button>
								)}

								{/* {this.state.logRecords.sourceNumberLogs.downloadUrl && (
									<Button
										className="mr8 mb8"
										icon="download"
										type=""
										onClick={e => {
											this.downloadFileToBrowser(this.state.logRecords.sourceNumberLogs.downloadUrl);
										}}>
										Download Source Number Logs
									</Button>
								)} */}
							</Card>
						)}
					</Col>
				</Row>

				<Modal
					title="New Contact List"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" icon="close" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" icon="check" loading={this.state.loading} onClick={this.handleOk}>
							Create List
						</Button>
					]}>
					<p>Org Name: </p>
					<p>Credit Card: </p>
					<p>All organizations require a valid credit card. Once you have created an organization, you will be billed in 30 days, and each month thereafter, based on our monthly billing rates.</p>
				</Modal>

				{/* <Table className="card1 bgWhite" columns={columns} dataSource={data} /> */}
			</Content>
		);
	}
}
