import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

// class App extends Component {
export class BillingMenu extends React.Component {
	render() {
		// console.log("ContactListMenu: this.props: ", this.props);
		const orgId = this.props.orgId;
		// const listId = this.props.listId;

		return (
			<ul className={`ant-menu ant-menu-light ant-menu-root ant-menu-horizontal`}>
				{/* <li  className={`ant-menu-item ${this.props.selectedKey === 'current' ? 'ant-menu-item-selected' : ''}`}>
					<Link to={`/orgs/${orgId}/billing/current`}>Current</Link>
				</li> */}
				<li className={`ant-menu-item ${this.props.selectedKey === "usage" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/usage`}>Usage</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "numbers" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/numbers`}>Numbers</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "logs" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/logs`}>Logs</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "history" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/history`}>History</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "plan" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/plan`}>Plan</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "paymentInfo" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/paymentInfo`}>Payment Info</Link>
				</li>
				<li className={`ant-menu-item ${this.props.selectedKey === "cancel" ? "ant-menu-item-selected" : ""}`}>
					<Link to={`/orgs/${orgId}/billing/cancel`}>Cancel</Link>
				</li>
			</ul>
			// <Menu onClick={this.handleClick} selectedKeys={[this.props.selectedKey]} mode="horizontal">
			// 	<Menu.Item key="current">
			// 		<Link to={`/orgs/${orgId}/billing/current`}>Current</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="history">
			// 		<Link to={`/orgs/${orgId}/billing/history`}>History</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="plan">
			// 		<Link to={`/orgs/${orgId}/billing/plan`}>Plan</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="paymentInfo">
			// 		<Link to={`/orgs/${orgId}/billing/paymentInfo`}>Payment Info</Link>
			// 	</Menu.Item>
			// 	<Menu.Item key="cancel">
			// 		<Link to={`/orgs/${orgId}/billing/cancel`}>Cancel</Link>
			// 	</Menu.Item>
			// </Menu>
		);
	}
}
